import { Menu, Transition } from "@headlessui/react";
import {
  GetCampaignByIdQuery,
  GetCampaignLeadProfilesQuery,
  GetCampaignTypesQuery,
  GetDoorKnockingCampaignByIdQuery,
  GetMessageSetsQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { CheckCircleIcon, EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { classNames } from "../../../../../shared/internals/utils";
import { UpdateCampaignMessageSetMutationOptions } from "../../graphql/hooks/useUpdateCampaignMessageSet";
import { DeleteCampaignMessageSetMutationOptions } from "../../graphql/hooks/useDeleteCampaignMessageSet";
import toast from "react-hot-toast";
import EditMessageModal from "./EditMessageModal";
import { UseMutateAsyncFunction } from "react-query";
import { User } from "firebase/auth";
import { CreateMessageSetBody } from "../../../doorKockingCampaign/api/createMessageSet/createMessageSet";

interface ActiveMessagePanelProps {
  campaign:
    | GetCampaignByIdQuery["campaign_by_pk"]
    | GetDoorKnockingCampaignByIdQuery["campaign_by_pk"];
  campaignLeadProfiles: GetCampaignLeadProfilesQuery["campaign_lead_profile"];
  messageSets: GetMessageSetsQuery["message_set"];
  deleteCampaignMessageSet: (options: DeleteCampaignMessageSetMutationOptions) => Promise<void>;
  createMessageSet: UseMutateAsyncFunction<number, any, CreateMessageSetBody, void>;
  createMessageSetLoading: boolean;
  updateCampaignMessageSet: (options: UpdateCampaignMessageSetMutationOptions) => Promise<void>;
  campaignTypes: GetCampaignTypesQuery["campaign_type"];
  user: User;
  isFreelancer: boolean;
}

const ActiveMessagePanel = ({
  campaign,
  campaignLeadProfiles,
  user,
  campaignTypes,
  createMessageSet,
  createMessageSetLoading,
  deleteCampaignMessageSet,
  messageSets,
  isFreelancer,
  updateCampaignMessageSet,
}: ActiveMessagePanelProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const removeTemplate = async () => {
    if (!campaign) {
      toast.error("Campaign not found, refresh.");
    } else {
      await deleteCampaignMessageSet({ campaignId: campaign.id });
    }
  };
  const selectedMessageSet = messageSets.find(set => set.id === campaign?.message_set?.id);
  return (
    <>
      {selectedMessageSet && isEditing && (
        <EditMessageModal
          campaign={campaign}
          messageSet={selectedMessageSet}
          user={user}
          isOpen={isEditing}
          campaignTypes={campaignTypes}
          setOpen={setIsEditing}
          updateCampaignMessageSet={updateCampaignMessageSet}
          createMessageSetLoading={createMessageSetLoading}
          createMessageSet={createMessageSet}
        />
      )}
      <div className="mt-10">
        <div className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
          <div className="flex items-center border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
            <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
              <div>
                <dt className="font-medium text-gray-900">Messaging</dt>
                <dd className="mt-1 text-gray-500">{selectedMessageSet?.name}</dd>
              </div>
              {selectedMessageSet?.user && (
                <div className="hidden sm:block">
                  <dt className="font-medium text-gray-900">Created By</dt>
                  <dd className="mt-1 text-gray-500">
                    {" "}
                    {isFreelancer ? "internal user" : selectedMessageSet.user.email}
                  </dd>
                </div>
              )}

              <div>
                <dt className="font-medium text-gray-900">Tags</dt>
                <dd className="mt-1 font-medium text-gray-900">
                  {selectedMessageSet?.is_public && (
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      Public
                    </span>
                  )}
                  {selectedMessageSet?.message_set_campaign_types.map(campaignType => {
                    return (
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {campaignType.campaign_type.name} {campaignType.is_default && " - Default"}
                      </span>
                    );
                  })}
                </dd>
              </div>
            </dl>

            <Menu as="div" className="relative flex justify-end lg:hidden">
              <div className="flex items-center">
                <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
                  <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            setIsEditing(true);
                          }}
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Edit
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => removeTemplate()}
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Remove Template
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
              <button
                type="button"
                onClick={() => {
                  setIsEditing(true);
                }}
                className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              >
                <span>Edit</span>
              </button>
              <button
                onClick={() => removeTemplate()}
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              >
                <span>Remove Template</span>
              </button>
            </div>
          </div>

          {/* Products */}
          <h4 className="sr-only">Items</h4>
          <ul role="list" className="divide-y divide-gray-200">
            {selectedMessageSet?.messages &&
              selectedMessageSet.messages?.length > 0 &&
              [...selectedMessageSet.messages]
                .sort((a, b) => a.msg_order - b.msg_order)
                .map(message => (
                  <li key={message.id} className="p-4 sm:p-6">
                    <div className="flex items-center sm:items-start">
                      <div className="ml-6 flex-1 text-sm">
                        <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                          <h5>Message {message.msg_order}</h5>
                          <p className="mt-2 text-gray-600 text-xs sm:mt-0">
                            After {message.send_after_days} Day(s)
                          </p>
                        </div>
                        <p className="hidden text-gray-500 sm:mt-2 sm:block">{message.content}</p>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ActiveMessagePanel;
