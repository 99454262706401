import { ApolloError } from "@apollo/client";
import {
  GetCampaignByIdQuery,
  useGetCampaignByIdQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { uniqBy } from "lodash";
import { ArrayElement } from "../../../../../shared/internals/utils";
import {
  CONSTRUCTED_OWNER_STATUS,
  SEARCH_TYPE,
} from "../../../../../shared/internals/constants/models";
import dayjs from "dayjs";
import { SEARCH_TYPE_RANK } from "../../data";

export type CampaignLead = ArrayElement<UseCampaignByIdReturnType["fixedCampaignLeads"]>;
export type UseCampaignByIdReturnType = ReturnType<typeof useCampaignById>;

export const useCampaignById = ({ id }: { id: number }) => {
  const { data, error, loading, refetch } = useGetCampaignByIdQuery({
    variables: {
      id,
    },
    skip: id === -1,
  });

  const refetchCampaign = async () => {
    await refetch();
  };

  // First, merge suspected_owners and create a processed list
  const processedLeads = data?.campaign_by_pk?.campaign_leads
    ? data.campaign_by_pk.campaign_leads.map(lead => {
        const addressSuspectedOwners = lead.address.suspected_owner_addresses.flatMap(
          suspectedAddress => ({
            owner: suspectedAddress.suspected_owner,
            suspected_owner_address: suspectedAddress,
          })
        );

        // add user provided owners

        // Merge and remove duplicates
        const allOwners = [...addressSuspectedOwners];
        // let uniqueOwners = uniqBy(allOwners, owner => owner.id);

        // Assign constructed_status and additional properties to each owner
        const augmentedOwners = allOwners
          .sort((a, b) => {
            // Check if end_date is undefined and handle the sorting
            if (
              typeof a.owner.end_date === "undefined" &&
              typeof b.owner.end_date === "undefined"
            ) {
              return 0; // Both are undefined, keep original order
            } else if (typeof a.owner.end_date === "undefined") {
              return -1; // a should come before b
            } else if (typeof b.owner.end_date === "undefined") {
              return 1; // b should come before a
            } else if (dayjs(a.owner.end_date).isSame(dayjs(b.owner.end_date))) {
              // If end_dates are the same, sort by start_date
              return dayjs(b.owner.start_date).isAfter(dayjs(a.owner.start_date)) ? 1 : -1;
            } else {
              // Sort by end_date
              return dayjs(b.owner.end_date).isAfter(dayjs(a.owner.end_date)) ? 1 : -1;
            }
          })
          .map(owner => {
            const address_match_quality = owner.suspected_owner_address.address_match_quality;
            const address_match_score = owner.suspected_owner_address.address_match_score;
            const is_correct_address = owner.suspected_owner_address.is_correct_address;
            const is_false_address = owner.suspected_owner_address.is_false_address;
            const address = lead.address;
            const id = owner.suspected_owner_address.id;

            // Determine constructed_status
            const constructed_status = is_correct_address
              ? CONSTRUCTED_OWNER_STATUS.VALIDATED_LEAD_ADDRESS
              : is_false_address
              ? CONSTRUCTED_OWNER_STATUS.REJECTED_LEAD_ADDRESS
              : CONSTRUCTED_OWNER_STATUS.NOT_VALIDATED;

            const digital_profiles = owner.suspected_owner_address.owner_address_profiles.map(
              owner_address_profile => {
                const searches = [
                  ...owner_address_profile.digital_profile.search_digital_profiles,
                ].sort((a, b) => {
                  const rankA = SEARCH_TYPE_RANK[a.search.search_type.id as SEARCH_TYPE];
                  const rankB = SEARCH_TYPE_RANK[b.search.search_type.id as SEARCH_TYPE];
                  return rankA - rankB;
                });

                return {
                  ...owner_address_profile.digital_profile,
                  digital_profile_status: owner_address_profile.digital_profile_status,
                  owner_address_profile_id: owner_address_profile.id,
                  owner_address_profile: owner_address_profile,
                  search: searches[0]?.search,
                };
              }
            );

            return {
              ...owner.owner,
              suspected_owner_status: owner.suspected_owner_address.suspected_owner_status,
              suspected_owner_address: { ...owner.suspected_owner_address, address },
              constructed_status,
              digital_profiles,
              matching_address: {
                id,
                address_match_quality,
                address_match_score,
                is_correct_address,
                is_false_address,
                address,
              },
            };
          });

        const status = lead.is_withdrawn
          ? "Withdrawn"
          : lead.is_under_offer
          ? "Under Offer"
          : lead.is_fall_through
          ? "Fall Through"
          : lead.is_reduced
          ? "Reduced"
          : lead.is_on_market
          ? "On Market"
          : lead.is_on_market === false
          ? "Off Market"
          : "Unknown Market Status";

        return {
          ...lead,
          status: status,
          address: {
            ...lead.address,
            suspected_owners: augmentedOwners,
          },
        };
      })
    : [];

  // // Now, create campaignLeads by sorting processedLeads based on address_match_score
  // const campaignLeads = [...processedLeads].sort(
  //   (leadA, leadB) => leadA.address_match_score - leadB.address_match_score
  // );

  // Similarly, create fixedCampaignLeads by sorting processedLeads based on id
  const fixedCampaignLeads = [...processedLeads].sort((leadA, leadB) => leadA.id - leadB.id);
  const estateAgentSet = new Set(fixedCampaignLeads.map(lead => lead.agent));

  const estateAgentOptions = Array.from(estateAgentSet).map((agent, index) => ({
    label: agent ?? ("No Estate Agent" as string),
    value: agent,
  }));

  return {
    campaign: data && data.campaign_by_pk,
    campaignError: error,
    campaignLoading: loading,
    // sortedCampaignLeads: campaignLeads,
    estateAgentOptions,
    fixedCampaignLeads: fixedCampaignLeads,
    refetch: refetchCampaign,
  };
};
