import { ApolloError } from "@apollo/client";
import { useUpdateCampaignLeadAddressMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";

export interface HookResult {
  updateCampaignLeadAddress: (options: UpdateCampaignLeadAddressMutationOptions) => Promise<void>;
}

export interface UpdateCampaignLeadAddressMutationOptions {
  campaignLeadId: number;
  addressId: number;
}

export interface Options {
  onError: (error: ApolloError) => void;
}

export const useUpdateCampaignLeadAddress = (): HookResult => {
  const [updateAddressMutation] = useUpdateCampaignLeadAddressMutation({
    onError: error => {
      throw error;
    },
  });

  return {
    updateCampaignLeadAddress: async ({
      campaignLeadId,
      addressId,
    }: UpdateCampaignLeadAddressMutationOptions) => {
      await updateAddressMutation({
        variables: {
          id: campaignLeadId,
          address_id: addressId,
        },
      });
    },
  };
};
