import { gql } from "@apollo/client";

// TODO MUST: STRUCTURE OF THIS QUERY IS BAD.

export const getCampaignById = gql`
  query getCampaignById($id: Int!) {
    campaign_by_pk(id: $id) {
      id
      outreach_agent {
        id
        external_id
      }
      name

      campaign_internal_status {
        id
        name
      }
      campaign_filters {
        id
        lower_bound
        upper_bound
        value
        campaign_filter_type {
          id
          name
        }
      }
      note

      lead_list_url
      created_at
      campaign_status {
        id
        name
      }
      campaign_leads(where: { deleted_at: { _is_null: true } }) {
        additional_info
        last_address_match_attempt
        is_address_validated
        address_match_score
        one_line_price
        prospecting_tool_provider_id
        public_url
        agent
        is_withdrawn
        is_under_offer
        is_fall_through
        is_reduced
        is_on_market
        bedrooms
        bathrooms
        stated_address
        id
        address_match_quality {
          id
          name
        }
        address {
          full_address
          id
          last_pa_search
          last_cho_search
          last_er_search
          last_lt_search
          last_epc_search
          property_sales(order_by: { sale_date: desc }) {
            id
            sale_date
            external_id
            sale_price
            stated_address
          }
          suspected_owner_addresses {
            address_match_score
            address_match_quality {
              id
              name
            }
            id
            is_correct_address
            is_false_address
            suspected_owner_status {
              id
              name
            }
            owner_address_profiles {
              id
              distance_km

              digital_profile_status {
                id
                name
              }
              campaign_lead_profile {
                id
                deleted_at
                owner_address_profile_id
              }
              digital_profile {
                digital_profile_type {
                  id
                  name
                }
                campaign_lead_profile {
                  id
                  deleted_at
                  owner_address_profile_id
                }
                geocoded_place_name
                primary_additional_info
                id
                name
                note
                value
                search_digital_profiles {
                  search {
                    search_type {
                      id
                      name
                    }
                  }
                }
              }
            }

            suspected_owner {
              id
              name
              start_date
              end_date
              last_li_search
              is_not_person
              last_address_match_attempt
              first_name
              last_name
              additional_info
              stated_address
              ownership_source {
                name
                id
              }
            }
          }

          postcode_unit {
            code
            id
            local_authority {
              id
              name
              has_search_enabled
              base_search_url
            }
            postcode_district {
              id
              code
              postcode_area {
                id
                code
              }
            }
          }
          additional_info
          campaign_leads_aggregate(
            where: { campaign_id: { _neq: $id }, deleted_at: { _is_null: true } }
          ) {
            aggregate {
              count
            }
          }
        }
      }

      campaign_type {
        name
        id
      }
      workspace {
        name
      }
      message_set {
        id
        name
        user {
          id
          email
        }
        is_public
        message_set_campaign_types {
          campaign_type {
            id
            name
          }
          id
          is_default
        }
        messages {
          msg_order
          content
          id
        }
      }
    }
  }
`;
