import { gql } from "@apollo/client";

export const getSearchGroupByCampaignId = gql`
  query getSearchGroupByCampaignId($campaignId: Int!) {
    search_group(
      where: { campaign_id: { _eq: $campaignId } }
      order_by: { created_at: desc } # This line orders the results by the created_at date in descending order
    ) {
      id
      is_cancelled
      is_completed
      is_error
      created_at
      phantom_agent_external_id
      searches {
        has_been_searched
        id
        profiles_returned
      }
    }
  }
`;
