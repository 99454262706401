import { gql } from "@apollo/client";

export const getTrackingGroupsByUSCampaignId = gql`
  query getTrackingGroupsByUSCampaignId($usCampaignId: Int!) {
    tracking_group(
      where: { us_campaign_id: { _eq: $usCampaignId } }
      order_by: { created_at: desc } # This line orders the results by the created_at date in descending order
    ) {
      tracking_group_type_id
      id
      is_cancelled
      is_completed
      is_error
      is_paused
      created_at
      tracking_items {
        is_completed
        id
      }
    }
  }
`;
