import {
  GetCampaignByIdQuery,
  GetTrackingGroupsByCampaignIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  Bold,
  Button,
  Callout,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionList,
  Text,
  Badge,
  Divider,
  BarList,
  Title,
  Icon,
} from "@tremor/react";

import {
  AdjustmentsVerticalIcon,
  BriefcaseIcon,
  ShieldCheckIcon,
  HandThumbDownIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
  CheckIcon,
  CalculatorIcon,
  MagnifyingGlassCircleIcon,
  SwatchIcon,
} from "@heroicons/react/24/solid";

import { CampaignLead } from "../graphql/hooks/useCampaignById";
import { GetPostcodeUnitByIdLazyHookResult } from "../graphql/hooks/usePostcodeUnitById";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EPC_CATEGORY,
  OWNER_CLEANING_SHOW_TABS,
  OWNER_COUNT,
  OWNER_SEARCH,
  UpdateMatchedAddressValues,
  UpdateOwnerNamesValues,
} from "../types";
import { updateMatchedAddressValidationSchema } from "../validation";
import Select from "react-select";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import {
  ADDRESS_MATCH_QUALITY,
  CONSTRUCTED_OWNER_STATUS,
  OWNERSHIP_SOURCE,
  OWNER_ADDRESS_VALIDATION_TYPE,
  SUSPECTED_OWNER_STATUS,
  TRACKING_GROUP_TYPE,
} from "../../../../shared/internals/constants/models";
import { ArrayElement, classNames } from "../../../../shared/internals/utils";
import { epcStatuses, suspectedOwnerCleanFilters, suspectedOwnerCleanRadioOptions } from "../data";
import { Menu, RadioGroup, Switch, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";
import Alert from "../../../../shared/internals/components/alert/Alert";
import { filtersStyling } from "../styles";
import { ValidateOwnerAddressBody } from "../api/validateOwnerAddress";
import { UseMutateAsyncFunction } from "react-query";
import BulkOwnerAddressMatcher from "./suspectedOwnerCleaning/BulkOwnerAddressMatcher";
import dayjs from "dayjs";
import { AutoValidateOwnerAddressessBody } from "../api/autoValidateOwnerAddresses";
import { TriggerTrackingGroupBody } from "../api/triggerTrackingGroup";
import { CancelTrackingGroupAPIOptions } from "../api/cancelTrackingGroup";
import { PauseTrackingGroupAPIOptions } from "../api/pauseTrackingGroup";

interface CampaignSummaryTabProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"];
  campaignLeads: CampaignLead[];
  getPostcodeUnitById: GetPostcodeUnitByIdLazyHookResult["getPostcodeUnitById"];
  postcodeUnit: GetPostcodeUnitByIdLazyHookResult["postcodeUnit"];

  suspectedOwnerAddressUpdater: (suspectedOwnerId: number, addressId: number) => Promise<void>;
  suspectedOwnerNamesUpdater: (
    suspectedOwnerId: number,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  validateOwnerAddress: UseMutateAsyncFunction<any, any, ValidateOwnerAddressBody, void>;
  validateOwnerAddressLoading: boolean;
  autoValidateOwnerAddresses: UseMutateAsyncFunction<
    any,
    any,
    AutoValidateOwnerAddressessBody,
    void
  >;
  triggerTrackingGroup: UseMutateAsyncFunction<any, any, TriggerTrackingGroupBody, void>;
  triggerTrackingGroupLoading: boolean;

  incompleteMatchOwnersGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  lastIncompleteMatchOwnersGroup?: ArrayElement<
    GetTrackingGroupsByCampaignIdQuery["tracking_group"]
  >;
  matchOwnersGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];

  cancelTrackingGroup: UseMutateAsyncFunction<any, any, CancelTrackingGroupAPIOptions, void>;
  pauseTrackingGroup: UseMutateAsyncFunction<any, any, PauseTrackingGroupAPIOptions, void>;
  pauseTrackingGroupLoading: boolean;
  cancelTrackingGroupLoading: boolean;
}

const SuspectedOwnersSearchTab = ({
  campaignLeads,
  campaign,
  postcodeUnit,
  getPostcodeUnitById,
  suspectedOwnerAddressUpdater,
  validateOwnerAddressLoading,
  autoValidateOwnerAddresses,
  suspectedOwnerNamesUpdater,
  validateOwnerAddress,
  matchOwnersGroups,
  pauseTrackingGroup,
  pauseTrackingGroupLoading,
  cancelTrackingGroup,
  cancelTrackingGroupLoading,
  triggerTrackingGroup,
  triggerTrackingGroupLoading,
}: CampaignSummaryTabProps) => {
  const leadsWithSelectableOwners = campaignLeads
    .filter(lead => lead.is_address_validated === true)
    .map(lead => {
      return {
        ...lead,
        address: {
          ...lead.address,
          suspected_owners: lead.address.suspected_owners
            .filter(owner => owner.name !== "Not Available")
            .map(owner => ({
              ...owner,
              isSelected: false,
            })),
        },
      };
    });
  const [isBulkMatching, setIsBulkMatching] = useState(false);

  const [filteredLeads, setFilteredLeads] = useState(leadsWithSelectableOwners);
  const [selectedOwner, setSelectedOwner] = useState<number | null>(null);
  const [selectedNamesOwner, setSelectedNamesOwner] = useState<{
    id: number;
    firstName: string;
    lastName: string;
  } | null>(null);

  const [selectAll, setSelectAll] = useState<{ status: boolean; changedByItemToggle: boolean }>({
    status: false,
    changedByItemToggle: false,
  });
  const [selectedRadioOption, setSelectedRadioOption] = useState(
    suspectedOwnerCleanRadioOptions[0]
  );
  const [activeEpcFilters, setActiveEpcFilters] = useState<{ label: string; value: string }[]>([]);
  const [activeOwnerSourceFilters, setActiveOwnerSourceFilters] = useState<
    { label: string; value: number }[]
  >([]);
  const [activeOwnerStatusFilters, setActiveOwnerStatusFilters] = useState<
    { label: string; value: number }[]
  >([]);
  const [activeOwnerSearchFilters, setActiveOwnerSearchFilters] = useState<
    { label: string; value: string }[]
  >([]);
  const [activeOwnerCountFilters, setActiveOwnerCountFilters] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (selectAll.status === true && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => {
        return {
          ...lead,
          address: {
            ...lead.address,
            suspected_owners: lead.address.suspected_owners.map(owner => ({
              ...owner,
              isSelected: true,
            })),
          },
        };
      });
      setFilteredLeads(updatedLeads);
    }

    if (selectAll.status === false && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => {
        return {
          ...lead,
          address: {
            ...lead.address,
            suspected_owners: lead.address.suspected_owners.map(owner => ({
              ...owner,
              isSelected: false,
            })),
          },
        };
      });
      setFilteredLeads(updatedLeads);
    }
  }, [selectAll.status]);

  useEffect(() => {
    let updatedLeads = [...leadsWithSelectableOwners];

    switch (selectedRadioOption.id) {
      case OWNER_CLEANING_SHOW_TABS.VALIDATED_LEAD_ADDRESS:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.address.suspected_owners.some(
              owner => owner.constructed_status === CONSTRUCTED_OWNER_STATUS.VALIDATED_LEAD_ADDRESS
            );
          })
          .map(lead => ({
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(
                owner =>
                  owner.constructed_status === CONSTRUCTED_OWNER_STATUS.VALIDATED_LEAD_ADDRESS
              ),
            },
          }));
        break;

      case OWNER_CLEANING_SHOW_TABS.REJECTED_LEAD_ADDRESS:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.address.suspected_owners.some(
              owner => owner.constructed_status === CONSTRUCTED_OWNER_STATUS.REJECTED_LEAD_ADDRESS
            );
          })
          .map(lead => ({
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(
                owner => owner.constructed_status === CONSTRUCTED_OWNER_STATUS.REJECTED_LEAD_ADDRESS
              ),
            },
          }));
        break;
      case OWNER_CLEANING_SHOW_TABS.UNVALIDATED:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.address.suspected_owners.some(
              owner => owner.constructed_status === CONSTRUCTED_OWNER_STATUS.NOT_VALIDATED
            );
          })
          .map(lead => ({
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(
                owner => owner.constructed_status === CONSTRUCTED_OWNER_STATUS.NOT_VALIDATED
              ),
            },
          }));
        break;
      default:
        break;
    }

    // address level filters
    if (activeEpcFilters.length > 0 || activeOwnerCountFilters.length > 0) {
      updatedLeads = updatedLeads.filter(lead => {
        let epcMatch = true;
        let ownerCountMatch = true;

        if (activeEpcFilters.length > 0) {
          const tenure = lead?.address?.additional_info?.epc?.tenure;
          epcMatch =
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.OWNER_OCCUPIED) &&
              epcStatuses.ownerOccupied.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.RENTED_SOCIAL) &&
              epcStatuses.rentedSocial.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.RENTED_PRIVATE) &&
              epcStatuses.rentedPrivate.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.UNDEFINED) &&
              epcStatuses.notDefined.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.NOT_AVAILABLE) &&
              !tenure);
        }

        if (activeOwnerCountFilters.length > 0) {
          const ownerCount = lead.address.suspected_owners.length;
          ownerCountMatch =
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.NONE) &&
              ownerCount === 0) ||
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.ONE) &&
              ownerCount === 1) ||
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.TWO_OR_MORE) &&
              ownerCount >= 2);
        }

        return epcMatch && ownerCountMatch;
      });
    }

    // owner level filters
    if (
      activeOwnerSourceFilters.length > 0 ||
      activeOwnerStatusFilters.length > 0 ||
      activeOwnerSearchFilters.length > 0
    ) {
      updatedLeads = updatedLeads
        .map(lead => {
          return {
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(owner => {
                let ownerSourceMatch =
                  activeOwnerSourceFilters.length === 0 ||
                  activeOwnerSourceFilters.some(
                    filter => filter.value === owner.ownership_source.id
                  );

                let ownerStatusMatch =
                  activeOwnerStatusFilters.length === 0 ||
                  activeOwnerStatusFilters.some(
                    filter => filter.value === owner.suspected_owner_status.id
                  );

                let ownerSearchMatch =
                  activeOwnerSearchFilters.length === 0 ||
                  activeOwnerSearchFilters.some(filter => {
                    switch (filter.value) {
                      case OWNER_SEARCH.LINKEDIN_SEARCHED:
                        return owner.last_li_search; // Assuming 'owner.linkedinSearched' is a boolean
                      case OWNER_SEARCH.LINKEDIN_NOT_SEARCHED:
                        return !owner.last_li_search;
                      case OWNER_SEARCH.ADDRESS_MATCHER_SEARCHED:
                        return owner.last_address_match_attempt; // Assuming 'owner.addressMatcherAttempted' is a boolean
                      case OWNER_SEARCH.ADDRESS_MATCHER_NOT_SEARCHED:
                        return !owner.last_address_match_attempt;
                      default:
                        return true;
                    }
                  });

                // Combining all owner level filters using AND logic
                return ownerSourceMatch && ownerStatusMatch && ownerSearchMatch;
              }),
            },
          };
        })
        .filter(lead => lead.address.suspected_owners.length > 0); // Hide addresses that have no remaining owners
    }

    if (selectAll.status === true) {
      // Handle the selectAll logic
      updatedLeads = updatedLeads.map(lead => {
        return {
          ...lead,
          address: {
            ...lead.address,
            suspected_owners: lead.address.suspected_owners.map(owner => ({
              ...owner,
              isSelected: true,
            })),
          },
        };
      });
    }

    setFilteredLeads(updatedLeads);
  }, [
    activeEpcFilters,
    activeOwnerCountFilters,
    activeOwnerSearchFilters,
    activeOwnerSourceFilters,
    activeOwnerStatusFilters,
    selectedRadioOption,
    campaignLeads,
  ]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateMatchedAddressValues>({
    resolver: yupResolver(updateMatchedAddressValidationSchema),
  });

  const {
    handleSubmit: handleNamesSubmit,
    register,
    reset: namesReset,
    formState: { errors: namesErrors },
  } = useForm<UpdateOwnerNamesValues>();

  useEffect(() => {
    // Reset the form values whenever owner.id changes
    if (selectedNamesOwner?.id) {
      namesReset({
        firstName: selectedNamesOwner.firstName,
        lastName: selectedNamesOwner.lastName,
      });
    }
  }, [selectedNamesOwner?.id]);

  const onSubmit = async ({ address }: UpdateMatchedAddressValues) => {
    if (!selectedOwner) {
      throw new Error("Missing lead");
    }
    await suspectedOwnerAddressUpdater(selectedOwner, address.value);
    setSelectedOwner(null);
  };

  const onNamesSubmit = async ({ firstName, lastName }: UpdateOwnerNamesValues) => {
    if (!selectedNamesOwner?.id) {
      throw new Error("Missing Owner");
    }
    await suspectedOwnerNamesUpdater(selectedNamesOwner.id, firstName, lastName);
    setSelectedNamesOwner(null);
  };

  const editOwner = async (lead: CampaignLead, ownerId: number) => {
    setSelectedOwner(ownerId);
    await getPostcodeUnitById({ variables: { id: lead.address.postcode_unit.id } });
  };

  const handleIndividualChange = (leadId: number, ownerId: number) => {
    // Find the specific lead and owner
    const lead = filteredLeads.find(lead => lead.id === leadId);
    const owner = lead?.address.suspected_owners.find(owner => owner.id === ownerId);
    const ownerWillBeSelected = !owner?.isSelected;

    // Update the leads
    const updatedLeads = filteredLeads.map(lead => {
      if (lead.id === leadId) {
        return {
          ...lead,
          address: {
            ...lead.address,
            suspected_owners: lead.address.suspected_owners.map(owner =>
              owner.id === ownerId ? { ...owner, isSelected: ownerWillBeSelected } : owner
            ),
          },
        };
      }
      return lead;
    });

    // If the owner is about to be selected, check if all owners will be selected
    if (ownerWillBeSelected) {
      const allOwnersSelected = updatedLeads.every(lead =>
        lead.address.suspected_owners.every(owner => owner.isSelected)
      );
      if (allOwnersSelected) {
        setSelectAll({ status: true, changedByItemToggle: true });
      }
    }
    // If the owner is about to be unselected and selectAll is currently true, update selectAll
    else if (selectAll.status) {
      setSelectAll({ status: false, changedByItemToggle: true });
    }

    // Update the filteredLeads state
    setFilteredLeads(updatedLeads);
  };

  const validatedCampaignAddressOwnersExclNameFiltered = leadsWithSelectableOwners
    .map(lead => lead.address.suspected_owners)
    .flat();

  const totalOwners = campaignLeads.map(lead => lead.address.suspected_owners).flat();

  const notValidatedOwnersCount = campaignLeads.reduce((count, lead) => {
    const notValidatedCount = lead.address.suspected_owners.filter(
      owner => owner.constructed_status === CONSTRUCTED_OWNER_STATUS.NOT_VALIDATED
    ).length;
    return count + notValidatedCount;
  }, 0);

  const filteredOwners = filteredLeads.map(lead => lead.address.suspected_owners).flat();

  return (
    <>
      <div className="flex w-full">
        <Callout
          title="Do the owners belong to the Campaign Adreess?"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>
            1. Click "Address Bulk Matching" and begin matching owners to their Campaign Address
          </div>
          <div>
            2. If the owner's stated address does not match the campaign address, simply click
            "false address".
          </div>
          <div>3. The remaining owners will be validated.</div>
          <div>
            NOTE: For owners who are companies or their first name / last name ought to be changed,
            this should now be done in the Owner Evaluation Tab. In that tab, simply reject Owners
            that are Companies and edit name using the "Edit Owner Name" Button.
          </div>
        </Callout>
      </div>

      <div className="mt-5">
        {matchOwnersGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              matchOwnersGroups[0].is_cancelled === true
                ? "red"
                : matchOwnersGroups[0].is_completed === true &&
                  matchOwnersGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              matchOwnersGroups[0].is_cancelled === true
                ? "Cancelled"
                : matchOwnersGroups[0].is_completed === true &&
                  matchOwnersGroups[0].is_cancelled === false
                ? "Completed"
                : matchOwnersGroups[0].is_error === true
                ? "In Error"
                : matchOwnersGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = matchOwnersGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = matchOwnersGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = matchOwnersGroups.slice(1).map(group => {
              const completedTrackingItemsCount = matchOwnersGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Owners Address Match ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(matchOwnersGroups[0].is_error || matchOwnersGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: matchOwnersGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!campaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: matchOwnersGroups[0].id,
                              campaignId: campaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.MATCH_OWNERS,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!matchOwnersGroups[0].is_error &&
                      !matchOwnersGroups[0].is_paused &&
                      !matchOwnersGroups[0].is_cancelled &&
                      !matchOwnersGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: matchOwnersGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} items completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Triggered:{" "}
                  <Bold>{dayjs(matchOwnersGroups[0].created_at).format("DD/MM/YYYY mm:ss")}</Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text> Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Requests"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>

      <div className="flex space-x-4 justify-between">
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 potential Owner</Text>
          <Metric>
            {(() => {
              const potentialOwnersCount = campaignLeads.filter(
                lead => lead.address.suspected_owners.length > 0
              ).length;
              const totalCount = campaignLeads.length;
              const percentage = ((potentialOwnersCount / totalCount) * 100).toFixed(0);
              return `${potentialOwnersCount} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>

        <Card className="max-w-xs mt-6" decoration="top" decorationColor="orange">
          <Text>Not Validated Owners / Total (excl. "Not Available") Owners</Text>
          <Metric>
            {notValidatedOwnersCount} / {validatedCampaignAddressOwnersExclNameFiltered.length} (
            {(
              (notValidatedOwnersCount / validatedCampaignAddressOwnersExclNameFiltered.length) *
              100
            ).toFixed(0)}
            %)
          </Metric>
        </Card>
      </div>

      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  checked={selectAll.status}
                  onChange={() =>
                    setSelectAll(selectAll => ({
                      status: !selectAll.status,
                      changedByItemToggle: false,
                    }))
                  }
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (
                  {filteredLeads.reduce((acc, lead) => {
                    return (
                      acc + lead.address.suspected_owners.filter(owner => owner.isSelected).length
                    );
                  }, 0)}
                  )
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all owners that you can currently see
                </p>
              </div>
            </div>
            <div className="flex flex-row space-x-10">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={isBulkMatching}
                  onChange={setIsBulkMatching}
                  className={classNames(
                    isBulkMatching ? "bg-orange-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isBulkMatching ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Bulk Address Matching</span>
                </Switch.Label>
              </Switch.Group>
              <Dropdown
                items={[
                  {
                    icon: CheckIcon,
                    name: "Validate Address",
                    function: async () => {
                      const selectedChanges = filteredLeads
                        .flatMap(lead => lead.address.suspected_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => {
                          return {
                            ownerId: owner.id,
                            addressId: owner.matching_address.address.id,
                            validationType: OWNER_ADDRESS_VALIDATION_TYPE.CORRECT,
                          };
                        });

                      if (selectedChanges.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }
                      await validateOwnerAddress({ changes: selectedChanges });
                    },
                  },
                  {
                    icon: SwatchIcon,
                    name: "Match Owners",
                    function: async () => {
                      const selectedOwnerAddressIds = filteredLeads
                        .flatMap(lead => lead.address.suspected_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => String(owner.suspected_owner_address.id));

                      if (selectedOwnerAddressIds.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }

                      if (!campaign) {
                        toast.error("Unexpected Error, Missing Campaign Id");
                        return;
                      }

                      triggerTrackingGroup({
                        campaignId: campaign.id,
                        trackingGroupTypeId: TRACKING_GROUP_TYPE.MATCH_OWNERS,
                        trackingItemValues: selectedOwnerAddressIds,
                      });
                    },
                  },
                  {
                    icon: CalculatorIcon,
                    name: "Auto-Validate Address",
                    function: async () => {
                      const ownerIds = filteredLeads
                        .flatMap(lead => lead.address.suspected_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => owner.id);

                      if (ownerIds.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }

                      if (!campaign?.id) {
                        toast.error("No Campaign Id found");
                        return;
                      }
                      await autoValidateOwnerAddresses({ ownerIds, campaignId: campaign.id });
                    },
                  },
                  {
                    icon: XMarkIcon,
                    name: "Invalidate Address",
                    function: async () => {
                      const selectedChanges = filteredLeads
                        .flatMap(lead => lead.address.suspected_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => {
                          return {
                            ownerId: owner.id,
                            addressId: owner.matching_address.address.id,
                            validationType: OWNER_ADDRESS_VALIDATION_TYPE.FALSE,
                          };
                        });

                      if (selectedChanges.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }
                      await validateOwnerAddress({ changes: selectedChanges });
                    },
                  },
                  {
                    icon: XMarkIcon,
                    name: "Set to Unchecked",
                    function: async () => {
                      const selectedChanges = filteredLeads
                        .flatMap(lead => lead.address.suspected_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => {
                          return {
                            ownerId: owner.id,
                            addressId: owner.matching_address.address.id,
                            validationType: OWNER_ADDRESS_VALIDATION_TYPE.UNKNOWN,
                          };
                        });

                      if (selectedChanges.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }
                      await validateOwnerAddress({ changes: selectedChanges });
                    },
                  },
                ]}
              />
            </div>
          </div>
          <Divider className="mt-1 mb-0" />
          <div className="flex p-4 justify-center w-full items-center space-x-4">
            <Text>Displaying</Text>
            <Metric className="text-gray-500">{filteredOwners.length}</Metric>
            <Text>
              {" "}
              / {validatedCampaignAddressOwnersExclNameFiltered.length} Owners{" "}
              <span className="italic">({totalOwners.length} total owners)</span>
            </Text>
          </div>
          <Divider className="mt-1 mb-0" />
          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">EPC:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="epc"
                options={suspectedOwnerCleanFilters.epc.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveEpcFilters(options);
                }}
                placeholder="Filter By EPC"
              />
            </div>
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Owner Source:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerSource"
                options={suspectedOwnerCleanFilters.ownerSource.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: number;
                  }[];
                  setActiveOwnerSourceFilters(options);
                }}
                placeholder="Filter By Owner Source"
              />
            </div>
          </div>
          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Owner Status:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerStatus"
                options={suspectedOwnerCleanFilters.ownerStatus.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: number;
                  }[];
                  setActiveOwnerStatusFilters(options);
                }}
                placeholder="Filter By Owner Status"
              />
            </div>
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Number of Owners:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerCount"
                options={suspectedOwnerCleanFilters.ownerCount.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveOwnerCountFilters(options);
                }}
                placeholder="Filter By Number of Owners"
              />
            </div>
          </div>
          <div className="py-4 px-4">
            <h2 className="text-sm font-medium text-gray-900 mb-2">Searches Conducted:</h2>

            <Select
              styles={filtersStyling}
              isMulti
              name="ownerSearches"
              options={suspectedOwnerCleanFilters.ownerSearch.options}
              onChange={selectedOptions => {
                const options = [...selectedOptions] as unknown as {
                  label: string;
                  value: string;
                }[];
                setActiveOwnerSearchFilters(options);
              }}
              placeholder="Filter By Owner Searches"
            />
          </div>
          <Divider className="mt-1 mb-0" />

          <div className="py-4 px-4">
            <div className="flex items-center justify-between">
              <h2 className="text-sm font-medium text-gray-900">Show Only:</h2>
            </div>
            <RadioGroup
              value={selectedRadioOption}
              onChange={setSelectedRadioOption}
              className="mt-2"
            >
              <div className="flex flex-row justify-between space-x-6">
                {suspectedOwnerCleanRadioOptions.map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium  sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
      {isBulkMatching && (
        <BulkOwnerAddressMatcher
          campaignId={campaign?.id}
          validateOwnerAddressLoading={validateOwnerAddressLoading}
          validateOwnerAddress={validateOwnerAddress}
          filteredCampaignLeads={filteredLeads}
        />
      )}
      {!isBulkMatching && (
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
          {filteredLeads.map(lead => {
            return (
              <Card className={"max-w-md mx-auto"}>
                <Flex className="mb-2">
                  {epcStatuses.ownerOccupied.includes(lead.address.additional_info?.epc?.tenure) ? (
                    <Text color="green">
                      <Bold>Owner Occupied</Bold>
                    </Text>
                  ) : (
                    <Text color="rose">
                      <Bold>
                        {lead.address.additional_info?.epc?.tenure
                          ? lead.address.additional_info?.epc?.tenure
                          : "No EPC Found"}
                      </Bold>
                    </Text>
                  )}
                </Flex>

                <Flex justifyContent="start" className="space-x-1" alignItems="baseline">
                  <Metric>{lead.stated_address}</Metric>
                </Flex>

                <Flex className="mt-4">
                  <div>
                    <Text>Date of EPC</Text>
                    <Text color="orange">
                      <Bold>
                        {lead.address.additional_info?.epc?.lodgementDate
                          ? lead.address.additional_info?.epc?.lodgementDate
                          : "N/A"}
                      </Bold>
                    </Text>
                  </div>
                  <div>
                    <Text className="text-right">EPC Match Method</Text>
                    <Text className="text-right">
                      <Bold>
                        {lead.address.additional_info?.epc?.matchingMethod
                          ? lead.address.additional_info?.epc?.matchingMethod
                          : "N/A"}
                      </Bold>
                    </Text>
                  </div>
                </Flex>

                {lead.address.additional_info?.epc?.lmkKey && (
                  <a
                    href={`https://epc.opendatacommunities.org/domestic/certificate/${lead.address.additional_info?.epc?.lmkKey}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      type="button"
                      className="rounded-md mt-5 w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      View EPC
                    </button>
                  </a>
                )}
                <Flex className="w-full">
                  <Bold className="mt-6 w-full pt-4 border-t">Potential Owners</Bold>
                </Flex>

                <AccordionList className="mt-6">
                  {lead.address.suspected_owners.map((owner, idx) => {
                    const startDate = owner.start_date
                      ? dayjs(owner.start_date).format("MM/YYYY")
                      : "N/A";
                    const endDate = owner.end_date ? dayjs(owner.end_date).format("MM/YYYY") : "";
                    const date = `${startDate} - ${endDate}`;

                    return (
                      <Accordion
                        key={owner.id}
                        className={classNames(
                          owner.constructed_status === CONSTRUCTED_OWNER_STATUS.NOT_VALIDATED
                            ? "border border-red-500 bg-red-50"
                            : "",
                          " overflow-visible	"
                        )}
                      >
                        <AccordionHeader className="w-full">
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between w-full">
                              <div className=" h-5 items-center mr-3">
                                <input
                                  type="checkbox"
                                  checked={owner.isSelected}
                                  onClick={event => event.stopPropagation()}
                                  onChange={event => {
                                    event.stopPropagation();

                                    handleIndividualChange(lead.id, owner.id);
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                />
                              </div>
                              <Flex className="flex-col justify-start items-start">
                                <Text>
                                  {owner.first_name} {owner.last_name} - ({date})
                                </Text>
                                <p className="text-xs text-gray-400">{owner.name}</p>
                              </Flex>

                              <div className="flex">
                                <Badge size="xs" color="orange" className="mr-2">
                                  <span className="text-xs">
                                    {owner.suspected_owner_status.name}
                                  </span>
                                </Badge>
                                <Badge size="xs">
                                  <span className="text-xs">{owner.ownership_source.name} </span>
                                </Badge>
                                {/* {owner.address?.id !== lead.address.id && (
                                <Badge color="red" size="xs">
                                  <span className="text-xs">Mismatched Address!!!</span>
                                </Badge>
                              )} */}
                              </div>
                            </div>
                            <div className="mt-2">
                              <div className="text-left	 text-gray-400 text-xs">
                                {owner.stated_address}
                              </div>
                            </div>
                          </div>
                        </AccordionHeader>
                        <AccordionBody className="overflow-visible	">
                          <Text className="flex flex-row">
                            <Bold>Stated Address</Bold>
                          </Text>

                          <Text className="mt-2">{owner.stated_address}</Text>

                          <Text className="flex flex-row mt-2">
                            <Bold>Matched Addresses</Bold>
                          </Text>
                          <Card className="mt-3 p-0 divide-y bg-inherit divide-gray-100">
                            <li
                              className={classNames(
                                owner.suspected_owner_address.is_correct_address
                                  ? "bg-green-50"
                                  : owner.suspected_owner_address.is_false_address
                                  ? "bg-red-50"
                                  : " bg-gray-50",
                                "flex items-center justify-between gap-x-6 p-2"
                              )}
                            >
                              <div className="min-w-0">
                                <div className="flex items-start gap-x-3">
                                  <p className="text-sm font-semibold leading-6 text-gray-900">
                                    {owner.suspected_owner_address.address.full_address}
                                  </p>
                                  <p
                                    className={classNames(
                                      owner.suspected_owner_address.is_correct_address
                                        ? "text-green-700 bg-green-50 ring-green-600/20"
                                        : owner.suspected_owner_address.is_false_address
                                        ? "text-red-700 bg-red-50 ring-red-600/20"
                                        : "text-gray-600 bg-gray-50 ring-gray-500/10",
                                      "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                                    )}
                                  >
                                    {owner.suspected_owner_address.is_correct_address
                                      ? "Correct Address"
                                      : owner.suspected_owner_address.is_false_address
                                      ? "False Address"
                                      : "Not Checked"}
                                  </p>
                                </div>
                                <div className="mt-1  gap-x-2 text-xs leading-5 text-gray-500">
                                  <ProgressBar
                                    value={owner.suspected_owner_address.address_match_score}
                                    color="orange"
                                    className="mt-6"
                                  />

                                  <Flex className="mt-4">
                                    <div>
                                      <Text>Match Score</Text>
                                      <Text color="orange">
                                        <Bold>
                                          {owner.suspected_owner_address.address_match_score}
                                        </Bold>
                                      </Text>
                                    </div>
                                    <div>
                                      <Text className="text-right">Match Quality</Text>
                                      <Text className="text-right">
                                        <Bold>
                                          {owner.suspected_owner_address.address_match_quality.name}
                                        </Bold>
                                      </Text>
                                    </div>
                                  </Flex>
                                </div>
                              </div>
                            </li>
                          </Card>

                          {owner.ownership_source.id === OWNERSHIP_SOURCE.COMPANIES_HOUSE && (
                            <Flex className="mt-4 flex-col pt-4 border-t">
                              <Flex className="mt-4">
                                <div>
                                  <Text>Creation Date</Text>
                                  <Text color="orange">
                                    <Bold>{owner.additional_info?.company?.dateOfCreation}</Bold>
                                  </Text>
                                </div>
                                <div>
                                  <Text className="text-right">Cessation Date</Text>
                                  <Text className="text-right">
                                    <Bold>
                                      {owner.additional_info?.company?.dateOfDissolution
                                        ? owner.additional_info?.company?.dateOfDissolution
                                        : "Still Active"}
                                    </Bold>
                                  </Text>
                                </div>
                              </Flex>

                              <a
                                href={`https://find-and-update.company-information.service.gov.uk/company/${owner.additional_info?.company?.companyNumber}`}
                                target="_blank"
                                className="w-full"
                                rel="noopener noreferrer"
                              >
                                <button
                                  type="button"
                                  className="rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                >
                                  View Companies House
                                </button>
                              </a>
                            </Flex>
                          )}

                          {owner.ownership_source.id === OWNERSHIP_SOURCE.PLANNING_APPLICATION && (
                            <Flex className="mt-4 flex-col pt-4 border-t">
                              <a
                                href={
                                  owner.additional_info?.document?.documentsUrl ??
                                  owner.additional_info?.contacts?.contactsUrl
                                }
                                target="_blank"
                                className="w-full"
                                rel="noopener noreferrer"
                              >
                                <button
                                  type="button"
                                  className="rounded-md  w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                >
                                  View Application Form
                                </button>
                              </a>
                            </Flex>
                          )}

                          {/* {owner.id !== selectedOwner && (
                          <Flex className="mt-4 pt-4 border-t">
                            <button
                              type="button"
                              onClick={() => {
                                editOwner(lead, owner.id);
                              }}
                              className="rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                            >
                              Edit Matched Address
                            </button>
                          </Flex>
                        )} */}
                          {/* {owner.id === selectedOwner && (
                          <Flex className="mt-4 	 pt-4 w-full border-t">
                            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                              <label className="block text-sm font-medium mb-2 text-gray-700">
                                New Address (within {postcodeUnit?.code})
                              </label>

                              <Controller
                                name="address"
                                control={control}
                                defaultValue={{
                                  value: lead.address.id,
                                  label: lead.address.full_address,
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    styles={reactSelectStyling}
                                    maxMenuHeight={220}
                                    options={postcodeUnit?.addresses.map(address => {
                                      return {
                                        label: address.full_address,
                                        value: address.id,
                                      };
                                    })}
                                    isSearchable
                                    placeholder="Select Address"
                                  />
                                )}
                              />
                              <div className="flex mt-4 4 ">
                                <button
                                  type="button"
                                  className="rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                  onClick={() => setSelectedOwner(null)}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className=" w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                >
                                  Save
                                </button>
                              </div>
                              {errors.address?.message && (
                                <ErrorMessage>{errors.address.message}</ErrorMessage>
                              )}
                            </form>
                          </Flex>
                        )} */}

                          {owner.id !== selectedNamesOwner?.id && (
                            <Flex className="mt-4 pt-4 border-t">
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedNamesOwner({
                                    id: owner.id,
                                    firstName: owner.first_name,
                                    lastName: owner.last_name,
                                  });
                                }}
                                className="rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                              >
                                Edit Owner Name
                              </button>
                            </Flex>
                          )}
                          {owner.id === selectedNamesOwner?.id && (
                            <Flex className="mt-4 pt-4 w-full border-t">
                              <form className="w-full" onSubmit={handleNamesSubmit(onNamesSubmit)}>
                                <label className="block text-sm font-medium mb-2 text-gray-700">
                                  Set new Owner Name
                                </label>
                                <input
                                  type="text"
                                  {...register("firstName", { required: true })}
                                  defaultValue={owner.first_name}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                                {namesErrors.firstName?.message && (
                                  <ErrorMessage>{namesErrors.firstName.message}</ErrorMessage>
                                )}
                                <input
                                  type="text"
                                  {...register("lastName", { required: true })}
                                  defaultValue={owner.last_name}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                                {namesErrors.lastName?.message && (
                                  <ErrorMessage>{namesErrors.lastName.message}</ErrorMessage>
                                )}

                                <div className="flex mt-4  ">
                                  <button
                                    type="button"
                                    className="rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                    onClick={() => setSelectedNamesOwner(null)}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className=" w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                  >
                                    Save
                                  </button>
                                </div>
                              </form>
                            </Flex>
                          )}
                        </AccordionBody>
                      </Accordion>
                    );
                  })}
                </AccordionList>
              </Card>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SuspectedOwnersSearchTab;
