export const filtersStyling = {
  control: (providedStyling: any) => ({
    ...providedStyling,
    border: "1px solid #D1D5DB",
    borderRadius: "5px",
    width: "w-full",
  }),
  placeholder: (providedStyling: any) => ({
    ...providedStyling,
    whiteSpace: "nowrap",
    color: "#374151",
  }),
  menu: (providedStyling: any) => ({
    ...providedStyling,
    position: "absolute",
  }),
  menuList: (providedStyling: any) => ({
    ...providedStyling,
    position: "relative",
  }),
};
