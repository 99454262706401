import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useMutation } from "react-query";
import * as yup from "yup";

// Define the API options interface
export interface ProcessUSCampaignAPIOptions {
  usCampaignId: number;
}

// Create the API function
export const processUSCampaignAPI = async ({ usCampaignId }: ProcessUSCampaignAPIOptions) => {
  const response = await meetmoreAPI.post(`/us-campaigns/${usCampaignId}/process`, {
    usCampaignId,
  });
  return response.data;
};

// Create the hook
export const useProcessUSCampaignMutation = () => {
  let loadingToastId: string;

  return useMutation(processUSCampaignAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Processing US Campaign...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("US Campaign processed successfully!");
    },
    onError: (error: Error, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error processing US Campaign: ${error.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
