import {
  GetCampaignByIdQuery,
  GetCampaignLeadProfilesQuery,
  GetDoorKnockingCampaignByIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";

export const getSingleSyncStatus = ({
  campaign,
  leadProfiles,
}: {
  campaign:
    | GetCampaignByIdQuery["campaign_by_pk"]
    | GetDoorKnockingCampaignByIdQuery["campaign_by_pk"]; // Replace YourCampaignType with the actual type of your campaign
  leadProfiles: GetCampaignLeadProfilesQuery["campaign_lead_profile"];
}) => {
  if (!campaign) return;

  const syncedOutreachAgent = campaign.outreach_agent && !!campaign.outreach_agent.external_id;

  const syncedLeadProfilesCount = leadProfiles.filter(
    leadProfile => leadProfile.external_id
  ).length;

  return {
    leadProfilesSyncStatus: {
      title: "Overlead Leads",
      isFullySynced: syncedLeadProfilesCount === leadProfiles.length && syncedLeadProfilesCount > 0,
      syncStatement: `${syncedLeadProfilesCount} / ${leadProfiles.length} Overlead Leads Synced`,
    },
    outreachAgentSyncStatus: {
      title: "Overlead Seat",
      isFullySynced: syncedOutreachAgent,
      syncStatement: syncedOutreachAgent
        ? `Provided ID = ${campaign.outreach_agent?.external_id}`
        : `Missing Overlead Seat ID`,
    },
  };
};
