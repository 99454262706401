import {
  CAMPAIGN_TYPE,
  CONSTRUCTED_OWNER_STATUS,
} from "../../../../shared/internals/constants/models";

// FORMS
export interface UpdateMatchedAddressValues {
  address: { label: string; value: number };
}

export interface UpdateOwnerNamesValues {
  firstName: string;
  lastName: string;
}

export interface UpdateSuspectedOwnerStatusValues {
  suspectedOwnerStatus: { label: string; value: number };
}

export interface Tab {
  name: string;
  current: boolean;
}

export interface UpdateBulkAddressMatchValues {
  campaignLeads: {
    id: number; // pushing this into form even though I dont need it
    index: number;
    statedAddress: string;
    initialAddressId: number;
    initialPostcodeUnitId: number;
    initialAddressFullAddress: string;
    initialPostcodeUnitCode: string;
    isAddressValidated: boolean;
    addressMatchScore: number;
    isExcluded: boolean;
    addressMatchQualityId: number;
    addressMatchQualityName: string;
    matchedAddress: { label: string; value: number };
    postcodeUnit: { label: string; value: number };
  }[];
}

export interface UpdateBulkOwnerAddressMatchValues {
  changes: {
    ownerId: number; // pushing this into form even though I dont need it
    index: number;
    ownerStatedAddress: string;
    leadMatchedAddressId: number;
    leadMatchedAddressFullAddress: string;
    isFalseAddress: boolean;
    isCorrectAddress: boolean;
    ownerFullName: string;
    addressMatchScore: number;
    addressMatchQualityId: number;
    initialIsFalseAddress: boolean;
    initialIsCorrectAddress: boolean;
    addressMatchQualityName: string;
    ownerConstructedStatus: CONSTRUCTED_OWNER_STATUS;
  }[];
}

// UTILS

interface BulkMatcherCampaignLeadInfo {
  id: number;
  statedAddress: string;
  initialAddressId: number;
  isExcluded: boolean;
  index: number;
  initialAddressFullAddress: string;
  initialPostcodeUnitCode: string;
  initialPostcodeUnitId: number;
  isAddressValidated: boolean;
  addressMatchScore: number;
  matchedAddressId: number;
  matchedAddressFullAddress: string;
  postcodeUnitId: number;
  postcodeUnitCode: string;
}

export interface GroupedBulkAddressMatcherCampaignLeads {
  [key: number]: {
    address_match_quality_id: number;
    address_match_quality_name: string;
    campaignLeads: BulkMatcherCampaignLeadInfo[];
  };
}

export interface BulkMatcherTransformedCategory {
  address_match_quality_id: number;
  address_match_quality_name: string;
  campaignLeads: BulkMatcherCampaignLeadInfo[];
}

interface TransformedAddressForPostcodeUnitIdsInfo {
  id: number;
  fullAddress: string;
}

export interface GroupedAddresses {
  [key: number]: {
    postcodeUnitId: number;
    postcodeUnitCode: string;
    addresses: TransformedAddressForPostcodeUnitIdsInfo[];
  };
}

export interface TransformedAddressesForPostcodeUnitIds {
  postcodeUnitId: number;
  postcodeUnitCode: string;
  addresses: TransformedAddressForPostcodeUnitIdsInfo[];
}

interface BulkMatcherOwnerInfo {
  ownerId: number; // pushing this into form even though I dont need it
  index: number;
  ownerStatedAddress: string;
  leadMatchedAddressId: number;
  leadMatchedAddressFullAddress: string;
  isFalseAddress: boolean;
  isCorrectAddress: boolean;
  ownerFullName: string;
  addressMatchScore: number;
  addressMatchQualityId: number;
  initialIsFalseAddress: boolean;
  initialIsCorrectAddress: boolean;
  addressMatchQualityName: string;
  ownerConstructedStatus: CONSTRUCTED_OWNER_STATUS;
}
export interface BulkMatcherForOwnersTransformedCategory {
  constructed_status: CONSTRUCTED_OWNER_STATUS;
  owners: BulkMatcherOwnerInfo[];
}

export interface GroupedBulkAddressMatcherForOwners {
  [key: string]: {
    constructed_status: CONSTRUCTED_OWNER_STATUS;
    owners: BulkMatcherOwnerInfo[];
  };
}

/// ADDRESS TABS

export enum ADDRESS_SHOW_TABS {
  ALL = 1,
  RISKY = 2,
  GOOD = 3,
  EXACT = 4,
  DUPLICATES_INTERNAL = 5,
  DUPLICATES_EXTERNAL = 6,
  NOT_VALIDATED = 7,
  VALIDATED = 8,
  MANUAL = 9,
}

export enum OWNER_POSSESSION {
  HAS_ELECTORAL_ROLL = "has_electoral_roll",
  HAS_PLANNING_APPS = "has_planning_apps",
  HAS_COMPANIES_HOUSE = "has_companies_house",
  HAS_LAND_REGISTRY = "has_land_registry",
  HAS_USER_PROVIDED = "has_user_provided",
  DOES_NOT_HAVE_ELECTORAL_ROLL = "does_not_have_electoral_roll",
  DOES_NOT_HAVE_PLANNING_APPS = "does_not_have_planning_apps",
  DOES_NOT_HAVE_COMPANIES_HOUSE = "does_not_have_companies_house",
  DOES_NOT_HAVE_LAND_REGISTRY = "does_not_have_land_registry",
  DOES_NOT_HAVE_USER_PROVIDED = "does_not_have_user_provided",
}

export enum ADDRESS_SEARCH {
  COMPANIES_HOUSE_SEARCHED = "companies_house_searched",
  ELECTORAL_ROLL_SEARCHED = "electoral_roll_searched",
  PLANNING_APP_SEARCHED = "planning_app_searched",
  COMPANIES_HOUSE_NOT_SEARCHED = "companies_house_not_searched",
  ELECTORAL_ROLL_NOT_SEARCHED = "electoral_roll_not_searched",
  PLANNING_APP_NOT_SEARCHED = "planning_app_not_searched",
  EPC_SEARCHED = "epc_searched",
  EPC_NOT_SEARCHED = "epc_not_searched",
  LAST_TRANSACTION_SEARCHED = "last_transaction_searched",
  LAST_TRANSACTION_NOT_SEARCHED = "last_transaction_not_searched",
  ADDRESS_MATCHER_SEARCHED = "address_matcher_searched",
  ADDRESS_MATCHER_NOT_SEARCHED = "address_matcher_not_searched",
}

export enum OWNER_COUNT {
  NONE = "no_owners",
  ONE = "one_owner",
  TWO_OR_MORE = "two_or_more_owners",
}

export enum OWNER_STATUS {
  ZERO_CONTENDER_OR_VERIFIED = "zero_contender_or_verified",
  ONE_CONTENDER_OR_VERIFIED = "one_contender_or_verified",
  MORE_THAN_ONE_CONTENDER_OR_VERIFIED = "more_than_one_contender_or_verified",
}

export enum EPC_CATEGORY {
  OWNER_OCCUPIED = "owner_occupied",
  RENTED_SOCIAL = "rented_social",
  RENTED_PRIVATE = "rented_private",
  UNDEFINED = "undefined",
  NOT_AVAILABLE = "not_available",
}

export enum OWNER_SOURCE {
  ONLY_PLANNING = "only_planning",
  ONLY_ELECTORAL = "only_electoral",
  ONLY_COMPANIES = "only_companies",
  ONLY_USER = "only_user",
  ONLY_LAND_REGISTRY = "only_land_registry",
  MULTIPLE = "multiple",
  NO_COMPANIES = "no_companies",
  NO_PLANNING = "no_planning",
  NO_ELECTORAL = "no_electoral",
  NO_LAND_REGISTRY = "no_land_registry",
  NO_USER = "no_user",
}

export enum MISSING_DATA {
  NO_AGENT = "Estate Agent Unknown",
  NO_PRICE = "Price Unknown",
}

export enum OWNER_SEARCH {
  ADDRESS_MATCHER_SEARCHED = "address_matcher_searched",
  ADDRESS_MATCHER_NOT_SEARCHED = "address_matcher_not_searched",
  LINKEDIN_SEARCHED = "linkedin_searched",
  LINKEDIN_NOT_SEARCHED = "linkedin_not_searched",
}

export enum OWNER_TAG {
  IS_PERSON = "is_person",
  IS_NOT_PERSON = "is_not_person",
}

export enum OWNER_CLEANING_SHOW_TABS {
  ALL = 1,
  VALIDATED_LEAD_ADDRESS = 2,
  VALIDATED_ALTERNATIVE_ADDRESS = 3,
  REJECTED_LEAD_ADDRESS = 4,
  UNVALIDATED = 5,
}

export enum OWNER_EVALUATION_SHOW_TABS {
  ALL = 1,
  NEW = 2,
  DUPLICATE = 3,
  REJECTED = 4,
  CONTENDER_AND_VERIFIED = 5,
}

export interface AddressCSVData {
  [key: string]: any;
}

interface Message {
  content: string;
  msgOrder: number;
  sendAfterDays: number;
}
export interface CreateMessageSetValues {
  name: string;
  messages: Message[];
  isPublic: boolean;
  userUid?: string;
  campaignTypes: { label: string; value: number }[];
}
export interface IMessageContents {
  [key: string]: string;
}

export interface Snippet {
  type: "agent" | "campaign" | "campaignProfile";
  name: "20/20_ADDRESS" | "20/20_POSTCODE" | "MY_NUMBER" | "LOCATION" | "PRICE" | "POSTCODE_UNIT";
  campaignTypeIdSpecific?: CAMPAIGN_TYPE;
  description: string;
}
