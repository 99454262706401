import {
  ADDRESS_MATCH_QUALITY,
  CAMPAIGN_TYPE,
  OWNERSHIP_SOURCE,
  SEARCH_TYPE,
  SUSPECTED_OWNER_STATUS,
} from "../../../../shared/internals/constants/models";
import {
  ADDRESS_SEARCH,
  ADDRESS_SHOW_TABS,
  EPC_CATEGORY,
  MISSING_DATA,
  OWNER_CLEANING_SHOW_TABS,
  OWNER_COUNT,
  OWNER_EVALUATION_SHOW_TABS,
  OWNER_POSSESSION,
  OWNER_SEARCH,
  OWNER_SOURCE,
  OWNER_STATUS,
  OWNER_TAG,
  Snippet,
} from "../types";

export const tabs = [
  { name: "Summary", current: true },
  { name: "Addresses", current: false },
  { name: "Suspected Owners - Cleaning", current: false },
  { name: "Suspected Owners - Evaluation", current: false },
  { name: "Digital Profiles", current: false },
  { name: "Leads", current: false },
  { name: "Sync", current: false },
];

export const fieldNames = {
  postRecipe: "postRecipe",
};

// Address Options

export const addressRadioOptions = [
  { id: ADDRESS_SHOW_TABS.ALL, name: "All" },
  { id: ADDRESS_SHOW_TABS.MANUAL, name: "Manual" },
  { id: ADDRESS_SHOW_TABS.EXACT, name: "Exact" },
  { id: ADDRESS_SHOW_TABS.GOOD, name: "Good" },
  { id: ADDRESS_SHOW_TABS.RISKY, name: "Risky" },
  { id: ADDRESS_SHOW_TABS.DUPLICATES_INTERNAL, name: "Duplicates (Internal)" },
  { id: ADDRESS_SHOW_TABS.DUPLICATES_EXTERNAL, name: "Duplicates (External)" },
  { id: ADDRESS_SHOW_TABS.VALIDATED, name: "Validated" },
  { id: ADDRESS_SHOW_TABS.NOT_VALIDATED, name: "Not Validated" },
];

export const addressTabFilters = {
  epc: {
    label: "EPC",
    options: [
      { value: EPC_CATEGORY.OWNER_OCCUPIED, label: "Owner Occupied" },
      { value: EPC_CATEGORY.RENTED_SOCIAL, label: "Rented Social" },
      { value: EPC_CATEGORY.RENTED_PRIVATE, label: "Rented Private" },
      { value: EPC_CATEGORY.UNDEFINED, label: "Undefined" },
      { value: EPC_CATEGORY.NOT_AVAILABLE, label: "Not Available" },
    ],
  },
  agents: {
    label: "Agent",
    options: [],
  },
  missing: {
    label: "Missing Data",
    options: [
      { value: MISSING_DATA.NO_AGENT, label: "Agent Unknown" },
      { value: MISSING_DATA.NO_PRICE, label: "Price Unknown" },
    ],
  },
  minPrice: {
    label: "Minimum Price",
    options: [],
  },
  maximumPrice: {
    label: "Maximum Price",
    options: [],
  },

  ownerCount: {
    label: "Owners Count",
    options: [
      { value: OWNER_COUNT.NONE, label: "Addresses with 0 Owners" },
      { value: OWNER_COUNT.ONE, label: "Addresses with 1 Owner" },
      { value: OWNER_COUNT.TWO_OR_MORE, label: "Addresses with 2+ Owners" },
    ],
  },
  ownerSource: {
    label: "Owner Source",
    options: [
      { value: OWNER_SOURCE.ONLY_PLANNING, label: "All Owners from Planning Apps" },
      { value: OWNER_SOURCE.ONLY_ELECTORAL, label: "All Owners from Electoral Roll" },
      { value: OWNER_SOURCE.ONLY_COMPANIES, label: "All Owners from Companies House" },
      { value: OWNER_SOURCE.ONLY_USER, label: "All Owners from User" },
      { value: OWNER_SOURCE.ONLY_LAND_REGISTRY, label: "All Owners from Land Registry" },
      { value: OWNER_SOURCE.MULTIPLE, label: "Mixed Owner Sources" },
      { value: OWNER_SOURCE.NO_COMPANIES, label: "No Owners from Companies House" },
      { value: OWNER_SOURCE.NO_PLANNING, label: "No Owners from Planning Apps" },
      { value: OWNER_SOURCE.NO_ELECTORAL, label: "No Owners from Electoral Roll" },
      { value: OWNER_SOURCE.NO_LAND_REGISTRY, label: "No Owners from Land Registry" },
      { value: OWNER_SOURCE.NO_USER, label: "No User-Provided Owners" },
    ],
  },
  ownerStatus: {
    label: "Owner Status",
    options: [
      {
        value: OWNER_STATUS.ZERO_CONTENDER_OR_VERIFIED,
        label: "Addresses with 0 Contender / Verified Owners",
      },
      {
        value: OWNER_STATUS.ONE_CONTENDER_OR_VERIFIED,
        label: "Addresses with 1 Contender / Verified Owner",
      },
      {
        value: OWNER_STATUS.MORE_THAN_ONE_CONTENDER_OR_VERIFIED,
        label: "Addresses with more than 1 Contender or Verified Owner",
      },
    ],
  },
  ownerPossession: {
    label: "Owner Possession",
    options: [
      { value: OWNER_POSSESSION.HAS_ELECTORAL_ROLL, label: "Address Has Electoral Roll" },
      { value: OWNER_POSSESSION.HAS_PLANNING_APPS, label: "Address Has Planning Apps" },
      { value: OWNER_POSSESSION.HAS_COMPANIES_HOUSE, label: "Address Has Companies House" },
      { value: OWNER_POSSESSION.HAS_LAND_REGISTRY, label: "Address Has Land Registry" },
      { value: OWNER_POSSESSION.HAS_USER_PROVIDED, label: "Address Has User Provided" },
      {
        value: OWNER_POSSESSION.DOES_NOT_HAVE_ELECTORAL_ROLL,
        label: "Address does Not Have Electoral Roll",
      },
      {
        value: OWNER_POSSESSION.DOES_NOT_HAVE_PLANNING_APPS,
        label: "Address does Not Have Planning Apps",
      },
      {
        value: OWNER_POSSESSION.DOES_NOT_HAVE_COMPANIES_HOUSE,
        label: "Address does Not Have Companies House",
      },
      {
        value: OWNER_POSSESSION.DOES_NOT_HAVE_LAND_REGISTRY,
        label: "Address does Not Have Land Registry",
      },
      {
        value: OWNER_POSSESSION.DOES_NOT_HAVE_USER_PROVIDED,
        label: "Address does Not Have User Provided",
      },
    ],
  },
  searches: {
    label: "Searches",
    options: [
      { value: ADDRESS_SEARCH.COMPANIES_HOUSE_SEARCHED, label: "Companies House Searched" },
      { value: ADDRESS_SEARCH.ELECTORAL_ROLL_SEARCHED, label: "Electoral Roll Searched" },
      { value: ADDRESS_SEARCH.PLANNING_APP_SEARCHED, label: "Planning App Searched" },
      {
        value: ADDRESS_SEARCH.COMPANIES_HOUSE_NOT_SEARCHED,
        label: "Companies House Not Searched",
      },
      { value: ADDRESS_SEARCH.ELECTORAL_ROLL_NOT_SEARCHED, label: "Electoral Roll Not Searched" },
      { value: ADDRESS_SEARCH.PLANNING_APP_NOT_SEARCHED, label: "Planning App Not Searched" },

      {
        value: ADDRESS_SEARCH.EPC_SEARCHED,
        label: "EPC Searched",
      },
      {
        value: ADDRESS_SEARCH.EPC_NOT_SEARCHED,
        label: "EPC Not Searched",
      },
      {
        value: ADDRESS_SEARCH.LAST_TRANSACTION_SEARCHED,
        label: "Last Transaction Searched",
      },
      {
        value: ADDRESS_SEARCH.LAST_TRANSACTION_NOT_SEARCHED,
        label: "Last Transaction Not Searched",
      },
      {
        value: ADDRESS_SEARCH.ADDRESS_MATCHER_SEARCHED,
        label: "Address Matcher Attempted",
      },
      {
        value: ADDRESS_SEARCH.ADDRESS_MATCHER_NOT_SEARCHED,
        label: "Address Matcher Not Attempted",
      },
    ],
  },
};

export const epcStatuses = {
  notDefined: [
    "NO DATA!",
    "unknown",
    "Not defined - use in the case of a new dwelling for which the intended tenure in not known. It is not to be used for an existing dwelling",
    "NONE",
    "",
  ],
  ownerOccupied: ["owner-occupied", "Owner-occupied"],
  rentedSocial: ["Rented (social)", "rental (social)"],
  rentedPrivate: ["Rented (private)", "rental (private)"],
};

// Suspected Owner Cleaning

export const suspectedOwnerCleanRadioOptions = [
  { id: OWNER_CLEANING_SHOW_TABS.ALL, name: "All" },
  { id: OWNER_CLEANING_SHOW_TABS.VALIDATED_LEAD_ADDRESS, name: "Validated [Lead Address]" },
  { id: OWNER_CLEANING_SHOW_TABS.REJECTED_LEAD_ADDRESS, name: "Rejected [Lead Address]" },
  { id: OWNER_CLEANING_SHOW_TABS.UNVALIDATED, name: "Not Validated" },
];

export const suspectedOwnerCleanFilters = {
  epc: {
    label: "EPC",
    options: [
      { value: EPC_CATEGORY.OWNER_OCCUPIED, label: "Owner Occupied" },
      { value: EPC_CATEGORY.RENTED_SOCIAL, label: "Rented Social" },
      { value: EPC_CATEGORY.RENTED_PRIVATE, label: "Rented Private" },
      { value: EPC_CATEGORY.UNDEFINED, label: "Undefined" },
      { value: EPC_CATEGORY.NOT_AVAILABLE, label: "Not Available" },
    ],
  },
  ownerSource: {
    label: "Owner Source",
    options: [
      { value: OWNERSHIP_SOURCE.PLANNING_APPLICATION, label: "Planning App" },
      { value: OWNERSHIP_SOURCE.ELECTORAL_ROLL, label: "Electorall Roll" },
      { value: OWNERSHIP_SOURCE.COMPANIES_HOUSE, label: "Companies House" },
      { value: OWNERSHIP_SOURCE.USER_PROVIDED, label: "User Provided" },
      { value: OWNERSHIP_SOURCE.LAND_REGISTRY, label: "Land Registry" },
    ],
  },
  ownerStatus: {
    label: "Owner Status",
    options: [
      { value: SUSPECTED_OWNER_STATUS.NEW, label: "New" },
      { value: SUSPECTED_OWNER_STATUS.REJECTED, label: "Rejected" },
      { value: SUSPECTED_OWNER_STATUS.DUPLICATE, label: "Duplicate" },
      { value: SUSPECTED_OWNER_STATUS.CONTENDER, label: "Contender" },
      { value: SUSPECTED_OWNER_STATUS.VERIFIED, label: "Verified" },
    ],
  },
  ownerSearch: {
    label: "Owner Search",
    options: [
      { value: OWNER_SEARCH.LINKEDIN_SEARCHED, label: "Linkedin Searched" },
      { value: OWNER_SEARCH.LINKEDIN_NOT_SEARCHED, label: "Linkedin Not Searched" },
      { value: OWNER_SEARCH.ADDRESS_MATCHER_SEARCHED, label: "Address Matcher Attempted" },
      { value: OWNER_SEARCH.ADDRESS_MATCHER_NOT_SEARCHED, label: "Address Matcher Not Attempted" },
    ],
  },
  ownerCount: {
    label: "Owner Count",
    options: [
      { value: OWNER_COUNT.NONE, label: "No Owners" },
      { value: OWNER_COUNT.ONE, label: "1 Owner" },
      { value: OWNER_COUNT.TWO_OR_MORE, label: "Two or More" },
    ],
  },
  addressMatchQuality: {
    label: "Address Match Quality",
    options: [
      { value: ADDRESS_MATCH_QUALITY.EXACT, label: "Exact" },
      { value: ADDRESS_MATCH_QUALITY.GOOD, label: "Good" },
      { value: ADDRESS_MATCH_QUALITY.RISKY, label: "Risky" },
      { value: ADDRESS_MATCH_QUALITY.MANUAL, label: "Manual" },
      { value: ADDRESS_MATCH_QUALITY.PARTIAL, label: "Partial" },
      { value: ADDRESS_MATCH_QUALITY.SUBDIVISION, label: "Sub-division" },
    ],
  },
};

// Suspected Owner Evaluation

export const suspectedOwnerEvaluationRadioOptions = [
  { id: OWNER_EVALUATION_SHOW_TABS.ALL, name: "All" },
  { id: OWNER_EVALUATION_SHOW_TABS.NEW, name: "New" },
  { id: OWNER_EVALUATION_SHOW_TABS.REJECTED, name: "Rejected" },
  { id: OWNER_EVALUATION_SHOW_TABS.DUPLICATE, name: "Duplicate" },
  { id: OWNER_EVALUATION_SHOW_TABS.CONTENDER_AND_VERIFIED, name: "Contender & Verified" },
];

export const suspectedOwnerEvaluationFilters = {
  epc: {
    label: "EPC",
    options: [
      { value: EPC_CATEGORY.OWNER_OCCUPIED, label: "Owner Occupied" },
      { value: EPC_CATEGORY.RENTED_SOCIAL, label: "Rented Social" },
      { value: EPC_CATEGORY.RENTED_PRIVATE, label: "Rented Private" },
      { value: EPC_CATEGORY.UNDEFINED, label: "Undefined" },
      { value: EPC_CATEGORY.NOT_AVAILABLE, label: "Not Available" },
    ],
  },
  ownerSource: {
    label: "Owner Source",
    options: [
      { value: OWNERSHIP_SOURCE.PLANNING_APPLICATION, label: "Planning App" },
      { value: OWNERSHIP_SOURCE.ELECTORAL_ROLL, label: "Electorall Roll" },
      { value: OWNERSHIP_SOURCE.COMPANIES_HOUSE, label: "Companies House" },
      { value: OWNERSHIP_SOURCE.USER_PROVIDED, label: "User Provided" },
      { value: OWNERSHIP_SOURCE.LAND_REGISTRY, label: "Land Registry" },
    ],
  },
  // ownerStatus: {
  //   label: "Owner Status",
  //   options: [
  //     { value: SUSPECTED_OWNER_STATUS.NEW, label: "New" },
  //     { value: SUSPECTED_OWNER_STATUS.REJECTED, label: "Rejected" },
  //     { value: SUSPECTED_OWNER_STATUS.DUPLICATE, label: "Duplicate" },
  //     { value: SUSPECTED_OWNER_STATUS.CONTENDER, label: "Contender" },
  //     { value: SUSPECTED_OWNER_STATUS.VERIFIED, label: "Verified" },
  //   ],
  // },
  ownerSearch: {
    label: "Owner Search",
    options: [
      { value: OWNER_SEARCH.LINKEDIN_SEARCHED, label: "Linkedin Searched" },
      { value: OWNER_SEARCH.LINKEDIN_NOT_SEARCHED, label: "Linkedin Not Searched" },
    ],
  },
  ownerCount: {
    label: "Owner Count",
    options: [
      { value: OWNER_COUNT.NONE, label: "No Owners" },
      { value: OWNER_COUNT.ONE, label: "1 Owner" },
      { value: OWNER_COUNT.TWO_OR_MORE, label: "Two or More" },
    ],
  },
  ownerTag: {
    label: "Owner Tag",
    options: [{ value: OWNER_TAG.IS_NOT_PERSON, label: "Not a Person" }],
  },
};

// Digital Profiles

export const digitalProfilesRadioOptions = [
  { id: 1, name: "All" },
  { id: 2, name: "Owners with 0 Profiles" },
  { id: 3, name: "Owners with 1 Profile" },
  { id: 4, name: "Owners with 2 Profiles" },
  { id: 5, name: "Owners with 3 Profiles" },

  { id: 6, name: "Owners with 4 Profiles" },

  { id: 7, name: "Owners with 5 Profiles" },
  { id: 8, name: "Owners with 6 Profiles" },

  { id: 9, name: "Owners with 7 Profiles" },

  { id: 10, name: "Owners with 8 Profiles" },
  { id: 11, name: "Owners with 9 Profiles" },
  { id: 12, name: "Owners with 10+ Profiles" },

  { id: 13, name: "New" },
  { id: 14, name: "Rejected" },
  { id: 15, name: "Duplicate" },
  { id: 16, name: "Contender & Verified" },
  { id: 17, name: "Overlead Profiles" },
];

export const SEARCH_TYPE_RANK = {
  [SEARCH_TYPE.LOCALITY]: 1,
  [SEARCH_TYPE.ADMINISTRATIVE_DISTRICT]: 2,
  [SEARCH_TYPE.POST_TOWN]: 3,
  [SEARCH_TYPE.LOCAL_AUTHORITY]: 4,
  [SEARCH_TYPE.POSTCODE_AREA]: 5,
  [SEARCH_TYPE.ADMINISTRATIVE_COUNTY]: 6,
  [SEARCH_TYPE.REGION]: 7,
  [SEARCH_TYPE.COUNTRY]: 8,
};

export const priceOptions = [
  // { id: 1, value: 25000, label: "£25k" },
  { id: 2, value: 50000, label: "£50k" },
  { id: 3, value: 75000, label: "£75k" },
  { id: 4, value: 100000, label: "£100k" },
  { id: 5, value: 125000, label: "£125k" },
  { id: 6, value: 150000, label: "£150k" },
  { id: 7, value: 175000, label: "£175k" },
  { id: 8, value: 200000, label: "£200k" },
  { id: 9, value: 225000, label: "£225k" },
  { id: 10, value: 250000, label: "£250k" },
  { id: 11, value: 275000, label: "£275k" },
  { id: 12, value: 300000, label: "£300k" },
  { id: 13, value: 325000, label: "£325k" },
  { id: 14, value: 350000, label: "£350k" },
  { id: 15, value: 375000, label: "£375k" },
  { id: 16, value: 400000, label: "£400k" },
  { id: 17, value: 425000, label: "£425k" },
  { id: 1, value: 445000, label: "£445k" },

  { id: 18, value: 450000, label: "£450k" },
  { id: 19, value: 475000, label: "£475k" },
  { id: 20, value: 500000, label: "£500k" },
  { id: 21, value: 525000, label: "£525k" },
  { id: 22, value: 550000, label: "£550k" },
  { id: 23, value: 575000, label: "£575k" },
  { id: 24, value: 600000, label: "£600k" },
  { id: 25, value: 625000, label: "£625k" },
  { id: 26, value: 650000, label: "£650k" },
  { id: 27, value: 675000, label: "£675k" },
  { id: 28, value: 700000, label: "£700k" },
  { id: 29, value: 725000, label: "£725k" },
  { id: 30, value: 750000, label: "£750k" },
  { id: 31, value: 775000, label: "£775k" },
  { id: 32, value: 800000, label: "£800k" },
  { id: 33, value: 825000, label: "£825k" },
  { id: 34, value: 850000, label: "£850k" },
  { id: 35, value: 875000, label: "£875k" },
  { id: 36, value: 900000, label: "£900k" },
  { id: 37, value: 925000, label: "£925k" },
  { id: 38, value: 950000, label: "£950k" },
  { id: 39, value: 975000, label: "£975k" },
  { id: 40, value: 1000000, label: "£1m" },
  { id: 41, value: 1100000, label: "£1.1m" },
  { id: 42, value: 1250000, label: "£1.25m" },
  { id: 43, value: 1500000, label: "£1.5m" },
  { id: 44, value: 1750000, label: "£1.75m" },
  { id: 45, value: 2000000, label: "£2m" },
  { id: 46, value: 2500000, label: "£2.5m" },
  { id: 47, value: 3000000, label: "£3m" },
  { id: 48, value: 4000000, label: "£4m" },
  { id: 49, value: 5000000, label: "£5m" },
  { id: 50, value: 7500000, label: "£7.5m" },
  { id: 51, value: 10000000, label: "£10m" },
  { id: 52, value: 15000000, label: "£15m" },
  { id: 53, value: 20000000, label: "£20m" },
];

export const snippets: Snippet[] = [
  // {
  //   type: "agent",
  //   name: "MY_NUMBER",
  //   description: "The Agent's contact number",
  // },
  {
    type: "campaignProfile",
    name: "LOCATION",
    description: "The locality of the lead (will fall back to post town if not available)",
  },
  {
    type: "campaignProfile",
    name: "PRICE",
    description: "The rounded price of the Lead's property",
  },
  {
    type: "campaignProfile",
    name: "POSTCODE_UNIT",
    description: "The full postcode of the Lead's property",
  },

  {
    type: "campaign",
    name: "20/20_ADDRESS",
    campaignTypeIdSpecific: CAMPAIGN_TYPE.TWENTY_TWENTY,
    description: "The address upon which circle prospecting is applied",
  },
  {
    type: "campaign",
    name: "20/20_POSTCODE",
    campaignTypeIdSpecific: CAMPAIGN_TYPE.TWENTY_TWENTY,
    description: "The postcode of the property upon which circle prospecting is applied",
  },
];
