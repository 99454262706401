import { ApolloError } from "@apollo/client";
import { useDeleteCampaignMessageSetMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";
import { getCampaignLeadProfiles } from "../queries/getCampaignLeadProfiles";
import { getCampaignById } from "../queries/getCampaignById";

export interface HookResult {
  deleteCampaignMessageSet: (options: DeleteCampaignMessageSetMutationOptions) => Promise<void>;
}

export interface DeleteCampaignMessageSetMutationOptions {
  campaignId: number;
}

export interface Options {
  onError: (error: ApolloError) => void;
}

export const useDeleteCampaignMessageSet = ({
  refetchCampaignId,
}: {
  refetchCampaignId: number;
}): HookResult => {
  let toastId: string;

  const [deleteCampaignMessageSet] = useDeleteCampaignMessageSetMutation({
    refetchQueries: [
      { query: getCampaignById, variables: { id: refetchCampaignId } },
      { query: getCampaignLeadProfiles, variables: { id: refetchCampaignId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.dismiss(toastId);
      toast.success("Messaging Removed successfully!");
    },
    onError: (error: ApolloError) => {
      toast.dismiss(toastId);
      toast.error(`Failed to Remove messaging for this campaign: ${error.message}`);
    },
  });

  return {
    deleteCampaignMessageSet: async ({ campaignId }: DeleteCampaignMessageSetMutationOptions) => {
      await deleteCampaignMessageSet({
        variables: {
          id: campaignId,
        },
      });
    },
  };
};
