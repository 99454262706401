import {
  privateLayoutRoutes,
  privateRoutes,
  publicRoutes,
  restrictedRoutes,
} from "./shared/internals/routes/index";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Layout from "./shared/internals/components/layout/Layout";
import PrivateRoute from "./shared/internals/components/privateRoute/PrivateRoute";
import RestrictedRoute from "./shared/internals/components/restrictedRoute/RestrictedRoute";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./shared/infrastructure/firebase";
import { meetmoreAPIClient } from "./shared/infrastructure/APIs/meetmoreAPI";
import LogRocket from "logrocket";
import ApolloWrapper from "./shared/infrastructure/graphQL/ApolloWrapper";
import { Crisp } from "crisp-sdk-web";
import { usePostHog } from "posthog-js/react";
import { QueryClientProvider } from "react-query";
import { reactQueryClient } from "./shared/infrastructure/APIs/ReactQueryInstance";
import { AblyProvider, useChannel, usePresence } from "ably/react";
import * as Ably from "ably";

const client = new Ably.Realtime.Promise({
  key: "h-TrpQ.xkeo8g:L9gF5b2k7SrGlxrziTxe1lkTlVGywbbqjozJonbYI0c",
  clientId: "mm-fe",
});

process.env.REACT_APP_ORIGIN === "https://app.meetmore.ai" && LogRocket.init("o37scr/meemore");

function App() {
  useEffect(() => {
    Crisp.configure("5bcf20e1-eb8e-4933-9ccd-21d991c87de5");
  }, []);
  return (
    <Router>
      <AblyProvider client={client}>
        <QueryClientProvider client={reactQueryClient}>
          <ApolloWrapper>
            <CoreApp />
            <Toaster toastOptions={{ duration: 10000 }} position={"top-right"} />
          </ApolloWrapper>
        </QueryClientProvider>
      </AblyProvider>
    </Router>
  );
}

const CoreApp = () => {
  const [user] = useAuthState(auth);
  const posthog = usePostHog();

  useEffect(() => {
    if (user) {
      posthog?.identify(user.uid, {
        email: user.email,
      });
    }
  }, [posthog, user]);

  return (
    <>
      <Switch>
        {publicRoutes.map((route, index) => {
          return <Route exact={route.exact} key={index} path={route.path} component={route.main} />;
        })}

        {restrictedRoutes.map((route, index) => {
          return (
            <RestrictedRoute
              exact={route.exact}
              key={index}
              path={route.path}
              redirectPath={route.redirectPath}
              component={route.main}
            />
          );
        })}

        {privateRoutes.map((route, index) => {
          return (
            <PrivateRoute
              exact={route.exact}
              isAdmin={route.isAdmin}
              redirectPath={route.redirectPath}
              requiredSubscription={route.requiredSubscription}
              isFreelancer={route.isFreelancer}
              key={index}
              path={route.path}
              component={() => {
                return <route.main />;
              }}
            />
          );
        })}

        {privateLayoutRoutes.map((route, index) => {
          return (
            <PrivateRoute
              exact={route.exact}
              key={index}
              isAdmin={route.isAdmin}
              redirectPath={route.redirectPath}
              isFreelancer={route.isFreelancer}
              requiredSubscription={route.requiredSubscription}
              path={route.path}
              component={() => {
                return (
                  <Layout>
                    <route.main />
                  </Layout>
                );
              }}
            />
          );
        })}

        <Redirect to="/not-found" />
      </Switch>
    </>
  );
};

export default App;
