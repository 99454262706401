import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { ADDRESS_MATCH_QUALITY } from "../../../../../shared/internals/constants/models";
import { useMutation } from "react-query";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";

export interface ExtractZooplaUrlsBody {
  campaignId: number;
}
export const extractZooplaUrls = async ({ campaignId }: ExtractZooplaUrlsBody) => {
  const response = await meetmoreAPI.post(`/campaigns/zoopla-urls`, {
    campaignId,
  });
  return response.data;
};

export const useExtractZooplaUrlsMutation = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchCampaign } = useCampaignById({
    id: refetchId ?? -1,
  });

  return useMutation(extractZooplaUrls, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Extracting Zoopla Leads...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Extracted Zoopla Leads Successfully!");
      await refetchCampaign();
    },
    onError: (error: Error, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error Extracting Zoopla Leads: ${error.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
