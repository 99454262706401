import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { ADDRESS_MATCH_QUALITY } from "../../../../../shared/internals/constants/models";
import { useMutation } from "react-query";

interface DeleteCampaignAPIOptions {
  campaignId: number;
}
export const deleteCampaignAPI = async ({ campaignId }: DeleteCampaignAPIOptions) => {
  const response = await meetmoreAPI.post(`/campaigns/delete`, {
    campaignId,
  });
  return response.data;
};

export const useDeleteCampaignMutation = () => {
  let loadingToastId: string;

  return useMutation(deleteCampaignAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Deleting Campaign...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Campaign Deleting Successfully!");
    },
    onError: (error: Error, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error Deleting Campaign: ${error.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
