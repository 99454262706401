import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useCampaignById } from "../../../../leadsAdmin/singleCampaign/graphql/hooks/useCampaignById";
import { useCampaignLeadProfiles } from "../../../../leadsAdmin/singleCampaign/graphql/hooks/useCampaignLeadProfilesByCampaignId";

export interface CreateMessageSetBody {
  messages: {
    content: string;
    msgOrder: number;
    sendAfterDays: number;
  }[];
  name: string;
  userUid?: string;
  isPublic: boolean;
  campaignTypeIds?: number[];
  campaignId?: number;
  messageSetPresetId?: number;
}

export interface CreateMessageSetResponse {
  id: number;
}

export const createMessageSetAPI = async ({
  messages,
  name,
  userUid,
  isPublic,
  messageSetPresetId,
  campaignTypeIds,
  campaignId,
}: CreateMessageSetBody) => {
  const payload = {
    messages,
    name,
    userUid,
    messageSetPresetId,
    isPublic,
    campaignId,
    campaignTypeIds,
  };

  const response = await meetmoreAPI.post<CreateMessageSetResponse>(`/message-sets/`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data.id;
};

export const useCreateMessageSetMutation = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;

  const { refetch: refetchCampaign } = useCampaignById({
    id: refetchId ?? -1,
  });

  const { refetch: refetchCampaignLeadProfiles } = useCampaignLeadProfiles({
    campaignId: refetchId ?? -1,
  });

  return useMutation(createMessageSetAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Saving Template...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Template saved successfully!");
      if (refetchId) {
        await refetchCampaign();
        await refetchCampaignLeadProfiles();
      }
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error.response.data.errors[0].message ?? error.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
