import {
  GetCampaignByIdQuery,
  GetTrackingGroupsByCampaignIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  Bold,
  Button,
  Callout,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionList,
  Text,
  Badge,
  Divider,
  Icon,
  Title,
  BarList,
} from "@tremor/react";

import {
  XCircleIcon,
  ArchiveBoxIcon,
  ShieldCheckIcon,
  TrashIcon,
  CheckBadgeIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  EyeIcon,
  ComputerDesktopIcon,
  MapPinIcon,
  TableCellsIcon,
  MagnifyingGlassCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Select from "react-select";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import {
  ADDRESS_MATCH_QUALITY,
  DIGITAL_PROFILE_STATUS,
  OWNERSHIP_SOURCE,
  SUSPECTED_OWNER_STATUS,
  TRACKING_GROUP_TYPE,
} from "../../../../shared/internals/constants/models";
import { ArrayElement, classNames } from "../../../../shared/internals/utils";
import { digitalProfilesRadioOptions, suspectedOwnerEvaluationRadioOptions } from "../data";
import { RadioGroup } from "@headlessui/react";
import toast from "react-hot-toast";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";
import Alert from "../../../../shared/internals/components/alert/Alert";
import { UseMutateAsyncFunction } from "react-query";

import { uniqBy } from "lodash";
import dayjs from "dayjs";

import { CursorArrowRippleIcon } from "@heroicons/react/24/outline";
import { UseUSCampaignByIdReturnType } from "../graphql/hooks/useUSCampaignById";
import { UpdateProfileContenderDecisionsAPIOptions } from "../api/updateUSProfileContenderDecision";
import Papa from "papaparse";
import { saveAs } from "file-saver"; // You might need to install file-saver as well

interface DigitalProfilesTabProps {
  campaign: UseUSCampaignByIdReturnType["campaign"];
  fixedAddresses: UseUSCampaignByIdReturnType["fixedAddresses"];
  updateProfileContenderDecisionsMutation: UseMutateAsyncFunction<
    any,
    Error,
    UpdateProfileContenderDecisionsAPIOptions,
    void
  >;
}

const DigitalProfilesTab = ({
  campaign,
  fixedAddresses,
  updateProfileContenderDecisionsMutation,
}: DigitalProfilesTabProps) => {
  const contenderOwners = fixedAddresses
    .map(lead => lead.us_owners.filter(owner => owner.is_correct_match === true))
    .flat();

  const ownersWithSelectableDigitalProfiles = contenderOwners.map(owner => ({
    ...owner,
    us_profiles: owner.us_profiles.map(profile => ({
      ...profile,
      isSelected: false,
    })),
  }));

  const [filteredOwners, setFilteredOwners] = useState(ownersWithSelectableDigitalProfiles);

  const [selectAll, setSelectAll] = useState<{ status: boolean; changedByItemToggle: boolean }>({
    status: false,
    changedByItemToggle: false,
  });

  const [selectedRadioOption, setSelectedRadioOption] = useState(digitalProfilesRadioOptions[0]);

  const [selectedFilters, setSelectedFilters] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (selectAll.status === true && selectAll.changedByItemToggle === false) {
      const updatedOwners = filteredOwners.map(owner => {
        return {
          ...owner,
          us_profiles: owner.us_profiles.map(profile => ({
            ...profile,
            isSelected: true,
          })),
        };
      });
      setFilteredOwners(updatedOwners);
    }

    if (selectAll.status === false && selectAll.changedByItemToggle === false) {
      const updatedOwners = filteredOwners.map(owner => {
        return {
          ...owner,
          us_profiles: owner.us_profiles.map(profile => ({
            ...profile,
            isSelected: false,
          })),
        };
      });
      setFilteredOwners(updatedOwners);
    }
  }, [selectAll.status]);

  useEffect(() => {
    let updatedOwners = [...ownersWithSelectableDigitalProfiles];

    // Apply the filter conditions

    // SHOW BY CONDITIONS

    // LEADS WITH 0 OWNERS
    if (selectedRadioOption.id === 2) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 0;
      });
    }

    // LEADS WITH 1 OWNERS
    else if (selectedRadioOption.id === 3) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 1;
      });
    } else if (selectedRadioOption.id === 4) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 2;
      });
    } else if (selectedRadioOption.id === 5) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 3;
      });
    } else if (selectedRadioOption.id === 6) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 4;
      });
    } else if (selectedRadioOption.id === 7) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 5;
      });
    } else if (selectedRadioOption.id === 8) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 6;
      });
    } else if (selectedRadioOption.id === 9) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 7;
      });
    } else if (selectedRadioOption.id === 10) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 8;
      });
    } else if (selectedRadioOption.id === 11) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length === 9;
      });
    } else if (selectedRadioOption.id === 12) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.us_profiles.length >= 10;
      });
    } else if (selectedRadioOption.id === 13) {
      updatedOwners = updatedOwners
        .filter(owner => {
          return owner.us_profiles.some(
            profile => profile.is_contender === null || profile.is_contender === undefined
          );
        })
        .map(owner => ({
          ...owner,
          us_profiles: owner.us_profiles.filter(
            profile => profile.is_contender === null || profile.is_contender === undefined
          ),
        }));
    } else if (selectedRadioOption.id === 14) {
      updatedOwners = updatedOwners
        .filter(owner => {
          return owner.us_profiles.some(profile => profile.is_contender === false);
        })
        .map(owner => ({
          ...owner,
          us_profiles: owner.us_profiles.filter(profile => profile.is_contender === false),
        }));
    } else if (selectedRadioOption.id === 15) {
      updatedOwners = updatedOwners
        .filter(owner => {
          return owner.us_profiles.some(profile => profile.is_contender === true);
        })
        .map(owner => ({
          ...owner,
          us_profiles: owner.us_profiles.filter(profile => profile.is_contender === true),
        }));
    }

    // Handle the selectAll logic
    if (selectAll.status === true) {
      updatedOwners = updatedOwners.map(owner => {
        return {
          ...owner,
          us_profiles: owner.us_profiles.map(profile => ({
            ...profile,
            isSelected: true,
          })),
        };
      });
    }

    setFilteredOwners(updatedOwners);
  }, [selectedFilters, selectedRadioOption, campaign, fixedAddresses]);

  const handleIndividualChange = (ownerId: number, profileId: number) => {
    // Find the specific owner and digital profile
    const owner = filteredOwners.find(owner => owner.id === ownerId);
    const profile = owner?.us_profiles.find(profile => profile.id === profileId);
    const profileWillBeSelected = !profile?.isSelected;

    // Update the owners
    const updatedOwners = filteredOwners.map(owner => {
      if (owner.id === ownerId) {
        return {
          ...owner,
          us_profiles: owner.us_profiles.map(profile =>
            profile.id === profileId ? { ...profile, isSelected: profileWillBeSelected } : profile
          ),
        };
      }
      return owner;
    });

    // If the digital profile is about to be selected, check if all profiles will be selected
    if (profileWillBeSelected) {
      const allProfilesSelected = updatedOwners.every(owner =>
        owner.us_profiles.every(profile => profile.isSelected)
      );
      if (allProfilesSelected) {
        setSelectAll({ status: true, changedByItemToggle: true });
      }
    }
    // If the digital profile is about to be unselected and selectAll is currently true, update selectAll
    else if (selectAll.status) {
      setSelectAll({ status: false, changedByItemToggle: true });
    }

    // Update the filteredOwners state
    setFilteredOwners(updatedOwners);
  };

  return (
    <>
      <div className="flex w-full">
        <Callout
          title="Decide which profiles are contenders and add them to the Overlead CSV"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>1. Make decisions for Owners with 1 profile</div>
          <div>2. Make decisions for Owners with 2+ owners</div>
          <div>3. Select all contender profiles and export them</div>
        </Callout>
      </div>

      <div className="flex space-x-4 justify-between">
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 Profile</Text>
          <Metric>
            {(() => {
              const addressesWithProfile = fixedAddresses.filter(lead =>
                lead.us_owners.some(owner => owner.us_profiles.length > 0)
              ).length;
              const totalCount = fixedAddresses.length;
              const percentage = ((addressesWithProfile / totalCount) * 100).toFixed(0);
              return `${addressesWithProfile} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>

        <Card className="max-w-xs mt-6" decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 Contender Profile</Text>
          <Metric>
            {(() => {
              const addressesWithContenderProfile = fixedAddresses.filter(lead =>
                lead.us_owners.some(owner =>
                  owner.us_profiles.some(profile => profile.is_contender === true)
                )
              ).length;
              const totalCount = fixedAddresses.length;
              const percentage = ((addressesWithContenderProfile / totalCount) * 100).toFixed(0);
              return `${addressesWithContenderProfile} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>
      </div>

      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  checked={selectAll.status}
                  onChange={() =>
                    setSelectAll(selectAll => ({
                      status: !selectAll.status,
                      changedByItemToggle: false,
                    }))
                  }
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (
                  {filteredOwners.reduce((acc, owner) => {
                    return acc + owner.us_profiles.filter(profile => profile.isSelected).length;
                  }, 0)}
                  )
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all profiles that you can currently see
                </p>
              </div>
            </div>

            <Dropdown
              items={[
                {
                  icon: HandThumbDownIcon,
                  name: "Mark as Rejected",
                  function: async () => {
                    const selectedProfiles = filteredOwners
                      .flatMap(owner => owner.us_profiles)
                      .filter(profile => profile.isSelected)
                      .map(profile => ({ usProfileId: profile.id, isContender: false }));

                    if (selectedProfiles.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    if (!campaign?.id) {
                      toast.error("Campaign ID not found");
                      return;
                    }
                    await updateProfileContenderDecisionsMutation({
                      usCampaignId: campaign.id,
                      usProfileContenderDecisions: selectedProfiles,
                    });
                  },
                },
                {
                  icon: HandThumbUpIcon,
                  name: "Mark as Contender",
                  function: async () => {
                    const selectedProfiles = filteredOwners
                      .flatMap(owner => owner.us_profiles)
                      .filter(profile => profile.isSelected)
                      .map(profile => ({ usProfileId: profile.id, isContender: true }));

                    if (selectedProfiles.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    if (!campaign?.id) {
                      toast.error("Campaign ID not found");
                      return;
                    }
                    await updateProfileContenderDecisionsMutation({
                      usCampaignId: campaign.id,
                      usProfileContenderDecisions: selectedProfiles,
                    });
                  },
                },

                {
                  icon: MagnifyingGlassCircleIcon,
                  name: "Export",
                  function: async () => {
                    const selectedProfiles = filteredOwners
                      .flatMap(owner => owner.us_profiles)
                      .filter(profile => profile.isSelected)
                      .map(profile => ({
                        profileName: profile.name,
                        profileUrl: profile.identifier,
                      }));

                    if (selectedProfiles.length === 0) {
                      toast.error("Please select profiles to export");
                      return;
                    }

                    const csv = Papa.unparse(selectedProfiles);

                    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
                    saveAs(blob, "selected_profiles.csv");
                  },
                },
              ]}
            />
          </div>

          <Divider className="mt-1 mb-0" />

          <div className="py-4 px-4">
            <RadioGroup
              value={selectedRadioOption}
              onChange={setSelectedRadioOption}
              className="mt-2"
            >
              <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium text-gray-900 mb-2">Show Only (Owners):</h2>
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6">
                {digitalProfilesRadioOptions.slice(0, 4).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6">
                {digitalProfilesRadioOptions.slice(4, 8).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6">
                {digitalProfilesRadioOptions.slice(8, 12).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
              <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium text-gray-900 mt-3">Show Only (Profiles):</h2>
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6 mt-2">
                {digitalProfilesRadioOptions.slice(12, 16).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? option.id === 14
                            ? "bg-pink-600 border-transparent text-white hover:bg-pink-700"
                            : option.id === 15
                            ? "bg-green-600 border-transparent text-white hover:bg-green-700"
                            : "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : option.id === 14
                          ? "bg-pink-50 border border-pink-500"
                          : option.id === 15
                          ? "bg-green-50 border border-green-500"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {filteredOwners.map(owner => {
          return (
            <Card className={"max-w-md mx-auto"}>
              <Flex className="mb-2">
                <Text>{owner.provider}</Text>

                {owner.is_contender === true ? (
                  <Text color="green">
                    <Bold>Contender</Bold>
                  </Text>
                ) : owner.is_contender === false ? (
                  <Text color="red">
                    <Bold>Rejected</Bold>
                  </Text>
                ) : owner.is_contender === null || owner.is_contender === undefined ? (
                  <Text color="gray">
                    <Bold>Unknown</Bold>
                  </Text>
                ) : null}
              </Flex>

              <Flex justifyContent="start" className="space-x-1 flex-col" alignItems="baseline">
                <Metric>
                  {owner.first_name} {owner.last_name}
                </Metric>
                <p className="text-sm  text-gray-400">{owner.full_name}</p>
              </Flex>

              <Flex className="mt-2 ">
                <Text>{owner.stated_address}</Text>
              </Flex>

              <Flex className=" ">
                <p className="text-xs text-gray-300"> The above is the address tied to the owner</p>
              </Flex>

              <Flex className="w-full">
                <Bold className="mt-6 w-full pt-4 border-t">Digital Profiles</Bold>
              </Flex>

              <AccordionList className="mt-6 ">
                {[...owner.us_profiles]
                  .sort((a, b) => {
                    const distanceA = a.computed_additional_info?.distance;
                    const distanceB = b.computed_additional_info?.distance;

                    if (
                      distanceA !== undefined &&
                      distanceA !== null &&
                      distanceB !== undefined &&
                      distanceB !== null
                    ) {
                      // If both distances are available, sort numerically
                      return distanceA - distanceB;
                    } else if (distanceA !== undefined) {
                      // If only distanceA is available, place it before distanceB
                      return -1;
                    } else if (distanceB !== undefined) {
                      // If only distanceB is available, place it after distanceA
                      return 1;
                    } else {
                      // If both are undefined, keep the original order
                      return 0;
                    }
                  })
                  .map((profile, idx) => {
                    return (
                      <Accordion
                        key={profile.id}
                        className={classNames(
                          profile.is_contender === false
                            ? "border border-pink-500 bg-pink-50"
                            : profile.is_contender === true
                            ? "border border-green-500 bg-green-50"
                            : "",
                          " "
                        )}
                      >
                        <AccordionHeader className="w-full">
                          <div className="flex flex-row justify-between items-center w-full">
                            <div className="flex  h-5 items-center mr-3">
                              <input
                                type="checkbox"
                                checked={profile.isSelected}
                                onClick={event => event.stopPropagation()}
                                onChange={event => {
                                  event.stopPropagation();

                                  handleIndividualChange(owner.id, profile.id);
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                            </div>
                            <div className="w-full ">
                              <Text>{profile.name ?? profile.identifier}</Text>
                              <div className="text-center	 text-gray-400 text-xs">
                                {profile.primary_additional_info?.location}-{" "}
                                {profile.computed_additional_info?.distance
                                  ? `${Math.round(
                                      profile.computed_additional_info?.distance / 1000
                                    ).toFixed(0)}km`
                                  : "?km"}
                              </div>
                            </div>
                            <div className="flex flex-row justify-center items-center ">
                              <Badge color="orange" className="mr-2">
                                <span className="text-xs">
                                  {profile.is_contender === true ? "Contender" : "Not Contender"}
                                </span>
                              </Badge>
                            </div>
                          </div>
                        </AccordionHeader>
                        <AccordionBody>
                          <a href={profile.identifier} target="_blank" rel="noopener noreferrer">
                            <button className="my-4 rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                              Go to Linkedin
                            </button>
                          </a>

                          <Text className="mt-2">
                            Full Name: {profile.primary_additional_info?.fullName}
                          </Text>

                          <Text className="mt-2">
                            Linkedin Location: {profile.primary_additional_info?.location}
                          </Text>

                          <Text className="mt-2">
                            Matched Location:{" "}
                            <span className="font-semibold">
                              {profile.computed_additional_info?.placeName}
                            </span>
                          </Text>

                          <Text className="mt-2">Job: {profile.primary_additional_info?.job}</Text>
                          {profile.primary_additional_info?.summary && (
                            <Text className="mt-2">
                              Summary: {profile.primary_additional_info?.summary}
                            </Text>
                          )}
                        </AccordionBody>
                      </Accordion>
                    );
                  })}
              </AccordionList>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default DigitalProfilesTab;
