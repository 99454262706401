import {
  GetCampaignByIdQuery,
  GetCampaignLeadProfilesQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import Select from "react-select";
import { fieldNames } from "../data";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FieldValues } from "react-hook-form";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import Uppy from "@uppy/core";
import { useEffect, useState } from "react";
import { DashboardModal } from "@uppy/react";
import { toast } from "react-hot-toast";
interface SyncCampaignTabProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"];
  campaignLeadProfiles: GetCampaignLeadProfilesQuery["campaign_lead_profile"];

  campaignSyncer: (campaignId: number, externalId: number) => Promise<void>;
}

const SyncCampaignTab = ({
  campaign,
  campaignLeadProfiles,
  campaignSyncer,
}: SyncCampaignTabProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: FieldValues) => {
    if (!campaign) return;
    await campaignSyncer(campaign.id, data.externalId);
  };

  const syncedLeadProfiles = campaignLeadProfiles.filter(leadProfile => leadProfile.external_id);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full pb-60  h-full mt-5 relative">
        <div className="block text-sm font-medium text-gray-700">Sync Campaign</div>
        <span className="text-xs text-gray-500">
          In order to display all the leads and conversations to our users, we need to sync overlead
          campaign with meetmore campaign by connecting the Overlead Campaign Id.
        </span>
        <div className="w-full mt-2 h-max bg-orange-200 border border-orange-500 rounded py-2 px-2">
          Connected to Overlead Seat ID (Must be connected for sync to work):{" "}
          <span className="font-semibold">
            {" "}
            {campaign?.outreach_agent?.external_id ?? "Not connected to an overlead seat"}{" "}
          </span>
        </div>
        <div className="flex flex-col rounded-md shadow-sm">
          <div className="w-full mt-5 h-max bg-orange-200 border border-orange-500 rounded py-2 px-2">
            Digital Profiles Synced with Overlead:{" "}
            <span className="font-semibold">
              {" "}
              {syncedLeadProfiles.length} / {campaignLeadProfiles.length}{" "}
            </span>
          </div>
        </div>

        <button
          type="submit"
          className="w-full mt-5 inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
        >
          Sync with Overlead
        </button>
      </form>
    </>
  );
};

export default SyncCampaignTab;
