import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import {
  GetCampaignByIdQuery,
  GetTrackingGroupsByCampaignIdQuery,
  GetTrackingGroupsByUsCampaignIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Badge,
  BarList,
  Bold,
  Button,
  Callout,
  Card,
  Divider,
  Flex,
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Title,
} from "@tremor/react";

import {
  ArrowRightIcon,
  ShieldCheckIcon,
  AdjustmentsVerticalIcon,
  TrashIcon,
  BoltIcon,
  BriefcaseIcon,
  ScissorsIcon,
  HomeIcon,
  TruckIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowRightCircleIcon,
  ShareIcon,
  MagnifyingGlassCircleIcon,
  ArrowPathIcon,
  MegaphoneIcon,
  CurrencyPoundIcon,
  UserIcon,
  LinkIcon,
  HomeModernIcon,
  SwatchIcon,
} from "@heroicons/react/24/solid";
import * as yup from "yup";

import { ArrayElement, classNames } from "../../../../shared/internals/utils";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";

import { UseUSCampaignByIdReturnType } from "../graphql/hooks/useUSCampaignById";
import {
  AddAddressesToUsCampaignAPIOptions,
  useAddAddressesToUSCampaignMutation,
} from "../api/addAddressesToUsCampaign";
import { UseMutateAsyncFunction } from "react-query";
import toast from "react-hot-toast";
import { addressesSchema, addressSchema } from "../validation";
import { TriggerTrackingGroupBody } from "../../singleCampaign/api/triggerTrackingGroup";
import { CancelTrackingGroupAPIOptions } from "../../singleCampaign/api/cancelTrackingGroup";
import { PauseTrackingGroupAPIOptions } from "../../singleCampaign/api/pauseTrackingGroup";
import dayjs from "dayjs";
import { TRACKING_GROUP_TYPE } from "../../../../shared/internals/constants/models";
interface AddressesTabProps {
  usCampaign: UseUSCampaignByIdReturnType["campaign"];
  fixedAddresses: UseUSCampaignByIdReturnType["fixedAddresses"];
  addAddressesToUsCampaignMutation: UseMutateAsyncFunction<
    any,
    Error,
    AddAddressesToUsCampaignAPIOptions,
    void
  >;
  incompleteGetUSOwnersGroups: GetTrackingGroupsByUsCampaignIdQuery["tracking_group"];
  lastIncompleteGetUSOwnersGroup?: ArrayElement<
    GetTrackingGroupsByUsCampaignIdQuery["tracking_group"]
  >;
  getUSOwnersGroups: GetTrackingGroupsByUsCampaignIdQuery["tracking_group"];
  triggerTrackingGroup: UseMutateAsyncFunction<any, any, TriggerTrackingGroupBody, void>;
  triggerTrackingGroupLoading: boolean;
  cancelTrackingGroup: UseMutateAsyncFunction<any, any, CancelTrackingGroupAPIOptions, void>;
  pauseTrackingGroup: UseMutateAsyncFunction<any, any, PauseTrackingGroupAPIOptions, void>;
  pauseTrackingGroupLoading: boolean;
  cancelTrackingGroupLoading: boolean;
}

export const AddressesTab = ({
  usCampaign,
  fixedAddresses,
  addAddressesToUsCampaignMutation,
  triggerTrackingGroup,
  triggerTrackingGroupLoading,
  cancelTrackingGroup,
  pauseTrackingGroup,
  pauseTrackingGroupLoading,
  cancelTrackingGroupLoading,
  getUSOwnersGroups,
}: AddressesTabProps) => {
  const selectableAddresses = fixedAddresses.map(lead => ({ ...lead, isSelected: false }));
  const [filteredAddresses, setFilteredAddresses] = useState(selectableAddresses);
  const [selectAll, setSelectAll] = useState<{ status: boolean; changedByItemToggle: boolean }>({
    status: false,
    changedByItemToggle: false,
  });

  useEffect(() => {
    if (selectAll.status === true && selectAll.changedByItemToggle === false) {
      const updatedAddresses = filteredAddresses.map(lead => ({ ...lead, isSelected: true }));
      setFilteredAddresses(updatedAddresses);
    }

    if (selectAll.status === false && selectAll.changedByItemToggle === false) {
      const updatedAddresses = filteredAddresses.map(lead => ({ ...lead, isSelected: false }));
      setFilteredAddresses(updatedAddresses);
    }
  }, [selectAll.status]);

  useEffect(() => {
    let updatedLeads = [...selectableAddresses];

    // Handle the selectAll logic
    if (selectAll.status === true) {
      updatedLeads = updatedLeads.map(lead => ({ ...lead, isSelected: true }));
    }

    setFilteredAddresses(updatedLeads);
  }, [fixedAddresses, usCampaign]);

  const handleIndividualChange = (id: number) => {
    // Determine the future state of the item being toggled
    const itemWillBeSelected = !filteredAddresses.find(lead => lead.id === id)?.isSelected;

    // If the item is about to be selected, check if this will cause all items to be selected
    if (itemWillBeSelected) {
      const allOthersSelected = filteredAddresses.every(lead => lead.id === id || lead.isSelected);
      if (allOthersSelected) {
        setSelectAll({ status: true, changedByItemToggle: true });
      }
    }
    // If the item is about to be unselected and selectAll is currently true, update selectAll
    else if (selectAll.status) {
      setSelectAll({ status: false, changedByItemToggle: true });
    }

    // Update the leads
    setFilteredAddresses(
      filteredAddresses.map(lead =>
        lead.id === id ? { ...lead, isSelected: itemWillBeSelected } : lead
      )
    );
  };

  const handleCSVUpload = async (data: string[][]) => {
    if (!usCampaign) {
      toast.error("Campaign not found");
      return;
    }
    const structuredData = data
      .filter(row => row[0].trim() !== "") // Filter out rows where the address is empty
      .map(row => ({
        address: row[0],
        streetAddress: row[1],
        city: row[2],
        state: row[3],
        zip: row[4],
        county: row[5] || null,
        district: row[6] || null,
        municipality: row[7] || null,
        stateCode: row[8]?.trim(),
      }));

    try {
      await addressesSchema.validate(structuredData, { abortEarly: false });
      await addAddressesToUsCampaignMutation({
        usCampaignId: usCampaign.id,
        addresses: structuredData,
      });
      toast.success("Addresses uploaded successfully");
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        toast.error(`Validation Error: ${error.errors.join(", ")}`);
      } else {
        toast.error(`Error uploading addresses: ${error.message}`);
      }
    }
  };

  return (
    <>
      <div className="flex w-full">
        <Callout
          title="Here are the addresses in your campaign"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>
            1. You can add additional addresses by clicking import addresses and uploading a CSV
          </div>
          <div>
            2. You can attempt to fetch owners for the selected addresses by clicking Get Owners
          </div>
        </Callout>
      </div>

      <div className="mt-5">
        {getUSOwnersGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              getUSOwnersGroups[0].is_cancelled === true
                ? "red"
                : getUSOwnersGroups[0].is_completed === true &&
                  getUSOwnersGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              getUSOwnersGroups[0].is_cancelled === true
                ? "Cancelled"
                : getUSOwnersGroups[0].is_completed === true &&
                  getUSOwnersGroups[0].is_cancelled === false
                ? "Completed"
                : getUSOwnersGroups[0].is_error === true
                ? "In Error"
                : getUSOwnersGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = getUSOwnersGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = getUSOwnersGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = getUSOwnersGroups.slice(1).map(group => {
              const completedTrackingItemsCount = getUSOwnersGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Owners Fetch ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(getUSOwnersGroups[0].is_error || getUSOwnersGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: getUSOwnersGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!usCampaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: getUSOwnersGroups[0].id,
                              campaignId: 5,
                              usCampaignId: usCampaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.GET_US_OWNERS,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!getUSOwnersGroups[0].is_error &&
                      !getUSOwnersGroups[0].is_paused &&
                      !getUSOwnersGroups[0].is_cancelled &&
                      !getUSOwnersGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: getUSOwnersGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} items completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Triggered:{" "}
                  <Bold>{dayjs(getUSOwnersGroups[0].created_at).format("DD/MM/YYYY mm:ss")}</Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text> Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Requests"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>

      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  checked={selectAll.status}
                  onChange={() =>
                    setSelectAll(selectAll => ({
                      status: !selectAll.status,
                      changedByItemToggle: false,
                    }))
                  }
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All ({filteredAddresses.filter(lead => lead.isSelected).length})
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all Addresses that you can currently see
                </p>
              </div>
            </div>
            <div className="flex flex-row space-x-10">
              <Dropdown
                items={[
                  {
                    icon: BoltIcon,
                    name: "Get Owners",
                    function: async () => {
                      const addressIds = filteredAddresses
                        .filter(lead => lead.isSelected)
                        .map(lead => String(lead.id));
                      if (addressIds.length === 0) {
                        toast.error("Please select addresses to perform operations on");
                        return;
                      }

                      if (!usCampaign?.id) {
                        toast.error("Campaign not found");
                        return;
                      }

                      triggerTrackingGroup({
                        usCampaignId: usCampaign.id,
                        campaignId: 5,
                        trackingGroupTypeId: TRACKING_GROUP_TYPE.GET_US_OWNERS,
                        trackingItemValues: addressIds,
                      });
                    },
                  },
                ]}
              />
            </div>
          </div>
          <Divider className="mt-1 mb-0" />
          <div className="flex p-4 justify-center w-full items-center space-x-4">
            <Text>Displaying</Text>
            <Metric className="text-gray-500">{filteredAddresses.length}</Metric>
            <Text> / {selectableAddresses.length} Addresses </Text>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <CSVReader onFileLoaded={handleCSVUpload} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {filteredAddresses.map(lead => {
          return (
            <Card key={lead.id} className={classNames("max-w-md mx-auto ")}>
              <Flex className="mb-2">
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      checked={lead.isSelected}
                      onChange={() => handleIndividualChange(lead.id)}
                      className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                    />
                  </div>
                </div>
              </Flex>

              <div className="address-details-container">
                <div className="address-main text-lg font-bold mb-2">{lead.address}</div>
                <div className="address-details flex flex-wrap gap-4">
                  <div className="address-field">
                    <span className="field-name text-sm text-gray-600">Street Address:</span>
                    <span className="field-value text-base"> {lead.street_address}</span>
                  </div>
                  <div className="address-field">
                    <span className="field-name text-sm text-gray-600">City:</span>
                    <span className="field-value text-base"> {lead.city}</span>
                  </div>
                  <div className="address-field">
                    <span className="field-name text-sm text-gray-600">County:</span>
                    <span className="field-value text-base"> {lead.county}</span>
                  </div>
                  <div className="address-field">
                    <span className="field-name text-sm text-gray-600">District:</span>
                    <span className="field-value text-base"> {lead.district}</span>
                  </div>
                  <div className="address-field">
                    <span className="field-name text-sm text-gray-600">State:</span>
                    <span className="field-value text-base"> {lead.state}</span>
                  </div>
                  <div className="address-field">
                    <span className="field-name text-sm text-gray-600">State Code:</span>
                    <span className="field-value text-base"> {lead.state_code}</span>
                  </div>
                  <div className="address-field">
                    <span className="field-name text-sm text-gray-600">Municipality:</span>
                    <span className="field-value text-base"> {lead.municipality}</span>
                  </div>
                  <div className="address-field">
                    <span className="field-name text-sm text-gray-600">ZIP:</span>
                    <span className="field-value text-base"> {lead.zip}</span>
                  </div>
                </div>
                <div
                  className={`badge mt-2 px-2 py-1 rounded text-white text-sm ${
                    lead.has_owner_searched ? "bg-green-500" : "bg-red-500"
                  }`}
                >
                  {lead.has_owner_searched ? "Owner Searched" : "Owner Not Searched"}
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
};
