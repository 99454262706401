// useBulkDeleteCampaignLeads.ts

import { ApolloError } from "@apollo/client";
import { useBulkDeleteCampaignLeadsMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";

export interface BulkDeleteCampaignLeadsHookResult {
  bulkDeleteCampaignLeads: (options: BulkDeleteCampaignLeadsMutationOptions) => Promise<void>;
}

export interface BulkDeleteCampaignLeadsMutationOptions {
  ids: number[];
  currentDate: string; // Assuming it's a string, adjust if needed
}

export const useBulkDeleteCampaignLeads = (): BulkDeleteCampaignLeadsHookResult => {
  const [bulkDeleteMutation] = useBulkDeleteCampaignLeadsMutation({
    onError: error => {
      throw error;
    },
  });

  return {
    bulkDeleteCampaignLeads: async ({
      ids,
      currentDate,
    }: BulkDeleteCampaignLeadsMutationOptions) => {
      await bulkDeleteMutation({
        variables: {
          ids,
          currentDate,
        },
      });
    },
  };
};
