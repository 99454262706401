import Logo from "../../../../shared/internals/assets/logo.svg";

const Confirmation = () => {
  return (
    <div className="min-h-screen flex-wrap flex flex-col items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Meetmore" />
          <h2 className="mt-6 text-center text-3xl font-extrabold ">
            Your log in link is in your email!
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <p className="text-center text-xl text-orange-400  ">
              You're 1 step away from wowing your local landlords and Vendors.
            </p>
            <p className="mt-6 text-center text-xl  ">
              <span className="font-bold">Please check your inbox</span>, or spam folder, for your
              sign in link.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
