import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";
import { useMutation } from "react-query";
import { OWNER_ADDRESS_VALIDATION_TYPE } from "../../../../../shared/internals/constants/models";

export interface ValidateOwnerAddressBody {
  changes: { ownerId: number; addressId: number; validationType: OWNER_ADDRESS_VALIDATION_TYPE }[];
}

export const validateOwnerAddressAPI = async ({ changes }: ValidateOwnerAddressBody) => {
  const response = await meetmoreAPI.post(`/campaigns/validate-owner-address`, {
    changes,
  });
  return response.data;
};

export const useValidateOwnerAddress = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchCampaign } = useCampaignById({
    id: refetchId ?? -1,
  });

  return useMutation(validateOwnerAddressAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Acting on Owner Addresses...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Acted on Owner Addresses");
      if (refetchId) {
        await refetchCampaign();
      }
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error Acting on Owner Addresses: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
