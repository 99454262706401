export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export interface Tab {
  name: string;
  current: boolean;
  count?: number;
}

export const selectTab = ({
  tabIndex,
  tabsState,
  setTabsState,
}: {
  tabIndex: number;
  tabsState: Tab[];
  setTabsState: React.Dispatch<React.SetStateAction<Tab[]>>;
}) => {
  const tabs = [...tabsState];
  const resetTabs = tabs.map(tab => {
    tab.current = false;
    return tab;
  });
  resetTabs[tabIndex].current = true;
  setTabsState(resetTabs);
  return resetTabs[tabIndex];
};

export const currencyFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const extractCurrentTab = (tabs: Tab[]): Tab => {
  const current = tabs.filter(tab => tab.current);
  if (current.length > 1) {
    throw new Error("Multiple Current Tabs");
  }
  return current[0];
};
