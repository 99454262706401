import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";
import { useMutation } from "react-query";

export interface AutomaticDigitalProfilesConfirmation {
  ownerAddressIds: number[];
  campaignId: number;
}

export const automaticDigitalProfilesConfirmationAPI = async ({
  campaignId,
  ownerAddressIds,
}: AutomaticDigitalProfilesConfirmation) => {
  const response = await meetmoreAPI.post(`/campaigns/confirm-profiles`, {
    campaignId,
    ownerAddressIds,
  });
  return response.data;
};

export const useAutomaticDigitalProfilesMutation = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchSearchGroups } = useCampaignById({
    id: refetchId ?? -1,
  });
  return useMutation(automaticDigitalProfilesConfirmationAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Automatic Decisions...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Automatic Decisions Made");
      await refetchSearchGroups();
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error Making Automatic Decisions: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
