import { useMutation } from "@apollo/client";
import { toast } from "react-hot-toast";
import { useDeleteCampaignLeadMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";

interface DeleteCampaignLeadMutationOptions {
  campaignLeadId: number;
  currentDate: string;
}

interface DeleteHookResult {
  deleteCampaignLead: (options: DeleteCampaignLeadMutationOptions) => Promise<void>;
}

export const useDeleteCampaignLead = (): DeleteHookResult => {
  const [deleteLeadMutation] = useDeleteCampaignLeadMutation({
    onError: error => {
      throw error;
    },
  });

  return {
    deleteCampaignLead: async ({
      campaignLeadId,
      currentDate,
    }: DeleteCampaignLeadMutationOptions) => {
      await deleteLeadMutation({
        variables: {
          id: campaignLeadId,
          currentDate: currentDate,
        },
      });
    },
  };
};
