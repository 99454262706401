import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import Spinner from "../../../../shared/internals/components/spinner/Spinner";
import { confirmMagicLinkValidationSchema } from "../validation";
import { fieldNames } from "../data";
import { ConfirmMagicLinkFormValues } from "../types";
import { signInWithLink } from "../../../../shared/infrastructure/firebase";
import Logo from "../../../../shared/internals/assets/logo.svg";

const ConfirmMagicLink = () => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ConfirmMagicLinkFormValues>({
    resolver: yupResolver(confirmMagicLinkValidationSchema),
  });

  useEffect(() => {
    const emailForSignIn = window.localStorage.getItem("emailForSignIn");
    if (emailForSignIn) {
      setValue("email", emailForSignIn);
      onSubmit({ email: emailForSignIn });
    }
  }, [handleSubmit, setValue]);

  const onSubmit = async ({ email }: ConfirmMagicLinkFormValues) => {
    setLoading(true);
    await signInWithLink({ email, link: window.location.href });
    setLoading(false);
  };

  return (
    <div className=" min-h-screen flex-wrap flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 self-center">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Meetmore" />
          <h2 className="mt-6 text-center text-3xl font-extrabold">Sign In with Link</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or &nbsp;
            <Link to="/" className="font-medium text-orange-600 hover:text-orange-500">
              request new sign in link
            </Link>
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor={fieldNames.email}
                className="mb-1 block text-sm font-medium text-gray-700"
              >
                Email address{" "}
                <span className="text-xs italic text-gray-400">
                  (that received the sign in link)
                </span>
              </label>
              <input
                {...register("email")}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
              {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
            </div>
          </div>

          <div>
            <button
              disabled={loading ? true : false}
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {loading ? (
                  <Spinner />
                ) : (
                  <svg
                    className="h-5 w-5 text-orange-500 group-hover:text-orange-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                )}
              </span>
              {loading ? "Loading" : "Sign in"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmMagicLink;
