import { ApolloError, LazyQueryExecFunction } from "@apollo/client";
import {
  GetPostcodeUnitByIdQuery,
  useGetPostcodeUnitByIdQuery,
  useGetPostcodeUnitByIdLazyQuery,
  Exact,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";

interface HookResult {
  postcodeUnit: GetPostcodeUnitByIdQuery["postcode_unit_by_pk"];
  postcodeUnitError?: ApolloError;
  postcodeUnitLoading: boolean;
  refetch: () => Promise<void>;
}

export interface GetPostcodeUnitByIdLazyHookResult extends HookResult {
  getPostcodeUnitById: LazyQueryExecFunction<
    GetPostcodeUnitByIdQuery,
    Exact<{
      id: number;
    }>
  >;
}

export const usePostcodeUnitById = ({ id }: { id: number }): HookResult => {
  const { data, error, loading, refetch } = useGetPostcodeUnitByIdQuery({
    variables: {
      id,
    },
  });

  const refetchPostcodeUnit = async () => {
    await refetch();
  };

  return {
    postcodeUnit: data && data.postcode_unit_by_pk,
    postcodeUnitError: error,
    postcodeUnitLoading: loading,
    refetch: refetchPostcodeUnit,
  };
};

export const useLazyPostcodeUnitById = (): GetPostcodeUnitByIdLazyHookResult => {
  const [getPostcodeUnitById, { data, loading, error, refetch }] =
    useGetPostcodeUnitByIdLazyQuery();

  const refetchPostcodeUnit = async () => {
    await refetch();
  };

  return {
    getPostcodeUnitById: getPostcodeUnitById,
    postcodeUnit: data && data.postcode_unit_by_pk,
    postcodeUnitError: error,
    postcodeUnitLoading: loading,
    refetch: refetchPostcodeUnit,
  };
};
