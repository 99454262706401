// useBulkDeleteCampaignLeadProfiles.ts

import { ApolloError } from "@apollo/client";
import { useBulkDeleteCampaignLeadProfilesMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";
import { getCampaignById } from "../queries/getCampaignById";
import { getCampaignLeadProfiles } from "../queries/getCampaignLeadProfiles";

export interface BulkDeleteCampaignLeadProfilesHookResult {
  bulkDeleteCampaignLeadProfiles: (
    options: BulkDeleteCampaignLeadProfilesMutationOptions
  ) => Promise<void>;
}

export interface BulkDeleteCampaignLeadProfilesMutationOptions {
  ids: number[];
}

export const useBulkDeleteCampaignLeadProfiles = ({
  refetchCampaignId,
}: {
  refetchCampaignId: number;
}): BulkDeleteCampaignLeadProfilesHookResult => {
  const [bulkDeleteMutation] = useBulkDeleteCampaignLeadProfilesMutation({
    refetchQueries: [
      { query: getCampaignById, variables: { id: refetchCampaignId } },
      { query: getCampaignLeadProfiles, variables: { id: refetchCampaignId } },
    ],
    awaitRefetchQueries: true,

    onCompleted: () => {
      toast.success("Added to Overlead CSV successfully!");
    },
    onError: error => {
      toast.error(`Failed to add to Overlead CSV: ${error.message}`);
    },
  });

  return {
    bulkDeleteCampaignLeadProfiles: async ({
      ids,
    }: BulkDeleteCampaignLeadProfilesMutationOptions) => {
      await bulkDeleteMutation({
        variables: {
          ids,
          currentDate: new Date().toISOString(),
        },
      });
    },
  };
};
