import { useGetUsCampaignByIdQuery } from "../../../../../shared/infrastructure/graphQL/generatedTypes";

export type UseUSCampaignByIdReturnType = ReturnType<typeof useUSCampaignById>;

export const useUSCampaignById = ({ id }: { id: number }) => {
  const { data, error, loading, refetch } = useGetUsCampaignByIdQuery({
    variables: {
      campaignId: id,
    },
    skip: id === -1,
  });

  const refetchCampaign = async () => {
    await refetch();
  };

  const fixedAddresses = data?.us_campaign_by_pk?.us_addresses
    ? [...data.us_campaign_by_pk.us_addresses].sort((leadA, leadB) => leadA.id - leadB.id)
    : [];

  return {
    campaign: data && data.us_campaign_by_pk,
    campaignError: error,
    fixedAddresses,
    campaignLoading: loading,
    refetch: refetchCampaign,
  };
};
