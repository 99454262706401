/// FOR DIGBY
/// 1) CREATE MUTATION HERE.see pauseTrackingGroup

import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useMutation } from "react-query";
import { useTrackingGroupsByCampaignId } from "../../graphql/hooks/useTrackingGroupsByCampaignId";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";

export interface addOrRemoveCampaignLeadProfilesAPIOptions {
  campaignId: number;
  toAdd: {
    campaignLeadId: number;
    digitalProfileId: number;
    ownerAddressProfileId: number;
  }[];
}

export const addOrRemoveCampaignLeadProfilesAPI = async ({
  campaignId,
  toAdd,
}: addOrRemoveCampaignLeadProfilesAPIOptions) => {
  const response = await meetmoreAPI.post(`/campaigns/add-or-remove-campaign-lead-profiles`, {
    campaignId,
    toAdd,
  });
  return response.data;
};

export const useAddOrRemoveCampaignLeadProfilesMutation = ({
  refetchId,
}: {
  refetchId?: number;
}) => {
  const { refetch: refetchCampaign } = useCampaignById({
    id: refetchId ?? -1,
  });
  let loadingToastId: string;

  return useMutation(addOrRemoveCampaignLeadProfilesAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Adding profiles...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Added profiles successfully!");
      await refetchCampaign();
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error adding profiles: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
