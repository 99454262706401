import { Fragment, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/20/solid";
import { ArrayElement, classNames } from "../../../../../shared/internals/utils";
import {
  GetCampaignByIdQuery,
  GetDoorKnockingCampaignByIdQuery,
  GetMessageSetsQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { UpdateCampaignMessageSetMutationOptions } from "../../graphql/hooks/useUpdateCampaignMessageSet";

interface TemplateSelectorModalProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"] | GetDoorKnockingCampaignByIdQuery["campaign_by_pk"];
  messageSet: ArrayElement<GetMessageSetsQuery["message_set"]>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  isFreelancer: boolean;
  updateCampaignMessageSet: (options: UpdateCampaignMessageSetMutationOptions) => Promise<void>;
}

export default function TemplateSelectorModal({
  campaign,
  messageSet,
  isOpen,
  isFreelancer,
  updateCampaignMessageSet,
  setOpen,
}: TemplateSelectorModalProps) {
  const setMessaging = async () => {
    if (campaign?.id && messageSet.id) {
      await updateCampaignMessageSet({ messageSetId: messageSet.id, campaignId: campaign?.id });
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="w-full ">
                    <div className="w-full ">
                      <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">
                        {messageSet.name}
                      </h2>

                      <section aria-labelledby="information-heading" className="mt-2">
                        <div className="flex flex-row space-x-2 my-2">
                          {messageSet.is_public && (
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                              Public
                            </span>
                          )}

                          {messageSet.user && (
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                              Created by {isFreelancer ? "internal user" : messageSet.user.email}
                            </span>
                          )}

                          {messageSet.message_set_campaign_types.map(campaignType => {
                            return (
                              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {campaignType.campaign_type.name}{" "}
                                {campaignType.is_default && " - Default"}
                              </span>
                            );
                          })}
                        </div>
                      </section>

                      <section aria-labelledby="options-heading" className="mt-5">
                        <div>
                          <ul role="list" className="divide-y divide-gray-100">
                            {messageSet.messages &&
                              messageSet.messages?.length > 0 &&
                              [...messageSet.messages]
                                .sort((a, b) => a.msg_order - b.msg_order)
                                .map((message, index) => {
                                  return (
                                    <li key={message.msg_order} className="flex gap-x-4 py-5">
                                      <div className="flex-auto">
                                        <div className="flex items-baseline justify-between gap-x-4">
                                          <p className="text-sm font-semibold leading-6 text-gray-900">
                                            Message {message.msg_order}
                                          </p>
                                          {index !== 0 && (
                                            <p className="flex-none text-xs text-gray-600">
                                              <span>After {message.send_after_days} days</span>
                                            </p>
                                          )}
                                        </div>
                                        <p className="mt-1 line-clamp-2 text-sm leading-6 text-gray-600">
                                          {message.content}
                                        </p>
                                      </div>
                                    </li>
                                  );
                                })}
                          </ul>
                        </div>

                        <button
                          type="button"
                          onClick={() => setMessaging()}
                          className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-orange-600 px-8 py-3 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Select this Messaging
                        </button>
                      </section>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
