import { Fragment, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/20/solid";
import { ArrayElement, classNames } from "../../../../../shared/internals/utils";
import {
  GetCampaignByIdQuery,
  GetCampaignTypesQuery,
  GetDoorKnockingCampaignByIdQuery,
  GetMessageSetsQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { UpdateCampaignMessageSetMutationOptions } from "../../graphql/hooks/useUpdateCampaignMessageSet";
import { EllipsisVerticalIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { CreateMessageSetValues } from "../../types";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createMessageSetValuesValidationSchema } from "../../validation";
import { UseMutateAsyncFunction } from "react-query";
import { Button, Divider } from "@tremor/react";
import ErrorMessage from "../../../../../shared/internals/components/errorMessage/ErrorMessage";
import Select, { createFilter } from "react-select";
import { User } from "firebase/auth";
import toast from "react-hot-toast";
import { CreateMessageSetBody } from "../../../doorKockingCampaign/api/createMessageSet/createMessageSet";
import { snippets } from "../../data";
import { reactSelectStyling } from "../../../../../shared/internals/styling";

interface EditMessageModalProps {
  campaign:
    | GetCampaignByIdQuery["campaign_by_pk"]
    | GetDoorKnockingCampaignByIdQuery["campaign_by_pk"];
  messageSet: ArrayElement<GetMessageSetsQuery["message_set"]>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  user: User;
  updateCampaignMessageSet: (options: UpdateCampaignMessageSetMutationOptions) => Promise<void>;
  createMessageSetLoading: boolean;
  createMessageSet: UseMutateAsyncFunction<number, any, CreateMessageSetBody, void>;
  campaignTypes: GetCampaignTypesQuery["campaign_type"];
}

export default function EditMessageModal({
  campaign,
  messageSet,
  isOpen,
  campaignTypes,
  user,
  createMessageSetLoading,
  createMessageSet,
  updateCampaignMessageSet,
  setOpen,
}: EditMessageModalProps) {
  const {
    handleSubmit,
    control,
    watch,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<CreateMessageSetValues>({
    resolver: yupResolver(createMessageSetValuesValidationSchema),
    defaultValues: {
      isPublic: true,
      messages: messageSet.messages.map(message => {
        return {
          msgOrder: message.msg_order,
          content: message.content,
          sendAfterDays: message.send_after_days,
        };
      }),
    },
  });

  const messages = watch("messages");

  const handleAddMessage = () => {
    // Get the current messages from the form state
    const currentMessages = getValues("messages");

    // Find the maximum msgOrder in the current messages array
    const maxOrder = Math.max(...currentMessages.map(message => message.msgOrder), 0);

    // Create a new message with msgOrder incremented by 1 and content set to "PLACEHOLDER"
    const newMessage = {
      msgOrder: maxOrder + 1,
      content: "PLACEHOLDER",
      sendAfterDays: 1,
      // Other properties of the message...
    };

    // Add the new message to the form state
    setValue("messages", [...currentMessages, newMessage]);
  };

  const handleDeleteMessage = (msgOrderToDelete: number) => {
    // Get the current messages from the form state
    let currentMessages = getValues("messages");

    // Remove the message with the given msgOrder
    currentMessages = currentMessages.filter(message => message.msgOrder !== msgOrderToDelete);

    // Update the msgOrder of the subsequent messages
    currentMessages = currentMessages.map(message => ({
      ...message,
      msgOrder: message.msgOrder > msgOrderToDelete ? message.msgOrder - 1 : message.msgOrder,
    }));

    // Update the form state
    setValue("messages", currentMessages);
  };

  const onSubmit = async (data: CreateMessageSetValues) => {
    if (!campaign?.id) {
      toast.error("Campaign not found");
      return;
    }

    if (!user?.uid) {
      toast.error("User not found");
      return;
    }

    const messageSetBody: CreateMessageSetBody = {
      name: data.name,
      isPublic: data.isPublic,
      messages: data.messages.map(message => {
        return {
          content: message.content,
          sendAfterDays: message.sendAfterDays,
          msgOrder: message.msgOrder,
        };
      }),
      userUid: user.uid,
      campaignId: campaign.id,
      campaignTypeIds: data.campaignTypes.map(campaignType => campaignType.value),
    };

    await createMessageSet(messageSetBody);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8"
                >
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="w-full ">
                    <div className="w-full ">
                      <div className="flex flex-row justify-between">
                        <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">
                          Editing {messageSet.name}
                        </h2>
                        <div className="flex flex-shrink-0 justify-start space-x-5 -mt-2 pr-10">
                          <Button
                            icon={PlusCircleIcon}
                            type="button"
                            onClick={handleAddMessage}
                            className="bg-white shadow-md border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
                          >
                            Add Message
                          </Button>
                        </div>
                      </div>
                      <div>
                        <h2 className="text-sm mt-4 font-medium text-gray-500">Snippets</h2>
                        <ul
                          role="list"
                          className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 "
                        >
                          {snippets.map(snippet => {
                            if (
                              snippet.type === "campaign" &&
                              snippet.campaignTypeIdSpecific !== undefined &&
                              snippet.campaignTypeIdSpecific !== campaign?.campaign_type.id
                            ) {
                              return null; // Don't render this snippet as it's not relevant for the current campaign type
                            }
                            return (
                              <li key={snippet.name} className="w-full  flex rounded-md shadow-sm">
                                <div
                                  className={classNames(
                                    "bg-purple-600",
                                    "flex w-5 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                                  )}
                                ></div>
                                <div className="flex flex-1 items-center justify-between  rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                  <div className="flex-1  px-4 py-2 text-sm">
                                    <div className="font-medium text-gray-900 hover:text-gray-600">
                                      {`{{${snippet.name}}}`}
                                    </div>
                                    <p className="text-gray-500">{snippet.description}</p>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <Divider />

                      <ul role="list" className="divide-y divide-gray-100">
                        {messages &&
                          messages
                            .sort((a, b) => a.msgOrder - b.msgOrder)
                            .map((message, index) => {
                              return (
                                <li key={message.msgOrder} className="flex gap-x-4 py-5">
                                  <div className="w-full">
                                    <div className="flex flex-row items-center space-x-4">
                                      <label className=" text-sm font-medium leading-6 text-gray-900">
                                        Message {message.msgOrder} -{" "}
                                      </label>
                                      <div className="relative">
                                        <label
                                          htmlFor="name"
                                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                                        >
                                          Sent Days After
                                        </label>
                                        <input
                                          type="number"
                                          defaultValue={message.sendAfterDays}
                                          {...register(`messages.${index}.sendAfterDays`)}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          placeholder="1-100"
                                        />
                                      </div>
                                    </div>
                                    <div className="mt-2 w-full flex items-center justify-center flex-row">
                                      <textarea
                                        rows={4}
                                        {...register(`messages.${index}.content`)}
                                        className="w-full bg-blue rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={message.content}
                                        disabled={createMessageSetLoading}
                                      />
                                      <div>
                                        <button
                                          onClick={() => handleDeleteMessage(message.msgOrder)}
                                          type="button"
                                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        >
                                          <span>Delete</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                      </ul>
                      {errors.messages && <ErrorMessage>{errors.messages.message}</ErrorMessage>}

                      <Divider />
                      <div className=" flex flex-col rounded-md shadow-sm">
                        <label className="block text-sm font-medium text-gray-700">
                          Applicable to the following Campaign Type
                        </label>
                        <div className="w-full h-max">
                          <Controller
                            name="campaignTypes"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isMulti
                                styles={reactSelectStyling}
                                maxMenuHeight={220}
                                options={campaignTypes.map(campaignType => {
                                  return {
                                    label: campaignType.name,
                                    value: campaignType.id,
                                  };
                                })}
                                isSearchable
                                placeholder="Select Campaign Type"
                              />
                            )}
                          />
                        </div>
                        {errors.campaignTypes && (
                          <ErrorMessage>{errors.campaignTypes.message}</ErrorMessage>
                        )}
                      </div>

                      <div>
                        <label className="mt-5 block text-sm font-medium text-gray-700">
                          Template Name
                        </label>
                        <div className="mt-1">
                          <input
                            {...register("name")}
                            placeholder="Template Name"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                          />
                          {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                        </div>
                      </div>

                      <button
                        type="submit"
                        disabled={createMessageSetLoading}
                        // onClick={() => setMessaging()}
                        className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-orange-600 px-8 py-3 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
