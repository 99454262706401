import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useMutation } from "react-query";

export interface UpdateOwnerMatchDecisionsAPIOptions {
  usCampaignId: number;
  usOwnerMatchDecisions: {
    usOwnerId: number;
    isCorrectMatch: boolean;
  }[];
}

export const updateOwnerMatchDecisionsAPI = async ({
  usCampaignId,
  usOwnerMatchDecisions,
}: UpdateOwnerMatchDecisionsAPIOptions) => {
  const response = await meetmoreAPI.post(
    `/us-campaigns/${usCampaignId}/update-owner-match-decisions`,
    {
      usOwnerMatchDecisions,
    }
  );
  return response.data;
};

export const useUpdateOwnerMatchDecisionsMutation = () => {
  let loadingToastId: string;

  return useMutation(updateOwnerMatchDecisionsAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Updating Owner Match Decisions...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Owner Match Decisions updated successfully!");
    },
    onError: (error: Error, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error updating Owner Match Decisions: ${error.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
