export enum POST_APPROVAL_TYPE {
  INTERNAL = 1,
  USER = 2,
  REVOKE_INTERNAL = 3,
  REVOKE_USER = 4,
  CANCEL = 5,
  REVOKE_CANCEL = 6,
}

export enum INSIGHT_STREAM_STATUS {
  ACTIVE = 1,
  SUSPENDED = 2,
  DELETED = 3,
  INTERNAL = 4,
}

export enum CAMPAIGN_STATUS {
  PROCESSING = 1,
  RUNNING = 2,
  COMPLETED = 3,
  ALWAYS_ON = 4,
  PAUSED = 5,
  STOPPED = 6,
}

export enum TEMPLATE_TYPE {
  QUOTE = 1,
  COMPARISON = 2,
  BUBBLES = 3,
  BOX = 4,
  FIGURE = 5,
}

export enum ADDRESS_MATCH_QUALITY {
  EXACT = 1,
  GOOD = 2,
  PARTIAL = 3,
  RISKY = 4,
  MANUAL = 5,
  SUBDIVISION = 6,
}

export enum SUSPECTED_OWNER_STATUS {
  NEW = 1,
  CONTENDER = 2,
  REJECTED = 3,
  VERIFIED = 4,
  DUPLICATE = 5,
}

export enum DIGITAL_PROFILE_STATUS {
  NEW = 1,
  CONTENDER = 2,
  REJECTED = 3,
  VERIFIED = 4,
  DUPLICATE = 5,
}

export enum PRODUCT {
  SOCIALS = 1,
  LEADS = 2,
  OWNER_NAMES = 3,
}

export enum PRODUCT_PRICE {
  SOCIALS_50 = 1,
  SOCIALS_450 = 2,
  LEADS_250 = 3,
  LEADS_350 = 4,
  LEADS_450 = 5,
  LEADS_200 = 6,
  LEADS_300 = 7,
  SOCIALS_100 = 10,
  LEADS_0 = 11,
  LEADS_400 = 12,
  OWNER_NAMES_25 = 14,
  OWNER_NAMES_100 = 15,
  OWNER_NAMES_500 = 16,
  OWNER_NAMES_0 = 17,
  OWNER_NAMES_PER_UNIT = 18,
}

export enum OUTREACH_AGENT_TYPE {
  PERSONAL = 1,
  SALES_ASSISTANT = 2,
}

export enum OUTREACH_PLATFORM {
  LINKEDIN = 1,
  FACEBOOK = 2,
  EMAIL = 3,
}

export enum PROSPECTING_TOOL_PROVIDER {
  LOOP = 1,
  HOMESEARCH = 2,
  INFORMATION_WORKS = 3,
  SPRIFT = 4,
  SPECTRE = 5,
  OTHER = 6,
  NONE = 7,
  PIVRO = 8,
  PROPALT = 9,
  ZOOPLA = 10,
}

export enum OWNERSHIP_SOURCE {
  ELECTORAL_ROLL = 1,
  LAND_REGISTRY = 2,
  PLANNING_APPLICATION = 3,
  USER_PROVIDED = 4,
  COMPANIES_HOUSE = 5,
}

export enum CAMPAIGN_LEAD_PROFILE_OUTREACH_STATUS {
  DISCOVERED = 1,
  CONNECTION_PENDING = 2,
  CONNECTED = 3,
  REPLIED = 4,
}

export enum CAMPAIGN_LEAD_PROFILE_VALUE_STATUS {
  QUALIFIED_LOW_INTENT = 1,
  QUALIFIED = 2,
  CALL_BOOKED = 3,
  VALUATION_BOOKED = 4,
  INSTRUCTION_WON = 5,
  NOT_INTERESTED = 6,
  RELATIONSHIP_BUILDING = 7,
}

export enum CAMPAIGN_TYPE {
  SLOW_MOVER = 1,
  WITHDRAWN = 2,
  CUSTOM_CSV = 3,
  TWENTY_TWENTY = 4,
  REDUCED = 5,
  UNDER_OFFER = 6,
  ON_BEHALF_OF_BUYER = 7,
  FALL_THROUGH = 8,
  CUSTOM_SEARCH = 9,
  DOOR_KNOCKING = 10,
  NEW = 11,
}

export enum SPREADSHEET_TYPE {
  ZOOPLA_IMPORTER = 1,
  GENERAL_IMPORTER = 2,
}

export enum TARGET_LOCATION {
  POSTCODE = 1,
  STREET_ESTATE = 2,
}

export enum CAMPAIGN_LEAD_STATUS {
  CURRENTLY_LISTED = "CURRENTLY LISTED",
  OFF_MARKET = "OFF-MARKET",
  WITHDRAWN = "WITHDRAWN",
}

export enum CLOUD_TASK_QUEUE {
  PLANNING_APP_SEARCH = "PLANNING-APP-SEARCH",
  CONFIRM_DIGITAL_PROFILE = "CONFIRM-DIGITAL-PROFILE",
  COMPANIES_HOUSE_SEARCH = "COMPANIES-HOUSE-SEARCH",
  PLAN_IT_SEARCH = "PLAN-IT-SEARCH",
  MATCH_ADDRESS = "MATCH-ADDRESS",
  SEARCH_LINKEDIN = "SEARCH-LINKEDIN",
  REVIVE_SEARCH_AGENT = "REVIVE-SEARCH-AGENT",
  CHANGE_PROXY_ALLOCATION = "CHANGE-PROXY-ALLOCATION",
  ZOOPLA = "ZOOPLA",
}

export enum CONSTRUCTED_OWNER_STATUS {
  VALIDATED_LEAD_ADDRESS = "validated_lead_address",
  VALIDATED_ALTERNATIVE_ADDRESS = "validated_alternative_address",
  REJECTED_LEAD_ADDRESS = "rejected_lead_address",
  NOT_VALIDATED = "not_validated",
}

export enum OWNER_ADDRESS_VALIDATION_TYPE {
  CORRECT = "correct",
  FALSE = "false",
  UNKNOWN = "unknown",
}

export enum SEARCH_AGENT_LOG_TYPE {
  CREATED = 0, // MANUAL DOESNT EXIST IN DB
  SESSION_COOKIE_INVALIDATED = 1,
  REVIVED_SUCCESSFULLY = 2,
  PROXY_ALLOCATED = 3,
  PROXY_DE_ALLOCATED = 4,
  SEARCHES_CONDUCTED = 5,
  REVIVAL_FAILED = 6,
  REMOVED_FROM_CIRCULATION = 7,
  ENTERED_INTO_CIRCULATION = 8,
  SEARCH_LIMIT_REACHED = 9,
  PASSPORT_REQUESTED = 10,
  NUMBER_REQUESTED = 11,
  PERMANENTLY_DELETED = 12,
  RETURNED_FROM_GRAVE = 13,
}

export enum SEARCH_TYPE {
  LOCALITY = 1,
  POST_TOWN = 2,
  LOCAL_AUTHORITY = 3,
  ADMINISTRATIVE_COUNTY = 4,
  ADMINISTRATIVE_DISTRICT = 5,
  POSTCODE_AREA = 6,
  COUNTRY = 7,
  REGION = 8,
}

export enum DISCOVERY_OPTION {
  LINKEDIN = 1,
  FACEBOOK = 2,
  INSTAGRAM = 3,
  WORD_OF_MOUTH = 4,
  GOOGLE_SEARCH = 5,
  OTHER = 6,
}

export enum TRACKING_GROUP_TYPE {
  CLEAN_PROFILES = 1,
  GET_ADDRESSES = 2,
  CLEAN_ADDRESSES = 3,
  GET_OWNERS = 4,
  CLEAN_OWNERS = 5,
  MATCH_OWNERS = 6,
  MATCH_ADDRESSES = 7,
  MATCH_PROFILES = 8,
  GET_PROSPECTED_PROFILES = 9,
  GET_EMAIL_PROFILES = 10,
  GET_US_OWNERS = 11,
  MATCH_US_OWNERS = 12,
  CLEAN_US_OWNERS = 13,
  GET_US_PROFILES = 14,
  CLEAN_US_PROFILES = 15,
  MATCH_US_PROFILES = 16,
}

export enum CAMPAIGN_EXTERNAL_CONNECTION_PROVIDER {
  HEYREACH = 1,
  OVERLEAD = 2,
}
