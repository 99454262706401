import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useMutation } from "react-query";
import { useTrackingGroupsByCampaignId } from "../../graphql/hooks/useTrackingGroupsByCampaignId";
import { useTrackingGroupsByUSCampaignId } from "../../../singleUSCampaign/graphql/hooks/useTrackingGroupsByUSCampaignId";

export interface CancelTrackingGroupAPIOptions {
  trackingGroupId: number;
}
export const cancelTrackingGroupAPI = async ({
  trackingGroupId,
}: CancelTrackingGroupAPIOptions) => {
  const response = await meetmoreAPI.patch(`/tracking-groups/${trackingGroupId}/cancel`);
  return response.data;
};

export const useCancelTrackingGroup = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchTrackingGroups } = useTrackingGroupsByUSCampaignId({
    usCampaignId: refetchId ?? -1,
  });

  return useMutation(cancelTrackingGroupAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Cancelling Workflow...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Workflow Cancelled");
      await refetchTrackingGroups();
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error Cancelling Workflow: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
