import { Fragment, useEffect, useRef, useState } from "react";
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../../../shared/internals/utils";
import { CampaignLead } from "../../graphql/hooks/useCampaignById";
import { GroupedBulkAddressMatcherCampaignLeads, UpdateBulkAddressMatchValues } from "../../types";
import { transformCampaignLeadsForBulkAddressMatcher } from "../../utils";
import { Badge } from "@tremor/react";
import { UseLazyAddressesByPostcodeUnitIdsReturnType } from "../../graphql/hooks/useAddressesByPostcodeUnitIds";
import Select from "react-select";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateBulkAddressMatchValuesSchema } from "../../validation";
import { FolderOpenIcon } from "@heroicons/react/24/solid";
import { usePrevious } from "@uidotdev/usehooks";
import Typeahead from "../../../../../shared/internals/components/typeahead/Typeahead";
import { useLazyPostcodeUnitByCodeReturnType } from "../../graphql/hooks/usePostcodeUnitByCode";
import { turnstoneStyles } from "../../../../../shared/internals/components/typeahead/data";
import { ChangeMatchQualityOptions } from "../../api/changeMatchQuality";
import { UseMutateAsyncFunction } from "react-query";
import toast from "react-hot-toast";
import { ADDRESS_MATCH_QUALITY } from "../../../../../shared/internals/constants/models";
import Loading from "../../../../../shared/internals/components/loading/Loading";
import { bulkAddressMatcherStyling } from "../../../../../shared/internals/styling";

interface BulkAddressMatcherProps {
  filteredCampaignLeads: CampaignLead[];
  aggregatedAddresses: UseLazyAddressesByPostcodeUnitIdsReturnType["aggregatedAddresses"];
  addressesLoading: boolean;
  getLazyAddressesByPostcodeUnitIds: UseLazyAddressesByPostcodeUnitIdsReturnType["getLazyAddressesByPostcodeUnitIds"];
  activeShowTabId: number;
  getLazyPostcodeUnitsByCode: useLazyPostcodeUnitByCodeReturnType["getLazyPostcodeUnitsByCode"];
  postcodeUnits: useLazyPostcodeUnitByCodeReturnType["postcodeUnits"];
  postcodeUnitsLoading: boolean;
  changeMatchQuality: UseMutateAsyncFunction<any, any, ChangeMatchQualityOptions, void>;
  campaignId?: number;
  changeMatchQualityLoading: boolean;
}
export default function BulkAddressMatcher({
  activeShowTabId,
  filteredCampaignLeads,
  addressesLoading,
  aggregatedAddresses,
  changeMatchQuality,
  campaignId,
  getLazyAddressesByPostcodeUnitIds,
  getLazyPostcodeUnitsByCode,
  changeMatchQualityLoading,
}: BulkAddressMatcherProps) {
  const [isChangingPostcodeLeadIndex, setIsChangingPostcodeLeadIndex] = useState<number>();

  const [aggregatedAddressesHolder, setAggregatedAddressesHolder] = useState<
    UseLazyAddressesByPostcodeUnitIdsReturnType["aggregatedAddresses"]
  >([]);

  const selectPostcodeUnit = async (item: { id?: number; code?: string }) => {
    if (isChangingPostcodeLeadIndex === null || isChangingPostcodeLeadIndex === undefined) return;
    const postcodeUnit = watch(`campaignLeads.${isChangingPostcodeLeadIndex}.postcodeUnit`);
    if (!item) return;
    if (postcodeUnit.value === item?.id) return;
    if (item.code && item.id) {
      setValue(`campaignLeads.${isChangingPostcodeLeadIndex}.postcodeUnit`, {
        value: item?.id,
        label: item?.code,
      });
      await getCampaignLeadInStateAddresses();
      setIsChangingPostcodeLeadIndex(undefined);
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UpdateBulkAddressMatchValues>({
    resolver: yupResolver(UpdateBulkAddressMatchValuesSchema),
    defaultValues: {
      campaignLeads: filteredCampaignLeads.map((campaignLead, index) => ({
        id: campaignLead.id,
        index: index,
        statedAddress: campaignLead.stated_address,
        isExcluded: false,
        initialAddressId: campaignLead.address.id,
        initialAddressFullAddress: campaignLead.address.full_address,
        initialPostcodeUnitCode: campaignLead.address.postcode_unit.code,
        initialPostcodeUnitId: campaignLead.address.postcode_unit.id,
        isAddressValidated: campaignLead.is_address_validated,
        addressMatchQualityId: campaignLead.address_match_quality.id,
        addressMatchQualityName: campaignLead.address_match_quality.name,
        addressMatchScore: campaignLead.address_match_score,
        matchedAddress: {
          label: campaignLead.address.full_address,
          value: campaignLead.address.id,
        },
        postcodeUnit: {
          label: campaignLead.address.postcode_unit.code,
          value: campaignLead.address.postcode_unit.id,
        },
      })),
    },
  });

  const previousFilteredCampaignLeads = usePrevious(filteredCampaignLeads) ?? [];

  const haveLeadsChanged = () => {
    // Check if the lengths of the arrays are different
    if (previousFilteredCampaignLeads.length !== filteredCampaignLeads.length) {
      return true;
    }

    // Iterating through the arrays to compare lead IDs and their indices
    for (let i = 0; i < filteredCampaignLeads.length; i++) {
      const prevLeadId = previousFilteredCampaignLeads[i]?.id;
      const currentLeadId = filteredCampaignLeads[i]?.id;

      // Check if there is a mismatch in lead IDs or their order (indices)
      if (prevLeadId !== currentLeadId) {
        return true;
      }
    }

    // No changes detected
    return false;
  };

  useEffect(() => {
    updateCampaignLeadsToMatch();
    getFilteredCampaignLeadAddresses();
  }, [haveLeadsChanged()]);
  // WHY THIS SOLUTION: active show tab on its own is inconsistent with filtered leads as its changed before filtered leads -
  // filtered leads on its rerenders a lot because getaddress call triggers rerender in campaign page

  const updateCampaignLeadsToMatch = async () => {
    const newCampaignLeadsInForm = [...filteredCampaignLeads].map((campaignLead, index) => ({
      id: campaignLead.id,
      index: index,
      statedAddress: campaignLead.stated_address,
      isExcluded: false,
      initialAddressId: campaignLead.address.id,
      initialAddressFullAddress: campaignLead.address.full_address,
      initialPostcodeUnitCode: campaignLead.address.postcode_unit.code,
      initialPostcodeUnitId: campaignLead.address.postcode_unit.id,
      isAddressValidated: campaignLead.is_address_validated,
      addressMatchQualityId: campaignLead.address_match_quality.id,
      addressMatchQualityName: campaignLead.address_match_quality.name,
      addressMatchScore: campaignLead.address_match_score,
      matchedAddress: {
        label: campaignLead.address.full_address,
        value: campaignLead.address.id,
      },
      postcodeUnit: {
        label: campaignLead.address.postcode_unit.code,
        value: campaignLead.address.postcode_unit.id,
      },
    }));

    setValue("campaignLeads", newCampaignLeadsInForm);
  };

  const getCampaignLeadInStateAddresses = async () => {
    const postcodeUnitIds = [...campaignLeadsInForm].map(
      campaignLead => campaignLead.postcodeUnit.value
    );
    const uniquePostcodeUnitIds = [...new Set(postcodeUnitIds)];
    const existingPostcodeUnitIds = new Set(
      aggregatedAddressesHolder.map(({ postcodeUnitId }) => postcodeUnitId)
    );

    const newUniquePostcodeUnitIds = uniquePostcodeUnitIds.filter(
      postcodeUnitId => !existingPostcodeUnitIds.has(postcodeUnitId)
    );

    await getLazyAddressesByPostcodeUnitIds(newUniquePostcodeUnitIds);
  };

  const getFilteredCampaignLeadAddresses = async () => {
    const postcodeUnitIds = [...filteredCampaignLeads].map(
      campaignLead => campaignLead.address.postcode_unit.id
    );
    const uniquePostcodeUnitIds = [...new Set(postcodeUnitIds)];
    const existingPostcodeUnitIds = new Set(
      aggregatedAddressesHolder.map(({ postcodeUnitId }) => postcodeUnitId)
    );

    const newUniquePostcodeUnitIds = uniquePostcodeUnitIds.filter(
      postcodeUnitId => !existingPostcodeUnitIds.has(postcodeUnitId)
    );

    await getLazyAddressesByPostcodeUnitIds(newUniquePostcodeUnitIds);
  };

  useEffect(() => {
    if (aggregatedAddresses.length > 0) {
      const existingPostcodeUnitIds = new Set(
        aggregatedAddressesHolder.map(({ postcodeUnitId }) => postcodeUnitId)
      );

      const newAggregatedAddresses = aggregatedAddresses.filter(
        ({ postcodeUnitId }) => !existingPostcodeUnitIds.has(postcodeUnitId)
      );

      setAggregatedAddressesHolder(current => [...current, ...newAggregatedAddresses]);
    }
  }, [addressesLoading]);

  const campaignLeadsInForm = watch("campaignLeads");

  const transformedCampaignLeads = transformCampaignLeadsForBulkAddressMatcher(campaignLeadsInForm);

  const changedAddresses = campaignLeadsInForm.filter(
    lead => lead.matchedAddress.value !== lead.initialAddressId && !lead.isExcluded
  );
  const excludedAddresses = campaignLeadsInForm.filter(lead => lead.isExcluded);

  const onSubmit = async (values: UpdateBulkAddressMatchValues) => {
    const validatedAddresses = values.campaignLeads.filter(lead => !lead.isExcluded);

    const readyToSubmit = validatedAddresses.map(campaignLead => {
      return {
        campaignLeadId: campaignLead.id,
        addressId: campaignLead.matchedAddress.value,
        addressMatchQualityId: ADDRESS_MATCH_QUALITY.MANUAL,
      };
    });

    if (!campaignId) {
      toast.error("No Campaign Id Found");
      return;
    }

    await changeMatchQuality({ campaignId, campaignLeads: readyToSubmit });
  };

  if (changeMatchQualityLoading) {
    return (
      <div className="mt-20">
        <Loading isMini />;
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <div className="mt-6 overflow-hidden  pb-56 ">
        <div className="border border-gray-200 rounded-lg mb-4 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="flex ml-2 items-center justify-left mt-2 flex-row">
              <p className="text-sm text-gray-500">
                <span className="font-bold text-lg">
                  {filteredCampaignLeads.length - excludedAddresses.length}{" "}
                </span>{" "}
                <span className="text-sm text-gray-500">Addresses will be validated.</span>{" "}
                <span className="text-sm text-gray-500">These include </span>{" "}
                <span className="font-bold text-lg">{changedAddresses.length} </span>{" "}
                <span className="text-sm text-gray-500">Addresses that were re-allocated.</span>{" "}
                <span className="font-bold text-lg">{excludedAddresses.length}</span>{" "}
                <span className="text-sm text-gray-500">
                  addresses could not be matched and were excluded.
                </span>{" "}
                There were a total of {filteredCampaignLeads.length} Addresses
              </p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <button
                type="submit"
                disabled={changeMatchQualityLoading}
                className="relative inline-flex items-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                Validate Addresses
              </button>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <table className="w-full text-left">
              {/* <thead className="sr-only">
                <tr>
                  <th>Amount</th>
                  <th className="hidden sm:table-cell">Client</th>
                  <th>More details</th>
                </tr>
              </thead> */}
              <tbody>
                {transformedCampaignLeads.map(category => (
                  <Fragment key={category.address_match_quality_name}>
                    <tr className="text-sm leading-6 text-gray-900">
                      <th
                        scope="colgroup"
                        colSpan={3}
                        className="relative px-2  isolate py-2 font-semibold"
                      >
                        <time>{category.address_match_quality_name}</time>
                        <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                        <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                      </th>
                    </tr>
                    {category.campaignLeads.map(campaignLead => {
                      const changedAddressId =
                        campaignLead.matchedAddressId !== campaignLead.initialAddressId;
                      return (
                        <tr
                          key={campaignLead.id}
                          className={classNames(
                            campaignLead.isExcluded
                              ? "bg-red-50"
                              : changedAddressId
                              ? "bg-gray-50"
                              : "",
                            "  "
                          )}
                        >
                          <td className="relative py-5 pl-4 pr-2">
                            {changedAddressId && !campaignLead.isExcluded && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-gray-600" />
                            )}
                            {campaignLead.isExcluded && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-red-600" />
                            )}

                            <div className="flex gap-x-6">
                              <div className="flex-auto">
                                <div className="flex items-start gap-x-3">
                                  <div
                                    className={classNames(
                                      "text-sm font-medium leading-6",
                                      changedAddressId ? "text-orange-600" : "text-gray-900"
                                    )}
                                  >
                                    {campaignLead.statedAddress}
                                  </div>
                                  <div
                                    className={classNames(
                                      campaignLead.isAddressValidated
                                        ? "text-green-700 bg-green-50 ring-green-600/20"
                                        : "text-red-700 bg-red-50 ring-red-600/10",
                                      "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                    )}
                                  >
                                    {campaignLead.isAddressValidated
                                      ? `Validated`
                                      : `Not Validated`}
                                  </div>
                                  <div
                                    className={classNames(
                                      campaignLead.isAddressValidated
                                        ? "text-green-700 bg-green-50 ring-green-600/20"
                                        : "text-red-700 bg-red-50 ring-red-600/10",
                                      "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                    )}
                                  >
                                    {campaignLead.addressMatchScore}
                                  </div>
                                </div>

                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  {campaignLead.matchedAddressFullAddress}
                                </div>
                                <div className="relative mt-4 flex items-start">
                                  <div className="flex h-6 items-center">
                                    <input
                                      type="checkbox"
                                      checked={campaignLead.isExcluded}
                                      onChange={event => {
                                        setValue(
                                          `campaignLeads.${campaignLead.index}.isExcluded`,
                                          !campaignLead.isExcluded
                                        );
                                      }}
                                      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm leading-6">
                                    <label className="font-normal text-xs text-gray-500 ">
                                      Exclude
                                    </label>
                                    <span className="text-gray-500 text-xs  ">
                                      <span className="sr-only">New comments </span> - Address will
                                      not be validated
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                          </td>
                          <td className="py-5 pr-6 sm:table-cell">
                            <div className="text-sm leading-6 text-gray-900">
                              Within ({campaignLead.postcodeUnitCode}){" "}
                              {isChangingPostcodeLeadIndex &&
                              addressesLoading &&
                              campaignLead.index === isChangingPostcodeLeadIndex
                                ? "...loading"
                                : !isChangingPostcodeLeadIndex && addressesLoading
                                ? "...loading"
                                : null}
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                              <Controller
                                name={`campaignLeads.${campaignLead.index}.matchedAddress`}
                                control={control}
                                defaultValue={{
                                  value: campaignLead.initialAddressId,
                                  label: campaignLead.initialAddressFullAddress,
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    styles={bulkAddressMatcherStyling}
                                    maxMenuHeight={220}
                                    options={aggregatedAddressesHolder
                                      .filter(
                                        ({ postcodeUnitId }) =>
                                          postcodeUnitId === campaignLead.postcodeUnitId
                                      )
                                      .flatMap(({ addresses }) => {
                                        return addresses.flatMap(address => ({
                                          label: address.fullAddress,
                                          value: address.id,
                                        }));
                                      })}
                                    isSearchable
                                    placeholder="Select Address"
                                  />
                                )}
                              />
                            </div>
                            {changedAddressId && (
                              <button
                                type="button"
                                onClick={() => {
                                  setValue(`campaignLeads.${campaignLead.index}.matchedAddress`, {
                                    value: campaignLead.initialAddressId,
                                    label: campaignLead.initialAddressFullAddress,
                                  });
                                  setValue(`campaignLeads.${campaignLead.index}.postcodeUnit`, {
                                    value: campaignLead.initialPostcodeUnitId,
                                    label: campaignLead.initialPostcodeUnitCode,
                                  });
                                }}
                                className="mt-2 text-left text-xs text-gray-500"
                              >
                                <span className="font-semibold leading-6 text-orange-600 text-hover:text-orange-500">
                                  Reset to original address
                                </span>
                              </button>
                            )}
                          </td>
                          <td className="py-4 px-2 pr-6 sm:table-cell">
                            {!isChangingPostcodeLeadIndex && (
                              <button
                                type="button"
                                onClick={() => setIsChangingPostcodeLeadIndex(campaignLead.index)}
                                className="flex justify-end"
                              >
                                <a className="text-sm font-medium leading-6 text-orange-600 hover:text-orange-500">
                                  Change <span className="hidden sm:inline">Postcode</span>
                                </a>
                              </button>
                            )}

                            {isChangingPostcodeLeadIndex === campaignLead.index && (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsChangingPostcodeLeadIndex(undefined);
                                  }}
                                  className="text-left text-xs text-gray-500"
                                >
                                  <span className="font-semibold text-left leading-6 text-orange-600 text-hover:text-orange-500">
                                    End Postcode Change
                                  </span>
                                </button>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  <Typeahead
                                    styles={turnstoneStyles}
                                    maxItems={10}
                                    onSelect={selectPostcodeUnit}
                                    placeholder="Enter a Postcode Unit"
                                    listbox={{
                                      id: "postcodeUnits",
                                      name: "Postcode Units",
                                      displayField: "code",
                                      data: async (query: string) => {
                                        const results = await getLazyPostcodeUnitsByCode({
                                          code: query,
                                          limit: 10,
                                        });
                                        return results;
                                      },
                                      searchType: "startswith",
                                    }}
                                    id="postcodeUnitSelector"
                                    name="postcodeUnitSelector"
                                  />
                                </div>
                              </>
                            )}

                            {/* <div className="mt-1 text-xs leading-5 text-gray-500">
                            Invoice <span className="text-gray-900">Edit Postcode</span>
                          </div> */}
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  );
}
