import { GetCampaignByIdQuery } from "../../../../shared/infrastructure/graphQL/generatedTypes";
import { Card, Metric, Text, Title, List, ListItem, Badge } from "@tremor/react";

import { PaperClipIcon } from "@heroicons/react/24/solid";

import { UseMutateAsyncFunction } from "react-query";
import { UseUSCampaignByIdReturnType } from "../graphql/hooks/useUSCampaignById";

interface CampaignSummaryTabProps {
  campaign: UseUSCampaignByIdReturnType["campaign"];
}

export const CampaignSummaryTab = ({ campaign }: CampaignSummaryTabProps) => {
  return (
    <>
      <div>
        <div className="mt-6 ">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">ID</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {campaign?.id}
              </dd>
            </div>

            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Addresses</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {campaign?.us_addresses.length}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};
