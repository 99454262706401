import * as yup from "yup";

export const updateMatchedAddressValidationSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      value: yup.number().required("Address is required"),
    })
    .typeError("Address is required"),
});

export const updateSuspectedOwnerStatusValidationSchema = yup.object().shape({
  suspectedOwnerStatus: yup
    .object()
    .shape({
      value: yup.number().required("Suspected Owner Status is required"),
    })
    .typeError("Suspected Owner Status is required"),
});

export const createMessageSetValuesValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  messages: yup
    .array()
    .of(
      yup.object().shape({
        content: yup.string().required("Content is required"),
        msgOrder: yup.number().required("Message order is required"),
        sendAfterDays: yup.number().required("Send after days is required"),
      })
    )
    .min(2, "At least two messages are required")
    .required("Messages are required"),
  isPublic: yup.boolean().required("Is public is required"),
  userUid: yup.string().nullable(),
  campaignTypes: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.number().required(),
        })
        .typeError("Please select a campaign type")
    )
    .min(1, "Please select a campaign type")
    .required("Campaign types are required"),
});

export const UpdateBulkAddressMatchValuesSchema = yup.object().shape({
  campaignLeads: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required("ID is required"),
        statedAddress: yup.string().required("Stated address is required"),
        isAddressValidated: yup.boolean().required("Address validation status is required"),
        addressMatchScore: yup.number().required("Address match score is required"),
        matchedAddress: yup
          .object()
          .shape({
            value: yup.number().required("Matched Address is required"),
          })
          .typeError("Matched Address is required"),
        postcodeUnit: yup
          .object()
          .shape({
            value: yup.number().required("Postcode Unit is required"),
          })
          .typeError("Postcode Unit is required"),
      })
    )
    .min(1, "At least one campaign lead is required")
    .required("Campaign leads are required"),
});
