import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";

interface GetCompaniesHouseOwnersOptions {
  campaignId: number;
  campaignLeadIds: number[];
}
export const GetCompaniesHouseOwnersAPI = async ({
  campaignId,
  campaignLeadIds,
}: GetCompaniesHouseOwnersOptions) => {
  try {
    const response = await meetmoreAPI.post(`/addresses/companies-house`, {
      campaignLeadIds,
      campaignId,
    });
    return response.data;
  } catch (err) {
    toast.error("Error in getting companies house owners");
    throw err;
  }
};
