import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useMutation } from "react-query";

export interface AddAddressesToUsCampaignAPIOptions {
  usCampaignId: number;
  addresses: {
    address: string;
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    county: string | null;
    district: string | null;
    municipality: string | null;
    stateCode: string;
  }[];
}
export const addAddressesToUSCampaignAPI = async ({
  usCampaignId,
  addresses,
}: AddAddressesToUsCampaignAPIOptions) => {
  const response = await meetmoreAPI.post(`/us-campaigns/${usCampaignId}/addresses`, {
    addresses,
  });
  return response.data;
};

export const useAddAddressesToUSCampaignMutation = () => {
  let loadingToastId: string;

  return useMutation(addAddressesToUSCampaignAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Adding Addresses to Campaign...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Addresses added to campaign Successfully!");
    },
    onError: (error: Error, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error Adding Addresses to Campaign: ${error.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
