import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../utils";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

interface DropdownProps {
  title?: string;
  items: {
    icon: React.ForwardRefExoticComponent<any>;

    name: string;
    function: Function;
  }[];
}

export default function Dropdown({ items, title }: DropdownProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {title ? title : "Actions"}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {Array(Math.ceil(items.length / 2))
            .fill(0)
            .map((_, idx) => (
              <div className="py-1" key={idx}>
                {items.slice(idx * 2, idx * 2 + 2).map(item => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <button
                        onClick={e => {
                          item.function();
                        }}
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "group flex items-center w-full px-4 py-2 text-sm"
                        )}
                      >
                        <item.icon
                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
