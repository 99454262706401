import { ApolloError } from "@apollo/client";
import { useUpdateCampaignMessageSetMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";
import { getCampaignLeadProfiles } from "../queries/getCampaignLeadProfiles";
import { getCampaignById } from "../queries/getCampaignById";

export interface HookResult {
  updateCampaignMessageSet: (options: UpdateCampaignMessageSetMutationOptions) => Promise<void>;
}

export interface UpdateCampaignMessageSetMutationOptions {
  campaignId: number;
  messageSetId: number;
}

export interface Options {
  onError: (error: ApolloError) => void;
}

export const useUpdateCampaignMessageSet = ({
  refetchCampaignId,
}: {
  refetchCampaignId: number;
}): HookResult => {
  let toastId: string;

  const [updateCamapignMessageSet] = useUpdateCampaignMessageSetMutation({
    refetchQueries: [
      { query: getCampaignById, variables: { id: refetchCampaignId } },
      { query: getCampaignLeadProfiles, variables: { id: refetchCampaignId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.dismiss(toastId);
      toast.success("Messaging Updated successfully!");
    },
    onError: (error: ApolloError) => {
      toast.dismiss(toastId);
      toast.error(`Failed to update messaging for this campaign: ${error.message}`);
    },
  });

  return {
    updateCampaignMessageSet: async ({
      campaignId,
      messageSetId,
    }: UpdateCampaignMessageSetMutationOptions) => {
      await updateCamapignMessageSet({
        variables: {
          id: campaignId,
          messageSetId: messageSetId,
        },
      });
    },
  };
};
