import { ApolloError } from "@apollo/client";
import { useUpdateSuspectedOwnerAddressMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";

export interface HookResult {
  updateSuspectedOwnerAddress: (
    options: UpdateSuspectedOwnerAddressMutationOptions
  ) => Promise<void>;
}

export interface UpdateSuspectedOwnerAddressMutationOptions {
  suspectedOwnerId: number;
  addressId: number;
}

export interface Options {
  onError: (error: ApolloError) => void;
}

export const useUpdateSuspectedOwnerAddress = (): HookResult => {
  const [updateAddressMutation] = useUpdateSuspectedOwnerAddressMutation({
    onError: error => {
      throw error;
    },
  });

  return {
    updateSuspectedOwnerAddress: async ({
      suspectedOwnerId,
      addressId,
    }: UpdateSuspectedOwnerAddressMutationOptions) => {
      await updateAddressMutation({
        variables: {
          id: suspectedOwnerId,
          address_id: addressId,
        },
      });
    },
  };
};
