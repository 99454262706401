import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useMutation } from "react-query";
import { useUSCampaignById } from "../../../singleUSCampaign/graphql/hooks/useUSCampaignById";
import { useTrackingGroupsByUSCampaignId } from "../../../singleUSCampaign/graphql/hooks/useTrackingGroupsByUSCampaignId";

export interface TriggerTrackingGroupBody {
  campaignId: number;
  usCampaignId?: number;
  trackingGroupId?: number;
  trackingItemValues: string[];
  trackingGroupTypeId: number;
}

export const triggerTrackingGroupAPI = async ({
  campaignId,
  trackingGroupTypeId,
  usCampaignId,
  trackingItemValues,
  trackingGroupId,
}: TriggerTrackingGroupBody) => {
  if (!trackingGroupId) {
    const response = await meetmoreAPI.post(`/tracking-groups/trigger`, {
      campaignId,
      trackingGroupTypeId,
      usCampaignId,
      trackingItemValues,
      trackingGroupId,
    });
    return response.data;
  } else {
    const response = await meetmoreAPI.patch(`/tracking-groups/${trackingGroupId}/resume`, {
      campaignId,
      trackingGroupTypeId,
      usCampaignId,
      trackingItemValues,
    });
    return response.data;
  }
};

export const useTriggerTrackingGroup = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchUSCampaign } = useUSCampaignById({
    id: refetchId ?? -1,
  });
  const { refetch: refetchTrackingGroups } = useTrackingGroupsByUSCampaignId({
    usCampaignId: refetchId ?? -1,
  });

  return useMutation(triggerTrackingGroupAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Triggering Workflow...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success(" Workflow Triggered Successfully");
      if (refetchId) {
        await refetchUSCampaign();
        await refetchTrackingGroups();
      }
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error Triggering Workflow: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
