import {
  GetCampaignByIdQuery,
  GetTrackingGroupsByCampaignIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  Bold,
  Button,
  Callout,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionList,
  Text,
  Badge,
  Divider,
  BarList,
  Title,
  Icon,
} from "@tremor/react";

import {
  AdjustmentsVerticalIcon,
  BriefcaseIcon,
  ShieldCheckIcon,
  HandThumbDownIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
  CheckIcon,
  CalculatorIcon,
  MagnifyingGlassCircleIcon,
  SwatchIcon,
} from "@heroicons/react/24/solid";

import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Select from "react-select";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import {
  ADDRESS_MATCH_QUALITY,
  CONSTRUCTED_OWNER_STATUS,
  OWNERSHIP_SOURCE,
  OWNER_ADDRESS_VALIDATION_TYPE,
  SUSPECTED_OWNER_STATUS,
  TRACKING_GROUP_TYPE,
} from "../../../../shared/internals/constants/models";
import { ArrayElement, classNames } from "../../../../shared/internals/utils";
import { Menu, RadioGroup, Switch, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";

import dayjs from "dayjs";

import { UseUSCampaignByIdReturnType } from "../graphql/hooks/useUSCampaignById";
import { suspectedOwnerCleanRadioOptions } from "../data";
import { OWNER_CLEANING_SHOW_TABS } from "../../singleCampaign/types";
import { UpdateOwnerMatchDecisionsAPIOptions } from "../api/updateUSOwnerMatchDecision";
import { UseMutateAsyncFunction } from "react-query";

interface SuspectedOwnersCleanTabProps {
  campaign: UseUSCampaignByIdReturnType["campaign"];
  fixedAddresses: UseUSCampaignByIdReturnType["fixedAddresses"];
  updateOwnerMatchDecisionsMutation: UseMutateAsyncFunction<
    any,
    Error,
    UpdateOwnerMatchDecisionsAPIOptions,
    void
  >;
}

const SuspectedOwnersCleanTab = ({
  fixedAddresses,
  campaign,
  updateOwnerMatchDecisionsMutation,
}: SuspectedOwnersCleanTabProps) => {
  const leadsWithSelectableOwners = fixedAddresses.map(lead => {
    return {
      ...lead,
      us_owners: lead.us_owners.map(owner => ({
        ...owner,
        isSelected: false,
      })),
    };
  });

  const [filteredLeads, setFilteredLeads] = useState(leadsWithSelectableOwners);

  const [selectAll, setSelectAll] = useState<{ status: boolean; changedByItemToggle: boolean }>({
    status: false,
    changedByItemToggle: false,
  });
  const [selectedRadioOption, setSelectedRadioOption] = useState(
    suspectedOwnerCleanRadioOptions[0]
  );

  useEffect(() => {
    if (selectAll.status === true && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => {
        return {
          ...lead,
          us_owners: lead.us_owners.map(owner => ({
            ...owner,
            isSelected: true,
          })),
        };
      });
      setFilteredLeads(updatedLeads);
    }

    if (selectAll.status === false && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => {
        return {
          ...lead,
          us_owners: lead.us_owners.map(owner => ({
            ...owner,
            isSelected: false,
          })),
        };
      });
      setFilteredLeads(updatedLeads);
    }
  }, [selectAll.status]);

  useEffect(() => {
    let updatedLeads = [...leadsWithSelectableOwners];

    switch (selectedRadioOption.id) {
      case OWNER_CLEANING_SHOW_TABS.VALIDATED_LEAD_ADDRESS:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.us_owners.some(owner => owner.is_correct_match === true);
          })
          .map(lead => ({
            ...lead,
            us_owners: lead.us_owners.filter(owner => owner.is_correct_match === true),
          }));
        break;

      case OWNER_CLEANING_SHOW_TABS.REJECTED_LEAD_ADDRESS:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.us_owners.some(owner => owner.is_correct_match === false);
          })
          .map(lead => ({
            ...lead,
            us_owners: lead.us_owners.filter(owner => owner.is_correct_match === false),
          }));
        break;
      case OWNER_CLEANING_SHOW_TABS.UNVALIDATED:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.us_owners.some(
              owner => owner.is_correct_match === null || owner.is_correct_match === undefined
            );
          })
          .map(lead => ({
            ...lead,
            us_owners: lead.us_owners.filter(
              owner => owner.is_correct_match === null || owner.is_correct_match === undefined
            ),
          }));
        break;
      default:
        break;
    }

    // owner level filters

    if (selectAll.status === true) {
      // Handle the selectAll logic
      updatedLeads = updatedLeads.map(lead => {
        return {
          ...lead,
          us_owners: lead.us_owners.map(owner => ({
            ...owner,
            isSelected: true,
          })),
        };
      });
    }

    setFilteredLeads(updatedLeads);
  }, [selectedRadioOption, fixedAddresses]);

  const handleIndividualChange = (leadId: number, ownerId: number) => {
    // Find the specific lead and owner
    const lead = filteredLeads.find(lead => lead.id === leadId);
    const owner = lead?.us_owners.find(owner => owner.id === ownerId);
    const ownerWillBeSelected = !owner?.isSelected;

    // Update the leads
    const updatedLeads = filteredLeads.map(lead => {
      if (lead.id === leadId) {
        return {
          ...lead,
          us_owners: lead.us_owners.map(owner =>
            owner.id === ownerId ? { ...owner, isSelected: ownerWillBeSelected } : owner
          ),
        };
      }
      return lead;
    });

    // If the owner is about to be selected, check if all owners will be selected
    if (ownerWillBeSelected) {
      const allOwnersSelected = updatedLeads.every(lead =>
        lead.us_owners.every(owner => owner.isSelected)
      );
      if (allOwnersSelected) {
        setSelectAll({ status: true, changedByItemToggle: true });
      }
    }
    // If the owner is about to be unselected and selectAll is currently true, update selectAll
    else if (selectAll.status) {
      setSelectAll({ status: false, changedByItemToggle: true });
    }

    // Update the filteredLeads state
    setFilteredLeads(updatedLeads);
  };

  const totalOwners = fixedAddresses.map(lead => lead.us_owners).flat();

  const unknownOwners = totalOwners.filter(
    owner => owner.is_correct_match === null || owner.is_correct_match === undefined
  );
  const filteredOwners = filteredLeads.map(lead => lead.us_owners).flat();

  return (
    <>
      <div className="flex w-full">
        <Callout
          title="Do the owners belong to the Adreess?"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>1. Click on the "Unknown" tab and select the owners to the address</div>
          <div>
            2. Owners who you have marked as correctly matched to the address have a green
            background
          </div>
          <div>
            3. Owners who you have marked as incorrectly matched to the address have a red
            background
          </div>
          <div>
            4. Owners who have not been decided on whether they are match have a grey background
          </div>
        </Callout>
      </div>

      <div className="flex space-x-4 justify-between">
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 potential Owner</Text>
          <Metric>
            {(() => {
              const potentialOwnersCount = fixedAddresses.filter(
                lead => lead.us_owners.length > 0
              ).length;
              const totalCount = fixedAddresses.length;
              const percentage = ((potentialOwnersCount / totalCount) * 100).toFixed(0);
              return `${potentialOwnersCount} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 correctly matched Owner</Text>
          <Metric>
            {(() => {
              const potentialOwnersCount = fixedAddresses.filter(lead =>
                lead.us_owners.some(owner => owner.is_correct_match === true)
              ).length;
              const totalCount = fixedAddresses.length;
              const percentage = ((potentialOwnersCount / totalCount) * 100).toFixed(0);
              return `${potentialOwnersCount} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>

        <Card className="max-w-xs mt-6" decoration="top" decorationColor="orange">
          <Text>Unknown Owners</Text>
          <Metric>
            {unknownOwners.length} / {totalOwners.length} (
            {((unknownOwners.length / totalOwners.length) * 100).toFixed(0)}
            %)
          </Metric>
        </Card>
      </div>

      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  checked={selectAll.status}
                  onChange={() =>
                    setSelectAll(selectAll => ({
                      status: !selectAll.status,
                      changedByItemToggle: false,
                    }))
                  }
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (
                  {filteredLeads.reduce((acc, lead) => {
                    return acc + lead.us_owners.filter(owner => owner.isSelected).length;
                  }, 0)}
                  )
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all owners that you can currently see
                </p>
              </div>
            </div>
            <div className="flex flex-row space-x-10">
              <Dropdown
                items={[
                  {
                    icon: CheckIcon,
                    name: "Mark As Correct",
                    function: async () => {
                      const selectedChanges = filteredLeads
                        .flatMap(lead => lead.us_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => {
                          return {
                            usOwnerId: owner.id,
                            isCorrectMatch: true,
                          };
                        });
                      if (selectedChanges.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }

                      if (!campaign?.id) {
                        toast.error("Campaign ID not found");
                        return;
                      }
                      await updateOwnerMatchDecisionsMutation({
                        usCampaignId: campaign.id,
                        usOwnerMatchDecisions: selectedChanges,
                      });
                    },
                  },
                  {
                    icon: XCircleIcon,
                    name: "Mark as Incorrect",
                    function: async () => {
                      const selectedChanges = filteredLeads
                        .flatMap(lead => lead.us_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => {
                          return {
                            usOwnerId: owner.id,
                            isCorrectMatch: false,
                          };
                        });
                      if (selectedChanges.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }

                      if (!campaign?.id) {
                        toast.error("Campaign ID not found");
                        return;
                      }
                      await updateOwnerMatchDecisionsMutation({
                        usCampaignId: campaign.id,
                        usOwnerMatchDecisions: selectedChanges,
                      });
                    },
                  },
                ]}
              />
            </div>
          </div>
          <Divider className="mt-1 mb-0" />
          <div className="flex p-4 justify-center w-full items-center space-x-4">
            <Text>Displaying</Text>
            <Metric className="text-gray-500">{filteredOwners.length}</Metric>
            <Text>
              {" "}
              / {totalOwners.length} Owners{" "}
              <span className="italic">({totalOwners.length} total owners)</span>
            </Text>
          </div>
          <Divider className="mt-1 mb-0" />

          <div className="py-4 px-4">
            <div className="flex items-center justify-between">
              <h2 className="text-sm font-medium text-gray-900">Show Only:</h2>
            </div>
            <RadioGroup
              value={selectedRadioOption}
              onChange={setSelectedRadioOption}
              className="mt-2"
            >
              <div className="flex flex-row justify-between space-x-6">
                {suspectedOwnerCleanRadioOptions.map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium  sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {filteredLeads.map(lead => {
          return (
            <Card className={"max-w-md mx-auto"}>
              <Flex justifyContent="start" className="space-x-1" alignItems="baseline">
                <Metric>{lead.address}</Metric>
              </Flex>

              <Flex className="w-full">
                <Bold className="mt-6 w-full pt-4 border-t">Potential Owners</Bold>
              </Flex>

              <AccordionList className="mt-6">
                {lead.us_owners.map((owner, idx) => {
                  const startDate = owner.start_date
                    ? dayjs(owner.start_date).format("MM/YYYY")
                    : "N/A";
                  const endDate = owner.end_date ? dayjs(owner.end_date).format("MM/YYYY") : "";
                  const date = `${startDate} - ${endDate}`;

                  return (
                    <Accordion
                      key={owner.id}
                      className={classNames(
                        owner.is_correct_match === true
                          ? " border border-green-500 bg-green-50"
                          : owner.is_correct_match === false
                          ? " border border-red-500 bg-red-50"
                          : " border border-gray-500 bg-gray-50",

                        " overflow-visible	"
                      )}
                    >
                      <AccordionHeader className="w-full">
                        <div className="flex flex-col">
                          <div className="flex flex-row justify-between w-full">
                            <div className=" h-5 items-center mr-3">
                              <input
                                type="checkbox"
                                checked={owner.isSelected}
                                onClick={event => event.stopPropagation()}
                                onChange={event => {
                                  event.stopPropagation();

                                  handleIndividualChange(lead.id, owner.id);
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                            </div>
                            <Flex className="flex-col justify-center items-center">
                              <Text>
                                {owner.first_name} {owner.last_name} - ({date})
                              </Text>
                              <p className="text-xs text-gray-400">{owner.full_name}</p>
                              <div className="mt-2">
                                <div className="text-left	 text-gray-400 text-xs">
                                  {owner.stated_address}
                                </div>
                              </div>
                            </Flex>

                            <div className="">
                              <Badge size="xs" color="orange" className="mr-2">
                                <span className="text-xs">
                                  {owner.is_contender ? "Contender" : "Not Contender"}
                                </span>
                              </Badge>
                              <Badge size="xs">
                                <span className="text-xs">{owner.provider} </span>
                              </Badge>
                              <Badge size="xs">
                                <span className="text-xs">
                                  {owner.is_correct_match
                                    ? "Correct Address"
                                    : owner.is_correct_match === false
                                    ? "False Address"
                                    : "Not Checked"}
                                </span>
                              </Badge>
                            </div>
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody className="overflow-visible	">
                        {/* <Text className="flex flex-row mt-2">
                          <Bold>Additional Info</Bold>
                        </Text>
                        <pre className="mt-2 p-2 bg-gray-100 rounded max-w-full whitespace-pre-wrap">
                          {owner.additional_info}
                        </pre> */}
                      </AccordionBody>
                    </Accordion>
                  );
                })}
              </AccordionList>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default SuspectedOwnersCleanTab;
