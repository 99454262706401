// useBulkUpdateDigitalProfileStatus.ts

import { ApolloError } from "@apollo/client";
import { useBulkUpdateDigitalProfileStatusMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";

export interface BulkUpdateDigitalProfileStatusHookResult {
  bulkUpdateDigitalProfileStatus: (
    options: BulkUpdateDigitalProfileStatusMutationOptions
  ) => Promise<void>;
}

export interface BulkUpdateDigitalProfileStatusMutationOptions {
  ids: number[];
  digitalProfileStatusId: number;
}

export const useBulkUpdateDigitalProfileStatus = (): BulkUpdateDigitalProfileStatusHookResult => {
  const [bulkUpdateMutation] = useBulkUpdateDigitalProfileStatusMutation({
    onError: error => {
      throw error;
    },
  });

  return {
    bulkUpdateDigitalProfileStatus: async ({
      ids,
      digitalProfileStatusId,
    }: BulkUpdateDigitalProfileStatusMutationOptions) => {
      await bulkUpdateMutation({
        variables: {
          ids,
          digital_profile_status_id: digitalProfileStatusId,
        },
      });
    },
  };
};
