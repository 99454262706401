import { ReactElement, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, useUserStatus } from "../../../infrastructure/firebase/index";
import {
  SubscriptionProduct,
  SubscriptionStatusClaim,
} from "../../../infrastructure/firebase/types";
import { hasRequiredSubscription } from "../layout/utils";
import Layout from "../layout/Layout";
import NotFound from "../../../../modules/notFound/notFound/page/NotFound";

interface PrivateRouteProps extends RouteProps {
  component: (props: any) => ReactElement;
  isAdmin: boolean;
  isFreelancer: boolean;
  requiredSubscription?: { type: SubscriptionProduct; statuses: SubscriptionStatusClaim[] };
  redirectPath?: string;
}

const PrivateRoute = ({
  component: Component,
  isAdmin,
  isFreelancer,
  requiredSubscription,
  redirectPath,
  ...rest
}: PrivateRouteProps) => {
  const [user, loading] = useAuthState(auth);
  const {
    isAdmin: adminStatus,
    isFreelancer: freelancerStatus,
    isLoading,
    leadsStatus,
    socialsStatus,
  } = useUserStatus({ user, withTokenRefresh: false });

  const isBlocked =
    // block if requiredSubscription is not met
    (requiredSubscription &&
      !hasRequiredSubscription(requiredSubscription, {
        leads: leadsStatus,
        socials: socialsStatus,
        isAdmin: adminStatus,
        isFreelancer: freelancerStatus,
      })) ||
    // if page only has isAdmin, block non-admins
    (isAdmin && !isFreelancer && !adminStatus) ||
    // if page has both isAdmin and isFreelancer, block if neither condition is met
    (isAdmin && isFreelancer && !adminStatus && !freelancerStatus);

  if (loading || isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props =>
        user && !isBlocked ? (
          <Component {...props} />
        ) : user && isBlocked ? (
          <Layout>
            <p>ACCESS DENIED</p>
          </Layout>
        ) : !user ? (
          <Redirect to="/" />
        ) : null
      }
    />
  );
};

export default PrivateRoute;
