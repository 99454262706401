import { ApolloError, useQuery } from "@apollo/client";
import { GetTrackingGroupsByCampaignIdQuery } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { getTrackingGroupsByCampaignId } from "../queries/getTrackingGroupsByCampaignId";
import { ArrayElement } from "../../../../../shared/internals/utils";
import { TRACKING_GROUP_TYPE } from "../../../../../shared/internals/constants/models";

export type UseTrackingGroupsByCampaignIdReturnType = ReturnType<
  typeof useTrackingGroupsByCampaignId
>;

export const useTrackingGroupsByCampaignId = ({ campaignId }: { campaignId: number }) => {
  const { data, error, loading, refetch } = useQuery<GetTrackingGroupsByCampaignIdQuery>(
    getTrackingGroupsByCampaignId,
    {
      variables: { campaignId: campaignId },
      skip: campaignId === -1,
    }
  );

  const allTrackingGroups = data && Array.isArray(data.tracking_group) ? data.tracking_group : [];

  ////////////// ADDRESSES GROUPS

  // GET DOOR KNOCKING CAMPAIGN LEAD PROFILES GROUP

  const getDoorKnockingCampaignLeadProfilesTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.GET_PROSPECTED_PROFILES
  );

  const incompleteDoorKnockingCampaignLeadProfilesGroups =
    getDoorKnockingCampaignLeadProfilesTrackingGroups.filter(group => group.is_completed === false);

  const lastIncompleteDoorKnockingCampaignLeadProfilesGroup =
    incompleteDoorKnockingCampaignLeadProfilesGroups[
      incompleteDoorKnockingCampaignLeadProfilesGroups.length - 1
    ];

  /// GET ADDRESSES GROUPS

  const getAddressesTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.GET_ADDRESSES
  );
  const incompleteGetAddressesGroups = getAddressesTrackingGroups.filter(
    group => group.is_completed === false
  );
  const lastIncompleteGetAddressesGroup =
    incompleteGetAddressesGroups[incompleteGetAddressesGroups.length - 1];

  /// CLEAN ADDRESSES GROUPS

  const cleanAddressesTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.CLEAN_ADDRESSES
  );

  const incompleteCleanAddressesGroups = cleanAddressesTrackingGroups.filter(
    group => group.is_completed === false
  );

  const lastIncompleteCleanAddressesGroup =
    incompleteCleanAddressesGroups[incompleteCleanAddressesGroups.length - 1];

  /// MATCH ADDRESSES GROUPS

  const matchAddressesTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.MATCH_ADDRESSES
  );

  const incompleteMatchAddressesGroups = matchAddressesTrackingGroups.filter(
    group => group.is_completed === false
  );

  const lastIncompleteMatchAddressesGroup =
    incompleteMatchAddressesGroups[incompleteMatchAddressesGroups.length - 1];

  ////////////// OWNERS GROUPS

  /// GET OWNERS GROUPS

  const getOwnersTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.GET_OWNERS
  );

  const incompleteGetOwnersGroups = getOwnersTrackingGroups.filter(
    group => group.is_completed === false
  );

  const lastIncompleteGetOwnersGroup =
    incompleteGetOwnersGroups[incompleteGetOwnersGroups.length - 1];

  /// MATCH OWNERS GROUPS

  const matchOwnersTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.MATCH_OWNERS
  );

  const incompleteMatchOwnersGroups = matchOwnersTrackingGroups.filter(
    group => group.is_completed === false
  );

  const lastIncompleteMatchOwnersGroup =
    incompleteMatchOwnersGroups[incompleteMatchOwnersGroups.length - 1];

  /// CLEAN OWNERS GROUPS

  const cleanOwnersTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.CLEAN_OWNERS
  );

  const incompleteCleanOwnersGroups = cleanOwnersTrackingGroups.filter(
    group => group.is_completed === false
  );

  const lastIncompleteCleanOwnersGroup =
    incompleteCleanOwnersGroups[incompleteCleanOwnersGroups.length - 1];

  ////////////// PROFILES GROUPS

  /// CLEAN PROFILES GROUPS

  const cleanProfilesTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.CLEAN_PROFILES
  );

  const incompleteCleanProfilesGroups = cleanProfilesTrackingGroups.filter(
    group => group.is_completed === false
  );
  const lastIncompleteCleanProfilesGroup =
    incompleteCleanProfilesGroups[incompleteCleanProfilesGroups.length - 1];

  // MATCH PROFILES

  const matchProfilesTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.MATCH_PROFILES
  );

  const incompleteMatchProfilesGroups = matchProfilesTrackingGroups.filter(
    group => group.is_completed === false
  );

  const lastIncompleteMatchProfilesGroup =
    incompleteMatchProfilesGroups[incompleteMatchProfilesGroups.length - 1];

  // GET EMAILS

  const getEmailProfilesTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.GET_EMAIL_PROFILES
  );

  const incompleteGetEmailProfilesGroups = getEmailProfilesTrackingGroups.filter(
    group => group.is_completed === false
  );

  const lastIncompleteGetEmailProfilesGroup =
    incompleteGetEmailProfilesGroups[incompleteGetEmailProfilesGroups.length - 1];

  const refetchTrackingGroups = async () => {
    await refetch();
  };
  return {
    getAddresses: {
      getAddressesGroups: getAddressesTrackingGroups,
      lastIncompleteGetAddressesGroup,
      incompleteGetAddressesGroups,
    },
    cleanAddresses: {
      cleanAddressesGroups: cleanAddressesTrackingGroups,
      lastIncompleteCleanAddressesGroup,
      incompleteCleanAddressesGroups,
    },
    matchAddresses: {
      matchAddressesGroups: matchAddressesTrackingGroups,
      lastIncompleteMatchAddressesGroup,
      incompleteMatchAddressesGroups,
    },
    getOwners: {
      getOwnersGroups: getOwnersTrackingGroups,
      lastIncompleteGetOwnersGroup,
      incompleteGetOwnersGroups,
    },
    matchOwners: {
      matchOwnersGroups: matchOwnersTrackingGroups,
      lastIncompleteMatchOwnersGroup,
      incompleteMatchOwnersGroups,
    },
    cleanOwners: {
      cleanOwnersGroups: cleanOwnersTrackingGroups,
      lastIncompleteCleanOwnersGroup,
      incompleteCleanOwnersGroups,
    },
    cleanProfiles: {
      cleanProfilesGroups: cleanProfilesTrackingGroups,
      lastIncompleteCleanProfilesGroup,
      incompleteCleanProfilesGroups,
    },
    matchProfiles: {
      matchProfilesGroups: matchProfilesTrackingGroups,
      lastIncompleteMatchProfilesGroup,
      incompleteMatchProfilesGroups,
    },
    doorKnockingCampaignLeadProfiles: {
      getDoorKnockingCampaignLeadProfilesGroups: getDoorKnockingCampaignLeadProfilesTrackingGroups,
      lastIncompleteDoorKnockingCampaignLeadProfilesGroup,
      incompleteDoorKnockingCampaignLeadProfilesGroups,
    },
    getEmailProfiles: {
      getEmailProfilesGroups: getEmailProfilesTrackingGroups,
      lastIncompleteGetEmailProfilesGroup,
      incompleteGetEmailProfilesGroups,
    },
    trackingGroupsError: error,
    trackingGroupsLoading: loading,
    refetch: refetchTrackingGroups,
  };
};
