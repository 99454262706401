import { ApolloError } from "@apollo/client";
import {
  GetCampaignLeadProfilesQuery,
  useGetCampaignLeadProfilesQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";

interface HookResult {
  campaignLeadProfiles: GetCampaignLeadProfilesQuery["campaign_lead_profile"];
  campaignLeadProfilesError?: ApolloError;
  campaignLeadProfilesLoading: boolean;
  refetch: () => Promise<void>;
}

export const useCampaignLeadProfiles = ({ campaignId }: { campaignId: number }): HookResult => {
  const { data, error, loading, refetch } = useGetCampaignLeadProfilesQuery({
    variables: {
      id: campaignId,
    },
    skip: campaignId === -1,
  });

  const refetchCampaignLeadProfiles = async () => {
    await refetch();
  };

  return {
    campaignLeadProfiles:
      data && Array.isArray(data.campaign_lead_profile) ? data.campaign_lead_profile : [],

    campaignLeadProfilesError: error,
    campaignLeadProfilesLoading: loading,

    refetch: refetchCampaignLeadProfiles,
  };
};
