import { ApolloError } from "@apollo/client";
import {
  GetMessageSetsQuery,
  useGetMessageSetsQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { ArrayElement } from "../../../../../shared/internals/utils";

export type SingleMessageSet = ArrayElement<GetMessageSetsQuery["message_set"]>;
interface HookResult {
  messageSets: GetMessageSetsQuery["message_set"];
  messageSetsError?: ApolloError;
  messageSetsLoading: boolean;
}

export const UseMessageSets = (): HookResult => {
  const { data, error, loading } = useGetMessageSetsQuery();

  return {
    messageSets: data && Array.isArray(data.message_set) ? data.message_set : [],
    messageSetsError: error,
    messageSetsLoading: loading,
  };
};
