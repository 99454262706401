export const turnstoneStyles = {
  input:
    "w-[170px] text-sm h-9  border rounded border-gray-400 py-2 px-4 text-gray-900  outline-none rounded-xl",
  inputFocus: "",
  // "w-[300px] h-12 border-x-0 border-t-0 border-b border-crystal-500 py-2 pl-10 pr-7 text-xl outline-none sm:rounded sm:border",
  query: "",
  typeahead: "text-crystal-500 text-3xl border-white",
  cancelButton: `absolute w-10 h-12 inset-y-0 left-0 items-center justify-center z-10 text-crystal-600 inline-flex sm:hidden`,
  clearButton:
    "absolute inset-y-0 right-0 w-8 inline-flex items-center justify-center text-crystal-500 hover:text-hotpink-300",
  listbox:
    "w-full bg-white  sm:border sm:border-crystal-500 sm:rounded text-left sm:mt-2 p-2 sm:drop-shadow-xl",
  groupHeading: "cursor-default mt-2 mb-0.5 px-1.5 uppercase text-sm text-hotpink-300",
  item: "cursor-pointer   p-1.5 text-lg overflow-ellipsis overflow-hidden ",
  highlightedItem:
    "cursor-pointer bg-blue-100 p-1.5 text-lg overflow-ellipsis overflow-hidden  rounded ",
  match: "font-bold ",
  noItems: "cursor-default text-center my-20",
};

// The maximum number of items we want to show in the list
// const Cancel = () => <Icon type="cancel" className="w-8 h-8" />;

// const Clear = () => <Icon type="clear" className="w-6 h-6" />;

// const listbox = [
//   {
//     id: "cities",
//     name: "Cities",
//     ratio: 8,
//     displayField: "name",
//     data: query =>
//       fetch(`/api/cities?q=${encodeURIComponent(query)}&limit=${maxItems}`).then(response =>
//         response.json()
//       ),
//     searchType: "startswith",
//   },
//   {
//     id: "airports",
//     name: "Airports",
//     ratio: 2,
//     displayField: "name",
//     data: query =>
//       fetch(`/api/airports?q=${encodeURIComponent(query)}&limit=${maxItems}`).then(response =>
//         response.json()
//       ),
//     searchType: "contains",
//   },
// ];
