import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";
import { useMutation } from "react-query";

export interface MatchAddressesBody {
  campaignLeadIds: number[];
  campaignId: number;
}

export const matchAddressesAPI = async ({ campaignId, campaignLeadIds }: MatchAddressesBody) => {
  const response = await meetmoreAPI.post(`/addresses/match`, { campaignId, campaignLeadIds });
  return response.data;
};

export const useMatchAddresses = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;

  return useMutation(matchAddressesAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Matching Addresses...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Matched Addresses");
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error Matching Addresses: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
