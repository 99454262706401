import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";

interface GetPlanningApplicationOwnersOptions {
  campaignId: number;
  campaignLeadIds: number[];
}

export const GetPlanningApplicationOwnersAPI = async ({
  campaignId,
  campaignLeadIds,
}: GetPlanningApplicationOwnersOptions) => {
  try {
    const response = await meetmoreAPI.post(`/addresses/planning`, {
      campaignLeadIds,
      campaignId,
    });
    return response.data;
  } catch (err) {
    toast.error("Error in getting planning owners");
    throw err;
  }
};
