import { GetCampaignByIdQuery } from "../../../../shared/infrastructure/graphQL/generatedTypes";
import { Card, Metric, Text, Title, List, ListItem, Badge } from "@tremor/react";
import CampaignAnalytics from "./CampaignAnalytics";
import { CampaignLead } from "../graphql/hooks/useCampaignById";
import { PaperClipIcon } from "@heroicons/react/24/solid";
import { ExtractZooplaUrlsBody } from "../api/extractZooplaUrls";
import { UseMutateAsyncFunction } from "react-query";

interface CampaignSummaryTabProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"];
  campaignLeads: CampaignLead[];
  extractZooplaUrls: UseMutateAsyncFunction<any, Error, ExtractZooplaUrlsBody, void>;
  extractZooplaUrlsLoading: boolean;
  isFreelancer: boolean;
}

export const CampaignSummaryTab = ({
  campaign,
  campaignLeads,
  extractZooplaUrls,
  isFreelancer,
  extractZooplaUrlsLoading,
}: CampaignSummaryTabProps) => {
  return (
    <>
      <div>
        <div className="mt-6 ">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {campaign?.name}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Lead List URL</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-col space-y-4">
                {campaign?.lead_list_url ?? "No Leads CSV"}{" "}
                {campaign?.lead_list_url && (
                  <div>
                    <button
                      type="button"
                      disabled={extractZooplaUrlsLoading}
                      onClick={() => {
                        extractZooplaUrls({ campaignId: campaign.id });
                      }}
                      className="rounded mt-4 bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Extract Zoopla Leads
                    </button>
                  </div>
                )}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Campaign Type</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {campaign?.campaign_type.name}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Campaign Status</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {campaign?.campaign_status.name}
              </dd>
            </div>

            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Note:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {campaign?.note ?? "N/A"}
              </dd>
            </div>

            {campaign?.campaign_filters.map(filter => {
              return (
                <div className="px-4  bg-orange-50 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    {filter.campaign_filter_type.name}
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {filter.value && filter.value}
                    {filter.lower_bound !== null && `Lower Bound: ${filter.lower_bound}`}{" "}
                    {filter.upper_bound !== null && `Upper Bound: ${filter.upper_bound}`}
                  </dd>
                </div>
              );
            })}
          </dl>
        </div>
      </div>
      <div className="mt-3">
        <CampaignAnalytics campaign={campaign} campaignLeads={campaignLeads} />
      </div>
    </>
    // <>

    //   <div className="flex  mt-6 space-x-6 ">

    //     <Card className="max-w-xs">
    //       <Title>Campaign Details</Title>
    //       <List>
    //         <ListItem className="flex flex-col items-start	">
    //           <span>
    //             Campaign Status: <Badge> {campaign?.campaign_status.name}</Badge>
    //           </span>
    //           <span className="mt-2">
    //             Campaign Type: <Badge> {campaign?.campaign_type.name}</Badge>
    //           </span>
    //         </ListItem>
    //       </List>
    //     </Card>
    //     <Card className="max-w-xs " decoration="top" decorationColor="orange">
    //       <Text>Addresses</Text>
    //       {/* <Metric>{campaign?.leads.aggregate?.count}</Metric> */}
    //     </Card>
    //     <Card className="max-w-xs " decoration="top" decorationColor="orange">
    //       <Text>Addresses with at least 1 confirmed Owner</Text>
    //       <Metric>
    //         {/* {campaign?.confirmed_suspected_owners.aggregate?.count} /{" "}
    //         {campaign?.leads.aggregate?.count}{" "} */}
    //       </Metric>
    //     </Card>
    //   </div>
    //   <div className="flex  mt-6 space-x-6 ">
    //     <Card className="max-w-xs " decoration="top" decorationColor="orange">
    //       <Text>Addresses with at least 1 Confirmed Digital Profile</Text>
    //       <Metric>
    //         {/* {campaign?.confirmed_digital_profiles.aggregate?.count} /{" "}
    //         {campaign?.leads.aggregate?.count} */}
    //       </Metric>
    //     </Card>

    //     <Card className="max-w-xs " decoration="top" decorationColor="orange">
    //       <Text>Total Leads taken into Overlead</Text>
    //       <Metric>
    //         {campaign?.campaign_leads.map(lead => lead.campaign_lead_profiles).flat().length}
    //       </Metric>
    //     </Card>
    //   </div>
    // </>
  );
};
