import {
  GetCampaignByIdQuery,
  GetTrackingGroupsByCampaignIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  Bold,
  Button,
  Callout,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionList,
  Text,
  Badge,
  Divider,
  Icon,
  Title,
  BarList,
} from "@tremor/react";

import {
  XCircleIcon,
  ArchiveBoxIcon,
  ShieldCheckIcon,
  TrashIcon,
  CheckBadgeIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  EyeIcon,
  ComputerDesktopIcon,
  MapPinIcon,
  TableCellsIcon,
  MagnifyingGlassCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

import { CampaignLead } from "../graphql/hooks/useCampaignById";
import { GetPostcodeUnitByIdLazyHookResult } from "../graphql/hooks/usePostcodeUnitById";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateMatchedAddressValues } from "../types";
import { updateMatchedAddressValidationSchema } from "../validation";
import Select from "react-select";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import {
  ADDRESS_MATCH_QUALITY,
  DIGITAL_PROFILE_STATUS,
  OWNERSHIP_SOURCE,
  SUSPECTED_OWNER_STATUS,
  TRACKING_GROUP_TYPE,
} from "../../../../shared/internals/constants/models";
import { ArrayElement, classNames } from "../../../../shared/internals/utils";
import {
  digitalProfilesRadioOptions,
  epcStatuses,
  suspectedOwnerEvaluationRadioOptions,
} from "../data";
import { RadioGroup } from "@headlessui/react";
import toast from "react-hot-toast";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";
import Alert from "../../../../shared/internals/components/alert/Alert";
import { AutomaticDigitalProfilesConfirmation } from "../api/automaticDigitalProfileConfirmation";
import { UseMutateAsyncFunction } from "react-query";
import { BulkAddToCampaignLeadProfileMutationOptions } from "../graphql/hooks/useBulkAddCampaignLeadProfiles";
import { BulkDeleteCampaignLeadProfilesMutationOptions } from "../graphql/hooks/useBulkDeleteCampaignLeadProfiles";
import { uniqBy } from "lodash";
import dayjs from "dayjs";
import { TriggerTrackingGroupBody } from "../api/triggerTrackingGroup";
import { CancelTrackingGroupAPIOptions } from "../api/cancelTrackingGroup";
import { PauseTrackingGroupAPIOptions } from "../api/pauseTrackingGroup";
import { CursorArrowRippleIcon } from "@heroicons/react/24/outline";
import { addOrRemoveCampaignLeadProfilesAPIOptions } from "../api/addOrRemoveCampaignLeadProfiles";
interface DigitalProfilesTabProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"];
  campaignLeads: CampaignLead[];
  bulkDigitalProfileStatusUpdater: (
    profileIds: number[],
    digitalProfileStatus: DIGITAL_PROFILE_STATUS
  ) => Promise<void>;
  bulkAddToCampaignLeadProfile: (
    options: BulkAddToCampaignLeadProfileMutationOptions
  ) => Promise<void>;
  bulkDeleteCampaignLeadProfiles: (
    options: BulkDeleteCampaignLeadProfilesMutationOptions
  ) => Promise<void>;

  confirmDigitalProfiles: UseMutateAsyncFunction<
    any,
    any,
    AutomaticDigitalProfilesConfirmation,
    void
  >;
  isFreelancer: boolean;
  triggerTrackingGroup: UseMutateAsyncFunction<any, any, TriggerTrackingGroupBody, void>;
  triggerTrackingGroupLoading: boolean;

  incompleteCleanProfilesGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  lastIncompleteCleanProfilesGroup?: ArrayElement<
    GetTrackingGroupsByCampaignIdQuery["tracking_group"]
  >;
  cleanProfilesGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  incompleteMatchProfilesGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  lastIncompleteMatchProfilesGroup?: ArrayElement<
    GetTrackingGroupsByCampaignIdQuery["tracking_group"]
  >;
  matchProfilesGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  cancelTrackingGroup: UseMutateAsyncFunction<any, any, CancelTrackingGroupAPIOptions, void>;
  pauseTrackingGroup: UseMutateAsyncFunction<any, any, PauseTrackingGroupAPIOptions, void>;
  pauseTrackingGroupLoading: boolean;
  cancelTrackingGroupLoading: boolean;
  // 4) Add prop definition
  addOrRemoveCampaignLeadProfiles: UseMutateAsyncFunction<
    any,
    any,
    addOrRemoveCampaignLeadProfilesAPIOptions,
    void
  >;
  incompleteGetEmailProfilesGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  lastIncompleteGetEmailProfilesGroup?: ArrayElement<
    GetTrackingGroupsByCampaignIdQuery["tracking_group"]
  >;
  getEmailProfilesGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
}

const DigitalProfilesTab = ({
  campaignLeads,
  isFreelancer,
  campaign,
  bulkAddToCampaignLeadProfile,
  bulkDigitalProfileStatusUpdater,
  confirmDigitalProfiles,
  cleanProfilesGroups,
  lastIncompleteCleanProfilesGroup,
  incompleteCleanProfilesGroups,
  triggerTrackingGroup,
  triggerTrackingGroupLoading,
  cancelTrackingGroup,
  pauseTrackingGroup,
  cancelTrackingGroupLoading,
  pauseTrackingGroupLoading,
  matchProfilesGroups,
  incompleteMatchProfilesGroups,
  lastIncompleteMatchProfilesGroup,
  getEmailProfilesGroups,
  incompleteGetEmailProfilesGroups,
  lastIncompleteGetEmailProfilesGroup,
  addOrRemoveCampaignLeadProfiles,
}: DigitalProfilesTabProps) => {
  const contenderOrVerifiedOwners = campaignLeads
    .map(lead =>
      lead.address.suspected_owners.filter(
        owner =>
          owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.CONTENDER ||
          owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.VERIFIED
      )
    )
    .flat();

  const ownersWithSelectableDigitalProfiles = contenderOrVerifiedOwners.map(owner => ({
    ...owner,
    digital_profiles: owner.digital_profiles.map(profile => ({
      ...profile,
      isSelected: false,
    })),
  }));

  const [filteredOwners, setFilteredOwners] = useState(ownersWithSelectableDigitalProfiles);

  const [selectAll, setSelectAll] = useState<{ status: boolean; changedByItemToggle: boolean }>({
    status: false,
    changedByItemToggle: false,
  });

  const [selectedRadioOption, setSelectedRadioOption] = useState(digitalProfilesRadioOptions[0]);

  const [selectedFilters, setSelectedFilters] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (selectAll.status === true && selectAll.changedByItemToggle === false) {
      const updatedOwners = filteredOwners.map(owner => {
        return {
          ...owner,
          digital_profiles: owner.digital_profiles.map(profile => ({
            ...profile,
            isSelected: true,
          })),
        };
      });
      setFilteredOwners(updatedOwners);
    }

    if (selectAll.status === false && selectAll.changedByItemToggle === false) {
      const updatedOwners = filteredOwners.map(owner => {
        return {
          ...owner,
          digital_profiles: owner.digital_profiles.map(profile => ({
            ...profile,
            isSelected: false,
          })),
        };
      });
      setFilteredOwners(updatedOwners);
    }
  }, [selectAll.status]);

  useEffect(() => {
    let updatedOwners = [...ownersWithSelectableDigitalProfiles];

    // Apply the filter conditions

    // SHOW BY CONDITIONS

    // LEADS WITH 0 OWNERS
    if (selectedRadioOption.id === 2) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 0;
      });
    }

    // LEADS WITH 1 OWNERS
    else if (selectedRadioOption.id === 3) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 1;
      });
    } else if (selectedRadioOption.id === 4) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 2;
      });
    } else if (selectedRadioOption.id === 5) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 3;
      });
    } else if (selectedRadioOption.id === 6) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 4;
      });
    } else if (selectedRadioOption.id === 7) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 5;
      });
    } else if (selectedRadioOption.id === 8) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 6;
      });
    } else if (selectedRadioOption.id === 9) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 7;
      });
    } else if (selectedRadioOption.id === 10) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 8;
      });
    } else if (selectedRadioOption.id === 11) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length === 9;
      });
    } else if (selectedRadioOption.id === 12) {
      updatedOwners = updatedOwners.filter(owner => {
        return owner.digital_profiles.length >= 10;
      });
    } else if (selectedRadioOption.id === 13) {
      updatedOwners = updatedOwners
        .filter(owner => {
          return owner.digital_profiles.some(
            profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.NEW
          );
        })
        .map(owner => ({
          ...owner,
          digital_profiles: owner.digital_profiles.filter(
            profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.NEW
          ),
        }));
    } else if (selectedRadioOption.id === 14) {
      updatedOwners = updatedOwners
        .filter(owner => {
          return owner.digital_profiles.some(
            profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.REJECTED
          );
        })
        .map(owner => ({
          ...owner,
          digital_profiles: owner.digital_profiles.filter(
            profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.REJECTED
          ),
        }));
    } else if (selectedRadioOption.id === 15) {
      updatedOwners = updatedOwners
        .filter(owner => {
          return owner.digital_profiles.some(
            profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.DUPLICATE
          );
        })
        .map(owner => ({
          ...owner,
          digital_profiles: owner.digital_profiles.filter(
            profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.DUPLICATE
          ),
        }));
    } else if (selectedRadioOption.id === 16) {
      updatedOwners = updatedOwners
        .filter(owner => {
          return owner.digital_profiles.some(profile =>
            [DIGITAL_PROFILE_STATUS.VERIFIED, DIGITAL_PROFILE_STATUS.CONTENDER].includes(
              profile.digital_profile_status.id
            )
          );
        })
        .map(owner => ({
          ...owner,
          digital_profiles: owner.digital_profiles.filter(profile =>
            [DIGITAL_PROFILE_STATUS.VERIFIED, DIGITAL_PROFILE_STATUS.CONTENDER].includes(
              profile.digital_profile_status.id
            )
          ),
        }));
    } else if (selectedRadioOption.id === 17) {
      updatedOwners = updatedOwners
        .filter(owner => {
          return owner.digital_profiles.some(profile => Boolean(profile.campaign_lead_profile?.id));
        })
        .map(owner => ({
          ...owner,
          digital_profiles: owner.digital_profiles.filter(profile =>
            Boolean(profile.campaign_lead_profile?.id)
          ),
        }));
    }

    // Handle the selectAll logic
    if (selectAll.status === true) {
      updatedOwners = updatedOwners.map(owner => {
        return {
          ...owner,
          digital_profiles: owner.digital_profiles.map(profile => ({
            ...profile,
            isSelected: true,
          })),
        };
      });
    }

    setFilteredOwners(updatedOwners);
  }, [selectedFilters, selectedRadioOption, campaign, campaignLeads]);

  const handleIndividualChange = (ownerId: number, profileId: number) => {
    // Find the specific owner and digital profile
    const owner = filteredOwners.find(owner => owner.id === ownerId);
    const profile = owner?.digital_profiles.find(profile => profile.id === profileId);
    const profileWillBeSelected = !profile?.isSelected;

    // Update the owners
    const updatedOwners = filteredOwners.map(owner => {
      if (owner.id === ownerId) {
        return {
          ...owner,
          digital_profiles: owner.digital_profiles.map(profile =>
            profile.id === profileId ? { ...profile, isSelected: profileWillBeSelected } : profile
          ),
        };
      }
      return owner;
    });

    // If the digital profile is about to be selected, check if all profiles will be selected
    if (profileWillBeSelected) {
      const allProfilesSelected = updatedOwners.every(owner =>
        owner.digital_profiles.every(profile => profile.isSelected)
      );
      if (allProfilesSelected) {
        setSelectAll({ status: true, changedByItemToggle: true });
      }
    }
    // If the digital profile is about to be unselected and selectAll is currently true, update selectAll
    else if (selectAll.status) {
      setSelectAll({ status: false, changedByItemToggle: true });
    }

    // Update the filteredOwners state
    setFilteredOwners(updatedOwners);
  };

  return (
    <>
      <div className="flex w-full">
        <Callout
          title="Decide which profiles are contenders and add them to the Overlead CSV"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>1. Make decisions for Owners with 1 profile</div>
          <div>2. Make decisions for Owners with 2+ owners</div>
          <div>
            <div>
              3. Select all profiles with "Verified" or "Contender" status and Add them to overlead
              (you can do this by clicking 'Contender' & 'Verified' Tab, then clicking the select
              all checkbox and then clicking Add to Overlead)
            </div>{" "}
            <div className="italic mt-3 text-gray-600">
              Any digital profile added to the Overlead CSV will be highlighted gold.
            </div>
          </div>
        </Callout>
      </div>
      <div className="mt-5">
        {cleanProfilesGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              cleanProfilesGroups[0].is_cancelled === true
                ? "red"
                : cleanProfilesGroups[0].is_completed === true &&
                  cleanProfilesGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              cleanProfilesGroups[0].is_cancelled === true
                ? "Cancelled"
                : cleanProfilesGroups[0].is_completed === true &&
                  cleanProfilesGroups[0].is_cancelled === false
                ? "Completed"
                : cleanProfilesGroups[0].is_error === true
                ? "In Error"
                : cleanProfilesGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = cleanProfilesGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = cleanProfilesGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = cleanProfilesGroups.slice(1).map(group => {
              const completedTrackingItemsCount = cleanProfilesGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Profiles Clean ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(cleanProfilesGroups[0].is_error || cleanProfilesGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: cleanProfilesGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!campaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: cleanProfilesGroups[0].id,
                              campaignId: campaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.CLEAN_PROFILES,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!cleanProfilesGroups[0].is_error &&
                      !cleanProfilesGroups[0].is_paused &&
                      !cleanProfilesGroups[0].is_cancelled &&
                      !cleanProfilesGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: cleanProfilesGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} searches completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Clean Triggered:{" "}
                  <Bold>{dayjs(cleanProfilesGroups[0].created_at).format("DD/MM/YYYY mm:ss")}</Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Cleaning Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text>Cleaning Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Cleans"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>
      <div className="mt-5">
        {matchProfilesGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              matchProfilesGroups[0].is_cancelled === true
                ? "red"
                : matchProfilesGroups[0].is_completed === true &&
                  matchProfilesGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              matchProfilesGroups[0].is_cancelled === true
                ? "Cancelled"
                : matchProfilesGroups[0].is_completed === true &&
                  matchProfilesGroups[0].is_cancelled === false
                ? "Completed"
                : matchProfilesGroups[0].is_error === true
                ? "In Error"
                : matchProfilesGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = matchProfilesGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = matchProfilesGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = matchProfilesGroups.slice(1).map(group => {
              const completedTrackingItemsCount = matchProfilesGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Profiles match ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(matchProfilesGroups[0].is_error || matchProfilesGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: matchProfilesGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!campaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: matchProfilesGroups[0].id,
                              campaignId: campaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.MATCH_PROFILES,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!matchProfilesGroups[0].is_error &&
                      !matchProfilesGroups[0].is_paused &&
                      !matchProfilesGroups[0].is_cancelled &&
                      !matchProfilesGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: matchProfilesGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} items completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Match Triggered:{" "}
                  <Bold>{dayjs(matchProfilesGroups[0].created_at).format("DD/MM/YYYY mm:ss")}</Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Matching Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text>Matching Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Matching"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>

      <div className="mt-5">
        {getEmailProfilesGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              getEmailProfilesGroups[0].is_cancelled === true
                ? "red"
                : getEmailProfilesGroups[0].is_completed === true &&
                  getEmailProfilesGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              getEmailProfilesGroups[0].is_cancelled === true
                ? "Cancelled"
                : getEmailProfilesGroups[0].is_completed === true &&
                  getEmailProfilesGroups[0].is_cancelled === false
                ? "Completed"
                : getEmailProfilesGroups[0].is_error === true
                ? "In Error"
                : getEmailProfilesGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = getEmailProfilesGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = getEmailProfilesGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = getEmailProfilesGroups.slice(1).map(group => {
              const completedTrackingItemsCount = getEmailProfilesGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Get Emails Request ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(getEmailProfilesGroups[0].is_error ||
                      getEmailProfilesGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: getEmailProfilesGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!campaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: getEmailProfilesGroups[0].id,
                              campaignId: campaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.GET_EMAIL_PROFILES,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!getEmailProfilesGroups[0].is_error &&
                      !getEmailProfilesGroups[0].is_paused &&
                      !getEmailProfilesGroups[0].is_cancelled &&
                      !getEmailProfilesGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: getEmailProfilesGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} items completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Match Triggered:{" "}
                  <Bold>
                    {dayjs(getEmailProfilesGroups[0].created_at).format("DD/MM/YYYY mm:ss")}
                  </Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Matching Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text>Matching Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Matching"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>
      <div className="flex space-x-4 justify-between">
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 Profile</Text>
          <Metric>
            {(() => {
              const addressesWithProfile = campaignLeads.filter(lead =>
                lead.address.suspected_owners.some(owner => owner.digital_profiles.length > 0)
              ).length;
              const totalCount = campaignLeads.length;
              const percentage = ((addressesWithProfile / totalCount) * 100).toFixed(0);
              return `${addressesWithProfile} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>

        <Card className="max-w-xs mt-6" decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 Contender Profile</Text>
          <Metric>
            {(() => {
              const addressesWithContenderProfile = campaignLeads.filter(lead =>
                lead.address.suspected_owners.some(owner =>
                  owner.digital_profiles.some(
                    profile =>
                      profile.digital_profile_status.id === SUSPECTED_OWNER_STATUS.CONTENDER
                  )
                )
              ).length;
              const totalCount = campaignLeads.length;
              const percentage = ((addressesWithContenderProfile / totalCount) * 100).toFixed(0);
              return `${addressesWithContenderProfile} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>
      </div>

      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  checked={selectAll.status}
                  onChange={() =>
                    setSelectAll(selectAll => ({
                      status: !selectAll.status,
                      changedByItemToggle: false,
                    }))
                  }
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (
                  {filteredOwners.reduce((acc, owner) => {
                    return (
                      acc + owner.digital_profiles.filter(profile => profile.isSelected).length
                    );
                  }, 0)}
                  )
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all profiles that you can currently see
                </p>
              </div>
            </div>

            <Dropdown
              items={[
                {
                  icon: HandThumbDownIcon,
                  name: "Mark as Rejected",
                  function: async () => {
                    const selectedProfileIds = filteredOwners
                      .flatMap(owner => owner.digital_profiles)
                      .filter(profile => profile.isSelected)
                      .map(profile => profile.owner_address_profile_id);

                    if (selectedProfileIds.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    await bulkDigitalProfileStatusUpdater(
                      selectedProfileIds,
                      DIGITAL_PROFILE_STATUS.REJECTED
                    );
                  },
                },
                {
                  icon: ArchiveBoxIcon,
                  name: "Mark as Duplicate",
                  function: async () => {
                    const selectedProfileIds = filteredOwners
                      .flatMap(owner => owner.digital_profiles)
                      .filter(profile => profile.isSelected)
                      .map(profile => profile.owner_address_profile_id);

                    if (selectedProfileIds.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    await bulkDigitalProfileStatusUpdater(
                      selectedProfileIds,
                      DIGITAL_PROFILE_STATUS.DUPLICATE
                    );
                  },
                },
                {
                  icon: HandThumbUpIcon,
                  name: "Mark as Contender",
                  function: async () => {
                    const selectedProfileIds = filteredOwners
                      .flatMap(owner => owner.digital_profiles)
                      .filter(profile => profile.isSelected)
                      .map(profile => profile.owner_address_profile_id);

                    if (selectedProfileIds.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    await bulkDigitalProfileStatusUpdater(
                      selectedProfileIds,
                      DIGITAL_PROFILE_STATUS.CONTENDER
                    );
                  },
                },
                {
                  icon: CheckBadgeIcon,
                  name: "Mark as Verified",
                  function: async () => {
                    const selectedProfileIds = filteredOwners
                      .flatMap(owner => owner.digital_profiles)
                      .filter(profile => profile.isSelected)
                      .map(profile => profile.owner_address_profile_id);

                    if (selectedProfileIds.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    await bulkDigitalProfileStatusUpdater(
                      selectedProfileIds,
                      DIGITAL_PROFILE_STATUS.VERIFIED
                    );
                  },
                },

                {
                  icon: MapPinIcon,
                  name: "Clean Profiles",
                  function: async () => {
                    const selectedSuspectedOwnerAddressIds = filteredOwners.reduce<string[]>(
                      (acc, owner) => {
                        const selectedProfiles = owner.digital_profiles.filter(
                          profile => profile.isSelected
                        );
                        if (selectedProfiles.length > 0) {
                          acc.push(String(owner.suspected_owner_address.id)); // Assuming each owner has a unique 'id' property
                        }
                        return acc;
                      },
                      []
                    );

                    if (selectedSuspectedOwnerAddressIds.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    if (!campaign) {
                      toast.error("Unexpected Error, Missing Campaign Id");
                      return;
                    }

                    triggerTrackingGroup({
                      campaignId: campaign.id,
                      trackingGroupTypeId: TRACKING_GROUP_TYPE.CLEAN_PROFILES,
                      trackingItemValues: selectedSuspectedOwnerAddressIds,
                    });
                  },
                },
                {
                  icon: CursorArrowRippleIcon,
                  name: "Match Profiles",
                  function: async () => {
                    const selectedSuspectedOwnerAddressIds = filteredOwners.reduce<string[]>(
                      (acc, owner) => {
                        const selectedProfiles = owner.digital_profiles.filter(
                          profile => profile.isSelected
                        );
                        if (selectedProfiles.length > 0) {
                          acc.push(String(owner.suspected_owner_address.id)); // Assuming each owner has a unique 'id' property
                        }
                        return acc;
                      },
                      []
                    );

                    if (selectedSuspectedOwnerAddressIds.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    if (!campaign) {
                      toast.error("Unexpected Error, Missing Campaign Id");
                      return;
                    }

                    triggerTrackingGroup({
                      campaignId: campaign.id,
                      trackingGroupTypeId: TRACKING_GROUP_TYPE.MATCH_PROFILES,
                      trackingItemValues: selectedSuspectedOwnerAddressIds,
                    });
                  },
                },
                {
                  icon: ExclamationCircleIcon,
                  name: "Get Emails",
                  function: async () => {
                    const selectedCampaignLeadProfileIds = filteredOwners.reduce<string[]>(
                      (acc, owner) => {
                        const selectedProfiles = owner.digital_profiles.filter(
                          profile => profile.isSelected
                        );
                        selectedProfiles.forEach(profile => {
                          if (profile.campaign_lead_profile && profile.campaign_lead_profile.id) {
                            acc.push(String(profile.campaign_lead_profile.id));
                          }
                        });
                        return acc;
                      },
                      []
                    );

                    if (selectedCampaignLeadProfileIds.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    if (!campaign) {
                      toast.error("Unexpected Error, Missing Campaign Id");
                      return;
                    }

                    triggerTrackingGroup({
                      campaignId: campaign.id,
                      trackingGroupTypeId: TRACKING_GROUP_TYPE.GET_EMAIL_PROFILES,
                      trackingItemValues: selectedCampaignLeadProfileIds,
                    });
                  },
                },
                {
                  icon: ExclamationCircleIcon,
                  name: "Prospect Emails",
                  function: async () => {
                    toast.success("Triggered Prospect Emails Successfully");
                  },
                },
                {
                  icon: ComputerDesktopIcon,
                  name: "Add to Overlead CSV",
                  function: async () => {
                    // First, get the IDs of the selected profiles from the filteredOwners array
                    const selectedDigitalProfiles = filteredOwners
                      .flatMap(owner => owner.digital_profiles)
                      .filter(profile => profile.isSelected)
                      // .filter(profile => !profile.campaign_lead_profile?.id)
                      .map(profile => {
                        return {
                          digitalProfileId: profile.id,
                          ownerAddressProfileId: profile.owner_address_profile_id,
                        };
                      });

                    // const uniqueProfiles = uniqBy(
                    //   selectedDigitalProfiles,
                    //   profile => profile.digitalProfileId
                    // );

                    const uniqueProfilesWithCampaignLeads = selectedDigitalProfiles.map(
                      ({ digitalProfileId, ownerAddressProfileId }) => {
                        const matchingCampaignLead = campaignLeads.find(lead =>
                          lead.address.suspected_owners.some(owner =>
                            owner.digital_profiles.some(
                              profile =>
                                profile.id === digitalProfileId &&
                                profile.owner_address_profile_id === ownerAddressProfileId
                            )
                          )
                        );

                        if (!matchingCampaignLead) {
                          toast.error("Unexpected Error, Missing Campaign Lead");
                          throw new Error("Unexpected Error, Missing Campaign Lead");
                        }
                        return {
                          digitalProfileId,
                          ownerAddressProfileId,
                          campaignLeadId: matchingCampaignLead.id,
                        };
                      }
                    );

                    if (uniqueProfilesWithCampaignLeads.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    if (!campaign) {
                      toast.error("Unexpected Error, Missing Campaign Id");
                      return;
                    }

                    // 5) CALL  MUTATION

                    await addOrRemoveCampaignLeadProfiles({
                      campaignId: campaign.id,
                      toAdd: uniqueProfilesWithCampaignLeads,
                    });

                    // await bulkAddToCampaignLeadProfile({
                    //   input: uniqueProfilesWithCampaignLeads,
                    // });
                  },
                },
                {
                  icon: EyeIcon,
                  name: "Mark as Pending Review",
                  function: async () => {
                    const selectedProfileIds = filteredOwners
                      .flatMap(owner => owner.digital_profiles)
                      .filter(profile => profile.isSelected)
                      .map(profile => profile.owner_address_profile_id);

                    if (selectedProfileIds.length === 0) {
                      toast.error("Please select profiles to perform operations on");
                      return;
                    }

                    await bulkDigitalProfileStatusUpdater(
                      selectedProfileIds,
                      DIGITAL_PROFILE_STATUS.NEW
                    );
                  },
                },
                // {
                //   icon: ArchiveBoxIcon,
                //   name: "Remove from CSV",
                //   function: async () => {
                //     // First, get the IDs of the selected profiles from the filteredOwners array
                //     const selectedDigitalProfileIds = filteredOwners
                //       .flatMap(owner => owner.digital_profiles)
                //       .filter(profile => profile.isSelected)
                //       .map(profile => profile.id);

                //     const selectedCampaignProfileLeadIds = filteredOwners
                //       .flatMap(owner => owner.digital_profiles)
                //       .filter(
                //         profile =>
                //           profile.isSelected &&
                //           profile.campaign_lead_profile &&
                //           !profile.campaign_lead_profile.deleted_at
                //       )
                //       .map(profile => profile.campaign_lead_profile?.id as number);

                //     // Now, map these IDs to their corresponding campaignLeadId

                //     if (selectedDigitalProfileIds.length === 0) {
                //       toast.error("Please select profiles to perform operations on");
                //       return;
                //     }

                //     if (
                //       selectedDigitalProfileIds.length !== selectedCampaignProfileLeadIds.length
                //     ) {
                //       toast.error(
                //         "Error: Are you sure you have only selected profiles in Overlead CSV to delete"
                //       );
                //       return;
                //     }

                //     await bulkDeleteCampaignLeadProfiles({ ids: selectedCampaignProfileLeadIds });
                //   },
                // },
              ]}
            />
          </div>

          <Divider className="mt-1 mb-0" />

          <div className="py-4 px-4">
            <RadioGroup
              value={selectedRadioOption}
              onChange={setSelectedRadioOption}
              className="mt-2"
            >
              <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium text-gray-900 mb-2">Show Only (Owners):</h2>
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6">
                {digitalProfilesRadioOptions.slice(0, 4).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6">
                {digitalProfilesRadioOptions.slice(4, 8).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6">
                {digitalProfilesRadioOptions.slice(8, 12).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
              <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium text-gray-900 mt-3">Show Only (Profiles):</h2>
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6 mt-2">
                {digitalProfilesRadioOptions.slice(12, 16).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? option.id === 14
                            ? "bg-pink-600 border-transparent text-white hover:bg-pink-700"
                            : option.id === 15
                            ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                            : option.id === 16
                            ? "bg-green-600 border-transparent text-white hover:bg-green-700"
                            : "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : option.id === 14
                          ? "bg-pink-50 border border-pink-500"
                          : option.id === 15
                          ? "bg-indigo-50 border border-indigo-500"
                          : option.id === 16
                          ? "bg-green-50 border border-green-500"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
              <div className="flex mt-2 flex-row justify-between space-x-6 mt-2">
                {digitalProfilesRadioOptions.slice(16, 17).map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? option.id === 6
                            ? "bg-pink-600 border-transparent text-white hover:bg-pink-700"
                            : option.id === 7
                            ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                            : option.id === 8
                            ? "bg-green-600 border-transparent text-white hover:bg-green-700"
                            : "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : option.id === 6
                          ? "bg-pink-50 border border-pink-500"
                          : option.id === 7
                          ? "bg-indigo-50 border border-indigo-500"
                          : option.id === 8
                          ? "bg-green-50 border border-green-500"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {filteredOwners.map(owner => {
          return (
            <Card className={"max-w-md mx-auto"}>
              <Flex className="mb-2">
                <Text>
                  {isFreelancer ? "OWNER" : owner.ownership_source.name.toLocaleUpperCase()}
                </Text>

                {owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.VERIFIED ||
                owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.CONTENDER ? (
                  <Text color="green">
                    <Bold>{owner.suspected_owner_status.name}</Bold>
                  </Text>
                ) : owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.REJECTED ||
                  owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.DUPLICATE ? (
                  <Text color="red">
                    <Bold>{owner.suspected_owner_status.name}</Bold>
                  </Text>
                ) : owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.NEW ? (
                  <Text color="gray">
                    <Bold>{owner.suspected_owner_status.name}</Bold>
                  </Text>
                ) : null}
              </Flex>

              <Flex justifyContent="start" className="space-x-1 flex-col" alignItems="baseline">
                <Metric>
                  {owner.first_name} {owner.last_name}
                </Metric>
                <p className="text-sm  text-gray-400">{owner.name}</p>
              </Flex>

              <Flex className="mt-2 ">
                <Text>{owner.stated_address}</Text>
              </Flex>

              <Flex className=" ">
                <p className="text-xs text-gray-300"> The above is the address tied to the owner</p>
              </Flex>

              {owner.ownership_source.id === OWNERSHIP_SOURCE.COMPANIES_HOUSE && (
                <Flex className="mt-6 flex-col pt-4 border-t">
                  <Flex className="">
                    <div>
                      <Text>Registration Date</Text>
                      <Text color="orange">
                        <Bold>{owner.additional_info?.company?.dateOfCreation}</Bold>
                      </Text>
                    </div>
                    <div>
                      <Text className="text-right">Cessation Date</Text>
                      <Text className="text-right">
                        <Bold>
                          {owner.additional_info?.company?.dateOfDissolution
                            ? owner.additional_info?.company?.dateOfDissolution
                            : "Still Active"}
                        </Bold>
                      </Text>
                    </div>
                  </Flex>

                  <a
                    href={`https://find-and-update.company-information.service.gov.uk/company/${owner.additional_info?.company?.companyNumber}`}
                    target="_blank"
                    className="w-full"
                    rel="noopener noreferrer"
                  >
                    <button
                      type="button"
                      className="rounded-md mt-5 w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      View Companies House
                    </button>
                  </a>
                </Flex>
              )}

              {owner.ownership_source.id === OWNERSHIP_SOURCE.PLANNING_APPLICATION && (
                <Flex className="mt-4 flex-col pt-4 border-t">
                  <a
                    href={
                      owner.additional_info?.document?.documentsUrl ??
                      owner.additional_info?.contacts?.contactsUrl
                    }
                    target="_blank"
                    className="w-full"
                    rel="noopener noreferrer"
                  >
                    <button
                      type="button"
                      className="rounded-md  w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      View Application Form
                    </button>
                  </a>
                </Flex>
              )}

              <Flex className="w-full">
                <Bold className="mt-6 w-full pt-4 border-t">Digital Profiles</Bold>
              </Flex>

              <AccordionList className="mt-6 ">
                {[...owner.digital_profiles]
                  .sort((a, b) => {
                    const distanceA = a.owner_address_profile?.distance_km;
                    const distanceB = b.owner_address_profile?.distance_km;

                    if (
                      distanceA !== undefined &&
                      distanceA !== null &&
                      distanceB !== undefined &&
                      distanceB !== null
                    ) {
                      // If both distances are available, sort numerically
                      return distanceA - distanceB;
                    } else if (distanceA !== undefined) {
                      // If only distanceA is available, place it before distanceB
                      return -1;
                    } else if (distanceB !== undefined) {
                      // If only distanceB is available, place it after distanceA
                      return 1;
                    } else {
                      // If both are undefined, keep the original order
                      return 0;
                    }
                  })
                  .map((profile, idx) => {
                    return (
                      <Accordion
                        key={profile.id}
                        className={classNames(
                          profile.campaign_lead_profile &&
                            !profile.campaign_lead_profile.deleted_at &&
                            profile.campaign_lead_profile.owner_address_profile_id ===
                              profile.owner_address_profile_id
                            ? "border border-yellow-400 bg-yellow-50"
                            : profile.campaign_lead_profile &&
                              !profile.campaign_lead_profile.deleted_at &&
                              profile.campaign_lead_profile.owner_address_profile_id !==
                                profile.owner_address_profile_id
                            ? "border border-slate-400 bg-slate-50"
                            : profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.REJECTED
                            ? "border border-pink-500 bg-pink-50"
                            : profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.DUPLICATE
                            ? "border border-indigo-500 bg-indigo-50"
                            : profile.digital_profile_status.id ===
                                DIGITAL_PROFILE_STATUS.VERIFIED ||
                              profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.CONTENDER
                            ? "border border-green-500 bg-green-50"
                            : "",
                          " "
                        )}
                      >
                        <AccordionHeader className="w-full">
                          <div className="flex flex-row justify-between items-center w-full">
                            <div className="flex  h-5 items-center mr-3">
                              <input
                                type="checkbox"
                                checked={profile.isSelected}
                                onClick={event => event.stopPropagation()}
                                onChange={event => {
                                  event.stopPropagation();

                                  handleIndividualChange(owner.id, profile.id);
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                            </div>
                            <div className="w-full ">
                              <Text>{profile.name ?? profile.value}</Text>
                              <div className="text-center	 text-gray-400 text-xs">
                                {profile.primary_additional_info?.location} -{" "}
                                {profile.owner_address_profile?.distance_km
                                  ? `${Math.round(
                                      profile.owner_address_profile?.distance_km / 1000
                                    ).toFixed(0)}km`
                                  : "?km"}
                              </div>
                            </div>
                            <div className="flex flex-row justify-center items-center ">
                              <Badge color="orange" className="mr-2">
                                <span className="text-xs">
                                  {profile.digital_profile_status.name}
                                </span>
                              </Badge>
                              {profile?.search?.search_type.name && (
                                <Badge color="orange" className="mr-2">
                                  <span className="text-xs">{profile.search.search_type.name}</span>
                                </Badge>
                              )}

                              {profile.campaign_lead_profile &&
                                !profile.campaign_lead_profile.deleted_at && (
                                  <TableCellsIcon color="gray" className="w-5" />
                                )}
                            </div>
                          </div>
                        </AccordionHeader>
                        <AccordionBody>
                          <a href={profile.value} target="_blank" rel="noopener noreferrer">
                            <button className="my-4 rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                              Go to Linkedin
                            </button>
                          </a>

                          <Text className="mt-2">
                            Full Name: {profile.primary_additional_info?.fullName}
                          </Text>

                          <Text className="mt-2">
                            Linkedin Location: {profile.primary_additional_info?.location}
                          </Text>

                          <Text className="mt-2">
                            Matched Location:{" "}
                            <span className="font-semibold">{profile.geocoded_place_name}</span>
                          </Text>

                          <Text className="mt-2">Job: {profile.primary_additional_info?.job}</Text>
                          {profile.primary_additional_info?.summary && (
                            <Text className="mt-2">
                              Summary: {profile.primary_additional_info?.summary}
                            </Text>
                          )}
                        </AccordionBody>
                      </Accordion>
                    );
                  })}
              </AccordionList>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default DigitalProfilesTab;
