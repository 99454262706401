import { ReactElement, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../infrastructure/firebase/index";

interface RestrictedRouteProps extends RouteProps {
  component: (props: any) => ReactElement;
  redirectPath?: string;
}

const RestrictedRoute = ({ component: Component, redirectPath, ...rest }: RestrictedRouteProps) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Redirect to={redirectPath ? redirectPath : "/internal-leads"} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default RestrictedRoute;
