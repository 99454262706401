import { useState, Fragment } from "react";
import { auth, useUserStatus } from "../../../../shared/infrastructure/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Loading from "../../../../shared/internals/components/loading/Loading";
import { Link, useHistory, useParams } from "react-router-dom";
import { classNames, extractCurrentTab, selectTab } from "../../../../shared/internals/utils";
import { tabs } from "../data";
import { toast } from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ADDRESS_MATCH_QUALITY,
  DIGITAL_PROFILE_STATUS,
  POST_APPROVAL_TYPE,
  SUSPECTED_OWNER_STATUS,
  TRACKING_GROUP_TYPE,
} from "../../../../shared/internals/constants/models";
import { useCampaignById } from "../graphql/hooks/useCampaignById";
import dayjs from "dayjs";
import { CampaignSummaryTab } from "../components/CampaignSummaryTab";
import { AddressesTab } from "../components/AddressesTab";
import { useLazyPostcodeUnitById } from "../graphql/hooks/usePostcodeUnitById";
import { useUpdateCampaignLeadAddress } from "../graphql/hooks/useUpdateCampaignLeadAddress";
import { useUpdateSuspectedOwnerAddress } from "../graphql/hooks/useUpdateSuspectedOwnerAddress";
import DigitalProfilesTab from "../components/DigitalProfilesTab";
import {
  searchLinkedinProfilesAPI,
  useSearchLinkedinProfileMutation,
} from "../api/searchLinkedInProfiles";
import {
  automaticSuspectedOwnerConfirmationAPI,
  useAutomaticSuspectedOwnerMutation,
} from "../api/automaticSuspectedOwnerConfirmation";
import { updateDigitalProfileAPI } from "../api/updateDigitalProfile";
import { FinalLeadsTab } from "../components/FinalLeadsTab";
import { useDeleteCampaignLead } from "../graphql/hooks/useDeleteCampaignLead";
import { useCampaignLeadProfiles } from "../graphql/hooks/useCampaignLeadProfilesByCampaignId";
import { GetEPCForAddressesAPI } from "../api/getEPCForAddresses";
import SyncCampaignTab from "../components/SyncCampaignTab";
import { syncWithOverleadAPI } from "../api/syncWithOverlead";
import { GetCompaniesHouseOwnersAPI } from "../api/getCompaniesHouseOwners";
import { useBulkDeleteCampaignLeads } from "../graphql/hooks/useBulkDeleteCampaignLeads";
import SuspectedOwnersCleaningTab from "../components/SuspectedOwnersCleanTab";
import SuspectedOwnersEvaluationTab from "../components/SuspectedOwnersEvaluationTab";
import { useBulkUpdateSuspectedOwnerAddressStatus } from "../graphql/hooks/useBulkUpdateSuspectedOwnerAddressStatus";
import { useBulkUpdateDigitalProfileStatus } from "../graphql/hooks/useBulkUpdateDigitalProfileStatus";
import { useBulkAddToCampaignLeadProfile } from "../graphql/hooks/useBulkAddCampaignLeadProfiles";
import { useBulkDeleteCampaignLeadProfiles } from "../graphql/hooks/useBulkDeleteCampaignLeadProfiles";
import { changeMatchQualityAPI, useChangeMatchQualityMutation } from "../api/changeMatchQuality";
import { GetPlanningApplicationOwnersAPI } from "../api/getPlanningApplicationOwners";
import { useUpdateSuspectedOwnerNames } from "../graphql/hooks/useUpdateSuspectedOwnerNames";
import { Badge, Bold, Card, Flex, Icon, List, ListItem, Text } from "@tremor/react";
import {
  ArchiveBoxXMarkIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ViewfinderCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useDeleteCampaignMutation } from "../api/deleteCampaign";
import { useResumeLinkedinSearchMutation } from "../api/resumeLinkedinSearch";
import { useElectoralRollOwnersMutation } from "../api/getElectoralRollOwners";
import { useSearchGroupByCampaignId } from "../graphql/hooks/useSearchGroupsByCampaignId";
import { useCancelLinkedinSearchMutation } from "../api/cancelLinkedinSearch";
import { UseMessageSets } from "../graphql/hooks/useGetMessageSets";
import { useGetLastTransactionMutation } from "../api/getLastTransaction";
import { useUpdateCampaignMessageSet } from "../graphql/hooks/useUpdateCampaignMessageSet";
import { useMatchAddresses } from "../api/matchAddresses";
import { useAutomaticDigitalProfilesMutation } from "../api/automaticDigitalProfileConfirmation";
import { useDeleteCampaignMessageSet } from "../graphql/hooks/useDeleteCampaignMessageSet";
import { useDisplayTasksMutation } from "../api/displayTasks";
import { useValidateOwnerAddress } from "../api/validateOwnerAddress";
import { useLazyAddressesByPostcodeUnitIds } from "../graphql/hooks/useAddressesByPostcodeUnitIds";
import { useLazyPostcodeUnitByCode } from "../graphql/hooks/usePostcodeUnitByCode";
import { useAutoValidateOwnerAddresses } from "../api/autoValidateOwnerAddresses";
import { useFixOwnerNamesMutation } from "../api/fixOwnerNames";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";
import { useExtractZooplaUrlsMutation } from "../api/extractZooplaUrls";
import { useTriggerSearchGroup } from "../api/triggerSearchGroup";
import { useTrackingGroupsByCampaignId } from "../graphql/hooks/useTrackingGroupsByCampaignId";
import { useTriggerTrackingGroup } from "../api/triggerTrackingGroup";
import { usePauseTrackingGroupMutation } from "../api/pauseTrackingGroup";
import { useCancelTrackingGroup } from "../api/cancelTrackingGroup";
import { useAddOrRemoveCampaignLeadProfilesMutation } from "../api/addOrRemoveCampaignLeadProfiles";
import { getSingleSyncStatus } from "../../doorKockingCampaign/utils";
import { useCampaignTypes } from "../../doorKockingCampaign/graphql/hooks/useCampaignTypes";
import { useCreateMessageSetMutation } from "../../doorKockingCampaign/api/createMessageSet/createMessageSet";

const SingleCampaign = () => {
  const [user, isLoading] = useAuthState(auth);
  let { campaignId } = useParams<{ campaignId: string }>();

  const { isFreelancer } = useUserStatus({ user });

  const {
    campaign,
    estateAgentOptions,
    fixedCampaignLeads,
    refetch: refetchCampaign,
  } = useCampaignById({ id: Number(campaignId) });

  let history = useHistory();
  const { mutateAsync: searchLinkedinProfiles, isLoading: searchLinkedinProfilesLoading } =
    useSearchLinkedinProfileMutation({ refetchId: Number(campaignId) });

  const { mutateAsync: cancelLinkedinSearch, isLoading: cancelLinkedinSearchLoading } =
    useCancelLinkedinSearchMutation({ refetchId: Number(campaignId) });

  const { searchGroups, lastIncompleteSearchGroup, incompleteSearchGroups } =
    useSearchGroupByCampaignId({ campaignId: Number(campaignId) });

  const { mutateAsync: resumeLinkedinSearch, isLoading: resumeLinkedinSearchLoading } =
    useResumeLinkedinSearchMutation({ refetchId: Number(campaignId) });

  const { mutateAsync: getLastTransaction, isLoading: getLastTransactionLoading } =
    useGetLastTransactionMutation({ refetchId: Number(campaignId) });

  const { mutateAsync: matchOwnersAutomatically, isLoading: matchOwnersAutomaticallyLoading } =
    useAutomaticSuspectedOwnerMutation({ refetchId: Number(campaignId) });

  const { campaignLeadProfiles, refetch: refetchCampaingLeadProfiles } = useCampaignLeadProfiles({
    campaignId: Number(campaignId),
  });

  const {
    cleanProfiles: {
      cleanProfilesGroups,
      lastIncompleteCleanProfilesGroup,
      incompleteCleanProfilesGroups,
    },
    cleanAddresses: {
      cleanAddressesGroups,
      lastIncompleteCleanAddressesGroup,
      incompleteCleanAddressesGroups,
    },
    matchProfiles: {
      matchProfilesGroups,
      lastIncompleteMatchProfilesGroup,
      incompleteMatchProfilesGroups,
    },
    getOwners: { getOwnersGroups, lastIncompleteGetOwnersGroup, incompleteGetOwnersGroups },
    matchOwners: { matchOwnersGroups, lastIncompleteMatchOwnersGroup, incompleteMatchOwnersGroups },
    cleanOwners: { cleanOwnersGroups, lastIncompleteCleanOwnersGroup, incompleteCleanOwnersGroups },
    getEmailProfiles: {
      getEmailProfilesGroups,
      lastIncompleteGetEmailProfilesGroup,
      incompleteGetEmailProfilesGroups,
    },
  } = useTrackingGroupsByCampaignId({
    campaignId: Number(campaignId),
  });

  const { updateCampaignMessageSet } = useUpdateCampaignMessageSet({
    refetchCampaignId: Number(campaignId),
  });

  const { postcodeUnitLoading, postcodeUnit, getPostcodeUnitById } = useLazyPostcodeUnitById();
  const { updateCampaignLeadAddress } = useUpdateCampaignLeadAddress();
  const { updateSuspectedOwnerAddress } = useUpdateSuspectedOwnerAddress();
  const { bulkDeleteCampaignLeads } = useBulkDeleteCampaignLeads();
  const { deleteCampaignLead } = useDeleteCampaignLead();
  const { bulkUpdateSuspectedOwnerAddressStatus } = useBulkUpdateSuspectedOwnerAddressStatus();
  const { bulkUpdateDigitalProfileStatus } = useBulkUpdateDigitalProfileStatus();
  const { bulkAddToCampaignLeadProfile } = useBulkAddToCampaignLeadProfile({
    refetchCampaignId: Number(campaignId),
  });

  const { mutateAsync: extractZooplaUrls, isLoading: extractZooplaUrlsLoading } =
    useExtractZooplaUrlsMutation({
      refetchId: Number(campaignId),
    });

  const { mutateAsync: validateOwnerAddress, isLoading: validateOwnerAddressLoading } =
    useValidateOwnerAddress({
      refetchId: Number(campaignId),
    });

  const { mutateAsync: triggerSearchGroup, isLoading: triggerSearchGroupLoading } =
    useTriggerSearchGroup({
      refetchId: Number(campaignId),
    });

  const { deleteCampaignMessageSet } = useDeleteCampaignMessageSet({
    refetchCampaignId: Number(campaignId),
  });
  const { bulkDeleteCampaignLeadProfiles } = useBulkDeleteCampaignLeadProfiles({
    refetchCampaignId: Number(campaignId),
  });
  const { updateSuspectedOwnerNames } = useUpdateSuspectedOwnerNames();
  const { mutateAsync: matchAddresses } = useMatchAddresses({ refetchId: Number(campaignId) });

  const { mutateAsync: confirmDigitalProfiles } = useAutomaticDigitalProfilesMutation({
    refetchId: Number(campaignId),
  });

  // 2) ADD YOUR CREATED MUTATION HERE

  const { mutateAsync: addOrRemoveCampaignLeadProfiles } =
    useAddOrRemoveCampaignLeadProfilesMutation({
      refetchId: Number(campaignId),
    });

  const { messageSets } = UseMessageSets();
  const { mutateAsync: deleteCampaign, isLoading: deleteCampaignLoading } =
    useDeleteCampaignMutation();
  const { mutateAsync: getElectoralRollOwners, isLoading: electoralRollOwnersLoading } =
    useElectoralRollOwnersMutation({ refetchId: Number(campaignId) });

  const { mutateAsync: createMessageSet, isLoading: createMessageSetLoading } =
    useCreateMessageSetMutation({ refetchId: Number(campaignId) });

  const { getLazyAddressesByPostcodeUnitIds, aggregatedAddresses, addressesLoading } =
    useLazyAddressesByPostcodeUnitIds();

  const { getLazyPostcodeUnitsByCode, postcodeUnitsLoading, postcodeUnits } =
    useLazyPostcodeUnitByCode();

  const { mutateAsync: changeMatchQuality, isLoading: changeMatchQualityLoading } =
    useChangeMatchQualityMutation({
      refetchId: Number(campaignId),
    });

  const { mutateAsync: autoValidateOwnerAddresses } = useAutoValidateOwnerAddresses({
    refetchId: Number(campaignId),
  });

  const { mutateAsync: triggerTrackingGroup, isLoading: triggerTrackingGroupLoading } =
    useTriggerTrackingGroup({
      refetchId: Number(campaignId),
    });

  const {
    mutateAsync: displayTasksPlanning,
    isLoading: displayTasksPlanningLoading,
    data: displayTasksPlanningResponse,
  } = useDisplayTasksMutation({
    refetchId: Number(campaignId),
  });

  const {
    mutateAsync: displayTasksZoopla,
    isLoading: displayTasksZooplaLoading,
    data: displayTasksZooplaResponse,
  } = useDisplayTasksMutation({
    refetchId: Number(campaignId),
  });

  const {
    mutateAsync: displayTasksCompaniesHouse,
    isLoading: displayTasksCompaniesHouseLoading,
    data: displayTasksCompaniesHouseResponse,
  } = useDisplayTasksMutation({
    refetchId: Number(campaignId),
  });

  const {
    mutateAsync: displayTasksAddress,
    isLoading: displayTasksAddressLoading,
    data: displayTasksAddressResponse,
  } = useDisplayTasksMutation({
    refetchId: Number(campaignId),
  });

  const {
    mutateAsync: displayTasksPlanIt,
    isLoading: displayTasksPlanItLoading,
    data: displayTasksPlanItResponse,
  } = useDisplayTasksMutation({
    refetchId: Number(campaignId),
  });

  const { mutateAsync: pauseTrackingGroup, isLoading: pauseTrackingGroupLoading } =
    usePauseTrackingGroupMutation({
      refetchId: Number(campaignId),
    });

  const { mutateAsync: cancelTrackingGroup, isLoading: cancelTrackingGroupLoading } =
    useCancelTrackingGroup({
      refetchId: Number(campaignId),
    });

  const { mutateAsync: fixOwnerNames } = useFixOwnerNamesMutation({
    refetchId: Number(campaignId),
  });

  const { campaignTypes } = useCampaignTypes();

  const epcPuller = async (addressIds: number[]) => {
    setPageLoading(true);
    try {
      await GetEPCForAddressesAPI({ addressIds: addressIds });
      toast.success("Successfully pulled epc for Addresses");
    } catch (e) {
      toast.error("error in pulling epc for Addresses");
    }
    await refetchCampaign();

    setPageLoading(false);
  };

  const companiesHousePuller = async (campaignId: number, campaignLeadIds: number[]) => {
    setPageLoading(true);
    try {
      await GetCompaniesHouseOwnersAPI({
        campaignLeadIds: campaignLeadIds,
        campaignId: campaignId,
      });
      toast.success("Successfully triggered out companies house owner check");
    } catch (e) {
      toast.error("error in triggering companies house owners");
    }
    await refetchCampaign();

    setPageLoading(false);
  };

  const planningApplicationPuller = async (campaignId: number, campaignLeadIds: number[]) => {
    setPageLoading(true);
    try {
      await GetPlanningApplicationOwnersAPI({
        campaignLeadIds: campaignLeadIds,
        campaignId: campaignId,
      });
      toast.success("Successfully triggered planning application owner check");
    } catch (e) {
      toast.error("error in triggered planning application owners");
    }
    await refetchCampaign();

    setPageLoading(false);
  };

  const campaignLeadUpdater = async (campaignLeadId: number, addressId: number) => {
    toast.loading("Updating Address");

    try {
      await updateCampaignLeadAddress({ campaignLeadId: campaignLeadId, addressId: addressId });
      toast.success("Successfully updated Lead Address");
    } catch (e) {
      toast.error("error in updating Lead Address");
    }
    await refetchCampaign();
  };

  const bulkCampaignLeadDeleter = async (campaignLeadIds: number[], currentDate: string) => {
    toast.loading("Deleting Addresses");

    try {
      await bulkDeleteCampaignLeads({ ids: campaignLeadIds, currentDate });
      toast.success("Successfully deleted Leads");
    } catch (e) {
      toast.error("error in deleting Leads");
    }
    await refetchCampaign();
  };

  const bulkSuspectedOwnerStatusUpdater = async (
    suspectedOwnerAddressIds: number[],
    suspectedOwnerStatus: SUSPECTED_OWNER_STATUS
  ) => {
    toast.loading(`Updating owner statuses to ${SUSPECTED_OWNER_STATUS[suspectedOwnerStatus]} `);
    try {
      await bulkUpdateSuspectedOwnerAddressStatus({
        ids: suspectedOwnerAddressIds,
        suspectedOwnerStatusId: suspectedOwnerStatus,
      });
      toast.success(
        `Successfully updated owner statuses to ${SUSPECTED_OWNER_STATUS[suspectedOwnerStatus]} `
      );
    } catch (e) {
      toast.error("error in updating owner statuses");
    }
    await refetchCampaign();
  };

  const bulkDigitalProfileStatusUpdater = async (
    ownerAddressProfilesIds: number[],
    digitalProfileStatus: DIGITAL_PROFILE_STATUS
  ) => {
    toast.loading(`Updating profile statuses to ${DIGITAL_PROFILE_STATUS[digitalProfileStatus]} `);
    try {
      await bulkUpdateDigitalProfileStatus({
        ids: ownerAddressProfilesIds,
        digitalProfileStatusId: digitalProfileStatus,
      });
      toast.success(
        `Successfully updated profile statuses to ${DIGITAL_PROFILE_STATUS[digitalProfileStatus]} `
      );
    } catch (e) {
      toast.error("error in updating profile statuses");
    }
    await refetchCampaign();
  };

  const campaignLeadDeleter = async (campaignLeadId: number, currentDate: string) => {
    toast.loading(`Deleting Address `);

    try {
      await deleteCampaignLead({ campaignLeadId: campaignLeadId, currentDate });
      toast.success("Successfully deleted Lead Address");
    } catch (e) {
      toast.error("error in deleted Lead Address");
    }
    await refetchCampaign();
  };

  const suspectedOwnerAddressUpdater = async (suspectedOwnerId: number, addressId: number) => {
    toast.loading("updating owner address");

    try {
      await updateSuspectedOwnerAddress({
        suspectedOwnerId: suspectedOwnerId,
        addressId: addressId,
      });
      toast.success("Successfully updated Suspected Owner Address");
    } catch (e) {
      toast.error("error in updating Suspected Owner");
    }
    await refetchCampaign();
  };

  const campaignSyncer = async (campaignId: number, externalId: number) => {
    toast.loading("Syncing Campaign");
    setPageLoading(true);
    try {
      await syncWithOverleadAPI({ campaignId: campaignId, externalCampaignId: externalId });
      toast.success("Successfully Updated Campaign External Id");
    } catch (e) {
      toast.error("error in updating Campaign External Id");
    }
    await refetchCampaign();
    await refetchCampaingLeadProfiles();

    setPageLoading(false);
  };

  const suspectedOwnerNamesUpdater = async (
    suspectedOwnerId: number,
    firstName: string,
    lastName: string
  ) => {
    toast.loading("Updating Owner Name");

    try {
      await updateSuspectedOwnerNames({
        suspectedOwnerId: suspectedOwnerId,
        firstName: firstName,
        lastName: lastName,
      });
      toast.success("Successfully updated Suspected Owner Names");
    } catch (e) {
      toast.error("error in updating Suspected Owner");
    }
    await refetchCampaign();
  };

  const [pageLoading, setPageLoading] = useState(false);

  const [tabsState, setTabsState] = useState(tabs);

  const singleSyncStatus = getSingleSyncStatus({
    campaign: campaign,
    leadProfiles: campaignLeadProfiles,
  });

  if (
    isLoading ||
    pageLoading ||
    searchLinkedinProfilesLoading ||
    cancelLinkedinSearchLoading ||
    deleteCampaignLoading ||
    resumeLinkedinSearchLoading ||
    getLastTransactionLoading ||
    matchOwnersAutomaticallyLoading
  ) {
    return <Loading />;
  }

  return (
    <>
      <main className="flex-1 overflow-y-auto focus:outline-none">
        <div className="relative max-w-7xl mx-auto md:px-8 ">
          <div className="pt-10 pb-8">
            <div className="px-4 sm:px-6 md:px-0">
              <div className="flex flex-row justify-between">
                <div>
                  <h1 className="text-3xl font-extrabold text-gray-900">
                    Campaign - ID {campaign?.id}
                  </h1>
                  <p className="mt-3 text-prose text-sm text-gray-500">
                    This campaign was created on{" "}
                    {campaign?.created_at ? dayjs(campaign.created_at).format("DD/MM/YY") : null}
                    and belongs to {campaign?.workspace.name}
                  </p>
                </div>
                <div className="">
                  {campaign?.id && (
                    <div className="mt-4  flex md:mt-0 md:ml-4">
                      <div>
                        {/* <Link
                          to={`./${campaign.id}/add-addresses`}
                          type="button"
                          className="ml-3 inline-flex items-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Add Addressess
                        </Link> */}
                        <Dropdown
                          items={[
                            {
                              icon: ChartBarIcon,
                              name: "Add On-M Addressess",
                              function: async () => {
                                history.push(`/leads-admin/campaigns/${campaign.id}/add-on-market`);
                              },
                            },
                            {
                              icon: ViewfinderCircleIcon,
                              name: "Add Off-M Addressess",
                              function: async () => {
                                history.push(
                                  `/leads-admin/campaigns/${campaign.id}/add-off-market`
                                );
                              },
                            },
                            {
                              icon: ArchiveBoxXMarkIcon,
                              name: "Delete Campaign",
                              function: async () => {
                                toast.error("No Action Hooked to this button yet");
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Card className="mt-5" decoration="top" decorationColor="orange">
                <div className="flex mb-5 flex-row justify-between">
                  <Badge size="lg">Public Status: {campaign?.campaign_status.name}</Badge>
                  <Badge size="lg">
                    Internal Status: {campaign?.campaign_internal_status.name}
                  </Badge>
                </div>

                <List className="flex flex-col sm:flex-row">
                  {[
                    singleSyncStatus?.outreachAgentSyncStatus,
                    singleSyncStatus?.leadProfilesSyncStatus,
                  ].map(syncStatus => {
                    if (!syncStatus) return <></>;
                    return (
                      <ListItem
                        key={syncStatus.syncStatement}
                        className="sm:border-t sm:border-gray-200"
                      >
                        <Flex justifyContent="start" className="truncate  space-x-4">
                          <Icon
                            variant="light"
                            icon={syncStatus.isFullySynced ? CheckCircleIcon : XCircleIcon}
                            size="md"
                            color={syncStatus.isFullySynced ? "green" : "red"}
                          />
                          <div className="truncate">
                            <Text className="truncate">
                              <Bold>{syncStatus.title}</Bold>
                            </Text>
                            <Text className="truncate">{syncStatus.syncStatement}</Text>
                          </div>
                        </Flex>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </div>
            <div className="px-4 sm:px-6 md:px-0">
              <div className="py-6">
                {/* Tabs */}
                <div className="lg:hidden">
                  <label htmlFor="selected-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    onChange={event =>
                      selectTab({ tabIndex: Number(event.target.value), tabsState, setTabsState })
                    }
                    id="selected-tab"
                    name="selected-tab"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm rounded-md"
                    defaultValue={tabsState.find(tab => tab.current)?.name}
                  >
                    {tabsState.map((tab, index) => (
                      <option value={index} key={tab.name}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden lg:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8">
                      {tabsState.map((tab, index) => (
                        <button
                          key={tab.name}
                          onClick={() => selectTab({ tabIndex: index, tabsState, setTabsState })}
                          className={classNames(
                            tab.current
                              ? "border-orange-500 text-orange-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                          )}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>

                {extractCurrentTab(tabsState).name === "Summary" && !isFreelancer && (
                  <CampaignSummaryTab
                    extractZooplaUrls={extractZooplaUrls}
                    extractZooplaUrlsLoading={extractZooplaUrlsLoading}
                    campaign={campaign}
                    campaignLeads={fixedCampaignLeads}
                    isFreelancer={isFreelancer}
                  />
                )}

                {extractCurrentTab(tabsState).name === "Addresses" && !isFreelancer && (
                  <AddressesTab
                    getLazyAddressesByPostcodeUnitIds={getLazyAddressesByPostcodeUnitIds}
                    displayTasksZoopla={displayTasksZoopla}
                    displayTasksZooplaLoading={displayTasksZooplaLoading}
                    displayTasksZooplaResponse={displayTasksZooplaResponse}
                    aggregatedAddresses={aggregatedAddresses}
                    estateAgentOptions={estateAgentOptions}
                    getLazyPostcodeUnitsByCode={getLazyPostcodeUnitsByCode}
                    postcodeUnits={postcodeUnits}
                    postcodeUnitsLoading={postcodeUnitsLoading}
                    addressesLoading={addressesLoading}
                    campaign={campaign}
                    campaignLeads={fixedCampaignLeads}
                    getPostcodeUnitById={getPostcodeUnitById}
                    getLastTransaction={getLastTransaction}
                    matchAddresses={matchAddresses}
                    postcodeUnitLoading={postcodeUnitLoading}
                    postcodeUnit={postcodeUnit}
                    campaignLeadUpdater={campaignLeadUpdater}
                    campaignLeadDeleter={campaignLeadDeleter}
                    bulkCampaignLeadDeleter={bulkCampaignLeadDeleter}
                    epcPuller={epcPuller}
                    companiesHousePuller={companiesHousePuller}
                    changeMatchQuality={changeMatchQuality}
                    changeMatchQualityLoading={changeMatchQualityLoading}
                    planningApplicationPuller={planningApplicationPuller}
                    electoralRollPuller={getElectoralRollOwners}
                    displayTasksPlanning={displayTasksPlanning}
                    displayTasksAddress={displayTasksAddress}
                    displayTasksPlanningLoading={displayTasksPlanningLoading}
                    displayTasksAddressLoading={displayTasksAddressLoading}
                    displayTasksAddressResponse={displayTasksAddressResponse}
                    displayTasksPlanningResponse={displayTasksPlanningResponse}
                    displayTasksCompaniesHouseLoading={displayTasksCompaniesHouseLoading}
                    displayTasksCompaniesHouseResponse={displayTasksCompaniesHouseResponse}
                    displayTasksCompaniesHouse={displayTasksCompaniesHouse}
                    displayTasksPlanIt={displayTasksPlanIt}
                    displayTasksPlanItLoading={displayTasksPlanItLoading}
                    displayTasksPlanItResponse={displayTasksPlanItResponse}
                    incompleteCleanAddressesGroups={incompleteCleanAddressesGroups}
                    lastIncompleteCleanAddressesGroup={lastIncompleteCleanAddressesGroup}
                    cleanAddressesGroups={cleanAddressesGroups}
                    incompleteGetOwnersGroups={incompleteGetOwnersGroups}
                    lastIncompleteGetOwnersGroup={lastIncompleteGetOwnersGroup}
                    getOwnersGroups={getOwnersGroups}
                    triggerTrackingGroup={triggerTrackingGroup}
                    triggerTrackingGroupLoading={triggerTrackingGroupLoading}
                    cancelTrackingGroup={cancelTrackingGroup}
                    cancelTrackingGroupLoading={cancelTrackingGroupLoading}
                    pauseTrackingGroup={pauseTrackingGroup}
                    pauseTrackingGroupLoading={pauseTrackingGroupLoading}
                  />
                )}

                {extractCurrentTab(tabsState).name === "Suspected Owners - Cleaning" &&
                  !isFreelancer && (
                    <SuspectedOwnersCleaningTab
                      campaign={campaign}
                      validateOwnerAddressLoading={validateOwnerAddressLoading}
                      campaignLeads={fixedCampaignLeads}
                      getPostcodeUnitById={getPostcodeUnitById}
                      postcodeUnit={postcodeUnit}
                      suspectedOwnerAddressUpdater={suspectedOwnerAddressUpdater}
                      suspectedOwnerNamesUpdater={suspectedOwnerNamesUpdater}
                      validateOwnerAddress={validateOwnerAddress}
                      autoValidateOwnerAddresses={autoValidateOwnerAddresses}
                      incompleteMatchOwnersGroups={incompleteMatchOwnersGroups}
                      lastIncompleteMatchOwnersGroup={lastIncompleteMatchOwnersGroup}
                      matchOwnersGroups={matchOwnersGroups}
                      triggerTrackingGroup={triggerTrackingGroup}
                      triggerTrackingGroupLoading={triggerTrackingGroupLoading}
                      cancelTrackingGroup={cancelTrackingGroup}
                      cancelTrackingGroupLoading={cancelTrackingGroupLoading}
                      pauseTrackingGroup={pauseTrackingGroup}
                      pauseTrackingGroupLoading={pauseTrackingGroupLoading}
                    />
                  )}

                {extractCurrentTab(tabsState).name === "Suspected Owners - Evaluation" &&
                  !isFreelancer && (
                    <SuspectedOwnersEvaluationTab
                      campaign={campaign}
                      incompleteSearchGroups={incompleteSearchGroups}
                      lastIncompleteSearchGroup={lastIncompleteSearchGroup}
                      campaignLeads={fixedCampaignLeads}
                      bulkSuspectedOwnerStatusUpdater={bulkSuspectedOwnerStatusUpdater}
                      searchLinkedinProfiles={searchLinkedinProfiles}
                      cancelLinkedinSearch={cancelLinkedinSearch}
                      fixOwnerNames={fixOwnerNames}
                      resumeLinkedinSearch={resumeLinkedinSearch}
                      searchGroups={searchGroups}
                      matchOwnersAutomatically={matchOwnersAutomatically}
                      triggerSearchGroup={triggerSearchGroup}
                      triggerSearchGroupLoading={triggerSearchGroupLoading}
                      incompleteCleanOwnersGroups={incompleteCleanOwnersGroups}
                      lastIncompleteCleanOwnersGroup={lastIncompleteCleanOwnersGroup}
                      cleanOwnersGroups={cleanOwnersGroups}
                      triggerTrackingGroup={triggerTrackingGroup}
                      triggerTrackingGroupLoading={triggerTrackingGroupLoading}
                      cancelTrackingGroup={cancelTrackingGroup}
                      cancelTrackingGroupLoading={cancelTrackingGroupLoading}
                      pauseTrackingGroup={pauseTrackingGroup}
                      pauseTrackingGroupLoading={pauseTrackingGroupLoading}
                    />
                  )}

                {extractCurrentTab(tabsState).name === "Sync" && !isFreelancer && (
                  <SyncCampaignTab
                    campaignLeadProfiles={campaignLeadProfiles}
                    campaign={campaign}
                    campaignSyncer={campaignSyncer}
                  />
                )}

                {extractCurrentTab(tabsState).name === "Digital Profiles" && (
                  <DigitalProfilesTab
                    campaign={campaign}
                    isFreelancer={isFreelancer}
                    campaignLeads={fixedCampaignLeads}
                    bulkDigitalProfileStatusUpdater={bulkDigitalProfileStatusUpdater}
                    bulkAddToCampaignLeadProfile={bulkAddToCampaignLeadProfile}
                    bulkDeleteCampaignLeadProfiles={bulkDeleteCampaignLeadProfiles}
                    confirmDigitalProfiles={confirmDigitalProfiles}
                    incompleteCleanProfilesGroups={incompleteCleanProfilesGroups}
                    lastIncompleteCleanProfilesGroup={lastIncompleteCleanProfilesGroup}
                    cleanProfilesGroups={cleanProfilesGroups}
                    triggerTrackingGroup={triggerTrackingGroup}
                    triggerTrackingGroupLoading={triggerTrackingGroupLoading}
                    cancelTrackingGroup={cancelTrackingGroup}
                    cancelTrackingGroupLoading={cancelTrackingGroupLoading}
                    pauseTrackingGroup={pauseTrackingGroup}
                    pauseTrackingGroupLoading={pauseTrackingGroupLoading}
                    incompleteMatchProfilesGroups={incompleteMatchProfilesGroups}
                    lastIncompleteMatchProfilesGroup={lastIncompleteMatchProfilesGroup}
                    matchProfilesGroups={matchProfilesGroups}
                    incompleteGetEmailProfilesGroups={incompleteGetEmailProfilesGroups}
                    lastIncompleteGetEmailProfilesGroup={lastIncompleteGetEmailProfilesGroup}
                    getEmailProfilesGroups={getEmailProfilesGroups}
                    // 3) PASS MUTATEASYNC HERE
                    addOrRemoveCampaignLeadProfiles={addOrRemoveCampaignLeadProfiles}
                  />
                )}

                {extractCurrentTab(tabsState).name === "Leads" && user && !isFreelancer && (
                  <FinalLeadsTab
                    campaign={campaign}
                    isFreelancer={isFreelancer}
                    messageSets={messageSets}
                    campaignLeadProfiles={campaignLeadProfiles}
                    createMessageSet={createMessageSet}
                    createMessageSetLoading={createMessageSetLoading}
                    campaignLeads={fixedCampaignLeads}
                    updateCampaignMessageSet={updateCampaignMessageSet}
                    deleteCampaignMessageSet={deleteCampaignMessageSet}
                    campaignTypes={campaignTypes}
                    user={user}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SingleCampaign;
