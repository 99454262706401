import { ApolloError, useQuery } from "@apollo/client";
import { GetSearchGroupByCampaignIdQuery } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { getSearchGroupByCampaignId } from "../queries/getSearchGroupsByCampaignId";
import { ArrayElement } from "../../../../../shared/internals/utils";

interface HookResult {
  searchGroups: GetSearchGroupByCampaignIdQuery["search_group"];
  incompleteSearchGroups: GetSearchGroupByCampaignIdQuery["search_group"];
  lastIncompleteSearchGroup?: ArrayElement<GetSearchGroupByCampaignIdQuery["search_group"]>;
  searchGroupsError?: ApolloError;
  searchGroupsLoading: boolean;
  refetch: () => Promise<void>;
}

export const useSearchGroupByCampaignId = ({ campaignId }: { campaignId: number }): HookResult => {
  const { data, error, loading, refetch } = useQuery<GetSearchGroupByCampaignIdQuery>(
    getSearchGroupByCampaignId,
    {
      variables: { campaignId: campaignId },
      // pollInterval: 2 * 60 * 1000, //2mins
    }
  );

  const incompleteSearchGroups = data?.search_group
    ? data?.search_group.filter(group => group.is_completed === false)
    : [];

  const refetchSearchGroups = async () => {
    await refetch();
  };
  return {
    searchGroups: data && Array.isArray(data.search_group) ? data.search_group : [],
    incompleteSearchGroups: incompleteSearchGroups,
    lastIncompleteSearchGroup: incompleteSearchGroups[incompleteSearchGroups.length - 1],
    searchGroupsError: error,
    searchGroupsLoading: loading,
    refetch: refetchSearchGroups,
  };
};
