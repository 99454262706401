import { OWNER_CLEANING_SHOW_TABS, OWNER_EVALUATION_SHOW_TABS } from "../../singleCampaign/types";

export const tabs = [
  { name: "Summary", current: true },
  { name: "Addresses", current: false },
  { name: "Owners - Matching", current: false },
  { name: "Owners - Selection", current: false },
  { name: "Digital Profiles", current: false },
];

export const suspectedOwnerCleanRadioOptions = [
  { id: OWNER_CLEANING_SHOW_TABS.ALL, name: "All" },
  { id: OWNER_CLEANING_SHOW_TABS.VALIDATED_LEAD_ADDRESS, name: "Correctly Matched Owners" },
  { id: OWNER_CLEANING_SHOW_TABS.REJECTED_LEAD_ADDRESS, name: "Incorrectly Matched Owners" },
  { id: OWNER_CLEANING_SHOW_TABS.UNVALIDATED, name: "Unknown Match Owners" },
];

export const suspectedOwnerEvaluationRadioOptions = [
  { id: OWNER_EVALUATION_SHOW_TABS.ALL, name: "All" },
  { id: OWNER_EVALUATION_SHOW_TABS.NEW, name: "Unknown" },
  { id: OWNER_EVALUATION_SHOW_TABS.REJECTED, name: "Rejected" },
  { id: OWNER_EVALUATION_SHOW_TABS.CONTENDER_AND_VERIFIED, name: "Contender" },
];

export const digitalProfilesRadioOptions = [
  { id: 1, name: "All" },
  { id: 2, name: "Owners with 0 Profiles" },
  { id: 3, name: "Owners with 1 Profile" },
  { id: 4, name: "Owners with 2 Profiles" },
  { id: 5, name: "Owners with 3 Profiles" },

  { id: 6, name: "Owners with 4 Profiles" },
  { id: 7, name: "Owners with 5 Profiles" },
  { id: 8, name: "Owners with 6 Profiles" },
  { id: 9, name: "Owners with 7 Profiles" },
  { id: 10, name: "Owners with 8 Profiles" },
  { id: 11, name: "Owners with 9 Profiles" },
  { id: 12, name: "Owners with 10+ Profiles" },

  { id: 13, name: "Unknown" },
  { id: 14, name: "Rejected" },
  { id: 15, name: "Contender" },
];
