import { Link } from "react-router-dom";
import { UserStatus } from "../../../../infrastructure/firebase/types";
import { NavigationItem } from "../types";
import { classNames } from "../../../utils";
import { Disclosure } from "@headlessui/react";
import { hasRequiredSubscription } from "../utils";
import { LockClosedIcon } from "@heroicons/react/24/solid";

export interface MobileLinksProps {
  navigationItem: NavigationItem;
  userStatus: UserStatus;
}

const MobileLinks = ({ navigationItem, userStatus }: MobileLinksProps) => {
  // Is admin link and user is not an admin
  const isBlocked =
    navigationItem.requiredSubscription &&
    !hasRequiredSubscription(navigationItem.requiredSubscription, userStatus);

  if (navigationItem.isAdmin && !userStatus.isAdmin) {
    return null;
  }
  // A non Admin Link for Basic Link
  if (navigationItem.path) {
    return (
      <Link
        to={isBlocked ? "#" : navigationItem.path}
        className={classNames(
          navigationItem.current
            ? "bg-gray-100 text-gray-900"
            : isBlocked
            ? "text-gray-600 cursor-not-allowed"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
        )}
      >
        <navigationItem.icon
          className={classNames(
            navigationItem.current
              ? "text-gray-500"
              : isBlocked
              ? "text-gray-400 cursor-not-allowed"
              : "text-gray-400 group-hover:text-gray-500",
            "mr-4 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        />
        {navigationItem.name}
        {isBlocked && (
          <LockClosedIcon
            className={classNames("ml-auto h-5 w-5 shrink-0 text-gray-400")}
            aria-hidden="true"
          />
        )}
      </Link>
    );
  }

  // a non Admin Link For Children Link
  if (navigationItem.children) {
    return (
      <Disclosure key={navigationItem.name} as="div">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                isBlocked ? "cursor-not-allowed" : "cursor-default",
                "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700"
              )}
            >
              <navigationItem.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
              {navigationItem.name}

              {isBlocked && (
                <LockClosedIcon
                  className={classNames("ml-auto h-5 w-5 shrink-0 text-gray-400")}
                  aria-hidden="true"
                />
              )}
            </Disclosure.Button>
            <Disclosure.Panel as="ul" static className="mt-1 px-2">
              {navigationItem?.children?.map(subnavigationItem => (
                <li key={subnavigationItem.name}>
                  {/* 44px */}
                  <div className="flex flex-row items-center w-full">
                    <Disclosure.Button
                      as={Link}
                      disabled={isBlocked}
                      onClick={isBlocked ? event => event.preventDefault() : undefined}
                      to={isBlocked ? "#" : subnavigationItem.path}
                      className={classNames(
                        subnavigationItem.current
                          ? "bg-gray-50"
                          : isBlocked
                          ? "cursor-not-allowed"
                          : "hover:bg-gray-50",
                        "block rounded-md  w-full py-2 pr-2 pl-9 text-sm leading-6 text-gray-700"
                      )}
                    >
                      {subnavigationItem.name}
                    </Disclosure.Button>
                    {isBlocked && (
                      <LockClosedIcon
                        className={classNames("ml-auto h-5 w-5 shrink-0 text-gray-400")}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </li>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  return null;
};

export default MobileLinks;
