import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useState } from "react";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";

export interface GetLastTransactionBody {
  addressIds: number[];
}

export const GetLastTransactionAPI = async ({ addressIds }: GetLastTransactionBody) => {
  const payload = {
    addressIds,
  };

  const response = await meetmoreAPI.post(`/addresses/last-transaction`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const useGetLastTransactionMutation = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchCampaign } = useCampaignById({
    id: refetchId ?? -1,
  });

  return useMutation(GetLastTransactionAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("fetching last transactions...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Last Transactions fetched successfully!");
      await refetchCampaign();
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error.response.data.errors[0].message ?? error.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
