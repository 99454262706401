import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../../shared/internals/components/loading/Loading";
import { Badge, Card } from "@tremor/react";
import { tabs } from "../data";
import { classNames, extractCurrentTab, selectTab } from "../../../../shared/internals/utils";
import { useUSCampaignById } from "../graphql/hooks/useUSCampaignById";
import dayjs from "dayjs";
import { CampaignSummaryTab } from "../components/CampaignSummaryTab";
import { AddressesTab } from "../components/AddressesTab";
import { useAddAddressesToUSCampaignMutation } from "../api/addAddressesToUsCampaign";
import SuspectedOwnersCleanTab from "../components/SuspectedOwnersCleanTab";
import { useUpdateOwnerMatchDecisionsMutation } from "../api/updateUSOwnerMatchDecision";
import { useUpdateOwnerContenderDecisionsMutation } from "../api/updateOwnerContenderDecision";
import SuspectedOwnersEvaluationTab from "../components/SuspectedOwnersEvaluationTab";
import DigitalProfilesTab from "../components/DigitalProfilesTab";
import { useUpdateProfileContenderDecisionsMutation } from "../api/updateUSProfileContenderDecision";

import { useTrackingGroupsByUSCampaignId } from "../graphql/hooks/useTrackingGroupsByUSCampaignId";
import { useTriggerTrackingGroup } from "../../singleCampaign/api/triggerTrackingGroup";
import { useCancelTrackingGroup } from "../../singleCampaign/api/cancelTrackingGroup";
import { usePauseTrackingGroupMutation } from "../../singleCampaign/api/pauseTrackingGroup";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import { useProcessUSCampaignMutation } from "../api/processUSCampaign";
import toast from "react-hot-toast";

const SingleUSCampaign = () => {
  let { usCampaignId } = useParams<{ usCampaignId: string }>();

  const {
    getUSOwners: { getUSOwnersGroups, lastIncompleteGetUSOwnersGroup, incompleteGetUSOwnersGroups },
    getUSProfiles: {
      getUSProfilesGroups,
      lastIncompleteGetUSProfilesGroup,
      incompleteGetUSProfilesGroups,
    },
  } = useTrackingGroupsByUSCampaignId({
    usCampaignId: Number(usCampaignId),
  });

  const {
    mutateAsync: processUSCampaignMutation,
    isLoading: processUSCampaignLoading,
    isSuccess: processUSCampaignSuccess,
  } = useProcessUSCampaignMutation();

  const { mutateAsync: triggerTrackingGroup, isLoading: triggerTrackingGroupLoading } =
    useTriggerTrackingGroup({
      refetchId: Number(usCampaignId),
    });

  const { mutateAsync: pauseTrackingGroup, isLoading: pauseTrackingGroupLoading } =
    usePauseTrackingGroupMutation({
      refetchId: Number(usCampaignId),
    });

  const { mutateAsync: cancelTrackingGroup, isLoading: cancelTrackingGroupLoading } =
    useCancelTrackingGroup({
      refetchId: Number(usCampaignId),
    });

  const {
    mutateAsync: addAddressesToUsCampaignMutation,
    isSuccess: addAddressesToUSCampaignSuccess,
    isLoading: addAddressesToUsCampaignLoading,
  } = useAddAddressesToUSCampaignMutation();

  const {
    mutateAsync: updateOwnerMatchDecisionsMutation,
    isSuccess: updateOwnerMatchDecisionsSuccess,
    isLoading: updateOwnerMatchDecisionsLoading,
  } = useUpdateOwnerMatchDecisionsMutation();

  const {
    mutateAsync: updateOwnerContenderDecisionsMutation,
    isSuccess: updateOwnerContenderDecisionsSuccess,
    isLoading: updateOwnerContenderDecisionsLoading,
  } = useUpdateOwnerContenderDecisionsMutation();

  const {
    mutateAsync: updateProfileContenderDecisionsMutation,
    isSuccess: updateProfileContenderDecisionsSuccess,
    isLoading: updateProfileContenderDecisionsLoading,
  } = useUpdateProfileContenderDecisionsMutation();

  const [tabsState, setTabsState] = useState(tabs);

  const {
    campaign: usCampaign,
    campaignLoading,
    fixedAddresses,
    refetch,
  } = useUSCampaignById({
    id: Number(usCampaignId),
  });

  useEffect(() => {
    if (
      addAddressesToUSCampaignSuccess ||
      updateOwnerMatchDecisionsSuccess ||
      updateOwnerContenderDecisionsSuccess ||
      updateProfileContenderDecisionsSuccess ||
      processUSCampaignSuccess
    ) {
      refetch();
    }
  }, [
    addAddressesToUSCampaignSuccess,
    updateOwnerMatchDecisionsSuccess,
    updateOwnerContenderDecisionsSuccess,
    updateProfileContenderDecisionsSuccess,
    processUSCampaignSuccess,
  ]);

  if (addAddressesToUsCampaignLoading) {
    return <Loading />;
  }

  return (
    <>
      <main className="flex-1 overflow-y-auto focus:outline-none">
        <div className="relative max-w-7xl mx-auto md:px-8 ">
          <div className="pt-10 pb-8">
            <div className="px-4 sm:px-6 md:px-0">
              <div className="flex flex-row justify-between">
                <div>
                  <h1 className="text-3xl font-extrabold text-gray-900">
                    Campaign - ID {usCampaign?.id}
                  </h1>
                  <p className="mt-3 text-prose text-sm text-gray-500">
                    This campaign was created on{" "}
                    {usCampaign?.created_at
                      ? dayjs(usCampaign.created_at).format("DD/MM/YY")
                      : null}
                  </p>
                </div>
                <div className="">
                  {usCampaign?.id && (
                    <div className="mt-4  flex md:mt-0 md:ml-4">
                      <div>
                        <Dropdown
                          items={[
                            {
                              icon: ChartBarIcon,
                              name: "Automatically Process Campaign",
                              function: async () => {
                                if (!usCampaign?.id) {
                                  toast.error(
                                    "Campaign ID not found, probably clicked before the campaign was loaded"
                                  );
                                  return;
                                }
                                await processUSCampaignMutation({ usCampaignId: usCampaign.id });
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="px-4 sm:px-6 md:px-0">
              <div className="py-6">
                {/* Tabs */}
                <div className="lg:hidden">
                  <label htmlFor="selected-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    onChange={event =>
                      selectTab({ tabIndex: Number(event.target.value), tabsState, setTabsState })
                    }
                    id="selected-tab"
                    name="selected-tab"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm rounded-md"
                    defaultValue={tabsState.find(tab => tab.current)?.name}
                  >
                    {tabsState.map((tab, index) => (
                      <option value={index} key={tab.name}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden lg:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8">
                      {tabsState.map((tab, index) => (
                        <button
                          key={tab.name}
                          onClick={() => selectTab({ tabIndex: index, tabsState, setTabsState })}
                          className={classNames(
                            tab.current
                              ? "border-orange-500 text-orange-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                          )}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>

                {extractCurrentTab(tabsState).name === "Summary" && (
                  <CampaignSummaryTab campaign={usCampaign} />
                )}

                {extractCurrentTab(tabsState).name === "Addresses" && (
                  <AddressesTab
                    addAddressesToUsCampaignMutation={addAddressesToUsCampaignMutation}
                    usCampaign={usCampaign}
                    fixedAddresses={fixedAddresses}
                    incompleteGetUSOwnersGroups={incompleteGetUSOwnersGroups}
                    lastIncompleteGetUSOwnersGroup={lastIncompleteGetUSOwnersGroup}
                    getUSOwnersGroups={getUSOwnersGroups}
                    triggerTrackingGroup={triggerTrackingGroup}
                    triggerTrackingGroupLoading={triggerTrackingGroupLoading}
                    cancelTrackingGroup={cancelTrackingGroup}
                    cancelTrackingGroupLoading={cancelTrackingGroupLoading}
                    pauseTrackingGroup={pauseTrackingGroup}
                    pauseTrackingGroupLoading={pauseTrackingGroupLoading}
                  />
                )}

                {extractCurrentTab(tabsState).name === "Owners - Matching" && (
                  <SuspectedOwnersCleanTab
                    updateOwnerMatchDecisionsMutation={updateOwnerMatchDecisionsMutation}
                    campaign={usCampaign}
                    fixedAddresses={fixedAddresses}
                  />
                )}

                {extractCurrentTab(tabsState).name === "Owners - Selection" && (
                  <SuspectedOwnersEvaluationTab
                    updateOwnerContenderDecisionsMutation={updateOwnerContenderDecisionsMutation}
                    usCampaign={usCampaign}
                    fixedAddresses={fixedAddresses}
                    incompleteGetUSProfilesGroups={incompleteGetUSProfilesGroups}
                    lastIncompleteGetUSProfilesGroup={lastIncompleteGetUSProfilesGroup}
                    getUSProfilesGroups={getUSProfilesGroups}
                    triggerTrackingGroup={triggerTrackingGroup}
                    triggerTrackingGroupLoading={triggerTrackingGroupLoading}
                    cancelTrackingGroup={cancelTrackingGroup}
                    cancelTrackingGroupLoading={cancelTrackingGroupLoading}
                    pauseTrackingGroup={pauseTrackingGroup}
                    pauseTrackingGroupLoading={pauseTrackingGroupLoading}
                  />
                )}

                {extractCurrentTab(tabsState).name === "Digital Profiles" && (
                  <DigitalProfilesTab
                    updateProfileContenderDecisionsMutation={
                      updateProfileContenderDecisionsMutation
                    }
                    campaign={usCampaign}
                    fixedAddresses={fixedAddresses}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SingleUSCampaign;
