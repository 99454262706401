import {
  Card,
  Title,
  Bold,
  Text,
  List,
  ListItem,
  Tab,
  TabList,
  TabGroup,
  Grid,
} from "@tremor/react";

import React, { useState } from "react";
import { epcStatuses } from "../data";
import { GetCampaignByIdQuery } from "../../../../shared/infrastructure/graphQL/generatedTypes";
import { CampaignLead } from "../graphql/hooks/useCampaignById";
import {
  DIGITAL_PROFILE_STATUS,
  OWNERSHIP_SOURCE,
  SUSPECTED_OWNER_STATUS,
} from "../../../../shared/internals/constants/models";

interface CampaignAnalyticsProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"];
  campaignLeads: CampaignLead[];
}

export default function CampaignAnalytics({ campaign, campaignLeads }: CampaignAnalyticsProps) {
  const owners = campaignLeads.map(lead => lead.address.suspected_owners).flat();
  const numberOfOwners = campaignLeads.map(lead => lead.address.suspected_owners).flat().length;
  const numberOfaddresses = campaignLeads.length;

  const contenderOwners = owners.filter(
    owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.CONTENDER
  );

  const verifiedOwners = owners.filter(
    owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.VERIFIED
  );

  const addressesWithOwners = campaignLeads.filter(
    lead => lead.address.suspected_owners.length > 0
  );

  const addressesWithContenderOwners = campaignLeads.filter(lead =>
    lead.address.suspected_owners.some(
      owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.CONTENDER
    )
  );

  const addressesWithVerfiedOwners = campaignLeads.filter(lead =>
    lead.address.suspected_owners.some(
      owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.VERIFIED
    )
  );

  const addressesWithDigitalProfile = campaignLeads.filter(lead =>
    lead.address.suspected_owners.some(owner => owner.digital_profiles.length > 0)
  );

  const addressesWithContenderDigitalProfile = campaignLeads.filter(lead =>
    lead.address.suspected_owners.some(owner =>
      owner.digital_profiles.some(
        profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.CONTENDER
      )
    )
  );

  const addressesWithVerifiedDigitalProfile = campaignLeads.filter(lead =>
    lead.address.suspected_owners.some(owner =>
      owner.digital_profiles.some(
        profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.VERIFIED
      )
    )
  );

  const ownerOccupiedAddresses = campaignLeads.filter(lead => {
    const tenure = lead?.address?.additional_info?.epc?.tenure;
    return tenure && epcStatuses.ownerOccupied.includes(tenure);
  });

  const rentedSocialAddresses = campaignLeads.filter(lead => {
    const tenure = lead?.address?.additional_info?.epc?.tenure;
    return tenure && epcStatuses.rentedSocial.includes(tenure);
  });

  const rentedPrivateAddresses = campaignLeads.filter(lead => {
    const tenure = lead?.address?.additional_info?.epc?.tenure;
    return tenure && epcStatuses.rentedPrivate.includes(tenure);
  });

  const undefinedEPCAddresses = campaignLeads.filter(lead => {
    const tenure = lead?.address?.additional_info?.epc?.tenure;
    return tenure && epcStatuses.notDefined.includes(tenure);
  });

  const notAvailableEPCAddresses = campaignLeads.filter(lead => {
    const tenure = lead?.address?.additional_info?.epc?.tenure;
    return !tenure;
  });

  const addressesWithMultipleSources = campaignLeads.filter(lead => {
    const ownerSources = lead.address.suspected_owners.map(owner => owner.ownership_source.id);
    const uniqueSources = [...new Set(ownerSources)];
    return uniqueSources.length > 1;
  });

  const addressesWithElectoralRollOnly = campaignLeads.filter(lead => {
    const ownerSources = lead.address.suspected_owners.map(owner => owner.ownership_source.id);
    return ownerSources.length === 1 && ownerSources[0] === OWNERSHIP_SOURCE.ELECTORAL_ROLL;
  });

  const addressesWithCompaniesHouseOnly = campaignLeads.filter(lead => {
    const ownerSources = lead.address.suspected_owners.map(owner => owner.ownership_source.id);
    return ownerSources.length === 1 && ownerSources[0] === OWNERSHIP_SOURCE.COMPANIES_HOUSE;
  });

  const addressesWithUserProvidedOnly = campaignLeads.filter(lead => {
    const ownerSources = lead.address.suspected_owners.map(owner => owner.ownership_source.id);
    return ownerSources.length === 1 && ownerSources[0] === OWNERSHIP_SOURCE.USER_PROVIDED;
  });

  const addressOwnerDeepDive = [
    {
      name: "Addresses with owners from multiple sources",
      value: `${addressesWithMultipleSources.length} / ${numberOfaddresses}`,
      percentage:
        ((addressesWithMultipleSources.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "Addresses with owners only from Electoral Roll",
      value: `${addressesWithElectoralRollOnly.length} / ${numberOfaddresses}`,
      percentage:
        ((addressesWithElectoralRollOnly.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "Addresses with owners only from Companies House",
      value: `${addressesWithCompaniesHouseOnly.length} / ${numberOfaddresses}`,
      percentage:
        ((addressesWithCompaniesHouseOnly.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "Addresses with owners only User Provided",
      value: `${addressesWithUserProvidedOnly.length} / ${numberOfaddresses}`,
      percentage:
        ((addressesWithUserProvidedOnly.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
  ];

  const addressEPCbreakdown = [
    {
      name: "EPC - Owner Occupied Addresses",
      value: `${ownerOccupiedAddresses.length} / ${numberOfaddresses}`,
      percentage: ((ownerOccupiedAddresses.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "EPC - Rented Social Addresses",
      value: `${rentedSocialAddresses.length} / ${numberOfaddresses}`,
      percentage: ((rentedSocialAddresses.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "EPC - Rented Private Addresses",
      value: `${rentedPrivateAddresses.length} / ${numberOfaddresses}`,
      percentage: ((rentedPrivateAddresses.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "EPC - Undefined Addresses",
      value: `${undefinedEPCAddresses.length} / ${numberOfaddresses}`,
      percentage: ((undefinedEPCAddresses.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "EPC - Not Available Addresses",
      value: `${notAvailableEPCAddresses.length} / ${numberOfaddresses}`,
      percentage: ((notAvailableEPCAddresses.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
  ];

  const addressTotals = [
    { name: "Addresses in Campaign", value: campaignLeads.length, percentage: "N/A" },
    {
      name: "Addresses with at least one owner",
      value: `${addressesWithOwners.length} / ${numberOfaddresses}`,
      percentage: ((addressesWithOwners.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "Addresses with at least one contender owner",
      value: `${addressesWithContenderOwners.length} / ${numberOfaddresses}`,
      percentage:
        ((addressesWithContenderOwners.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "Addresses with at least one verified owner",
      value: `${addressesWithVerfiedOwners.length} / ${numberOfaddresses}`,
      percentage: ((addressesWithVerfiedOwners.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "Addresses with at least one digital profile",
      value: `${addressesWithDigitalProfile.length} / ${numberOfaddresses}`,
      percentage: ((addressesWithDigitalProfile.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "Addresses with at least one contender digital profile",
      value: `${addressesWithContenderDigitalProfile.length} / ${numberOfaddresses}`,
      percentage:
        ((addressesWithContenderDigitalProfile.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
    {
      name: "Addresses with at least one verified digital profile",
      value: `${addressesWithVerifiedDigitalProfile.length} / ${numberOfaddresses}`,
      percentage:
        ((addressesWithVerifiedDigitalProfile.length / numberOfaddresses) * 100).toFixed(0) + "%",
    },
  ];

  const addressAverages = [
    {
      name: "Average number of Owners per Address",
      value: (numberOfOwners / numberOfaddresses).toFixed(2),
      percentage: "N/A",
    },
    {
      name: "Average number of Contender Owners per Address",
      value: (contenderOwners.length / numberOfaddresses).toFixed(2),
      percentage: "N/A",
    },
    {
      name: "Average number of Verified Owners per Address",
      value: (verifiedOwners.length / numberOfaddresses).toFixed(2),
      percentage: "N/A",
    },
  ];

  const totalOwners = owners.length;

  const ownersWithProfile = owners.filter(owner => owner.digital_profiles.length > 0).length;

  const ownersWithContenderProfile = owners.filter(owner =>
    owner.digital_profiles.some(
      profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.CONTENDER
    )
  ).length;

  const ownersWithVerifiedProfile = owners.filter(owner =>
    owner.digital_profiles.some(
      profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.VERIFIED
    )
  ).length;

  const ownersTotals = [
    {
      name: "Total number of owners",
      value: totalOwners,
      percentage: "N/A",
    },
    {
      name: "Total number of contender owners",
      value: `${contenderOwners.length} / ${totalOwners}`,
      percentage: ((contenderOwners.length / totalOwners) * 100).toFixed(0) + "%",
    },
    {
      name: "Total number of verified owners",
      value: `${verifiedOwners.length} / ${totalOwners}`,
      percentage: ((verifiedOwners.length / totalOwners) * 100).toFixed(0) + "%",
    },
    {
      name: "Owners with at least one profile",
      value: `${ownersWithProfile} / ${totalOwners}`,
      percentage: ((ownersWithProfile / totalOwners) * 100).toFixed(0) + "%",
    },
    {
      name: "Owners with at least one contender profile",
      value: `${ownersWithContenderProfile} / ${totalOwners}`,
      percentage: ((ownersWithContenderProfile / totalOwners) * 100).toFixed(0) + "%",
    },
    {
      name: "Owners with at least one verified profile",
      value: `${ownersWithVerifiedProfile} / ${totalOwners}`,
      percentage: ((ownersWithVerifiedProfile / totalOwners) * 100).toFixed(0) + "%",
    },
  ];

  const companiesHouseOwners = owners.filter(
    owner => owner.ownership_source.id === OWNERSHIP_SOURCE.COMPANIES_HOUSE
  );
  const electoralRollOwners = owners.filter(
    owner => owner.ownership_source.id === OWNERSHIP_SOURCE.ELECTORAL_ROLL
  );
  const userProvidedOwners = owners.filter(
    owner => owner.ownership_source.id === OWNERSHIP_SOURCE.USER_PROVIDED
  );

  const ownersSources = [
    {
      name: "Electoral Roll Owners",
      value: `${electoralRollOwners.length} / ${totalOwners}`,
      percentage: ((electoralRollOwners.length / totalOwners) * 100).toFixed(0) + "%",
    },
    {
      name: "Companies House Owners",
      value: `${companiesHouseOwners.length} / ${totalOwners}`,
      percentage: ((companiesHouseOwners.length / totalOwners) * 100).toFixed(0) + "%",
    },

    {
      name: "User Provided Owners",
      value: `${userProvidedOwners.length} / ${totalOwners}`,
      percentage: ((userProvidedOwners.length / totalOwners) * 100).toFixed(0) + "%",
    },
  ];

  const allDigitalProfiles = owners.map(owner => owner.digital_profiles).flat();

  const totalProfiles = allDigitalProfiles.length;

  const contenderProfiles = allDigitalProfiles.filter(
    profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.CONTENDER
  ).length;

  const verifiedProfiles = allDigitalProfiles.filter(
    profile => profile.digital_profile_status.id === DIGITAL_PROFILE_STATUS.VERIFIED
  ).length;

  const digitalProfilesTotals = [
    {
      name: "Total number of profiles",
      value: totalProfiles,
      percentage: "N/A",
    },
    {
      name: "Total number of contender profiles",
      value: `${contenderProfiles} / ${totalProfiles}`,
      percentage: ((contenderProfiles / totalProfiles) * 100).toFixed(0) + "%",
    },
    {
      name: "Total number of verified profiles",
      value: `${verifiedProfiles} / ${totalProfiles}`,
      percentage: ((verifiedProfiles / totalProfiles) * 100).toFixed(0) + "%",
    },
  ];

  const averageProfilesPerContenderOwner = totalProfiles / contenderOwners.length;

  const ownerAverages = [
    {
      name: "Average number of Profiles per Contender Owner",
      value: averageProfilesPerContenderOwner.toFixed(2),
      percentage: "N/A",
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <Card>
      <Title>Overall Analytics</Title>
      <TabGroup className="mt-10" index={selectedIndex} onIndexChange={setSelectedIndex}>
        <TabList variant="line">
          <Tab>Addresses</Tab>
          <Tab>Owners & Profiles</Tab>
        </TabList>
      </TabGroup>
      {selectedIndex === 0 && (
        <Grid numItemsMd={2} className="gap-x-8 gap-y-2">
          <div>
            <Title className="mt-8">Totals</Title>
            <List className="mt-2">
              {addressTotals.map(item => (
                <ListItem key={item.name}>
                  <Text>{item.name}</Text>
                  <Text>
                    <Bold>{item.value}</Bold> {`(${item.percentage})`}
                  </Text>
                </ListItem>
              ))}
            </List>
          </div>
          <div>
            <Title className="mt-8">Averages</Title>
            <List className="mt-2">
              {addressAverages.map(item => (
                <ListItem key={item.name}>
                  <Text>{item.name}</Text>
                  <Text>
                    <Bold>{item.value}</Bold> {`(${item.percentage})`}
                  </Text>
                </ListItem>
              ))}
            </List>
          </div>
          <div>
            <Title className="mt-8">Energy Performance Certificate - Breakdown</Title>
            <List className="mt-2">
              {addressEPCbreakdown.map(item => (
                <ListItem key={item.name}>
                  <Text>{item.name}</Text>
                  <Text>
                    <Bold>{item.value}</Bold> {`(${item.percentage})`}
                  </Text>
                </ListItem>
              ))}
            </List>
          </div>
          <div>
            <Title className="mt-8">Address - Owner Deep Dive</Title>
            <List className="mt-2">
              {addressOwnerDeepDive.map(item => (
                <ListItem key={item.name}>
                  <Text>{item.name}</Text>
                  <Text>
                    <Bold>{item.value}</Bold> {`(${item.percentage})`}
                  </Text>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      )}
      {selectedIndex === 1 && (
        <Grid numItemsMd={2} className="gap-x-8 gap-y-2">
          <div>
            <Title className="mt-8">Totals - Owners</Title>
            <List className="mt-2">
              {ownersTotals.map(item => (
                <ListItem key={item.name}>
                  <Text>{item.name}</Text>
                  <Text>
                    <Bold>{item.value}</Bold> {`(${item.percentage})`}
                  </Text>
                </ListItem>
              ))}
            </List>
          </div>
          <div>
            <Title className="mt-8">Totals - Profiles</Title>
            <List className="mt-2">
              {digitalProfilesTotals.map(item => (
                <ListItem key={item.name}>
                  <Text>{item.name}</Text>
                  <Text>
                    <Bold>{item.value}</Bold> {`(${item.percentage})`}
                  </Text>
                </ListItem>
              ))}
            </List>
          </div>
          <div>
            <Title className="mt-8">Owner - Averages</Title>
            <List className="mt-2">
              {ownerAverages.map(item => (
                <ListItem key={item.name}>
                  <Text>{item.name}</Text>
                  <Text>
                    <Bold>{item.value}</Bold> {`(${item.percentage})`}
                  </Text>
                </ListItem>
              ))}
            </List>
          </div>
          <div>
            <Title className="mt-8">Owner - Sources</Title>
            <List className="mt-2">
              {ownersSources.map(item => (
                <ListItem key={item.name}>
                  <Text>{item.name}</Text>
                  <Text>
                    <Bold>{item.value}</Bold> {`(${item.percentage})`}
                  </Text>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      )}
    </Card>
  );
}
