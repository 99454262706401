import {
  GetAddressesByPostcodeUnitIdsQuery,
  GetCampaignByIdQuery,
  GetCampaignLeadProfilesQuery,
  GetDoorKnockingCampaignByIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  OWNERSHIP_SOURCE,
  SUSPECTED_OWNER_STATUS,
} from "../../../../shared/internals/constants/models";
import { CampaignLead } from "../graphql/hooks/useCampaignById";
import { startCase } from "lodash";
import {
  AddressCSVData,
  BulkMatcherForOwnersTransformedCategory,
  BulkMatcherTransformedCategory,
  GroupedAddresses,
  GroupedBulkAddressMatcherCampaignLeads,
  GroupedBulkAddressMatcherForOwners,
  IMessageContents,
  TransformedAddressesForPostcodeUnitIds,
  UpdateBulkAddressMatchValues,
  UpdateBulkOwnerAddressMatchValues,
} from "../types";
import toast from "react-hot-toast";
import { currencyFormatter } from "../../../../shared/internals/utils";

export const isAddressDuplicate = (address: string, campaignLeads: CampaignLead[]) => {
  return campaignLeads.filter(lead => lead.address.full_address === address).length > 1;
};

export const constructIntroMessage = ({
  status,
  postcodeUnit,
  postTown,
  isReduced,
  isUnderOffer,
  localAuthority,
  price,
  locality,
  messages,
}: {
  status: string;
  postTown: string;
  postcodeUnit: string;
  localAuthority: string;
  messages: {
    msg_order: number;
    content: string;
    id: number;
  }[];
  locality?: string | null;
  isReduced?: boolean;
  isUnderOffer?: boolean;
  price?: number;
}) => {
  let priceSegment = "";
  if (price !== undefined) {
    let segmentMidpoint;

    if (price <= 1000000) {
      // Round to the nearest 250K segment (250K, 500K, 750K, 1M) for prices <= 1M
      const segments = [250000, 500000, 750000, 1000000];
      segmentMidpoint = segments.reduce((prev, curr) =>
        Math.abs(curr - price) < Math.abs(prev - price) ? curr : prev
      );
    } else {
      // For prices > 1M, round to the nearest 0.5M increment (1M, 1.5M, 2M, etc.)
      segmentMidpoint = Math.round(price / 500000) * 500000;
    }

    // Format the segment midpoint
    let formattedSegmentMidpoint;
    if (segmentMidpoint >= 1000000) {
      formattedSegmentMidpoint = `${segmentMidpoint / 1000000}M`;
    } else {
      formattedSegmentMidpoint = `${segmentMidpoint / 1000}K`;
    }

    // Create the price segment string
    priceSegment = formattedSegmentMidpoint;
  }

  let location = locality ? locality : postTown;
  if (location === "LONDON") {
    location = localAuthority;
  }

  const replaceSnippets = (content: string) => {
    const normalizedLocation = startCase(location.toLowerCase());
    return content
      .replace(/{{PRICE}}/g, priceSegment)
      .replace(/{{POSTCODE_UNIT}}/g, postcodeUnit)

      .replace(/{{LOCATION}}/g, normalizedLocation);
  };

  const replacedMessages = messages.map(message => ({
    ...message,
    content: replaceSnippets(message.content),
  }));

  return replacedMessages;
};

export const generateCSVData = (
  campaignLeadProfiles: GetCampaignLeadProfilesQuery["campaign_lead_profile"],
  campaign:
    | GetCampaignByIdQuery["campaign_by_pk"]
    | GetDoorKnockingCampaignByIdQuery["campaign_by_pk"]
) => {
  const messageSet = campaign?.message_set;

  if (!messageSet) {
    toast.error("No Messages were set for this campaign");
    return [];
  }

  const messages = [...messageSet.messages].sort((a, b) => a.msg_order - b.msg_order);

  return campaignLeadProfiles.map(leadProfile => {
    const postTown = leadProfile.campaign_lead.address.postcode_unit.post_town.name;
    const {
      building_name,
      building_number,
      sub_building_name,
      thoroughfare_name,
      dependent_locality,
      postcode_unit,
    } = leadProfile.campaign_lead.address;

    const replacedMessagesArray = constructIntroMessage({
      status: leadProfile.campaign_lead.additional_info?.["Status"],
      postTown: postTown,
      postcodeUnit: postcode_unit.code,
      isReduced: leadProfile.campaign_lead.additional_info?.["Reduced"],
      isUnderOffer: leadProfile.campaign_lead.additional_info?.["isUnderOffer"],
      price: leadProfile.campaign_lead.additional_info?.["Current Price (£)"],
      locality: dependent_locality,
      localAuthority: postcode_unit.local_authority.name,
      messages,
    });
    // Convert array to object with keys like message1, message2, etc.
    const replacedMessages: IMessageContents = {};
    replacedMessagesArray.forEach(message => {
      replacedMessages[`message${message.msg_order}`] = message.content;
    });

    return {
      "First Name":
        leadProfile.owner_address_profile?.suspected_owner_address?.suspected_owner?.first_name,
      "Last Name":
        leadProfile.owner_address_profile?.suspected_owner_address?.suspected_owner?.last_name,
      "Synced with Overlead?": leadProfile.external_id ? "Yes" : "No",
      "Profile Name": leadProfile.owner_address_profile?.digital_profile?.name,
      profileUrl: leadProfile.owner_address_profile?.digital_profile?.value,
      isReduced: leadProfile.campaign_lead.additional_info?.["Reduced"],
      isUnderOffer: leadProfile.campaign_lead.additional_info?.["isUnderOffer"],
      price: leadProfile.campaign_lead.additional_info?.["Current Price (£)"],
      "Full Address": leadProfile.campaign_lead.stated_address,
      "Full PostCode": leadProfile.campaign_lead.additional_info?.["Full Postcode"],
      Status: leadProfile.campaign_lead.additional_info?.["Status"],
      campaignLeadProfileId: leadProfile.id,
      messageSetId: messageSet.id,
      ...replacedMessages, // Spread operator to include dynamic message fields
    };
  });
};

export const getLocalAuthorities = (campaignLeads: CampaignLead[]) => {
  // Step 1: Create a map with localAuthority id as key and count as value
  const localAuthorityMap = campaignLeads.reduce<
    Record<string, { count: number; name: string; hasSearchEnabled: boolean }>
  >((acc, lead) => {
    const localAuthority = lead.address.postcode_unit.local_authority;
    if (!acc[localAuthority.id]) {
      acc[localAuthority.id] = {
        count: 0,
        name: localAuthority.name,
        hasSearchEnabled: localAuthority.has_search_enabled,
      };
    }
    acc[localAuthority.id].count += 1;
    return acc;
  }, {});

  // Step 2: Create an array of unique local authorities with their incidence
  const uniqueLocalAuthoritiesWithCount = Object.keys(localAuthorityMap).map(id => {
    return {
      id,
      ...localAuthorityMap[id],
    };
  });

  // Step 3 (Optional): Sort the array if needed
  // Example: Sort by count in descending order
  uniqueLocalAuthoritiesWithCount.sort((a, b) => b.count - a.count);

  return uniqueLocalAuthoritiesWithCount;
};

const getMaxOwners = (campaignLeads: CampaignLead[]) => {
  return Math.max(...campaignLeads.map(lead => lead.address.suspected_owners.length));
};

export const generateOwnerCSVData = (campaignLeads: CampaignLead[]) => {
  const maxOwners = getMaxOwners(campaignLeads);

  return campaignLeads
    .filter(
      lead =>
        lead.address.suspected_owners.filter(
          owner =>
            owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.CONTENDER ||
            owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.VERIFIED
        ).length > 0
    )
    .map(lead => {
      const owners = lead.address.suspected_owners.filter(
        owner =>
          owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.CONTENDER ||
          owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.VERIFIED
      );

      let response: AddressCSVData = {
        Address: lead.stated_address,
      };

      for (let index = 0; index < maxOwners; index++) {
        const owner = owners[index];
        if (owner) {
          response[`Owner ${index + 1} - Name`] =
            owner.ownership_source.id === OWNERSHIP_SOURCE.COMPANIES_HOUSE
              ? `${startCase(owner.first_name?.toLowerCase())} ${startCase(
                  owner.last_name?.toLowerCase()
                )}`
              : `${startCase(owner.name?.toLowerCase())}`;
          response[`Owner ${index + 1} - Start Date`] = owner.start_date;
          response[`Owner ${index + 1} - End Date`] = owner.end_date;
        } else {
          // Add placeholders for missing owners
          response[`Owner ${index + 1} - Name`] = "";
          response[`Owner ${index + 1} - Start Date`] = "";
          response[`Owner ${index + 1} - End Date`] = "";
        }
      }

      owners.forEach((owner, index) => {
        if (owner) {
          response[`Owner ${index + 1} - Name`] =
            owner.ownership_source.id === OWNERSHIP_SOURCE.COMPANIES_HOUSE
              ? `${startCase(owner.first_name?.toLowerCase())} ${startCase(
                  owner.last_name?.toLowerCase()
                )}`
              : `${startCase(owner.name?.toLowerCase())}`;
          response[`Owner ${index + 1} - Start Date`] = owner.start_date;
          response[`Owner ${index + 1} - End Date`] = owner.end_date;
        }
      });

      if (lead.additional_info) {
        Object.keys(lead.additional_info).forEach(key => {
          response[key] = lead.additional_info[key];
        });
      }

      return response;
    });
};

export const generateAddressCSVData = (campaignLeads: CampaignLead[]) => {
  return campaignLeads.map(lead => {
    let response: AddressCSVData = {
      address: lead.stated_address,
      bedrooms: lead.bedrooms ?? "N/A",
      bathrooms: lead.bathrooms ?? "N/A",
      agent: lead.agent ?? "N/A",
      price: lead.one_line_price ? currencyFormatter.format(lead.one_line_price) : "N/A",
      publicUrl: lead.public_url,
    };

    if (lead.additional_info) {
      Object.keys(lead.additional_info).forEach(key => {
        response[key] = lead.additional_info[key];
      });
    }

    return response;
  });
};

export function transformCampaignLeadsForBulkAddressMatcher(
  sortedCampaignLeads: UpdateBulkAddressMatchValues["campaignLeads"]
): BulkMatcherTransformedCategory[] {
  const grouped = sortedCampaignLeads.reduce<GroupedBulkAddressMatcherCampaignLeads>(
    (acc, lead) => {
      const matchQualityId = lead.addressMatchQualityId;

      if (!acc[matchQualityId]) {
        acc[matchQualityId] = {
          address_match_quality_id: lead.addressMatchQualityId,
          address_match_quality_name: lead.addressMatchQualityName,
          campaignLeads: [],
        };
      }

      acc[matchQualityId].campaignLeads.push({
        id: lead.id,
        statedAddress: lead.statedAddress,
        initialAddressId: lead.initialAddressId,
        isExcluded: lead.isExcluded,
        initialAddressFullAddress: lead.initialAddressFullAddress,
        initialPostcodeUnitCode: lead.initialPostcodeUnitCode,
        initialPostcodeUnitId: lead.initialPostcodeUnitId,
        isAddressValidated: lead.isAddressValidated,
        addressMatchScore: lead.addressMatchScore,
        index: lead.index,
        matchedAddressId: lead.matchedAddress.value,
        matchedAddressFullAddress: lead.matchedAddress.label,
        postcodeUnitId: lead.postcodeUnit.value,
        postcodeUnitCode: lead.postcodeUnit.label,
      });

      return acc;
    },
    {}
  );

  const groupedArray = Object.values(grouped);

  // Convert the grouped object into an array format
  return groupedArray;
}

export const transformAddressesByPostcodeUnitIdsResult = ({
  data,
}: {
  data: GetAddressesByPostcodeUnitIdsQuery;
}): TransformedAddressesForPostcodeUnitIds[] => {
  // Reduce the array of addresses into an object grouped by postcodeUnitId
  const groupedByPostcodeUnitId = data.address.reduce<GroupedAddresses>((acc, address) => {
    if (!acc[address.postcode_unit.id]) {
      acc[address.postcode_unit.id] = {
        postcodeUnitId: address.postcode_unit.id,
        postcodeUnitCode: address.postcode_unit.code,
        addresses: [],
      };
    }
    acc[address.postcode_unit.id].addresses.push({
      id: address.id,
      fullAddress: address.full_address,
    });
    return acc;
  }, {});

  // Convert the object into an array of the grouped data
  return Object.values(groupedByPostcodeUnitId);
};

export function transformOwnersForBulkAddressMatcher(
  owners: UpdateBulkOwnerAddressMatchValues["changes"]
): BulkMatcherForOwnersTransformedCategory[] {
  const grouped = owners.reduce<GroupedBulkAddressMatcherForOwners>((acc, owner) => {
    const constructedStatus = owner.ownerConstructedStatus;

    if (!acc[constructedStatus]) {
      acc[constructedStatus] = {
        constructed_status: owner.ownerConstructedStatus,
        owners: [],
      };
    }

    acc[constructedStatus].owners.push({
      ownerId: owner.ownerId,
      ownerFullName: owner.ownerFullName,
      ownerConstructedStatus: owner.ownerConstructedStatus,
      ownerStatedAddress: owner.ownerStatedAddress,
      isFalseAddress: owner.isFalseAddress,
      index: owner.index,
      isCorrectAddress: owner.isCorrectAddress,
      leadMatchedAddressId: owner.leadMatchedAddressId,
      leadMatchedAddressFullAddress: owner.leadMatchedAddressFullAddress,
      addressMatchScore: owner.addressMatchScore,
      addressMatchQualityId: owner.addressMatchQualityId,
      initialIsFalseAddress: owner.initialIsFalseAddress,
      initialIsCorrectAddress: owner.initialIsCorrectAddress,
      addressMatchQualityName: owner.addressMatchQualityName,
    });

    return acc;
  }, {});

  const groupedArray = Object.values(grouped);

  // Convert the grouped object into an array format
  return groupedArray;
}
