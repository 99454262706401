import { Link } from "react-router-dom";
import { UserStatus } from "../../../../infrastructure/firebase/types";
import { NavigationItem } from "../types";
import { classNames } from "../../../utils";
import { Disclosure } from "@headlessui/react";
import { hasRequiredSubscription } from "../utils";
import { LockClosedIcon } from "@heroicons/react/24/solid";

export interface DesktopLinksProps {
  navigationItem: NavigationItem;
  userStatus: UserStatus;
}

const DesktopLinks = ({ navigationItem, userStatus }: DesktopLinksProps) => {
  // Determine if the link should be blocked
  const isBlocked =
    navigationItem.requiredSubscription &&
    !hasRequiredSubscription(navigationItem.requiredSubscription, userStatus);

  // Is admin link and user is not an admin
  if (navigationItem.isAdmin && !userStatus.isAdmin) {
    return null;
  }

  if (isBlocked && navigationItem.hiddenCondition?.hideFullNavItem) {
    return null;
  }

  if (navigationItem.path) {
    return (
      <Link
        to={navigationItem.path}
        className={classNames(
          navigationItem.current
            ? "bg-gray-100 text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )}
      >
        <navigationItem.icon
          className={classNames(
            navigationItem.current ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
            "mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        />
        {navigationItem.name}
      </Link>
    );
  }

  if (navigationItem.children) {
    return (
      <Disclosure key={navigationItem.name} as="div">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                "flex items-center w-full text-left cursor-default rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700"
              )}
            >
              <navigationItem.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
              {navigationItem.name}
            </Disclosure.Button>
            <Disclosure.Panel as="ul" static className="mt-1 px-2">
              {navigationItem?.children?.map(subnavigationItem => {
                if (
                  isBlocked &&
                  navigationItem.hiddenCondition?.hiddenChildrenUrls?.includes(
                    subnavigationItem.path
                  )
                ) {
                  return null;
                }
                return (
                  <li key={subnavigationItem.name}>
                    {/* 44px */}
                    <div className="flex flex-row items-center w-full">
                      <Disclosure.Button
                        as={Link}
                        to={subnavigationItem.path}
                        className={classNames(
                          subnavigationItem.current ? "bg-gray-50" : "hover:bg-gray-50",
                          "block rounded-md w-full py-2 pr-2 pl-9 text-sm leading-6 text-gray-700"
                        )}
                      >
                        {subnavigationItem.name}
                      </Disclosure.Button>
                    </div>
                  </li>
                );
              })}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  return null;
};

export default DesktopLinks;
