import { initializeApp } from "firebase/app";

import {
  getAuth,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
  signInAnonymously,
  signOut,
  sendEmailVerification,
  updateProfile,
  reauthenticateWithCredential,
  updatePassword,
  User,
  EmailAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { firebaseCredentials } from "./credentials";
import { UserStatus } from "./types";

const app = initializeApp(firebaseCredentials);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");

const sendSignInLink = async ({ email, hostname }: { email: string; hostname: string }) => {
  try {
    const actionCodeSettings = {
      url: `https://${hostname}/handle-sign-up`, // Here you would put the url of your application's sign-in completion page
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    // Save the email locally so you can complete the sign in later
    window.localStorage.setItem("emailForSignIn", email);
    toast.success("Sign in link sent to your email. Be sure to check Spam.");
  } catch (err) {
    toast.error(err.code);
  }
};

const signInWithLink = async ({ email, link }: { email: string; link: string }) => {
  try {
    if (isSignInWithEmailLink(auth, link)) {
      await signInWithEmailLink(auth, email, link);
      window.localStorage.removeItem("emailForSignIn");
    }
  } catch (err) {
    toast.error(err.code);
  }
};

const loginAnonymously = async () => {
  try {
    await signInAnonymously(auth);
  } catch (err) {
    toast.error(err.code);
  }
};

const logInWithEmailAndPassword = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    toast.error(err.code);
  }
};

const logInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (err) {
    toast.error(err.code);
  }
};

const logInWithGithub = async () => {
  try {
    await signInWithPopup(auth, githubProvider);
  } catch (err) {
    toast.error(err.code);
  }
};

const logInWithMicrosoft = async () => {
  try {
    await signInWithPopup(auth, microsoftProvider);
  } catch (err) {
    toast.error(err.code);
  }
};

const changePassword = async ({
  user,
  currentPassword,
  newPassword,
}: {
  user: User;
  currentPassword: string;
  newPassword: string;
}) => {
  toast.loading("Changing Password....");
  try {
    if (user && user.email) {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      toast.success("Password Changed");
    }
  } catch (err) {
    toast.error(err.code);
  }
};

const sendVerificationEmail = async ({ user }: { user: User }) => {
  toast.loading("Sending Email....");
  try {
    if (user && user.emailVerified === false) {
      await sendEmailVerification(user);
      toast.success("Verification Email Sent");
    }
  } catch (err) {
    toast.error(err.code);
  }
};

const registerWithEmailAndPassword = async ({
  email,
  password,
  name,
}: {
  email: string;
  password: string;
  name: string;
}) => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(user, { displayName: name });
    if (user && user.emailVerified === false) {
      await sendEmailVerification(user);
      toast.success("Account Created: Please verify email");
    }
  } catch (err) {
    toast.error(err.code);
  }
};
const sendPasswordReset = async ({ email }: { email: string }) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success("Password Reset Email Sent");
  } catch (err) {
    toast.error(err.code);
  }
};

const logout = async () => {
  try {
    await signOut(auth);
    toast.success("Logged Out Successfully");
  } catch (err) {
    toast.error(err.code);
  }
};

const userStatus = async ({
  user,
  withTokenRefresh,
}: {
  user: User;
  withTokenRefresh?: boolean;
}): Promise<UserStatus> => {
  if (withTokenRefresh) {
    await user.getIdToken(true);
  }
  const decodedToken = await user.getIdTokenResult();

  // Extracting leads and socials directly
  const leadsClaims = decodedToken?.claims?.leads as any;
  const socialsClaims = decodedToken?.claims?.socials as any;
  const adminClaim = decodedToken?.claims?.isAdmin as any;
  const freelancerClaim = decodedToken?.claims?.isFreelancer as any;

  // Derive isOnboarded state for leads and socials
  const isOnboardedLeads =
    (typeof leadsClaims?.isOnboarded === "boolean" && leadsClaims?.isOnboarded === true) ||
    (typeof leadsClaims?.isOnboarded === "string" && leadsClaims?.isOnboarded === "true");

  const isOnboardedSocials =
    (typeof socialsClaims?.isOnboarded === "boolean" && socialsClaims?.isOnboarded === true) ||
    (typeof socialsClaims?.isOnboarded === "string" && socialsClaims?.isOnboarded === "true");

  const isAdmin = typeof adminClaim === "boolean" && adminClaim === true;
  const isFreelancer = typeof freelancerClaim === "boolean" && freelancerClaim === true;

  return {
    leads: {
      isOnboarded: isOnboardedLeads,
      subscriptionStatus: leadsClaims?.subscriptionStatus,
    },
    socials: {
      isOnboarded: isOnboardedSocials,
      subscriptionStatus: socialsClaims?.subscriptionStatus,
    },
    isAdmin: isAdmin,
    isFreelancer: isFreelancer,
  };
};

const useUserStatus = ({
  user,
  withTokenRefresh,
}: {
  user: User | null | undefined;
  withTokenRefresh?: boolean;
}) => {
  const [leadsStatus, setLeadsStatus] = useState<UserStatus["leads"]>({
    isOnboarded: false,
    subscriptionStatus: undefined,
  });
  const [socialsStatus, setSocialsStatus] = useState<UserStatus["socials"]>({
    isOnboarded: false,
    subscriptionStatus: undefined,
  });

  const [isAdmin, setIsAdmin] = useState<UserStatus["isAdmin"]>(false);
  const [isFreelancer, setIsFreelancer] = useState<UserStatus["isFreelancer"]>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (user) {
        const status = await userStatus({ user, withTokenRefresh });
        setLeadsStatus(status.leads);
        setSocialsStatus(status.socials);
        setIsAdmin(status.isAdmin);
        setIsFreelancer(status.isFreelancer);
      } else {
        setLeadsStatus({
          isOnboarded: false,
          subscriptionStatus: undefined,
        });
        setSocialsStatus({
          isOnboarded: false,
          subscriptionStatus: undefined,
        });
        setIsAdmin(false);
        setIsFreelancer(false);
      }
      setIsLoading(false);
    })();
  }, [user]);

  return {
    leadsStatus,
    socialsStatus,
    isLoading,
    isAdmin,
    isFreelancer,
  };
};

const refreshToken = async ({ user }: { user: User }): Promise<void> => {
  await user.getIdToken(true);
};

if (process.env.REACT_APP_ENVIRONMENT === "local") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

export {
  auth,
  logInWithEmailAndPassword,
  sendVerificationEmail,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  changePassword,
  useUserStatus,
  refreshToken,
  logInWithGoogle,
  logInWithGithub,
  sendSignInLink,
  signInWithLink,
  logInWithMicrosoft,
  loginAnonymously,
};
