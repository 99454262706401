import { gql } from "@apollo/client";

export const getCampaignLeadProfiles = gql`
  query getCampaignLeadProfiles($id: Int!) {
    campaign_lead_profile(
      where: { deleted_at: { _is_null: true }, campaign_lead: { campaign: { id: { _eq: $id } } } }
    ) {
      id
      external_id
      owner_address_profile {
        digital_profile {
          value
          name
        }
        suspected_owner_address {
          suspected_owner {
            first_name
            last_name
            name
          }
        }
      }
      campaign_lead {
        additional_info
        stated_address
        address {
          full_address
          dependent_locality
          double_dependent_locality
          thoroughfare_name
          building_number
          building_name
          sub_building_name
          postcode_unit {
            id
            code
            local_authority {
              id
              name
            }
            post_town {
              id
              name
            }
          }
        }
      }
    }
  }
`;
