import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import {
  GetAddressesByPostcodeUnitIdsQuery,
  GetSearchGroupByCampaignIdQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { getSearchGroupByCampaignId } from "../queries/getSearchGroupsByCampaignId";
import { ArrayElement } from "../../../../../shared/internals/utils";
import { getAddressesByPostcodeUnitIds } from "../queries/getAddressesByPostcodeUnitIds";
import { transformAddressesByPostcodeUnitIdsResult } from "../../utils";

export type UseLazyAddressesByPostcodeUnitIdsReturnType = ReturnType<
  typeof useLazyAddressesByPostcodeUnitIds
>;

export const useLazyAddressesByPostcodeUnitIds = () => {
  const [getAddressesByPostcodeUnitIdsExec, { data, loading, error }] = useLazyQuery(
    getAddressesByPostcodeUnitIds
  );

  const aggregatedAddresses = data ? transformAddressesByPostcodeUnitIdsResult({ data }) : [];

  const getLazyAddressesByPostcodeUnitIds = async (postcodeUnitIds: number[]) => {
    await getAddressesByPostcodeUnitIdsExec({
      variables: { postcodeUnitIds },
    });
  };

  return {
    getLazyAddressesByPostcodeUnitIds,
    addresses: data && Array.isArray(data.address) ? data.address : [],
    aggregatedAddresses,
    addressesError: error,
    addressesLoading: loading,
  };
};
