import { ApolloError } from "@apollo/client";
import { useUpdateSuspectedOwnerNamesMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";

export interface HookResult {
  updateSuspectedOwnerNames: (options: UpdateSuspectedOwnerNamesMutationOptions) => Promise<void>;
}

export interface UpdateSuspectedOwnerNamesMutationOptions {
  suspectedOwnerId: number;
  firstName: string;
  lastName: string;
}

export interface Options {
  onError: (error: ApolloError) => void;
}

export const useUpdateSuspectedOwnerNames = (): HookResult => {
  const [updateNamesMutation] = useUpdateSuspectedOwnerNamesMutation({
    onError: error => {
      throw error;
    },
  });

  return {
    updateSuspectedOwnerNames: async ({
      suspectedOwnerId,
      firstName,
      lastName,
    }: UpdateSuspectedOwnerNamesMutationOptions) => {
      await updateNamesMutation({
        variables: {
          id: suspectedOwnerId,
          firstName: firstName,
          secondName: lastName,
        },
      });
    },
  };
};
