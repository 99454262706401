import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";
import { useMutation } from "react-query";

export interface GetElectoralRollOwnersAPIOptions {
  campaignId: number;
  campaignLeadIds: number[];
}

export const getElectoralRollOwnersAPI = async ({
  campaignId,
  campaignLeadIds,
}: GetElectoralRollOwnersAPIOptions) => {
  const response = await meetmoreAPI.post(`/addresses/electoral-roll`, {
    campaignLeadIds,
    campaignId,
  });
  return response.data;
};

export const useElectoralRollOwnersMutation = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchCampaign } = useCampaignById({ id: refetchId ?? -1 });

  return useMutation(getElectoralRollOwnersAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Searching Electoral Roll...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Electoral Roll Search Completed");
      await refetchCampaign();
    },
    onError: (error: any, variables, context) => {
      toast.error(`Reason: ${error?.response?.data?.errors[0].meta.uniqueDuplicates}`);
      toast.error(`Error getting Electoral Roll Owners: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
