import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { useMutation } from "react-query";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";
import { useSearchGroupByCampaignId } from "../../graphql/hooks/useSearchGroupsByCampaignId";

export interface CancelLinkedinSearchAPIOptions {
  searchGroupId: number;
}
export const cancelLinkedinSearchAPI = async ({
  searchGroupId,
}: CancelLinkedinSearchAPIOptions) => {
  const response = await meetmoreAPI.post(`/search-groups/cancel`, { searchGroupId });
  return response.data;
};

export const useCancelLinkedinSearchMutation = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchSearchGroups } = useSearchGroupByCampaignId({
    campaignId: refetchId ?? -1,
  });
  return useMutation(cancelLinkedinSearchAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Cancelling Linkedin Search...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Linkedin Search Cancelled");
      await refetchSearchGroups();
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error Cancelling Linkedin Search: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
