import {
  GetCampaignByIdQuery,
  GetTrackingGroupsByCampaignIdQuery,
  GetTrackingGroupsByUsCampaignIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  Bold,
  Button,
  Callout,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionList,
  Text,
  Badge,
  Divider,
  BarList,
  Title,
  Icon,
} from "@tremor/react";

import {
  AdjustmentsVerticalIcon,
  BriefcaseIcon,
  ShieldCheckIcon,
  HandThumbDownIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
  CheckIcon,
  CalculatorIcon,
  MagnifyingGlassCircleIcon,
  SwatchIcon,
} from "@heroicons/react/24/solid";

import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Select from "react-select";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import {
  ADDRESS_MATCH_QUALITY,
  CONSTRUCTED_OWNER_STATUS,
  OWNERSHIP_SOURCE,
  OWNER_ADDRESS_VALIDATION_TYPE,
  SUSPECTED_OWNER_STATUS,
  TRACKING_GROUP_TYPE,
} from "../../../../shared/internals/constants/models";
import { ArrayElement, classNames } from "../../../../shared/internals/utils";
import { Menu, RadioGroup, Switch, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";

import dayjs from "dayjs";

import { UseUSCampaignByIdReturnType } from "../graphql/hooks/useUSCampaignById";
import { suspectedOwnerEvaluationRadioOptions } from "../data";
import { OWNER_CLEANING_SHOW_TABS, OWNER_EVALUATION_SHOW_TABS } from "../../singleCampaign/types";
import { UpdateOwnerMatchDecisionsAPIOptions } from "../api/updateUSOwnerMatchDecision";
import { UseMutateAsyncFunction } from "react-query";
import { UpdateOwnerContenderDecisionsAPIOptions } from "../api/updateOwnerContenderDecision";
import { TriggerTrackingGroupBody } from "../../singleCampaign/api/triggerTrackingGroup";
import { CancelTrackingGroupAPIOptions } from "../../singleCampaign/api/cancelTrackingGroup";
import { PauseTrackingGroupAPIOptions } from "../../singleCampaign/api/pauseTrackingGroup";

interface SuspectedOwnersEvaluationTabProps {
  usCampaign: UseUSCampaignByIdReturnType["campaign"];
  fixedAddresses: UseUSCampaignByIdReturnType["fixedAddresses"];
  incompleteGetUSProfilesGroups: GetTrackingGroupsByUsCampaignIdQuery["tracking_group"];
  lastIncompleteGetUSProfilesGroup?: ArrayElement<
    GetTrackingGroupsByUsCampaignIdQuery["tracking_group"]
  >;
  getUSProfilesGroups: GetTrackingGroupsByUsCampaignIdQuery["tracking_group"];
  triggerTrackingGroup: UseMutateAsyncFunction<any, any, TriggerTrackingGroupBody, void>;
  triggerTrackingGroupLoading: boolean;
  cancelTrackingGroup: UseMutateAsyncFunction<any, any, CancelTrackingGroupAPIOptions, void>;
  pauseTrackingGroup: UseMutateAsyncFunction<any, any, PauseTrackingGroupAPIOptions, void>;
  pauseTrackingGroupLoading: boolean;
  cancelTrackingGroupLoading: boolean;
  updateOwnerContenderDecisionsMutation: UseMutateAsyncFunction<
    any,
    Error,
    UpdateOwnerContenderDecisionsAPIOptions,
    void
  >;
}

const SuspectedOwnersEvaluationTab = ({
  fixedAddresses,
  usCampaign,
  updateOwnerContenderDecisionsMutation,
  triggerTrackingGroup,
  triggerTrackingGroupLoading,
  cancelTrackingGroup,
  cancelTrackingGroupLoading,
  pauseTrackingGroup,
  pauseTrackingGroupLoading,
  getUSProfilesGroups,
  incompleteGetUSProfilesGroups,
  lastIncompleteGetUSProfilesGroup,
}: SuspectedOwnersEvaluationTabProps) => {
  const leadsWithSelectableOwners = fixedAddresses.map(lead => {
    return {
      ...lead,
      us_owners: lead.us_owners
        .filter(owner => owner.is_correct_match) // Filter in only correct match owners
        .map(owner => ({
          ...owner,
          isSelected: false,
        })),
    };
  });

  const [filteredLeads, setFilteredLeads] = useState(leadsWithSelectableOwners);

  const [selectAll, setSelectAll] = useState<{ status: boolean; changedByItemToggle: boolean }>({
    status: false,
    changedByItemToggle: false,
  });
  const [selectedRadioOption, setSelectedRadioOption] = useState(
    suspectedOwnerEvaluationRadioOptions[0]
  );

  useEffect(() => {
    if (selectAll.status === true && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => {
        return {
          ...lead,
          us_owners: lead.us_owners.map(owner => ({
            ...owner,
            isSelected: true,
          })),
        };
      });
      setFilteredLeads(updatedLeads);
    }

    if (selectAll.status === false && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => {
        return {
          ...lead,
          us_owners: lead.us_owners.map(owner => ({
            ...owner,
            isSelected: false,
          })),
        };
      });
      setFilteredLeads(updatedLeads);
    }
  }, [selectAll.status]);

  useEffect(() => {
    let updatedLeads = [...leadsWithSelectableOwners];

    switch (selectedRadioOption.id) {
      case OWNER_EVALUATION_SHOW_TABS.CONTENDER_AND_VERIFIED:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.us_owners.some(owner => owner.is_contender === true);
          })
          .map(lead => ({
            ...lead,
            us_owners: lead.us_owners.filter(owner => owner.is_contender === true),
          }));
        break;

      case OWNER_EVALUATION_SHOW_TABS.REJECTED:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.us_owners.some(owner => owner.is_contender === false);
          })
          .map(lead => ({
            ...lead,
            us_owners: lead.us_owners.filter(owner => owner.is_contender === false),
          }));
        break;
      case OWNER_EVALUATION_SHOW_TABS.NEW:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.us_owners.some(
              owner => owner.is_contender === null || owner.is_contender === undefined
            );
          })
          .map(lead => ({
            ...lead,
            us_owners: lead.us_owners.filter(
              owner => owner.is_contender === null || owner.is_contender === undefined
            ),
          }));
        break;
      default:
        break;
    }

    // owner level filters

    if (selectAll.status === true) {
      // Handle the selectAll logic
      updatedLeads = updatedLeads.map(lead => {
        return {
          ...lead,
          us_owners: lead.us_owners.map(owner => ({
            ...owner,
            isSelected: true,
          })),
        };
      });
    }

    setFilteredLeads(updatedLeads);
  }, [selectedRadioOption, fixedAddresses]);

  const handleIndividualChange = (leadId: number, ownerId: number) => {
    // Find the specific lead and owner
    const lead = filteredLeads.find(lead => lead.id === leadId);
    const owner = lead?.us_owners.find(owner => owner.id === ownerId);
    const ownerWillBeSelected = !owner?.isSelected;

    // Update the leads
    const updatedLeads = filteredLeads.map(lead => {
      if (lead.id === leadId) {
        return {
          ...lead,
          us_owners: lead.us_owners.map(owner =>
            owner.id === ownerId ? { ...owner, isSelected: ownerWillBeSelected } : owner
          ),
        };
      }
      return lead;
    });

    // If the owner is about to be selected, check if all owners will be selected
    if (ownerWillBeSelected) {
      const allOwnersSelected = updatedLeads.every(lead =>
        lead.us_owners.every(owner => owner.isSelected)
      );
      if (allOwnersSelected) {
        setSelectAll({ status: true, changedByItemToggle: true });
      }
    }
    // If the owner is about to be unselected and selectAll is currently true, update selectAll
    else if (selectAll.status) {
      setSelectAll({ status: false, changedByItemToggle: true });
    }

    // Update the filteredLeads state
    setFilteredLeads(updatedLeads);
  };

  const totalOwners = fixedAddresses.map(lead => lead.us_owners).flat();

  const unknownOwners = totalOwners.filter(
    owner => owner.is_contender === null || owner.is_contender === undefined
  );
  const filteredOwners = filteredLeads.map(lead => lead.us_owners).flat();

  return (
    <>
      <div className="flex w-full">
        <Callout
          title="Do the owners belong to the Adreess?"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>1. Mark owners who you believe to be the correct ones as contenders</div>
          <div>2. Owners who you have marked as contenders have a green background</div>
          <div>3. Owners who you have marked as rejected have a red background</div>
          <div>
            4. Owners who have not been decided on whether they are contender have a grey background
          </div>
        </Callout>
      </div>

      <div className="mt-5">
        {getUSProfilesGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              getUSProfilesGroups[0].is_cancelled === true
                ? "red"
                : getUSProfilesGroups[0].is_completed === true &&
                  getUSProfilesGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              getUSProfilesGroups[0].is_cancelled === true
                ? "Cancelled"
                : getUSProfilesGroups[0].is_completed === true &&
                  getUSProfilesGroups[0].is_cancelled === false
                ? "Completed"
                : getUSProfilesGroups[0].is_error === true
                ? "In Error"
                : getUSProfilesGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = getUSProfilesGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = getUSProfilesGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = getUSProfilesGroups.slice(1).map(group => {
              const completedTrackingItemsCount = getUSProfilesGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Get & Process Profiles Request ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(getUSProfilesGroups[0].is_error || getUSProfilesGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: getUSProfilesGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!usCampaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: getUSProfilesGroups[0].id,
                              campaignId: 5,
                              usCampaignId: usCampaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.GET_US_PROFILES,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!getUSProfilesGroups[0].is_error &&
                      !getUSProfilesGroups[0].is_paused &&
                      !getUSProfilesGroups[0].is_cancelled &&
                      !getUSProfilesGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: getUSProfilesGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} items completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Triggered:{" "}
                  <Bold>{dayjs(getUSProfilesGroups[0].created_at).format("DD/MM/YYYY mm:ss")}</Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text> Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Requests"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>

      <div className="flex space-x-4 justify-between">
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 potential Owner</Text>
          <Metric>
            {(() => {
              const potentialOwnersCount = fixedAddresses.filter(
                lead => lead.us_owners.length > 0
              ).length;
              const totalCount = fixedAddresses.length;
              const percentage = ((potentialOwnersCount / totalCount) * 100).toFixed(0);
              return `${potentialOwnersCount} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 contender Owner</Text>
          <Metric>
            {(() => {
              const potentialOwnersCount = fixedAddresses.filter(lead =>
                lead.us_owners.some(owner => owner.is_contender === true)
              ).length;
              const totalCount = fixedAddresses.length;
              const percentage = ((potentialOwnersCount / totalCount) * 100).toFixed(0);
              return `${potentialOwnersCount} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>

        <Card className="max-w-xs mt-6" decoration="top" decorationColor="orange">
          <Text>Unknown Owners</Text>
          <Metric>
            {unknownOwners.length} / {totalOwners.length} (
            {((unknownOwners.length / totalOwners.length) * 100).toFixed(0)}
            %)
          </Metric>
        </Card>
      </div>

      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  checked={selectAll.status}
                  onChange={() =>
                    setSelectAll(selectAll => ({
                      status: !selectAll.status,
                      changedByItemToggle: false,
                    }))
                  }
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (
                  {filteredLeads.reduce((acc, lead) => {
                    return acc + lead.us_owners.filter(owner => owner.isSelected).length;
                  }, 0)}
                  )
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all owners that you can currently see
                </p>
              </div>
            </div>
            <div className="flex flex-row space-x-10">
              <Dropdown
                items={[
                  {
                    icon: CheckIcon,
                    name: "Mark As Contender",
                    function: async () => {
                      const selectedChanges = filteredLeads
                        .flatMap(lead => lead.us_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => {
                          return {
                            usOwnerId: owner.id,
                            isContender: true,
                          };
                        });
                      if (selectedChanges.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }

                      if (!usCampaign?.id) {
                        toast.error("Campaign ID not found");
                        return;
                      }
                      await updateOwnerContenderDecisionsMutation({
                        usCampaignId: usCampaign.id,
                        usOwnerContenderDecisions: selectedChanges,
                      });
                    },
                  },
                  {
                    icon: XCircleIcon,
                    name: "Mark as Rejected",
                    function: async () => {
                      const selectedChanges = filteredLeads
                        .flatMap(lead => lead.us_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => {
                          return {
                            usOwnerId: owner.id,
                            isContender: false,
                          };
                        });
                      if (selectedChanges.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }

                      if (!usCampaign?.id) {
                        toast.error("Campaign ID not found");
                        return;
                      }
                      await updateOwnerContenderDecisionsMutation({
                        usCampaignId: usCampaign.id,
                        usOwnerContenderDecisions: selectedChanges,
                      });
                    },
                  },
                  {
                    icon: MagnifyingGlassCircleIcon,
                    name: "Search & process Profiles",
                    function: async () => {
                      const selectedChanges = filteredLeads
                        .flatMap(lead => lead.us_owners)
                        .filter(owner => owner.isSelected)
                        .map(owner => {
                          return String(owner.id);
                        });
                      if (selectedChanges.length === 0) {
                        toast.error("Please select owners to perform operations on");
                        return;
                      }

                      if (!usCampaign?.id) {
                        toast.error("Campaign ID not found");
                        return;
                      }
                      await triggerTrackingGroup({
                        campaignId: 5,
                        usCampaignId: usCampaign.id,
                        trackingGroupTypeId: TRACKING_GROUP_TYPE.GET_US_PROFILES,
                        trackingItemValues: selectedChanges,
                      });
                    },
                  },
                ]}
              />
            </div>
          </div>
          <Divider className="mt-1 mb-0" />
          <div className="flex p-4 justify-center w-full items-center space-x-4">
            <Text>Displaying</Text>
            <Metric className="text-gray-500">{filteredOwners.length}</Metric>
            <Text>
              {" "}
              / {totalOwners.length} Owners{" "}
              <span className="italic">({totalOwners.length} total owners)</span>
            </Text>
          </div>
          <Divider className="mt-1 mb-0" />

          <div className="py-4 px-4">
            <div className="flex items-center justify-between">
              <h2 className="text-sm font-medium text-gray-900">Show Only:</h2>
            </div>
            <RadioGroup
              value={selectedRadioOption}
              onChange={setSelectedRadioOption}
              className="mt-2"
            >
              <div className="flex flex-row justify-between space-x-6">
                {suspectedOwnerEvaluationRadioOptions.map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium  sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {filteredLeads.map(lead => {
          return (
            <Card className={"max-w-md mx-auto"}>
              <Flex justifyContent="start" className="space-x-1" alignItems="baseline">
                <Metric>{lead.address}</Metric>
              </Flex>

              <Flex className="w-full">
                <Bold className="mt-6 w-full pt-4 border-t">Potential Owners</Bold>
              </Flex>

              <AccordionList className="mt-6">
                {lead.us_owners.map((owner, idx) => {
                  const startDate = owner.start_date
                    ? dayjs(owner.start_date).format("MM/YYYY")
                    : "N/A";
                  const endDate = owner.end_date ? dayjs(owner.end_date).format("MM/YYYY") : "";
                  const date = `${startDate} - ${endDate}`;

                  return (
                    <Accordion
                      key={owner.id}
                      className={classNames(
                        owner.is_contender === true
                          ? " border border-green-500 bg-green-50"
                          : owner.is_contender === false
                          ? " border border-red-500 bg-red-50"
                          : " border border-gray-500 bg-gray-50",

                        " overflow-visible	"
                      )}
                    >
                      <AccordionHeader className="w-full">
                        <div className="flex flex-col">
                          <div className="flex flex-row justify-between w-full">
                            <div className=" h-5 items-center mr-3">
                              <input
                                type="checkbox"
                                checked={owner.isSelected}
                                onClick={event => event.stopPropagation()}
                                onChange={event => {
                                  event.stopPropagation();

                                  handleIndividualChange(lead.id, owner.id);
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                            </div>
                            <Flex className="flex-col justify-center items-center">
                              <Text>
                                {owner.first_name} {owner.last_name} - ({date})
                              </Text>
                              <p className="text-xs text-gray-400">{owner.full_name}</p>
                              <div className="mt-2">
                                <div className="text-left	 text-gray-400 text-xs">
                                  {owner.stated_address}
                                </div>
                              </div>
                            </Flex>

                            <div className="">
                              <Badge size="xs" color="orange" className="mr-2">
                                <span className="text-xs">
                                  {owner.is_contender ? "Contender" : "Not Contender"}
                                </span>
                              </Badge>
                              <Badge size="xs">
                                <span className="text-xs">{owner.provider} </span>
                              </Badge>
                              <Badge color={owner.has_profile_searched ? "green" : "red"} size="xs">
                                <span className="text-xs">
                                  {owner.has_profile_searched ? "Searched" : "Not Searched"}{" "}
                                </span>
                              </Badge>
                              <Badge color={owner.has_profiles_cleaned ? "green" : "red"} size="xs">
                                <span className="text-xs">
                                  {owner.has_profiles_cleaned
                                    ? "Profiles Cleaned"
                                    : "Profiles Not Cleaned"}{" "}
                                </span>
                              </Badge>
                              <Badge color={owner.has_profiles_matched ? "green" : "red"} size="xs">
                                <span className="text-xs">
                                  {owner.has_profiles_matched
                                    ? "Profiles Matched"
                                    : "Profiles Not Matched"}{" "}
                                </span>
                              </Badge>
                            </div>
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody className="overflow-visible	">
                        <Text className="flex flex-row">
                          <Bold>Stated Address</Bold>
                        </Text>

                        <Text className="mt-2">{owner.stated_address}</Text>

                        <Text className="flex flex-row mt-2">
                          <Bold>Contender Status</Bold>
                        </Text>
                        <Card className="mt-3 p-0 divide-y bg-inherit divide-gray-100">
                          <li
                            className={classNames(
                              owner.is_contender === true
                                ? "bg-green-50"
                                : owner.is_contender === false
                                ? "bg-red-50"
                                : " bg-gray-50",
                              "flex items-center justify-between gap-x-6 p-2"
                            )}
                          >
                            <div className="min-w-0">
                              <div className="flex items-start gap-x-3">
                                <p
                                  className={classNames(
                                    owner.is_contender === true
                                      ? "text-green-700 bg-green-50 ring-green-600/20"
                                      : owner.is_contender === false
                                      ? "text-red-700 bg-red-50 ring-red-600/20"
                                      : "text-gray-600 bg-gray-50 ring-gray-500/10",
                                    "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                                  )}
                                >
                                  {owner.is_contender === true
                                    ? "Contender"
                                    : owner.is_contender === false
                                    ? "Not Contender"
                                    : "Unknown"}
                                </p>
                              </div>
                            </div>
                          </li>
                        </Card>
                      </AccordionBody>
                    </Accordion>
                  );
                })}
              </AccordionList>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default SuspectedOwnersEvaluationTab;
