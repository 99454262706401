import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";
import { ADDRESS_MATCH_QUALITY } from "../../../../../shared/internals/constants/models";
import { useCampaignById } from "../../graphql/hooks/useCampaignById";
import { useMutation } from "react-query";

export interface ChangeMatchQualityOptions {
  campaignId: number;
  campaignLeads: {
    campaignLeadId: number;
    addressId: number;
    addressMatchQualityId: ADDRESS_MATCH_QUALITY;
  }[];
}
export const changeMatchQualityAPI = async ({
  campaignId,
  campaignLeads,
}: ChangeMatchQualityOptions) => {
  const response = await meetmoreAPI.post(`/campaigns/change-match-quality`, {
    campaignId,
    campaignLeads,
  });
  return response.data;
};

export const useChangeMatchQualityMutation = ({ refetchId }: { refetchId?: number }) => {
  let loadingToastId: string;
  const { refetch: refetchCampaign } = useCampaignById({
    id: refetchId ?? -1,
  });
  return useMutation(changeMatchQualityAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Changing Addreses...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      await refetchCampaign();
      toast.success("Addresses Changed");
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error Changing Addresses: ${error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
    },
  });
};
