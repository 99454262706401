import { ChatBubbleBottomCenterTextIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  GetCampaignByIdQuery,
  GetCampaignLeadProfilesQuery,
  GetDoorKnockingCampaignByIdQuery,
  GetMessageSetsQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { ArrayElement, classNames } from "../../../../../shared/internals/utils";
import { Card } from "@tremor/react";
import TemplateSelectorModal from "./TemplateSelectorModal";
import { useState } from "react";
import { SingleMessageSet } from "../../graphql/hooks/useGetMessageSets";
import { UpdateCampaignMessageSetMutationOptions } from "../../graphql/hooks/useUpdateCampaignMessageSet";

interface EmptyMessagePanelProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"] | GetDoorKnockingCampaignByIdQuery["campaign_by_pk"];
  campaignLeadProfiles: GetCampaignLeadProfilesQuery["campaign_lead_profile"];
  messageSets: GetMessageSetsQuery["message_set"];
  updateCampaignMessageSet: (options: UpdateCampaignMessageSetMutationOptions) => Promise<void>;
  isFreelancer: boolean;
}

const EmptyMessagePanel = ({
  campaign,
  campaignLeadProfiles,
  isFreelancer,
  updateCampaignMessageSet,
  messageSets,
}: EmptyMessagePanelProps) => {
  const [selectedMessageSet, setSelectedMessageSet] = useState<SingleMessageSet | null>(null);
  const [isTemplateSelectorPanelOpen, setIsTemplateSelectorPanelOpen] = useState(false);

  return (
    <>
      {selectedMessageSet && (
        <TemplateSelectorModal
          campaign={campaign}
          messageSet={selectedMessageSet}
          isOpen={isTemplateSelectorPanelOpen}
          setOpen={setIsTemplateSelectorPanelOpen}
          updateCampaignMessageSet={updateCampaignMessageSet}
          isFreelancer={isFreelancer}
        />
      )}
      <Card className="mt-5">
        <div className="">
          <h2 className="text-base font-semibold leading-6 text-gray-900">
            Set Messaging for this Campaign
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            No Messaging has been set for this campaign, please select from one of the templates for{" "}
            {campaign?.campaign_type.name} campaigns below or create a manual set of messages.
          </p>
          <ul
            role="list"
            className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200"
          >
            {messageSets
              .filter(set =>
                set.message_set_campaign_types.some(
                  type => type.campaign_type.id === campaign?.campaign_type.id
                )
              )
              .map(messageSet => (
                <li
                  onClick={() => {
                    setSelectedMessageSet(messageSet);
                    setIsTemplateSelectorPanelOpen(true);
                  }}
                  className="cursor-pointer"
                  key={messageSet.id}
                >
                  <div className="group relative flex items-start space-x-3 py-4">
                    <div className="flex-shrink-0">
                      <span
                        className={classNames(
                          "bg-orange-500",
                          "inline-flex h-10 w-10 items-center justify-center rounded-lg"
                        )}
                      >
                        <ChatBubbleBottomCenterTextIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div className="text-sm font-medium text-gray-900">
                        <div>
                          <span className="absolute inset-0" aria-hidden="true" />
                          {messageSet.name}
                          <div className="flex flex-row space-x-2 my-2">
                            {messageSet.is_public && (
                              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                Public
                              </span>
                            )}

                            {messageSet.user && (
                              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                Created by {isFreelancer ? "internal user" : messageSet.user.email}
                              </span>
                            )}

                            {messageSet.message_set_campaign_types.map(campaignType => {
                              return (
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  {campaignType.campaign_type.name}{" "}
                                  {campaignType.is_default && " - Default"}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <p className="text-sm text-gray-500">
                        {messageSet.messages.find(message => message.msg_order === 1)?.content}
                      </p>
                    </div>
                    <div className="flex-shrink-0 self-center">
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          <div className="mt-6 flex flex-row justify-between">
            {/* <a href="#" className="text-sm font-medium text-orange-600 hover:text-orange-500">
              Or create a new set of messages
              <span aria-hidden="true"> &rarr;</span>
            </a>
            <a className="flex cursor-pointer items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
              <span>View All Templates in Library</span>
            </a> */}
          </div>
        </div>
      </Card>
    </>
  );
};

export default EmptyMessagePanel;
