import {
  SubscriptionProduct,
  SubscriptionStatusClaim,
  UserStatus,
} from "../../../../infrastructure/firebase/types";
import { NavigationItem } from "../types";

export const hasRequiredSubscription = (
  requiredSubscription: NavigationItem["requiredSubscription"],
  userStatus: UserStatus
): boolean => {
  if (!requiredSubscription) return true;

  const { type, statuses } = requiredSubscription;

  let userSubscriptionStatuses: SubscriptionStatusClaim[] = [];

  if (type === SubscriptionProduct.LEADS || type === SubscriptionProduct.BOTH) {
    userStatus.leads.subscriptionStatus &&
      userSubscriptionStatuses.push(userStatus.leads.subscriptionStatus);
  }

  if (type === SubscriptionProduct.SOCIALS || type === SubscriptionProduct.BOTH) {
    userStatus.socials.subscriptionStatus &&
      userSubscriptionStatuses.push(userStatus.socials.subscriptionStatus);
  }

  // If the user does not have any subscription status, they do not have the required subscription
  if (userSubscriptionStatuses.length === 0) return false;

  // Check if any of the user's subscription statuses is one of the required statuses
  return userSubscriptionStatuses.some(status => statuses.includes(status));
};
