import {
  GetCampaignByIdQuery,
  GetTrackingGroupsByCampaignIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Badge,
  BarList,
  Bold,
  Button,
  Callout,
  Card,
  Divider,
  Flex,
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Title,
} from "@tremor/react";

import {
  ArrowRightIcon,
  ShieldCheckIcon,
  AdjustmentsVerticalIcon,
  TrashIcon,
  BoltIcon,
  BriefcaseIcon,
  ScissorsIcon,
  HomeIcon,
  TruckIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowRightCircleIcon,
  ShareIcon,
  MagnifyingGlassCircleIcon,
  ArrowPathIcon,
  MegaphoneIcon,
  CurrencyPoundIcon,
  UserIcon,
  LinkIcon,
  HomeModernIcon,
  SwatchIcon,
} from "@heroicons/react/24/solid";

import { CampaignLead } from "../graphql/hooks/useCampaignById";
import { GetPostcodeUnitByIdLazyHookResult } from "../graphql/hooks/usePostcodeUnitById";
import { useEffect, useRef, useState } from "react";
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ADDRESS_SEARCH,
  ADDRESS_SHOW_TABS,
  EPC_CATEGORY,
  MISSING_DATA,
  OWNER_COUNT,
  OWNER_POSSESSION,
  OWNER_SOURCE,
  OWNER_STATUS,
  UpdateMatchedAddressValues,
} from "../types";
import { updateMatchedAddressValidationSchema } from "../validation";
import Select from "react-select";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import { ArrayElement, classNames, currencyFormatter } from "../../../../shared/internals/utils";
import { getLocalAuthorities, isAddressDuplicate, generateAddressCSVData } from "../utils";
import { addressRadioOptions, epcStatuses, addressTabFilters, priceOptions } from "../data";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";
import toast from "react-hot-toast";
import { RadioGroup, Switch } from "@headlessui/react";
import { usePrevious } from "@uidotdev/usehooks";
import {
  ADDRESS_MATCH_QUALITY,
  CLOUD_TASK_QUEUE,
  OWNERSHIP_SOURCE,
  PROSPECTING_TOOL_PROVIDER,
  SUSPECTED_OWNER_STATUS,
  TRACKING_GROUP_TYPE,
} from "../../../../shared/internals/constants/models";
import { startCase, truncate } from "lodash";
import { CrossIcon } from "react-select/dist/declarations/src/components/indicators";
import { UseMutateAsyncFunction } from "react-query";
import { GetElectoralRollOwnersAPIOptions } from "../api/getElectoralRollOwners";
import { filtersStyling } from "../styles";
import { GetLastTransactionBody } from "../api/getLastTransaction";
import { ReceiptRefundIcon } from "@heroicons/react/20/solid";
import dayjs from "dayjs";
import { MatchAddressesBody } from "../api/matchAddresses";
import Papa from "papaparse";
import { DisplayTasksAPIOptions, DisplayTasksAPIResponse } from "../api/displayTasks";
import BulkAddressMatcher from "./addresses/BulkAddressMatcher";
import { UseLazyAddressesByPostcodeUnitIdsReturnType } from "../graphql/hooks/useAddressesByPostcodeUnitIds";
import { useLazyPostcodeUnitByCodeReturnType } from "../graphql/hooks/usePostcodeUnitByCode";
import { ChangeMatchQualityOptions } from "../api/changeMatchQuality";
import Spinner from "../../../../shared/internals/components/spinner/Spinner";
import { ExtractZooplaUrlsBody } from "../api/extractZooplaUrls";
import { TriggerTrackingGroupBody } from "../api/triggerTrackingGroup";
import { CancelTrackingGroupAPIOptions } from "../api/cancelTrackingGroup";
import { PauseTrackingGroupAPIOptions } from "../api/pauseTrackingGroup";
import { reactSelectStyling } from "../../../../shared/internals/styling";

interface AddressesTabProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"];
  campaignLeads: CampaignLead[];
  getPostcodeUnitById: GetPostcodeUnitByIdLazyHookResult["getPostcodeUnitById"];
  postcodeUnit: GetPostcodeUnitByIdLazyHookResult["postcodeUnit"];
  postcodeUnitLoading: GetPostcodeUnitByIdLazyHookResult["postcodeUnitLoading"];
  campaignLeadUpdater: (campaignLeadId: number, addressId: number) => Promise<void>;
  campaignLeadDeleter: (campaignLeadId: number, currentDate: string) => Promise<void>;
  bulkCampaignLeadDeleter: (campaignLeadIds: number[], currentDate: string) => Promise<void>;

  epcPuller: (addressIds: number[]) => Promise<void>;
  companiesHousePuller: (campaignId: number, campaignLeadIds: number[]) => Promise<void>;
  planningApplicationPuller: (campaignId: number, campaignLeadIds: number[]) => Promise<void>;
  electoralRollPuller: UseMutateAsyncFunction<any, any, GetElectoralRollOwnersAPIOptions, void>;
  getLastTransaction: UseMutateAsyncFunction<any, any, GetLastTransactionBody, void>;
  matchAddresses: UseMutateAsyncFunction<any, any, MatchAddressesBody, void>;
  displayTasksPlanning: UseMutateAsyncFunction<any, any, DisplayTasksAPIOptions, void>;
  displayTasksAddress: UseMutateAsyncFunction<any, any, DisplayTasksAPIOptions, void>;
  displayTasksCompaniesHouse: UseMutateAsyncFunction<any, any, DisplayTasksAPIOptions, void>;

  displayTasksPlanningLoading: boolean;
  displayTasksAddressLoading: boolean;
  displayTasksPlanningResponse: DisplayTasksAPIResponse | undefined;
  displayTasksCompaniesHouseResponse: DisplayTasksAPIResponse | undefined;
  displayTasksCompaniesHouseLoading: boolean;
  displayTasksPlanIt: UseMutateAsyncFunction<any, any, DisplayTasksAPIOptions, void>;
  displayTasksPlanItLoading: boolean;
  displayTasksPlanItResponse: DisplayTasksAPIResponse | undefined;

  displayTasksZoopla: UseMutateAsyncFunction<any, any, DisplayTasksAPIOptions, void>;
  displayTasksZooplaLoading: boolean;
  displayTasksZooplaResponse: DisplayTasksAPIResponse | undefined;

  displayTasksAddressResponse: DisplayTasksAPIResponse | undefined;
  aggregatedAddresses: UseLazyAddressesByPostcodeUnitIdsReturnType["aggregatedAddresses"];
  addressesLoading: boolean;
  getLazyAddressesByPostcodeUnitIds: UseLazyAddressesByPostcodeUnitIdsReturnType["getLazyAddressesByPostcodeUnitIds"];
  getLazyPostcodeUnitsByCode: useLazyPostcodeUnitByCodeReturnType["getLazyPostcodeUnitsByCode"];
  postcodeUnits: useLazyPostcodeUnitByCodeReturnType["postcodeUnits"];
  postcodeUnitsLoading: boolean;
  changeMatchQuality: UseMutateAsyncFunction<any, any, ChangeMatchQualityOptions, void>;
  changeMatchQualityLoading: boolean;
  estateAgentOptions: {
    label: string;
    value: string | null | undefined;
  }[];

  incompleteCleanAddressesGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  lastIncompleteCleanAddressesGroup?: ArrayElement<
    GetTrackingGroupsByCampaignIdQuery["tracking_group"]
  >;
  cleanAddressesGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];

  incompleteGetOwnersGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  lastIncompleteGetOwnersGroup?: ArrayElement<GetTrackingGroupsByCampaignIdQuery["tracking_group"]>;
  getOwnersGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  triggerTrackingGroup: UseMutateAsyncFunction<any, any, TriggerTrackingGroupBody, void>;
  triggerTrackingGroupLoading: boolean;
  cancelTrackingGroup: UseMutateAsyncFunction<any, any, CancelTrackingGroupAPIOptions, void>;
  pauseTrackingGroup: UseMutateAsyncFunction<any, any, PauseTrackingGroupAPIOptions, void>;
  pauseTrackingGroupLoading: boolean;
  cancelTrackingGroupLoading: boolean;
}

export const AddressesTab = ({
  campaignLeads,
  postcodeUnit,
  campaign,
  matchAddresses,
  changeMatchQualityLoading,
  estateAgentOptions,
  getPostcodeUnitById,
  planningApplicationPuller,
  electoralRollPuller,
  campaignLeadUpdater,
  bulkCampaignLeadDeleter,
  epcPuller,
  companiesHousePuller,
  getLastTransaction,
  displayTasksAddressResponse,
  displayTasksZoopla,
  displayTasksZooplaLoading,
  displayTasksZooplaResponse,
  displayTasksPlanningResponse,
  changeMatchQuality,
  displayTasksAddress,
  displayTasksAddressLoading,
  displayTasksPlanning,
  getLazyPostcodeUnitsByCode,
  postcodeUnitsLoading,
  postcodeUnits,
  displayTasksPlanningLoading,
  addressesLoading,
  aggregatedAddresses,
  getLazyAddressesByPostcodeUnitIds,
  displayTasksCompaniesHouse,
  displayTasksCompaniesHouseResponse,
  displayTasksCompaniesHouseLoading,
  displayTasksPlanIt,
  displayTasksPlanItResponse,
  displayTasksPlanItLoading,
  triggerTrackingGroup,
  triggerTrackingGroupLoading,
  incompleteCleanAddressesGroups,
  lastIncompleteCleanAddressesGroup,
  cleanAddressesGroups,
  incompleteGetOwnersGroups,
  lastIncompleteGetOwnersGroup,
  getOwnersGroups,
  cancelTrackingGroup,
  cancelTrackingGroupLoading,
  pauseTrackingGroup,
  pauseTrackingGroupLoading,
}: AddressesTabProps) => {
  const [addressMatcherTasks, setAddressMatcherTasks] = useState(0);
  const [planningAppTasks, setPlanningAppTasks] = useState(0);
  const [zooplaAppTasks, setZooplaAppTasks] = useState(0);

  const [companiesHouseTasks, setCompaniesHouseTasks] = useState(0);
  const [planItTasks, setPlanItTasks] = useState(0);

  const [isBulkMatching, setIsBulkMatching] = useState(false);
  const [isRemovingExternalDuplicates, setIsRemovingExternalDuplicates] = useState(false);

  useEffect(() => {
    if (displayTasksPlanItResponse) {
      setPlanItTasks(displayTasksPlanItResponse.taskCount);
    }
  }, [displayTasksPlanItLoading]);

  useEffect(() => {
    if (displayTasksCompaniesHouseResponse) {
      setCompaniesHouseTasks(displayTasksCompaniesHouseResponse.taskCount);
    }
  }, [displayTasksCompaniesHouseLoading]);

  useEffect(() => {
    if (displayTasksAddressResponse) {
      setAddressMatcherTasks(displayTasksAddressResponse.taskCount);
    }
  }, [displayTasksAddressLoading]);

  useEffect(() => {
    if (displayTasksPlanningResponse) {
      setPlanningAppTasks(displayTasksPlanningResponse.taskCount);
    }
  }, [displayTasksPlanningLoading]);

  useEffect(() => {
    if (displayTasksZooplaResponse) {
      setZooplaAppTasks(displayTasksZooplaResponse.taskCount);
    }
  }, [displayTasksZooplaLoading]);

  const displayTasks = async (queue: CLOUD_TASK_QUEUE) => {
    if (campaign?.id) {
      if (queue === CLOUD_TASK_QUEUE.MATCH_ADDRESS) {
        await displayTasksAddress({
          campaignId: campaign?.id,
          taskType: CLOUD_TASK_QUEUE.MATCH_ADDRESS,
        });
      } else if (queue === CLOUD_TASK_QUEUE.PLANNING_APP_SEARCH) {
        await displayTasksPlanning({
          campaignId: campaign?.id,
          taskType: CLOUD_TASK_QUEUE.PLANNING_APP_SEARCH,
        });
      } else if (queue === CLOUD_TASK_QUEUE.COMPANIES_HOUSE_SEARCH) {
        await displayTasksCompaniesHouse({
          campaignId: campaign?.id,
          taskType: CLOUD_TASK_QUEUE.COMPANIES_HOUSE_SEARCH,
        });
      } else if (queue === CLOUD_TASK_QUEUE.ZOOPLA) {
        await displayTasksZoopla({
          campaignId: campaign?.id,
          taskType: CLOUD_TASK_QUEUE.ZOOPLA,
        });
      } else if (queue === CLOUD_TASK_QUEUE.PLAN_IT_SEARCH) {
        await displayTasksPlanIt({
          campaignId: campaign?.id,
          taskType: CLOUD_TASK_QUEUE.PLAN_IT_SEARCH,
        });
      }
    } else {
      toast.loading("Wait for Campaign to Fully Load");
    }
  };

  const selectableCampaignLeads = campaignLeads.map(lead => ({ ...lead, isSelected: false }));
  const localAuthorities = getLocalAuthorities(campaignLeads);
  const [filteredLeads, setFilteredLeads] = useState(selectableCampaignLeads);
  const [selectedLead, setSelectedLead] = useState<number | null>(null);
  const [selectAll, setSelectAll] = useState<{ status: boolean; changedByItemToggle: boolean }>({
    status: false,
    changedByItemToggle: false,
  });
  const [selectedRadioOption, setSelectedRadioOption] = useState(addressRadioOptions[0]);

  const [activeEpcFilters, setActiveEpcFilters] = useState<{ label: string; value: string }[]>([]);
  const [activeOwnerCountFilters, setActiveOwnerCountFilters] = useState<
    { label: string; value: string }[]
  >([]);

  const [activeOwnerStatusFilters, setActiveOwnerStatusFilters] = useState<
    { label: string; value: string }[]
  >([]);
  const [activeEstateAgentFilters, setActiveEstateAgentFilters] = useState<
    { label: string; value?: string | null }[]
  >([]);
  const [activeMinPriceFilter, setMinPriceFilters] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [activeMaxPriceFilter, setMaxPriceFilters] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [activeMissingDataFilters, setActiveMissingDataFilters] = useState<
    { label: string; value: string }[]
  >([]);
  const [activeSearchesFilters, setActiveSearchesFilters] = useState<
    { label: string; value: string }[]
  >([]);
  const [activeOwnerPossessionFilters, setActiveOwnerPossesionFilters] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (selectAll.status === true && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => ({ ...lead, isSelected: true }));
      setFilteredLeads(updatedLeads);
    }

    if (selectAll.status === false && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => ({ ...lead, isSelected: false }));
      setFilteredLeads(updatedLeads);
    }
  }, [selectAll.status]);

  useEffect(() => {
    let updatedLeads = [...selectableCampaignLeads];

    // First filter by radio options
    switch (selectedRadioOption.id) {
      case ADDRESS_SHOW_TABS.MANUAL:
        updatedLeads = updatedLeads.filter(
          lead => lead.address_match_quality.id === ADDRESS_MATCH_QUALITY.MANUAL
        );
        break;
      case ADDRESS_SHOW_TABS.RISKY:
        updatedLeads = updatedLeads.filter(
          lead => lead.address_match_quality.id === ADDRESS_MATCH_QUALITY.RISKY
        );
        break;
      case ADDRESS_SHOW_TABS.GOOD:
        updatedLeads = updatedLeads.filter(
          lead => lead.address_match_quality.id === ADDRESS_MATCH_QUALITY.GOOD
        );
        break;
      case ADDRESS_SHOW_TABS.EXACT:
        updatedLeads = updatedLeads.filter(
          lead => lead.address_match_quality.id === ADDRESS_MATCH_QUALITY.EXACT
        );
        break;
      case ADDRESS_SHOW_TABS.DUPLICATES_INTERNAL:
        const addressIds = updatedLeads.map(l => l.address.id);
        updatedLeads = updatedLeads.filter(lead => {
          const firstIndex = addressIds.indexOf(lead.address.id);
          const lastIndex = addressIds.lastIndexOf(lead.address.id);
          return firstIndex !== lastIndex;
        });
        break;
      case ADDRESS_SHOW_TABS.DUPLICATES_EXTERNAL:
        updatedLeads = updatedLeads.filter(lead => {
          const count = lead.address?.campaign_leads_aggregate?.aggregate?.count;
          return count && count > 0;
        });
        break;
      case ADDRESS_SHOW_TABS.VALIDATED:
        updatedLeads = updatedLeads.filter(lead => lead.is_address_validated === true);
        break;
      case ADDRESS_SHOW_TABS.NOT_VALIDATED:
        updatedLeads = updatedLeads.filter(lead => lead.is_address_validated === false);
        break;
      default:
        break;
    }

    if (
      activeEpcFilters.length > 0 ||
      activeOwnerCountFilters.length > 0 ||
      activeOwnerStatusFilters.length ||
      activeOwnerPossessionFilters.length > 0 ||
      activeEstateAgentFilters.length > 0 ||
      activeSearchesFilters.length > 0 ||
      activeMissingDataFilters.length > 0 ||
      activeMinPriceFilter ||
      activeMaxPriceFilter
    ) {
      updatedLeads = updatedLeads.filter(lead => {
        let epcMatch = true;
        let ownerCountMatch = true;
        let ownerSourceMatch = true;
        let ownerStatusMatch = true;
        let ownerPossessionMatch = true;
        let searchesMatch = true;
        let estateAgentMatch = true;
        let missingDataMatch = true;
        let minPriceMatch = true;
        let maxPriceMatch = true;

        if (activeEpcFilters.length > 0) {
          const tenure = lead?.address?.additional_info?.epc?.tenure;
          epcMatch =
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.OWNER_OCCUPIED) &&
              epcStatuses.ownerOccupied.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.RENTED_SOCIAL) &&
              epcStatuses.rentedSocial.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.RENTED_PRIVATE) &&
              epcStatuses.rentedPrivate.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.UNDEFINED) &&
              epcStatuses.notDefined.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.NOT_AVAILABLE) &&
              !tenure);
        }

        if (activeMinPriceFilter) {
          const price = lead.one_line_price;
          minPriceMatch = price ? price >= activeMinPriceFilter.value : false;
        }

        if (activeMaxPriceFilter) {
          const price = lead.one_line_price;
          maxPriceMatch = price ? price <= activeMaxPriceFilter.value : false;
        }

        if (activeMissingDataFilters.length > 0) {
          const estateAgent = lead.agent;
          const price = lead.one_line_price;
          missingDataMatch =
            (activeMissingDataFilters.some(filter => filter.value === MISSING_DATA.NO_AGENT) &&
              !estateAgent) ||
            (activeMissingDataFilters.some(filter => filter.value === MISSING_DATA.NO_PRICE) &&
              (!price || typeof price !== "number"));
        }

        if (activeEstateAgentFilters.length > 0) {
          estateAgentMatch = activeEstateAgentFilters.some(filter => {
            return lead.agent === filter.value;
          });
        }

        if (activeOwnerCountFilters.length > 0) {
          const ownerCount = lead.address.suspected_owners.length;
          ownerCountMatch =
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.NONE) &&
              ownerCount === 0) ||
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.ONE) &&
              ownerCount === 1) ||
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.TWO_OR_MORE) &&
              ownerCount >= 2);
        }

        if (activeOwnerStatusFilters.length > 0) {
          const ownerStatuses = lead.address.suspected_owners.map(
            owner => owner.suspected_owner_status.id
          );
          ownerStatusMatch =
            (activeOwnerStatusFilters.some(
              filter => filter.value === OWNER_STATUS.ZERO_CONTENDER_OR_VERIFIED
            ) &&
              !ownerStatuses.includes(SUSPECTED_OWNER_STATUS.CONTENDER) &&
              !ownerStatuses.includes(SUSPECTED_OWNER_STATUS.VERIFIED)) ||
            (activeOwnerStatusFilters.some(
              filter => filter.value === OWNER_STATUS.ONE_CONTENDER_OR_VERIFIED
            ) &&
              ownerStatuses.filter(
                status =>
                  status === SUSPECTED_OWNER_STATUS.CONTENDER ||
                  status === SUSPECTED_OWNER_STATUS.VERIFIED
              ).length === 1) ||
            (activeOwnerStatusFilters.some(
              filter => filter.value === OWNER_STATUS.MORE_THAN_ONE_CONTENDER_OR_VERIFIED
            ) &&
              ownerStatuses.filter(
                status =>
                  status === SUSPECTED_OWNER_STATUS.CONTENDER ||
                  status === SUSPECTED_OWNER_STATUS.VERIFIED
              ).length > 1);
        }

        if (activeOwnerPossessionFilters.length > 0) {
          const ownerSources = lead.address.suspected_owners.map(
            owner => owner.ownership_source.id
          );
          ownerPossessionMatch = activeOwnerPossessionFilters.some(filter => {
            switch (filter.value) {
              case OWNER_POSSESSION.HAS_ELECTORAL_ROLL:
                return ownerSources.includes(OWNERSHIP_SOURCE.ELECTORAL_ROLL);
              case OWNER_POSSESSION.HAS_PLANNING_APPS:
                return ownerSources.includes(OWNERSHIP_SOURCE.PLANNING_APPLICATION);
              case OWNER_POSSESSION.HAS_COMPANIES_HOUSE:
                return ownerSources.includes(OWNERSHIP_SOURCE.COMPANIES_HOUSE);
              case OWNER_POSSESSION.HAS_LAND_REGISTRY:
                return ownerSources.includes(OWNERSHIP_SOURCE.LAND_REGISTRY);
              case OWNER_POSSESSION.HAS_USER_PROVIDED:
                return ownerSources.includes(OWNERSHIP_SOURCE.USER_PROVIDED);
              case OWNER_POSSESSION.DOES_NOT_HAVE_ELECTORAL_ROLL:
                return !ownerSources.includes(OWNERSHIP_SOURCE.ELECTORAL_ROLL);
              case OWNER_POSSESSION.DOES_NOT_HAVE_PLANNING_APPS:
                return !ownerSources.includes(OWNERSHIP_SOURCE.PLANNING_APPLICATION);
              case OWNER_POSSESSION.DOES_NOT_HAVE_COMPANIES_HOUSE:
                return !ownerSources.includes(OWNERSHIP_SOURCE.COMPANIES_HOUSE);
              case OWNER_POSSESSION.DOES_NOT_HAVE_LAND_REGISTRY:
                return !ownerSources.includes(OWNERSHIP_SOURCE.LAND_REGISTRY);
              case OWNER_POSSESSION.DOES_NOT_HAVE_USER_PROVIDED:
                return !ownerSources.includes(OWNERSHIP_SOURCE.USER_PROVIDED);
              default:
                return false;
            }
          });
        }
        if (activeSearchesFilters.length > 0) {
          ownerPossessionMatch = activeSearchesFilters.some(filter => {
            switch (filter.value) {
              case ADDRESS_SEARCH.PLANNING_APP_SEARCHED:
                return lead.address.last_pa_search;
              case ADDRESS_SEARCH.COMPANIES_HOUSE_SEARCHED:
                return lead.address.last_cho_search;
              case ADDRESS_SEARCH.ELECTORAL_ROLL_SEARCHED:
                return lead.address.last_er_search;
              case ADDRESS_SEARCH.PLANNING_APP_NOT_SEARCHED:
                return !lead.address.last_pa_search;
              case ADDRESS_SEARCH.COMPANIES_HOUSE_NOT_SEARCHED:
                return !lead.address.last_cho_search;
              case ADDRESS_SEARCH.ELECTORAL_ROLL_NOT_SEARCHED:
                return !lead.address.last_er_search;
              case ADDRESS_SEARCH.ADDRESS_MATCHER_NOT_SEARCHED:
                return !lead.last_address_match_attempt;
              case ADDRESS_SEARCH.ADDRESS_MATCHER_SEARCHED:
                return lead.last_address_match_attempt;
              case ADDRESS_SEARCH.EPC_NOT_SEARCHED:
                return !lead.address.last_epc_search;
              case ADDRESS_SEARCH.EPC_SEARCHED:
                return lead.address.last_epc_search;
              case ADDRESS_SEARCH.LAST_TRANSACTION_NOT_SEARCHED:
                return !lead.address.last_lt_search;
              case ADDRESS_SEARCH.LAST_TRANSACTION_SEARCHED:
                return lead.address.last_lt_search;

              default:
                return false;
            }
          });
        }

        return (
          epcMatch &&
          ownerCountMatch &&
          ownerSourceMatch &&
          ownerStatusMatch &&
          ownerPossessionMatch &&
          searchesMatch &&
          estateAgentMatch &&
          missingDataMatch &&
          minPriceMatch &&
          maxPriceMatch
        );
      });
    }
    // Handle the selectAll logic
    if (selectAll.status === true) {
      updatedLeads = updatedLeads.map(lead => ({ ...lead, isSelected: true }));
    }

    setFilteredLeads(updatedLeads);
  }, [
    activeEpcFilters,
    activeOwnerCountFilters,
    activeSearchesFilters,
    activeOwnerStatusFilters,
    activeOwnerPossessionFilters,
    activeSearchesFilters,
    activeEstateAgentFilters,
    activeMissingDataFilters,
    activeMinPriceFilter,
    activeMaxPriceFilter,
    selectedRadioOption,
    campaignLeads,
    campaign,
  ]);

  // This effect runs whenever selectAll state changes

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateMatchedAddressValues>({
    resolver: yupResolver(updateMatchedAddressValidationSchema),
  });

  const onSubmit = async ({ address }: UpdateMatchedAddressValues) => {
    if (!selectedLead) {
      throw new Error("Missing lead");
    }
    await campaignLeadUpdater(selectedLead, address.value);
    setSelectedLead(null);
  };

  const editLead = async (lead: CampaignLead) => {
    setSelectedLead(lead.id);
    await getPostcodeUnitById({ variables: { id: lead.address.postcode_unit.id } });
  };

  const handleIndividualChange = (id: number) => {
    // Determine the future state of the item being toggled
    const itemWillBeSelected = !filteredLeads.find(lead => lead.id === id)?.isSelected;

    // If the item is about to be selected, check if this will cause all items to be selected
    if (itemWillBeSelected) {
      const allOthersSelected = filteredLeads.every(lead => lead.id === id || lead.isSelected);
      if (allOthersSelected) {
        setSelectAll({ status: true, changedByItemToggle: true });
      }
    }
    // If the item is about to be unselected and selectAll is currently true, update selectAll
    else if (selectAll.status) {
      setSelectAll({ status: false, changedByItemToggle: true });
    }

    // Update the leads
    setFilteredLeads(
      filteredLeads.map(lead =>
        lead.id === id ? { ...lead, isSelected: itemWillBeSelected } : lead
      )
    );
  };

  const exportToCSV = () => {
    // Prepare the data
    const data = generateAddressCSVData(campaignLeads);
    const csv = Papa.unparse(data);

    // Trigger download
    const blob = new Blob(["\ufeff" + csv], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "addresses.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <div className="flex w-full">
        <Callout
          title="Is the address matched to the right address in our Database?"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>
            1. Click "Auto-Match Addresses" to match incoming campaign addresses to our property
            database
          </div>
          <div>
            2. Click the refresh icon next to "Address Matcher Queue" to see how many addresses left
            to be processed.
          </div>
          <div>
            3. When all addresses are processed, click on the "unvalidated" tab and turn on the
            toggle "Bulk Address Matching" to begin manually match remaining addresses.
          </div>
          <div>
            4. For those addresses that are already matched correct yet still unvalidated - do
            nothing.
          </div>

          <div>
            5. For those wrongly matched addresses, re-assign them to the correct address. You can
            change postcode unit by clicking on "change postcode"
          </div>
          <div>
            6. if you are unable to find a correct match, simply click the "exclude" checkbox for
            that address so it is not validated when you click "Validate Addresses"
          </div>
          <div>7. For those addresses you were unable to validate, delete them.</div>
          <div>
            8. After matching: Delete Duplicates INTERNAL + External to the campaign (only do after
            matching so you dont accidentally delete non-duplicates)
          </div>
          <div>9. Pull EPC's for all addresses</div>

          <div>10. Pull Last Transaction's for all addresses</div>

          <div>11. Pull Companies House owners for all properties</div>
          <div>12. Pull Planning application owners for all properties</div>
          <div>
            13. Following owner cleaning and matching - you may return here to pull electoral roll
            owners for those addresses you wish using the filters to narrow down.
          </div>
        </Callout>
      </div>
      <div className="mt-5">
        {getOwnersGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              getOwnersGroups[0].is_cancelled === true
                ? "red"
                : getOwnersGroups[0].is_completed === true &&
                  getOwnersGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              getOwnersGroups[0].is_cancelled === true
                ? "Cancelled"
                : getOwnersGroups[0].is_completed === true &&
                  getOwnersGroups[0].is_cancelled === false
                ? "Completed"
                : getOwnersGroups[0].is_error === true
                ? "In Error"
                : getOwnersGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = getOwnersGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = getOwnersGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = getOwnersGroups.slice(1).map(group => {
              const completedTrackingItemsCount = getOwnersGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Owners Fetch ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(getOwnersGroups[0].is_error || getOwnersGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: getOwnersGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!campaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: getOwnersGroups[0].id,
                              campaignId: campaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.GET_OWNERS,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!getOwnersGroups[0].is_error &&
                      !getOwnersGroups[0].is_paused &&
                      !getOwnersGroups[0].is_cancelled &&
                      !getOwnersGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: getOwnersGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} items completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Triggered:{" "}
                  <Bold>{dayjs(getOwnersGroups[0].created_at).format("DD/MM/YYYY mm:ss")}</Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text> Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Requests"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>
      <div className="mt-5">
        {cleanAddressesGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              cleanAddressesGroups[0].is_cancelled === true
                ? "red"
                : cleanAddressesGroups[0].is_completed === true &&
                  cleanAddressesGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              cleanAddressesGroups[0].is_cancelled === true
                ? "Cancelled"
                : cleanAddressesGroups[0].is_completed === true &&
                  cleanAddressesGroups[0].is_cancelled === false
                ? "Completed"
                : cleanAddressesGroups[0].is_error === true
                ? "In Error"
                : cleanAddressesGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = cleanAddressesGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = cleanAddressesGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = cleanAddressesGroups.slice(1).map(group => {
              const completedTrackingItemsCount = cleanAddressesGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Addresses Clean ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(cleanAddressesGroups[0].is_error || cleanAddressesGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: cleanAddressesGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!campaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: cleanAddressesGroups[0].id,
                              campaignId: campaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.CLEAN_ADDRESSES,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!cleanAddressesGroups[0].is_error &&
                      !cleanAddressesGroups[0].is_paused &&
                      !cleanAddressesGroups[0].is_cancelled &&
                      !cleanAddressesGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: cleanAddressesGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} items completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Triggered:{" "}
                  <Bold>
                    {dayjs(cleanAddressesGroups[0].created_at).format("DD/MM/YYYY mm:ss")}
                  </Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text> Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Requests"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>
      <div className="flex flex-row justify-between  space-x-2">
        <Card className="max-w-xs mt-6   " decoration="top" decorationColor="orange">
          <Text>Not Validated Addresses</Text>
          <Metric>
            {campaignLeads.filter(lead => lead.is_address_validated === false).length} /{" "}
            {campaignLeads.length}
          </Metric>
        </Card>
        <Card className="max-w-xs mt-6   " decoration="top" decorationColor="orange">
          <Text>Planning Application Availability</Text>
          <List>
            {localAuthorities.map(localAuthority => (
              <ListItem key={localAuthority.id}>
                <Flex justifyContent="start" className="truncate space-x-4">
                  <Icon
                    variant="light"
                    icon={localAuthority.hasSearchEnabled ? CheckCircleIcon : XCircleIcon}
                    size="md"
                    color={localAuthority.hasSearchEnabled ? "green" : "red"}
                  />
                  <div className="truncate">
                    <Text className="truncate">
                      <Bold>{localAuthority.name}</Bold>
                    </Text>
                    <Text className="truncate">{`${localAuthority.count} / ${campaignLeads.length} Addresses`}</Text>
                  </div>
                </Flex>
              </ListItem>
            ))}
          </List>
        </Card>
        <Card className="max-w-xs mt-6  " decoration="top" decorationColor="orange">
          <Text>Addresses with EPC</Text>
          <Metric>
            {campaignLeads.filter(lead => lead.address?.additional_info?.epc).length} /{" "}
            {campaignLeads.length}
          </Metric>
        </Card>
      </div>
      <div className="bg-gray-100 rounded-lg py-4 px-4 mt-4 w-full">
        <Card className="w-full flex flex-row justify-between items-center w-full  ">
          <div>
            <Text>Address Matcher Queue</Text>
            <div className="flex flex-row items-baseline space-x-2">
              {displayTasksAddressLoading ? <Spinner /> : <Metric>{addressMatcherTasks}</Metric>}
              <Text className="text-xs">Addresses </Text>
            </div>
          </div>

          <button
            disabled={displayTasksAddressLoading}
            onClick={() => displayTasks(CLOUD_TASK_QUEUE.MATCH_ADDRESS)}
          >
            <Flex justifyContent="start" className="space-x-4">
              <Icon variant="outlined" icon={ArrowPathIcon} size="sm" color={"gray"} />
            </Flex>
          </button>
        </Card>
      </div>
      <div className="bg-gray-100 rounded-lg py-4 px-4 mt-4 w-full">
        <Card className="w-full flex flex-row justify-between items-center w-full  ">
          <div>
            <Text>Manual Planning Scraping Queue</Text>
            <div className="flex flex-row items-baseline space-x-2">
              {displayTasksPlanningLoading ? <Spinner /> : <Metric>{planningAppTasks}</Metric>}
              <Text className="text-xs"> Addresses </Text>
            </div>
          </div>

          <button
            disabled={displayTasksPlanningLoading}
            onClick={() => displayTasks(CLOUD_TASK_QUEUE.PLANNING_APP_SEARCH)}
          >
            <Flex justifyContent="start" className="space-x-4">
              <Icon variant="outlined" icon={ArrowPathIcon} size="sm" color={"gray"} />
            </Flex>
          </button>
        </Card>
      </div>
      <div className="bg-gray-100 rounded-lg py-4 px-4 mt-4 w-full">
        <Card className="w-full flex flex-row justify-between items-center w-full  ">
          <div>
            <Text>Zoopla Queue</Text>
            <div className="flex flex-row items-baseline space-x-2">
              {displayTasksZooplaLoading ? <Spinner /> : <Metric>{zooplaAppTasks}</Metric>}
              <Text className="text-xs"> Addresses </Text>
            </div>
          </div>

          <button
            disabled={displayTasksZooplaLoading}
            onClick={() => displayTasks(CLOUD_TASK_QUEUE.ZOOPLA)}
          >
            <Flex justifyContent="start" className="space-x-4">
              <Icon variant="outlined" icon={ArrowPathIcon} size="sm" color={"gray"} />
            </Flex>
          </button>
        </Card>
      </div>
      <div className="bg-gray-100 rounded-lg py-4 px-4 mt-4 w-full">
        <Card className="w-full flex flex-row justify-between items-center w-full  ">
          <div>
            <Text>Companies House Queue</Text>
            <div className="flex flex-row items-baseline space-x-2">
              {displayTasksCompaniesHouseLoading ? (
                <Spinner />
              ) : (
                <Metric>{companiesHouseTasks}</Metric>
              )}
              <Text className="text-xs"> Addresses </Text>
            </div>
          </div>

          <button
            disabled={displayTasksCompaniesHouseLoading}
            onClick={() => displayTasks(CLOUD_TASK_QUEUE.COMPANIES_HOUSE_SEARCH)}
          >
            <Flex justifyContent="start" className="space-x-4">
              <Icon variant="outlined" icon={ArrowPathIcon} size="sm" color={"gray"} />
            </Flex>
          </button>
        </Card>
      </div>
      <div className="bg-gray-100 rounded-lg py-4 px-4 mt-4 w-full">
        <Card className="w-full flex flex-row justify-between items-center w-full  ">
          <div>
            <Text>Andrew's Plan It Queue</Text>
            <div className="flex flex-row items-baseline space-x-2">
              {displayTasksPlanItLoading ? <Spinner /> : <Metric>{planItTasks}</Metric>}
              <Text className="text-xs"> Addresses </Text>
            </div>
          </div>

          <button
            disabled={displayTasksPlanItLoading}
            onClick={() => displayTasks(CLOUD_TASK_QUEUE.PLAN_IT_SEARCH)}
          >
            <Flex justifyContent="start" className="space-x-4">
              <Icon variant="outlined" icon={ArrowPathIcon} size="sm" color={"gray"} />
            </Flex>
          </button>
        </Card>
      </div>
      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  checked={selectAll.status}
                  onChange={() =>
                    setSelectAll(selectAll => ({
                      status: !selectAll.status,
                      changedByItemToggle: false,
                    }))
                  }
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All ({filteredLeads.filter(lead => lead.isSelected).length})
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all Addresses that you can currently see
                </p>
              </div>
            </div>
            <div className="flex flex-row space-x-10">
              {/* <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={isRemovingExternalDuplicates}
                  onChange={setIsRemovingExternalDuplicates}
                  className={classNames(
                    isRemovingExternalDuplicates ? "bg-orange-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isRemovingExternalDuplicates ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Rule Based De-Duplication</span>
                </Switch.Label>
              </Switch.Group> */}
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={isBulkMatching}
                  onChange={setIsBulkMatching}
                  className={classNames(
                    isBulkMatching ? "bg-orange-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isBulkMatching ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Bulk Address Matching</span>
                </Switch.Label>
              </Switch.Group>
              <Dropdown
                items={[
                  {
                    icon: AdjustmentsVerticalIcon,
                    name: "Validate Addresses",
                    function: async () => {
                      const leadIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => ({
                          campaignLeadId: lead.id,
                          addressId: lead.address.id,
                          addressMatchQualityId: ADDRESS_MATCH_QUALITY.MANUAL,
                        }));

                      if (leadIds.length === 0) {
                        toast.error("Please select addresses to perform operations on");
                        return;
                      }
                      if (!campaign?.id) {
                        toast.error("Missing Campaign Id");
                        return;
                      }

                      await changeMatchQuality({ campaignId: campaign.id, campaignLeads: leadIds });
                    },
                  },

                  {
                    icon: BoltIcon,
                    name: "Pull EPC",
                    function: async () => {
                      const addressIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => lead.address.id);
                      if (addressIds.length === 0) {
                        toast.error("Please select addresses to perform operations on");
                        return;
                      }
                      await epcPuller(addressIds);
                    },
                  },

                  {
                    icon: ReceiptRefundIcon,
                    name: "Auto-Match Addresses",
                    function: async () => {
                      const leadIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => lead.id);
                      if (leadIds.length === 0) {
                        toast.error("Please select addresses to perform operations on");
                        return;
                      }
                      if (!campaign?.id) {
                        toast.error("Missing Campaign Id");
                        return;
                      }

                      await matchAddresses({ campaignId: campaign.id, campaignLeadIds: leadIds });
                    },
                  },
                  {
                    icon: ReceiptRefundIcon,
                    name: "Get Transactions",
                    function: async () => {
                      const addressIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => lead.address.id);
                      if (addressIds.length === 0) {
                        toast.error("Please select addresses to perform operations on");
                        return;
                      }
                      await getLastTransaction({ addressIds: addressIds });
                    },
                  },
                  {
                    icon: BriefcaseIcon,
                    name: "Search Companies House",
                    function: async () => {
                      const leadIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => lead.id);
                      if (leadIds.length === 0) {
                        toast.error("Please select leads to perform operations on");
                        return;
                      }

                      if (!campaign?.id) {
                        toast.error("Missing Campaign Id");
                        return;
                      }

                      await companiesHousePuller(campaign.id, leadIds);
                    },
                  },

                  {
                    icon: HomeIcon,
                    name: "Search Planning Apps",
                    function: async () => {
                      const leadIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => lead.id);
                      if (leadIds.length === 0) {
                        toast.error("Please select leads to perform operations on");
                        return;
                      }

                      if (!campaign?.id) {
                        toast.error("Missing Campaign Id");
                        return;
                      }

                      await planningApplicationPuller(campaign.id, leadIds);
                    },
                  },
                  {
                    icon: HomeIcon,
                    name: "Search Electoral Roll",
                    function: async () => {
                      const leadIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => lead.id);
                      if (leadIds.length === 0) {
                        toast.error("Please select leads to perform operations on");
                        return;
                      }

                      if (!campaign?.id) {
                        toast.error("Missing Campaign Id");
                        return;
                      }

                      await electoralRollPuller({
                        campaignId: campaign.id,
                        campaignLeadIds: leadIds,
                      });
                    },
                  },
                  {
                    icon: SwatchIcon,
                    name: "Clean Addresses",
                    function: async () => {
                      const leadIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => String(lead.id));

                      if (leadIds.length === 0) {
                        toast.error("Please select leads to perform operations on");
                        return;
                      }

                      if (!campaign) {
                        toast.error("Unexpected Error, Missing Campaign Id");
                        return;
                      }

                      triggerTrackingGroup({
                        campaignId: campaign.id,
                        trackingGroupTypeId: TRACKING_GROUP_TYPE.CLEAN_ADDRESSES,
                        trackingItemValues: leadIds,
                      });
                    },
                  },
                  {
                    icon: SwatchIcon,
                    name: "Get Owners",
                    function: async () => {
                      const leadIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => String(lead.id));

                      if (leadIds.length === 0) {
                        toast.error("Please select leads to perform operations on");
                        return;
                      }

                      if (!campaign) {
                        toast.error("Unexpected Error, Missing Campaign Id");
                        return;
                      }

                      triggerTrackingGroup({
                        campaignId: campaign.id,
                        trackingGroupTypeId: TRACKING_GROUP_TYPE.GET_OWNERS,
                        trackingItemValues: leadIds,
                      });
                    },
                  },
                  {
                    icon: TrashIcon,
                    name: "Delete",
                    function: async () => {
                      const leadIds = filteredLeads
                        .filter(lead => lead.isSelected)
                        .map(lead => lead.id);
                      if (leadIds.length === 0) {
                        toast.error("Please select addresses to perform operations on");
                        return;
                      }
                      await bulkCampaignLeadDeleter(leadIds, new Date().toISOString());
                    },
                  },
                  {
                    icon: ShareIcon,
                    name: "Export",
                    function: async () => {
                      exportToCSV();
                    },
                  },
                ]}
              />
            </div>
          </div>
          <Divider className="mt-1 mb-0" />
          <div className="flex p-4 justify-center w-full items-center space-x-4">
            <Text>Displaying</Text>
            <Metric className="text-gray-500">{filteredLeads.length}</Metric>
            <Text> / {selectableCampaignLeads.length} Addresses </Text>
          </div>
          <Divider className="mt-1 mb-0" />

          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 mb-2">EPC:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="epc"
                options={addressTabFilters.epc.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveEpcFilters(options);
                }}
                placeholder="Filter By EPC"
              />
            </div>
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Number of Owners:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerCount"
                options={addressTabFilters.ownerCount.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveOwnerCountFilters(options);
                }}
                placeholder="Filter by Owner Count"
              />
            </div>
          </div>
          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Status of Owners:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerStatus"
                options={addressTabFilters.ownerStatus.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveOwnerStatusFilters(options);
                }}
                placeholder="Filter by Owner Status"
              />
            </div>
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Owners Found:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownersFound"
                options={addressTabFilters.ownerPossession.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveOwnerPossesionFilters(options);
                }}
                placeholder="Filter by Owners Found"
              />
            </div>
          </div>
          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 ">Minimum Price:</h2>
              <p className="text-xs font-normal text-gray-700 mb-2">
                Filters remove addresses where price is unknown
              </p>
              <Select
                styles={filtersStyling}
                name="minPrice"
                isClearable
                options={priceOptions.map(option => ({
                  label: option.label,
                  value: option.value,
                }))}
                onChange={selectedOption => {
                  setMinPriceFilters(selectedOption);
                }}
                placeholder="Filter by Min Price"
              />
            </div>
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 ">Maximum Price:</h2>
              <p className="text-xs font-normal text-gray-700 mb-2">
                Filters remove addresses where price is unknown
              </p>
              <Select
                styles={filtersStyling}
                name="maxPrice"
                isClearable
                options={priceOptions.map(option => ({
                  label: option.label,
                  value: option.value,
                }))}
                onChange={selectedOption => {
                  setMaxPriceFilters(selectedOption);
                }}
                placeholder="Filter by Max Price"
              />
            </div>
          </div>
          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Searches Conducted:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="searchesConducted"
                options={addressTabFilters.searches.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveSearchesFilters(options);
                }}
                placeholder="Filter by Searches Made"
              />
            </div>
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Missing Data:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="missingData"
                options={addressTabFilters.missing.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveMissingDataFilters(options);
                }}
                placeholder="Filter by Missing Data"
              />
            </div>
            <div className="py-4 px-4 w-full">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Estate Agent:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="estateAgent"
                options={estateAgentOptions}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveEstateAgentFilters(options);
                }}
                placeholder="Filter by Estate Agent"
              />
            </div>
          </div>
          <Divider className="mt-1 mb-0" />

          <div className="py-4 px-4">
            <div className="flex items-center justify-between">
              <h2 className="text-sm font-medium text-gray-900">Show Only:</h2>
            </div>
            <RadioGroup
              value={selectedRadioOption}
              onChange={setSelectedRadioOption}
              className="mt-2"
            >
              <div className="flex flex-row justify-between space-x-6">
                {addressRadioOptions.map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium  sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>

      {isRemovingExternalDuplicates && <>Removing External Duplicates</>}
      {isBulkMatching && (
        <BulkAddressMatcher
          changeMatchQualityLoading={changeMatchQualityLoading}
          campaignId={campaign?.id}
          changeMatchQuality={changeMatchQuality}
          activeShowTabId={selectedRadioOption.id}
          postcodeUnits={postcodeUnits}
          getLazyPostcodeUnitsByCode={getLazyPostcodeUnitsByCode}
          postcodeUnitsLoading={postcodeUnitsLoading}
          filteredCampaignLeads={filteredLeads}
          addressesLoading={addressesLoading}
          aggregatedAddresses={aggregatedAddresses}
          getLazyAddressesByPostcodeUnitIds={getLazyAddressesByPostcodeUnitIds}
        />
      )}
      {!isBulkMatching && !isRemovingExternalDuplicates && (
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
          {filteredLeads.map(lead => {
            const isDuplicate = isAddressDuplicate(lead.address.full_address, campaignLeads);

            return (
              <>
                <Card
                  className={classNames(
                    isDuplicate || lead.is_address_validated === false
                      ? "border border-red-500 bg-red-50"
                      : "",
                    "max-w-md mx-auto "
                  )}
                >
                  <Flex className="mb-2">
                    {epcStatuses.ownerOccupied.includes(
                      lead.address.additional_info?.epc?.tenure
                    ) ? (
                      <Text color="green">
                        <Bold>Owner Occupied</Bold>
                      </Text>
                    ) : (
                      <Text color="rose">
                        <Bold>
                          {lead.address.additional_info?.epc?.tenure
                            ? lead.address.additional_info?.epc?.tenure
                            : "No EPC Found"}
                        </Bold>
                      </Text>
                    )}

                    {lead.is_address_validated === false && isDuplicate ? (
                      <Text color="red">
                        <Bold>Not Validated & Duplicate</Bold>
                      </Text>
                    ) : lead.is_address_validated === false ? (
                      <Text color="red">
                        <Bold>Not Validated</Bold>
                      </Text>
                    ) : isDuplicate ? (
                      <Text color="red">
                        <Bold>Duplicate</Bold>
                      </Text>
                    ) : null}

                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          type="checkbox"
                          checked={lead.isSelected}
                          onChange={() => handleIndividualChange(lead.id)}
                          className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                      </div>
                    </div>
                  </Flex>

                  <Flex justifyContent="start" className="space-x-1" alignItems="baseline">
                    <Metric>{lead.stated_address}</Metric>
                  </Flex>

                  <Card className="mt-5">
                    <Flex>
                      <Text className="font-bold">
                        Matched Address (LA: {lead.address.postcode_unit.local_authority.name})
                      </Text>
                    </Flex>
                    <Text>{lead.address.full_address}</Text>
                  </Card>

                  <Card className="mt-5 flex  w-full flex-wrap space-y-4 justify-between">
                    <div className="flex w-full flex-wrap justify-between ">
                      <Badge color="gray" icon={HomeModernIcon}>
                        {lead.status}
                      </Badge>
                    </div>
                    <div className="flex w-full flex-wrap justify-between ">
                      <Badge color="gray" icon={CurrencyPoundIcon}>
                        {lead.one_line_price
                          ? currencyFormatter.format(lead.one_line_price)
                          : "Price Unknown"}
                      </Badge>
                      <Badge color="gray" icon={UserIcon}>
                        {lead.agent ?? "Agent Unknown"}
                      </Badge>
                    </div>

                    <div className="flex space-y-4  w-full flex-wrap justify-between">
                      <Badge color="gray" icon={MagnifyingGlassCircleIcon}>
                        {lead.prospecting_tool_provider_id
                          ? PROSPECTING_TOOL_PROVIDER[lead.prospecting_tool_provider_id]
                          : "Provider Unknown"}
                      </Badge>
                      <Badge color="gray" icon={LinkIcon}>
                        {lead.public_url ?? "Listing Url Unavailable"}
                      </Badge>
                    </div>
                  </Card>

                  {/* <div className="flex flex-wrap items-center justify-center gap-6">
                    <Badge icon={CurrencyPoundIcon}>live</Badge>
                  </div> */}
                  {lead.address.property_sales.length > 0 && (
                    <Card className="mt-5">
                      <>
                        <Flex className="" justifyContent="between">
                          <Text className="truncate">
                            <Bold>Transaction Date</Bold>
                          </Text>
                          <Text>Price</Text>
                        </Flex>
                        <List className="mt-2">
                          {lead.address.property_sales.map(sale => (
                            <ListItem className="flex flex-col" key={sale.id}>
                              <Flex flexDirection="row">
                                <a
                                  href={`http://landregistry.data.gov.uk/data/ppi/transaction/${sale.external_id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="rounded text-xs bg-white px-1.5 py-1 text-xs font-semibold cursor-pointer text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                  {truncate(sale.stated_address, {
                                    length: 30,
                                    separator: /,? +/,
                                    omission: "...",
                                  })}
                                </a>

                                <Flex justifyContent="end" className="space-x-2">
                                  <p className="text-xs">
                                    {dayjs(sale.sale_date).format("DD/MM/YYYY")}
                                  </p>

                                  <Badge color="green" size="xs">
                                    <p className="text-xs">
                                      {" "}
                                      £{" "}
                                      {Intl.NumberFormat("GBP")
                                        .format(sale.sale_price)
                                        .toString()}{" "}
                                    </p>
                                  </Badge>
                                </Flex>
                              </Flex>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    </Card>
                  )}

                  <ProgressBar value={lead.address_match_score} color="orange" className="mt-6" />

                  <Flex className="mt-4">
                    <div>
                      <Text>Match Score</Text>
                      <Text color="orange">
                        <Bold>{lead.address_match_score}</Bold>
                      </Text>
                    </div>
                    <div>
                      <Text className="text-right">Match Quality</Text>
                      <Text className="text-right">
                        <Bold>{lead.address_match_quality.name}</Bold>
                      </Text>
                    </div>
                  </Flex>

                  {lead.id !== selectedLead && (
                    <Flex className="mt-6 pt-4 border-t">
                      <Button
                        type="button"
                        onClick={() => {
                          editLead(lead);
                        }}
                        size="xs"
                        variant="light"
                        icon={ArrowRightIcon}
                        iconPosition="right"
                      >
                        Edit Matched Address
                      </Button>
                    </Flex>
                  )}

                  {lead.id === selectedLead && (
                    <Flex className="mt-6 pt-4 w-full border-t">
                      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                        <label className="block text-sm font-medium mb-2 text-gray-700">
                          New Address (within {postcodeUnit?.code})
                        </label>

                        <Controller
                          name="address"
                          control={control}
                          defaultValue={{
                            value: lead.address.id,
                            label: lead.address.full_address,
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={reactSelectStyling}
                              maxMenuHeight={220}
                              options={postcodeUnit?.addresses.map(address => {
                                return {
                                  label: address.full_address,
                                  value: address.id,
                                };
                              })}
                              isSearchable
                              placeholder="Select Address"
                            />
                          )}
                        />
                        <div className="flex mt-4 space-x-8 ">
                          <button
                            type="button"
                            className="rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                            onClick={() => setSelectedLead(null)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className=" w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Save
                          </button>
                        </div>
                        {errors.address?.message && (
                          <ErrorMessage>{errors.address.message}</ErrorMessage>
                        )}
                      </form>
                    </Flex>
                  )}
                </Card>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};
