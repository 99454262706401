import { ApolloError, useLazyQuery } from "@apollo/client";
import { ArrayElement } from "../../../../../shared/internals/utils";
import {
  GetPostcodeUnitsByCodeQuery,
  GetPostcodeUnitsByCodeQueryVariables,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { getPostcodeUnitsByCode } from "../queries/getPostcodeUnitsByCode";

export type useLazyPostcodeUnitByCodeReturnType = ReturnType<typeof useLazyPostcodeUnitByCode>;
export const useLazyPostcodeUnitByCode = () => {
  const [getPostcodeUnitByCode, { data, error, loading }] = useLazyQuery<
    GetPostcodeUnitsByCodeQuery,
    GetPostcodeUnitsByCodeQueryVariables
  >(getPostcodeUnitsByCode);

  const getLazyPostcodeUnitsByCode = async ({ limit, code }: { code: string; limit: number }) => {
    const response = await getPostcodeUnitByCode({
      variables: { limit, code: code.toUpperCase() + "%" },
    });

    return response.data ? response.data.postcode_unit : [];
  };

  return {
    postcodeUnits: data && Array.isArray(data.postcode_unit) ? data.postcode_unit : [],
    postcodeUnitsError: error,
    postcodeUnitsLoading: loading,
    getLazyPostcodeUnitsByCode: getLazyPostcodeUnitsByCode,
  };
};
