import { ReactElement } from "react";
import SignIn from "../../../modules/authentication/signIn/page/SignIn";
import NotFound from "../../../modules/notFound/notFound/page/NotFound";
import Confirmation from "../../../modules/authentication/completion/page/Confirmation";
import SingleCampaign from "../../../modules/leadsAdmin/singleCampaign/page/SingleCampaign";
import { SubscriptionProduct, SubscriptionStatusClaim } from "../../infrastructure/firebase/types";
import ForgotPassword from "../../../modules/authentication/forgotPassword/page/ForgotPass";
import Register from "../../../modules/authentication/register/page/Register";
import ConfirmMagicLink from "../../../modules/authentication/confirmMagicLink/page/ConfirmMagicLink";
import SignInWithMagicLink from "../../../modules/authentication/signInWithMagicLink/page/SignInWithMagicLink";
import SingleUSCampaign from "../../../modules/leadsAdmin/singleUSCampaign/page/SingleUSCampaign";

export interface Route {
  path: string;
  exact: boolean;
  main: (any: any) => ReactElement;
  isAdmin: boolean;
  isFreelancer: boolean;
  requiredSubscription?: { type: SubscriptionProduct; statuses: SubscriptionStatusClaim[] };
  redirectPath?: string;
}

// Routes that can be accessed anytime
export const publicRoutes: Route[] = [
  {
    path: "/not-found",
    main: NotFound,
    exact: true,
    isAdmin: false,
    isFreelancer: false,
  },
];

// routes that can be access when user is inauthenticated
// if paid - reroute to x
// if not paid - reroute to y
export const restrictedRoutes: Route[] = [
  {
    path: "/handle-sign-up",
    main: ConfirmMagicLink,
    exact: true,
    isAdmin: false,
    isFreelancer: false,
  },
  {
    path: "/register",
    main: Register,
    exact: true,
    isAdmin: false,
    isFreelancer: false,
  },
  {
    path: "/confirm-email",
    main: Confirmation,
    exact: true,
    isAdmin: false,
    isFreelancer: false,
  },

  {
    path: "/",
    main: SignIn,
    exact: true,
    isAdmin: false,
    isFreelancer: false,
  },
  {
    path: "/login-with-magic-link",
    main: SignInWithMagicLink,
    exact: true,
    isAdmin: false,
    isFreelancer: false,
  },
  {
    path: "/forgot-password",
    main: ForgotPassword,
    exact: true,
    isAdmin: false,
    isFreelancer: false,
  },
];

// routes that can be accessed if user is authenticated
export const privateRoutes: Route[] = [];

export const privateLayoutRoutes: Route[] = [
  {
    path: "/campaigns/:campaignId",
    main: SingleCampaign,
    exact: true,
    isAdmin: true,
    isFreelancer: true,
  },
  {
    path: "/us-campaigns/:usCampaignId",
    main: SingleUSCampaign,
    exact: true,
    isAdmin: true,
    isFreelancer: true,
  },
];
