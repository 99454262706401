import { ApolloError } from "@apollo/client";
import {
  GetCampaignTypesQuery,
  useGetCampaignTypesQuery,
} from "../../../../../shared/infrastructure/graphQL/generatedTypes";

interface HookResult {
  campaignTypes: GetCampaignTypesQuery["campaign_type"];
  displayedCampaignTypes: GetCampaignTypesQuery["campaign_type"];
  campaignTypesError?: ApolloError;
  campaignTypesLoading: boolean;
}

export const useCampaignTypes = (): HookResult => {
  const { data, error, loading } = useGetCampaignTypesQuery();

  const campaignTypes = data && Array.isArray(data.campaign_type) ? data.campaign_type : [];

  const displayedCampaignTypes = campaignTypes.filter(type => type.is_displayed === true);

  return {
    campaignTypes: campaignTypes,
    displayedCampaignTypes: displayedCampaignTypes,
    campaignTypesError: error,
    campaignTypesLoading: loading,
  };
};
