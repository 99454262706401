import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";

interface GetEPCForAddressesOptions {
  addressIds: number[];
}
export const GetEPCForAddressesAPI = async ({ addressIds }: GetEPCForAddressesOptions) => {
  try {
    const response = await meetmoreAPI.post(`/addresses/epc`, { addressIds });
    return response.data;
  } catch (err) {
    toast.error("Error in getting EPC for Addresses");
    throw err;
  }
};
