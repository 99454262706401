import { ApolloError, useQuery } from "@apollo/client";
import { GetTrackingGroupsByUsCampaignIdQuery } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { getTrackingGroupsByUSCampaignId } from "../queries/getTrackingGroupsByUSCampaignId";
import { ArrayElement } from "../../../../../shared/internals/utils";
import { TRACKING_GROUP_TYPE } from "../../../../../shared/internals/constants/models";

export type UseTrackingGroupsByUSCampaignIdReturnType = ReturnType<
  typeof useTrackingGroupsByUSCampaignId
>;

export const useTrackingGroupsByUSCampaignId = ({ usCampaignId }: { usCampaignId: number }) => {
  const { data, error, loading, refetch } = useQuery<GetTrackingGroupsByUsCampaignIdQuery>(
    getTrackingGroupsByUSCampaignId,
    {
      variables: { usCampaignId: usCampaignId },
      skip: usCampaignId === -1,
    }
  );

  const allTrackingGroups = data && Array.isArray(data.tracking_group) ? data.tracking_group : [];

  /// GET US OWNERS GROUPS

  const getUSOwnersTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.GET_US_OWNERS
  );
  const incompleteGetUSOwnersGroups = getUSOwnersTrackingGroups.filter(
    group => group.is_completed === false
  );
  const lastIncompleteGetUSOwnersGroup =
    incompleteGetUSOwnersGroups[incompleteGetUSOwnersGroups.length - 1];

  /// GET US PROFILES GROUPS

  const getUSProfilesTrackingGroups = allTrackingGroups.filter(
    group => group.tracking_group_type_id === TRACKING_GROUP_TYPE.GET_US_PROFILES
  );

  const incompleteGetUSProfilesGroups = getUSProfilesTrackingGroups.filter(
    group => group.is_completed === false
  );

  const lastIncompleteGetUSProfilesGroup =
    incompleteGetUSProfilesGroups[incompleteGetUSProfilesGroups.length - 1];

  const refetchTrackingGroups = async () => {
    await refetch();
  };
  return {
    getUSOwners: {
      getUSOwnersGroups: getUSOwnersTrackingGroups,
      lastIncompleteGetUSOwnersGroup,
      incompleteGetUSOwnersGroups,
    },
    getUSProfiles: {
      getUSProfilesGroups: getUSProfilesTrackingGroups,
      lastIncompleteGetUSProfilesGroup,
      incompleteGetUSProfilesGroups,
    },

    trackingGroupsError: error,
    trackingGroupsLoading: loading,
    refetch: refetchTrackingGroups,
  };
};
