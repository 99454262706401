export enum SubscriptionStatusClaim {
  NEW = "new",
  INACTIVE = "inactive",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  TRIALING = "trialing",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
  ACTIVE = "active",
}

export enum SubscriptionProduct {
  LEADS = "leads",
  SOCIALS = "socials",
  BOTH = "both",
}
export const SUBSCRIBED_AT_ONE_POINT = [
  SubscriptionStatusClaim.INACTIVE,
  SubscriptionStatusClaim.INCOMPLETE,
  SubscriptionStatusClaim.INCOMPLETE_EXPIRED,
  SubscriptionStatusClaim.PAST_DUE,
  SubscriptionStatusClaim.CANCELED,
  SubscriptionStatusClaim.UNPAID,
  SubscriptionStatusClaim.ACTIVE,
  SubscriptionStatusClaim.TRIALING,
];

export const NEVER_SUBSCRIBED_CLAIMS = [SubscriptionStatusClaim.NEW, undefined];
export const SUBSCRIBED_CLAIMS = [SubscriptionStatusClaim.TRIALING, SubscriptionStatusClaim.ACTIVE];
export const PAYMENT_FAILED_CLAIMS = [
  SubscriptionStatusClaim.PAST_DUE,
  SubscriptionStatusClaim.UNPAID,
  SubscriptionStatusClaim.INCOMPLETE_EXPIRED,
];
export interface UserStatus {
  leads: {
    isOnboarded: boolean;
    subscriptionStatus?: SubscriptionStatusClaim;
  };
  socials: {
    isOnboarded: boolean;
    subscriptionStatus?: SubscriptionStatusClaim;
  };
  isAdmin: boolean;
  isFreelancer: boolean;
}
