import { useForm } from "react-hook-form";
import { CampaignLead } from "../../graphql/hooks/useCampaignById";
import { UpdateBulkOwnerAddressMatchValues } from "../../types";
import { transformOwnersForBulkAddressMatcher } from "../../utils";
import { Fragment, useEffect } from "react";
import { classNames } from "../../../../../shared/internals/utils";
import {
  CONSTRUCTED_OWNER_STATUS,
  OWNER_ADDRESS_VALIDATION_TYPE,
} from "../../../../../shared/internals/constants/models";
import { UseMutateAsyncFunction } from "react-query";
import { ValidateOwnerAddressBody } from "../../api/validateOwnerAddress";
import Loading from "../../../../../shared/internals/components/loading/Loading";
import toast from "react-hot-toast";

interface BulkOwnerAddressMatcherProps {
  filteredCampaignLeads: CampaignLead[];
  campaignId?: number;
  validateOwnerAddress: UseMutateAsyncFunction<any, any, ValidateOwnerAddressBody, void>;
  validateOwnerAddressLoading: boolean;
}

const BulkOwnerAddressMatcher = ({
  filteredCampaignLeads,
  validateOwnerAddress,
  validateOwnerAddressLoading,
}: BulkOwnerAddressMatcherProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UpdateBulkOwnerAddressMatchValues>({
    defaultValues: {
      changes: filteredCampaignLeads
        .map(lead => lead.address.suspected_owners)
        .flat()
        .map((owner, index) => {
          return {
            ownerId: owner.id,
            ownerFullName: owner.name,
            ownerConstructedStatus: owner.constructed_status,
            ownerStatedAddress: owner.stated_address,
            isFalseAddress: false,
            isCorrectAddress: false,
            index: index,
            leadMatchedAddressId: owner.matching_address.address.id,
            leadMatchedAddressFullAddress: owner.matching_address.address.full_address,
            addressMatchScore: owner.matching_address.address_match_score,
            addressMatchQualityId: owner.matching_address.address_match_quality.id,
            initialIsFalseAddress: owner.matching_address.is_false_address,
            initialIsCorrectAddress: owner.matching_address.is_correct_address,
            addressMatchQualityName: owner.matching_address.address_match_quality.name,
          };
        }),
    },
  });

  useEffect(() => {
    updateOwnersToMatch();
  }, [filteredCampaignLeads]);

  const updateOwnersToMatch = async () => {
    const newOwnersInForm = filteredCampaignLeads
      .map(lead => lead.address.suspected_owners)
      .flat()
      .map((owner, index) => {
        return {
          ownerId: owner.id,
          ownerFullName: owner.name,
          ownerConstructedStatus: owner.constructed_status,
          ownerStatedAddress: owner.stated_address,
          isFalseAddress: false,
          isCorrectAddress: false,
          index: index,
          leadMatchedAddressId: owner.matching_address.address.id,
          leadMatchedAddressFullAddress: owner.matching_address.address.full_address,
          addressMatchScore: owner.matching_address.address_match_score,
          addressMatchQualityId: owner.matching_address.address_match_quality.id,
          initialIsFalseAddress: owner.matching_address.is_false_address,
          initialIsCorrectAddress: owner.matching_address.is_correct_address,
          addressMatchQualityName: owner.matching_address.address_match_quality.name,
        };
      });

    setValue("changes", newOwnersInForm);
  };

  const onSubmit = async (values: UpdateBulkOwnerAddressMatchValues) => {
    const changes = values.changes
      .filter(owner => owner.isCorrectAddress === true || owner.isFalseAddress === true)
      .map(owner => {
        return {
          ownerId: owner.ownerId,
          addressId: owner.leadMatchedAddressId,
          validationType: owner.isCorrectAddress
            ? OWNER_ADDRESS_VALIDATION_TYPE.CORRECT
            : OWNER_ADDRESS_VALIDATION_TYPE.FALSE,
        };
      });

    if (!changes.length) {
      toast.error("Please make judgements on owner addresses");
      return;
    }

    await validateOwnerAddress({ changes });
  };

  const ownersInForm = watch("changes");

  const transformedOwners = transformOwnersForBulkAddressMatcher(ownersInForm);

  const devalidatedOwners = ownersInForm.filter(owner => owner.isFalseAddress);
  const validatedOwners = ownersInForm.filter(owner => owner.isCorrectAddress);

  if (validateOwnerAddressLoading) {
    return (
      <div className="mt-20">
        <Loading isMini />;
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <div className="mt-6 overflow-hidden  pb-56 ">
        <div className="border border-gray-200 rounded-lg mb-4 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="flex ml-2 items-center justify-left mt-2 flex-row">
              <p className="text-sm text-gray-500">
                <span className="font-bold text-lg">{validatedOwners.length} </span>{" "}
                <span className="text-sm text-gray-500">
                  owner(s) will be correctly matched to their campaign address.
                </span>{" "}
                <span className="font-bold text-lg">{devalidatedOwners.length} </span>{" "}
                <span className="text-sm text-gray-500">
                  owner(s) will be marked as falsely matched to their campaign address.
                </span>{" "}
                There are a total of {ownersInForm.length} owners of which{" "}
                {validatedOwners.length + devalidatedOwners.length} have been altered
              </p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <button
                type="submit"
                // disabled={changeMatchQualityLoading}
                className="relative inline-flex items-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                Update Owners
              </button>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <table className="w-full text-left">
              <tbody>
                {transformedOwners.map(category => (
                  <Fragment key={category.constructed_status}>
                    <tr className="text-sm leading-6 text-gray-900">
                      <th
                        scope="colgroup"
                        colSpan={3}
                        className="relative px-2  isolate py-2 font-semibold"
                      >
                        <time>{category.constructed_status}</time>
                        <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                        <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                      </th>
                    </tr>
                    {category.owners.map(owner => {
                      return (
                        <tr
                          key={owner.ownerId}
                          className={classNames(
                            owner.isFalseAddress
                              ? "bg-red-50"
                              : owner.isCorrectAddress
                              ? "bg-green-50"
                              : "",
                            "  "
                          )}
                        >
                          <td className="relative py-5 pl-4 pr-2">
                            {owner.isFalseAddress && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-red-600" />
                            )}
                            {owner.isCorrectAddress && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                            )}

                            <div className="flex gap-x-6">
                              <div className="flex-auto">
                                <div
                                  className={classNames(
                                    "text-xs font-normal leading-6 text-gray-600"
                                  )}
                                >
                                  {owner.ownerFullName}
                                </div>
                                <div className="flex items-start gap-x-3">
                                  <div
                                    className={classNames(
                                      "text-sm font-medium leading-6 text-gray-900"
                                    )}
                                  >
                                    {owner.ownerStatedAddress}
                                  </div>
                                  <div
                                    className={classNames(
                                      owner.initialIsCorrectAddress
                                        ? "text-green-700 bg-green-50 ring-green-600/20"
                                        : owner.initialIsFalseAddress
                                        ? "text-red-700 bg-red-50 ring-red-600/10"
                                        : "text-gray-700 bg-gray-50 ring-gray-600/10",

                                      "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                    )}
                                  >
                                    {owner.initialIsCorrectAddress
                                      ? `Correct Address`
                                      : owner.initialIsFalseAddress
                                      ? `False Address`
                                      : "Not Checked"}
                                  </div>
                                  <div
                                    className={classNames(
                                      owner.initialIsCorrectAddress
                                        ? "text-green-700 bg-green-50 ring-green-600/20"
                                        : owner.initialIsFalseAddress
                                        ? "text-red-700 bg-red-50 ring-red-600/10"
                                        : "text-gray-700 bg-gray-50 ring-gray-600/10",

                                      "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                    )}
                                  >
                                    {owner.addressMatchQualityName}
                                  </div>
                                  <div
                                    className={classNames(
                                      owner.initialIsCorrectAddress
                                        ? "text-green-700 bg-green-50 ring-green-600/20"
                                        : owner.initialIsFalseAddress
                                        ? "text-red-700 bg-red-50 ring-red-600/10"
                                        : "text-gray-700 bg-gray-50 ring-gray-600/10",

                                      "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                    )}
                                  >
                                    {owner.addressMatchScore}
                                  </div>
                                </div>

                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  {owner.leadMatchedAddressFullAddress}
                                </div>
                              </div>
                            </div>
                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                          </td>
                          <td className="py-5 pr-6 sm:table-cell">
                            {!owner.isFalseAddress && !owner.isCorrectAddress && (
                              <div className="flex flex-row space-x-6">
                                <button
                                  type="button"
                                  onClick={() =>
                                    setValue(`changes.${owner.index}.isCorrectAddress`, true)
                                  }
                                  className=" w-full inline-flex justify-center py-1 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                  Correct
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    setValue(`changes.${owner.index}.isFalseAddress`, true)
                                  }
                                  className=" w-full inline-flex justify-center py-1 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                  False
                                </button>
                              </div>
                            )}

                            {(owner.isFalseAddress || owner.isCorrectAddress) && (
                              <button
                                type="button"
                                onClick={() => {
                                  setValue(`changes.${owner.index}.isFalseAddress`, false);
                                  setValue(`changes.${owner.index}.isCorrectAddress`, false);
                                }}
                                className="bg-white w-full py-1 px-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                              >
                                Revoke
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BulkOwnerAddressMatcher;
