import toast from "react-hot-toast";
import { meetmoreAPI } from "../../../../../shared/infrastructure/APIs/meetmoreAPI";

interface SyncWithOverleadOptions {
  campaignId: number;
  externalCampaignId: number;
}
export const syncWithOverleadAPI = async ({
  campaignId,
  externalCampaignId,
}: SyncWithOverleadOptions) => {
  try {
    const response = await meetmoreAPI.post(`/campaigns/${campaignId}/sync`, {});
    return response.data;
  } catch (err) {
    toast.error("Error in syncing campaing with Overlead");
    throw err;
  }
};
