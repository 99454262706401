import * as yup from "yup";

export const addressSchema = yup.object().shape({
  address: yup.string().defined().required(),
  streetAddress: yup.string().defined().required(),
  city: yup.string().defined().required(),
  state: yup.string().defined().required(),
  zip: yup.string().defined().required(),
  county: yup.string().nullable(),
  district: yup.string().nullable(),
  municipality: yup.string().nullable(),
  stateCode: yup.string().defined().required().max(2, "State code must be 2 characters"),
});

export const addressesSchema = yup.array().of(addressSchema);
