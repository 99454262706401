// useBulkUpdateSuspectedOwnerAddressStatus.ts

import { ApolloError } from "@apollo/client";
import { useBulkUpdateSuspectedOwnerAddressStatusMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";

export interface BulkUpdateSuspectedOwnerAddressStatusHookResult {
  bulkUpdateSuspectedOwnerAddressStatus: (
    options: BulkUpdateSuspectedOwnerAddressStatusMutationOptions
  ) => Promise<void>;
}

export interface BulkUpdateSuspectedOwnerAddressStatusMutationOptions {
  ids: number[];
  suspectedOwnerStatusId: number;
}

export const useBulkUpdateSuspectedOwnerAddressStatus =
  (): BulkUpdateSuspectedOwnerAddressStatusHookResult => {
    const [bulkMatchMutation] = useBulkUpdateSuspectedOwnerAddressStatusMutation({
      onError: error => {
        throw error;
      },
    });

    return {
      bulkUpdateSuspectedOwnerAddressStatus: async ({
        ids,
        suspectedOwnerStatusId,
      }: BulkUpdateSuspectedOwnerAddressStatusMutationOptions) => {
        await bulkMatchMutation({
          variables: {
            ids,
            suspected_owner_status_id: suspectedOwnerStatusId,
          },
        });
      },
    };
  };
