export const reactSelectStyling = {
  control: (providedStyling: any) => ({
    ...providedStyling,
    border: "1px solid #C4C4C4",
    borderRadius: "10px",
  }),
  placeholder: (providedStyling: any) => ({
    ...providedStyling,
    whiteSpace: "nowrap",
    color: "#8D8D8D",
  }),
  menu: (providedStyling: any) => ({
    ...providedStyling,
    position: "absolute",
  }),
  menuList: (providedStyling: any) => ({
    ...providedStyling,
    position: "relative",
    overflowY: "hidden",
  }),
};

export const bulkAddressMatcherStyling = {
  control: (providedStyling: any) => ({
    ...providedStyling,
    border: "1px solid #C4C4C4",
    borderRadius: "10px",
    width: 300,
  }),
  placeholder: (providedStyling: any) => ({
    ...providedStyling,
    whiteSpace: "nowrap",
    color: "#8D8D8D",
  }),
  menu: (providedStyling: any) => ({
    ...providedStyling,
    position: "absolute",
    width: 300,
  }),
  menuList: (providedStyling: any) => ({
    ...providedStyling,
    position: "relative",
    width: 300,
  }),
};
