import {
  PhoneIcon,
  CogIcon,
  FireIcon,
  HandThumbUpIcon,
  CreditCardIcon,
  AdjustmentsHorizontalIcon,
  AdjustmentsVerticalIcon,
  BoltIcon,
  ChartBarIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { logout, useUserStatus } from "../../../../infrastructure/firebase";
import { NavigationItem } from "../types";
import {
  SUBSCRIBED_AT_ONE_POINT,
  SubscriptionProduct,
} from "../../../../infrastructure/firebase/types";

export const secondaryNavigation = [{ name: "Logout", action: logout, icon: CogIcon }];

export const navigation: NavigationItem[] = [
  {
    name: "Socials",
    requiredSubscription: { type: SubscriptionProduct.SOCIALS, statuses: SUBSCRIBED_AT_ONE_POINT },
    hiddenCondition: { hideFullNavItem: true },
    icon: HandThumbUpIcon,
    isAdmin: false,
    isChildrenOpen: false,
    children: [
      { name: "Dashboard", path: "/internal-socials", current: false },
      { name: "Analytics", path: "/analytics", current: false },
    ],
  },

  {
    name: "Linkedin Leads",
    icon: BoltIcon,
    isAdmin: false,
    requiredSubscription: { type: SubscriptionProduct.LEADS, statuses: SUBSCRIBED_AT_ONE_POINT },
    hiddenCondition: {
      hideFullNavItem: false,
      hiddenChildrenUrls: [
        "/internal-leads/commission-campaign",
        "/internal-leads/leads",
        "/internal-leads/conversations",
      ],
    },

    isChildrenOpen: false,
    children: [
      { name: "Dashboard", path: "/internal-leads", current: false },
      { name: "Request Campaign", path: "/internal-leads/commission-campaign", current: false },
      // { name: "Campaigns", path: "/internal-leads/campaigns", current: false },
      // { name: "Replied Leads", path: "/internal-leads/leads", current: false },
      { name: "Conversations", path: "/internal-leads/conversations", current: false },
    ],
  },
  {
    name: "Account",
    icon: CogIcon,
    isAdmin: false,
    isChildrenOpen: false,
    children: [
      { name: "Settings", path: "/settings", current: false },
      { name: "Billing", path: "/subscription", current: false },
    ],
  },
  {
    name: "Leads Admin",
    icon: AdjustmentsVerticalIcon,
    isAdmin: true,
    isChildrenOpen: false,
    children: [
      {
        name: "Lead Streams (Customer View)",
        path: "/leads-admin-v2/lead-streams",
        current: false,
      },
      { name: "Search Agents", path: "/leads-admin-v2/search-agents", current: false },
      { name: "Messaging", path: "/leads-admin-v2/messages", current: false },
      { name: "Customers", path: "/leads-admin-v2/customers", current: false },
      // { name: "Lead Magnet", path: "/lead-magnet/send-on-market-addresses", current: false },

      // { name: "Requested Campaigns", path: "/leads-admin-v2/requested-campaigns", current: false },

      { name: "Lead Streams (Admin)", path: "/leads-admin", current: false },
    ],
  },

  {
    name: "Socials Admin",
    path: "/socials-admin",
    icon: AdjustmentsHorizontalIcon,
    current: false,
    isAdmin: true,
  },

  // {
  //   name: "Leads Admin (OLD)",
  //   path: "/leads-admin",
  //   icon: FireIcon,
  //   current: false,
  //   isAdmin: true,
  // },
  // {
  //   name: "Message Admin",
  //   path: "/message-admin",
  //   icon: ChatIcon,
  //   current: false,
  //   isAdmin: true,
  // },
];
