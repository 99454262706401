import {
  GetCampaignByIdQuery,
  GetSearchGroupByCampaignIdQuery,
  GetTrackingGroupsByCampaignIdQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";
import {
  Bold,
  Button,
  Callout,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionList,
  Text,
  Badge,
  Divider,
  BarList,
  Title,
  Icon,
  List,
  ListItem,
} from "@tremor/react";

import {
  XCircleIcon,
  ArchiveBoxIcon,
  ShieldCheckIcon,
  TrashIcon,
  CheckBadgeIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  EyeIcon,
  MagnifyingGlassCircleIcon,
  ArrowRightCircleIcon,
  ShareIcon,
  SwatchIcon,
  WrenchIcon,
} from "@heroicons/react/24/solid";

import { CampaignLead } from "../graphql/hooks/useCampaignById";
import { GetPostcodeUnitByIdLazyHookResult } from "../graphql/hooks/usePostcodeUnitById";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EPC_CATEGORY,
  OWNER_COUNT,
  OWNER_EVALUATION_SHOW_TABS,
  OWNER_SEARCH,
  OWNER_TAG,
  UpdateMatchedAddressValues,
} from "../types";
import { updateMatchedAddressValidationSchema } from "../validation";
import Select from "react-select";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import {
  ADDRESS_MATCH_QUALITY,
  CONSTRUCTED_OWNER_STATUS,
  OWNERSHIP_SOURCE,
  SUSPECTED_OWNER_STATUS,
  TRACKING_GROUP_TYPE,
} from "../../../../shared/internals/constants/models";
import { ArrayElement, classNames } from "../../../../shared/internals/utils";
import {
  epcStatuses,
  suspectedOwnerEvaluationFilters,
  suspectedOwnerEvaluationRadioOptions,
} from "../data";
import { RadioGroup } from "@headlessui/react";
import toast from "react-hot-toast";
import Dropdown from "../../../../shared/internals/components/dropdown/Dropdown";
import Alert from "../../../../shared/internals/components/alert/Alert";
import { UseMutateAsyncFunction } from "react-query";
import { SearchLinkedinProfilesAPIOptions } from "../api/searchLinkedInProfiles";
import { ResumeLinkedinSearchAPIOptions } from "../api/resumeLinkedinSearch";
import { filtersStyling } from "../styles";
import dayjs from "dayjs";
import { CancelLinkedinSearchAPIOptions } from "../api/cancelLinkedinSearch";
import Papa from "papaparse";
import { generateCSVData, generateOwnerCSVData } from "../utils";
import { AutomaticSuspectedOwnerConfirmation } from "../api/automaticSuspectedOwnerConfirmation";
import { truncate } from "lodash";
import { FixOwnerNamesBody } from "../api/fixOwnerNames";
import { TriggerSearchGroupBody } from "../api/triggerSearchGroup";
import { TriggerTrackingGroupBody } from "../api/triggerTrackingGroup";
import { CancelTrackingGroupAPIOptions } from "../api/cancelTrackingGroup";
import { PauseTrackingGroupAPIOptions } from "../api/pauseTrackingGroup";
interface CampaignSummaryTabProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"];
  campaignLeads: CampaignLead[];
  bulkSuspectedOwnerStatusUpdater: (
    suspectedOwnerAddressIds: number[],
    suspectedOwnerStatus: SUSPECTED_OWNER_STATUS
  ) => Promise<void>;
  resumeLinkedinSearch: UseMutateAsyncFunction<any, any, ResumeLinkedinSearchAPIOptions, void>;
  searchGroups: GetSearchGroupByCampaignIdQuery["search_group"];
  searchLinkedinProfiles: UseMutateAsyncFunction<any, any, SearchLinkedinProfilesAPIOptions, void>;
  incompleteSearchGroups: GetSearchGroupByCampaignIdQuery["search_group"];
  lastIncompleteSearchGroup?: ArrayElement<GetSearchGroupByCampaignIdQuery["search_group"]>;
  fixOwnerNames: UseMutateAsyncFunction<any, any, FixOwnerNamesBody, void>;
  cancelLinkedinSearch: UseMutateAsyncFunction<any, any, CancelLinkedinSearchAPIOptions, void>;
  triggerSearchGroup: UseMutateAsyncFunction<any, any, TriggerSearchGroupBody, void>;
  triggerSearchGroupLoading: boolean;

  matchOwnersAutomatically: UseMutateAsyncFunction<
    any,
    any,
    AutomaticSuspectedOwnerConfirmation,
    void
  >;

  triggerTrackingGroup: UseMutateAsyncFunction<any, any, TriggerTrackingGroupBody, void>;
  triggerTrackingGroupLoading: boolean;

  incompleteCleanOwnersGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];
  lastIncompleteCleanOwnersGroup?: ArrayElement<
    GetTrackingGroupsByCampaignIdQuery["tracking_group"]
  >;
  cleanOwnersGroups: GetTrackingGroupsByCampaignIdQuery["tracking_group"];

  cancelTrackingGroup: UseMutateAsyncFunction<any, any, CancelTrackingGroupAPIOptions, void>;
  pauseTrackingGroup: UseMutateAsyncFunction<any, any, PauseTrackingGroupAPIOptions, void>;
  pauseTrackingGroupLoading: boolean;
  cancelTrackingGroupLoading: boolean;
}

const SuspectedOwnersEvaluationTab = ({
  campaignLeads,
  campaign,
  resumeLinkedinSearch,
  searchGroups,
  bulkSuspectedOwnerStatusUpdater,
  cancelLinkedinSearch,
  fixOwnerNames,
  searchLinkedinProfiles,
  triggerSearchGroup,
  matchOwnersAutomatically,
  triggerSearchGroupLoading,
  triggerTrackingGroup,
  triggerTrackingGroupLoading,
  pauseTrackingGroup,
  pauseTrackingGroupLoading,
  cancelTrackingGroup,
  cancelTrackingGroupLoading,
  cleanOwnersGroups,
}: CampaignSummaryTabProps) => {
  const leadsWithSelectableOwners = campaignLeads
    .filter(lead => lead.is_address_validated === true)
    .map(lead => {
      return {
        ...lead,
        address: {
          ...lead.address,
          suspected_owners: lead.address.suspected_owners
            .filter(
              owner =>
                owner.name !== "Not Available" &&
                owner.constructed_status === CONSTRUCTED_OWNER_STATUS.VALIDATED_LEAD_ADDRESS
            )
            .map(owner => ({
              ...owner,
              isSelected: false,
            })),
        },
      };
    });

  const [filteredLeads, setFilteredLeads] = useState(leadsWithSelectableOwners);
  const [selectAll, setSelectAll] = useState<{ status: boolean; changedByItemToggle: boolean }>({
    status: false,
    changedByItemToggle: false,
  });
  const [selectedRadioOption, setSelectedRadioOption] = useState(
    suspectedOwnerEvaluationRadioOptions[0]
  );

  const [activeEpcFilters, setActiveEpcFilters] = useState<{ label: string; value: string }[]>([]);
  const [activeOwnerSourceFilters, setActiveOwnerSourceFilters] = useState<
    { label: string; value: number }[]
  >([]);

  const [activeOwnerSearchFilters, setActiveOwnerSearchFilters] = useState<
    { label: string; value: string }[]
  >([]);
  const [activeOwnerTagFilters, setActiveOwnerTagFilters] = useState<
    { label: string; value: string }[]
  >([]);

  const [activeOwnerCountFilters, setActiveOwnerCountFilters] = useState<
    { label: string; value: string }[]
  >([]);

  const exportToCSV = ({ campaignLeads }: { campaignLeads: CampaignLead[] }) => {
    // Prepare the data
    const data = generateOwnerCSVData(campaignLeads);
    const csv = Papa.unparse(data);

    // Trigger download
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "owners.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (selectAll.status === true && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => {
        return {
          ...lead,
          address: {
            ...lead.address,
            suspected_owners: lead.address.suspected_owners.map(owner => ({
              ...owner,
              isSelected: true,
            })),
          },
        };
      });
      setFilteredLeads(updatedLeads);
    }

    if (selectAll.status === false && selectAll.changedByItemToggle === false) {
      const updatedLeads = filteredLeads.map(lead => {
        return {
          ...lead,
          address: {
            ...lead.address,
            suspected_owners: lead.address.suspected_owners.map(owner => ({
              ...owner,
              isSelected: false,
            })),
          },
        };
      });
      setFilteredLeads(updatedLeads);
    }
  }, [selectAll.status]);

  useEffect(() => {
    let updatedLeads = [...leadsWithSelectableOwners];

    switch (selectedRadioOption.id) {
      case OWNER_EVALUATION_SHOW_TABS.NEW:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.address.suspected_owners.some(
              owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.NEW
            );
          })
          .map(lead => ({
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(
                owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.NEW
              ),
            },
          }));
        break;
      case OWNER_EVALUATION_SHOW_TABS.REJECTED:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.address.suspected_owners.some(
              owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.REJECTED
            );
          })
          .map(lead => ({
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(
                owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.REJECTED
              ),
            },
          }));
        break;
      case OWNER_EVALUATION_SHOW_TABS.DUPLICATE:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.address.suspected_owners.some(
              owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.DUPLICATE
            );
          })
          .map(lead => ({
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(
                owner => owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.DUPLICATE
              ),
            },
          }));
        break;
      case OWNER_EVALUATION_SHOW_TABS.CONTENDER_AND_VERIFIED:
        updatedLeads = updatedLeads
          .filter(lead => {
            return lead.address.suspected_owners.some(owner =>
              [SUSPECTED_OWNER_STATUS.VERIFIED, SUSPECTED_OWNER_STATUS.CONTENDER].includes(
                owner.suspected_owner_status.id
              )
            );
          })
          .map(lead => ({
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(owner =>
                [SUSPECTED_OWNER_STATUS.VERIFIED, SUSPECTED_OWNER_STATUS.CONTENDER].includes(
                  owner.suspected_owner_status.id
                )
              ),
            },
          }));
        break;
      default:
        break;
    }

    // address level filters
    if (activeEpcFilters.length > 0 || activeOwnerCountFilters.length > 0) {
      updatedLeads = updatedLeads.filter(lead => {
        let epcMatch = true;
        let ownerCountMatch = true;

        if (activeEpcFilters.length > 0) {
          const tenure = lead?.address?.additional_info?.epc?.tenure;
          epcMatch =
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.OWNER_OCCUPIED) &&
              epcStatuses.ownerOccupied.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.RENTED_SOCIAL) &&
              epcStatuses.rentedSocial.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.RENTED_PRIVATE) &&
              epcStatuses.rentedPrivate.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.UNDEFINED) &&
              epcStatuses.notDefined.includes(tenure)) ||
            (activeEpcFilters.some(filter => filter.value === EPC_CATEGORY.NOT_AVAILABLE) &&
              !tenure);
        }

        if (activeOwnerCountFilters.length > 0) {
          const ownerCount = lead.address.suspected_owners.length;
          ownerCountMatch =
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.NONE) &&
              ownerCount === 0) ||
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.ONE) &&
              ownerCount === 1) ||
            (activeOwnerCountFilters.some(filter => filter.value === OWNER_COUNT.TWO_OR_MORE) &&
              ownerCount >= 2);
        }

        return epcMatch && ownerCountMatch;
      });
    }

    // owner level filters
    if (
      activeOwnerSourceFilters.length > 0 ||
      activeOwnerTagFilters.length > 0 ||
      activeOwnerSearchFilters.length > 0
    ) {
      updatedLeads = updatedLeads
        .map(lead => {
          return {
            ...lead,
            address: {
              ...lead.address,
              suspected_owners: lead.address.suspected_owners.filter(owner => {
                let ownerSourceMatch =
                  activeOwnerSourceFilters.length === 0 ||
                  activeOwnerSourceFilters.some(
                    filter => filter.value === owner.ownership_source.id
                  );

                let ownerTagMatch =
                  activeOwnerTagFilters.length === 0 ||
                  activeOwnerTagFilters.some(filter => {
                    switch (filter.value) {
                      case OWNER_TAG.IS_NOT_PERSON:
                        return owner.is_not_person;

                      default:
                        return true;
                    }
                  });

                let ownerSearchMatch =
                  activeOwnerSearchFilters.length === 0 ||
                  activeOwnerSearchFilters.some(filter => {
                    switch (filter.value) {
                      case OWNER_SEARCH.LINKEDIN_SEARCHED:
                        return owner.last_li_search || owner.digital_profiles.length > 0; // Assuming 'owner.linkedinSearched' is a boolean
                      case OWNER_SEARCH.LINKEDIN_NOT_SEARCHED:
                        return owner.digital_profiles.length === 0;
                      case OWNER_SEARCH.ADDRESS_MATCHER_SEARCHED:
                        return owner.last_address_match_attempt; // Assuming 'owner.addressMatcherAttempted' is a boolean
                      case OWNER_SEARCH.ADDRESS_MATCHER_NOT_SEARCHED:
                        return !owner.last_address_match_attempt;
                      default:
                        return true;
                    }
                  });

                // Combining all owner level filters using AND logic
                return ownerSourceMatch && ownerSearchMatch && ownerTagMatch;
              }),
            },
          };
        })
        .filter(lead => lead.address.suspected_owners.length > 0); // Hide addresses that have no remaining owners
    }

    if (selectAll.status === true) {
      // Handle the selectAll logic
      updatedLeads = updatedLeads.map(lead => {
        return {
          ...lead,
          address: {
            ...lead.address,
            suspected_owners: lead.address.suspected_owners.map(owner => ({
              ...owner,
              isSelected: true,
            })),
          },
        };
      });
    }

    setFilteredLeads(updatedLeads);
  }, [
    activeEpcFilters,
    activeOwnerCountFilters,
    activeOwnerSearchFilters,
    activeOwnerSourceFilters,
    activeOwnerTagFilters,
    selectedRadioOption,
    campaignLeads,
  ]);

  const handleIndividualChange = (leadId: number, ownerId: number) => {
    // Find the specific lead and owner
    const lead = filteredLeads.find(lead => lead.id === leadId);
    const owner = lead?.address.suspected_owners.find(owner => owner.id === ownerId);
    const ownerWillBeSelected = !owner?.isSelected;

    // Update the leads
    const updatedLeads = filteredLeads.map(lead => {
      if (lead.id === leadId) {
        return {
          ...lead,
          address: {
            ...lead.address,
            suspected_owners: lead.address.suspected_owners.map(owner =>
              owner.id === ownerId ? { ...owner, isSelected: ownerWillBeSelected } : owner
            ),
          },
        };
      }
      return lead;
    });

    // If the owner is about to be selected, check if all owners will be selected
    if (ownerWillBeSelected) {
      const allOwnersSelected = updatedLeads.every(lead =>
        lead.address.suspected_owners.every(owner => owner.isSelected)
      );
      if (allOwnersSelected) {
        setSelectAll({ status: true, changedByItemToggle: true });
      }
    }
    // If the owner is about to be unselected and selectAll is currently true, update selectAll
    else if (selectAll.status) {
      setSelectAll({ status: false, changedByItemToggle: true });
    }

    // Update the filteredLeads state
    setFilteredLeads(updatedLeads);
  };

  const totalOwners = campaignLeads.map(lead => lead.address.suspected_owners).flat();

  const validatedCampaignAndOwnerAddressOwners = campaignLeads
    .filter(lead => lead.is_address_validated)
    .map(lead =>
      lead.address.suspected_owners.filter(
        owner => owner.constructed_status === CONSTRUCTED_OWNER_STATUS.VALIDATED_LEAD_ADDRESS
      )
    )
    .flat();

  const filteredOwners = filteredLeads.map(lead => lead.address.suspected_owners).flat();

  return (
    <>
      <div className="flex w-full">
        <Callout
          title="Decide which owners are contenders"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>1. Make decisions for Addresses with 1 owner</div>
          <div>2. Make decisions for Addresses with 2+ owners</div>
          <div>
            3. Select all owners with "Verified" or "Contender" status and search for linkedin
            Profiles (you can do this by clicking 'Contender' & 'Verified' Tab, then clicking the
            select all checkbox and then clicking Search Linkedin Profiles)
          </div>
          <div className="italic mt-3 text-gray-600">We may not need automatic confirmer.</div>
        </Callout>
      </div>
      <div className="mt-5">
        {cleanOwnersGroups.length > 0 &&
          (() => {
            const latestTrackingGroupColor =
              cleanOwnersGroups[0].is_cancelled === true
                ? "red"
                : cleanOwnersGroups[0].is_completed === true &&
                  cleanOwnersGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestTrackingGroupStatus =
              cleanOwnersGroups[0].is_cancelled === true
                ? "Cancelled"
                : cleanOwnersGroups[0].is_completed === true &&
                  cleanOwnersGroups[0].is_cancelled === false
                ? "Completed"
                : cleanOwnersGroups[0].is_error === true
                ? "In Error"
                : cleanOwnersGroups[0].is_paused === true
                ? "Paused"
                : "In Progress";

            const completedtrackingItemsCount = cleanOwnersGroups[0].tracking_items.filter(
              item => item.is_completed === true
            ).length;
            const totaltrackingItemsCount = cleanOwnersGroups[0].tracking_items.length;

            const trackingItemsProgress = Math.round(
              (completedtrackingItemsCount / totaltrackingItemsCount) * 100
            );

            const trackingGroupsBarFirstData = cleanOwnersGroups.slice(1).map(group => {
              const completedTrackingItemsCount = cleanOwnersGroups[0].tracking_items.filter(
                item => item.is_completed === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedTrackingItemsCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestTrackingGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestTrackingGroupColor}
                    />
                    <Title className="truncate">
                      Last Owners Clean ({latestTrackingGroupStatus})
                    </Title>
                  </Flex>

                  <div className="flex justify-between space-x-2">
                    {(cleanOwnersGroups[0].is_error || cleanOwnersGroups[0].is_paused) && (
                      <>
                        <button
                          type="button"
                          disabled={cancelTrackingGroupLoading}
                          onClick={() => {
                            cancelTrackingGroup({ trackingGroupId: cleanOwnersGroups[0].id });
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          disabled={triggerTrackingGroupLoading}
                          onClick={() => {
                            if (!campaign?.id) {
                              toast.error("missing campaign id");
                              return;
                            }
                            triggerTrackingGroup({
                              trackingGroupId: cleanOwnersGroups[0].id,
                              campaignId: campaign.id,
                              trackingGroupTypeId: TRACKING_GROUP_TYPE.CLEAN_OWNERS,
                              trackingItemValues: [],
                            });
                          }}
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Resume
                        </button>
                      </>
                    )}

                    {!cleanOwnersGroups[0].is_error &&
                      !cleanOwnersGroups[0].is_paused &&
                      !cleanOwnersGroups[0].is_cancelled &&
                      !cleanOwnersGroups[0].is_completed && (
                        <>
                          <button
                            type="button"
                            disabled={pauseTrackingGroupLoading}
                            onClick={() => {
                              pauseTrackingGroup({ trackingGroupId: cleanOwnersGroups[0].id });
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Pause
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={trackingItemsProgress}
                    color={latestTrackingGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <p>
                      {completedtrackingItemsCount} / {totaltrackingItemsCount} items completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Triggered:{" "}
                  <Bold>{dayjs(cleanOwnersGroups[0].created_at).format("DD/MM/YYYY mm:ss")}</Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Requests</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text> Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Requests"}
                      data={trackingGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>
      <div className="mt-5">
        {searchGroups.length > 0 &&
          (() => {
            const latestSearchGroupColor =
              searchGroups[0].is_cancelled === true
                ? "red"
                : searchGroups[0].is_completed === true && searchGroups[0].is_cancelled === false
                ? "green"
                : "amber";

            const latestSearchGroupStatus =
              searchGroups[0].is_cancelled === true
                ? "Cancelled"
                : searchGroups[0].is_completed === true && searchGroups[0].is_cancelled === false
                ? "Completed"
                : searchGroups[0].is_error === true
                ? "In Error"
                : "In Progress";

            const completedSearchesCount = searchGroups[0].searches.filter(
              search => search.has_been_searched === true
            ).length;
            const totalSearchesCount = searchGroups[0].searches.length;

            const searchesProgress = Math.round(
              (completedSearchesCount / totalSearchesCount) * 100
            );

            const searchGroupsBarFirstData = searchGroups.slice(1).map(group => {
              const completedSearchesCount = searchGroups[0].searches.filter(
                search => search.has_been_searched === true
              ).length;

              return {
                name: `ID ${group.id} - ${dayjs(group.created_at).format("DD/MM/YYYY MM:ss")}`,
                value: completedSearchesCount,
              };
            });

            return (
              <Card decoration="left" decorationColor={latestSearchGroupColor} className="h-fit">
                <div className="flex justify-between">
                  <Flex justifyContent="start" className="space-x-4">
                    <Icon
                      variant="outlined"
                      icon={MagnifyingGlassCircleIcon}
                      size="sm"
                      color={latestSearchGroupColor}
                    />
                    <Title className="truncate">
                      Last Linkedin Search ({latestSearchGroupStatus})
                    </Title>
                  </Flex>
                  {searchGroups[0].is_error && (
                    <div className="flex justify-between space-x-2">
                      <button
                        type="button"
                        onClick={() => cancelLinkedinSearch({ searchGroupId: searchGroups[0].id })}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if (!campaign?.id) {
                            toast.error("missing campaign id");
                            return;
                          }

                          triggerSearchGroup({
                            searchGroupId: searchGroups[0].id,
                            campaignId: campaign.id,
                            suspectedOwnerAddressIds: [],
                          });
                        }}
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                      >
                        Resume
                      </button>
                    </div>
                  )}
                </div>

                <Flex flexDirection="col" className="space-x-3 mt-10">
                  <ProgressBar
                    className="mt-0"
                    value={searchesProgress}
                    color={latestSearchGroupColor}
                  />
                  <div className="flex mt-2 justify-between w-full">
                    <a
                      href={`https://phantombuster.com/2948545028720502/phantoms/${searchGroups[0].phantom_agent_external_id}/console`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="button"
                        size="sm"
                        variant="light"
                        icon={ArrowRightCircleIcon}
                        iconPosition="right"
                      >
                        View Phantom Used
                      </Button>
                    </a>
                    <p>
                      {completedSearchesCount} / {totalSearchesCount} searches completed
                    </p>
                  </div>
                </Flex>
                <Divider />
                <Text>
                  Search Triggered:{" "}
                  <Bold>{dayjs(searchGroups[0].created_at).format("DD/MM/YYYY mm:ss")}</Bold>
                </Text>
                <Accordion className="mt-4">
                  <AccordionHeader className="flex justify-between">
                    <p className="text-sm font-semibold">Historical Searches</p>
                  </AccordionHeader>
                  <AccordionBody>
                    <Flex>
                      <Text>Date</Text>
                      <Text>Searches Completed</Text>
                    </Flex>
                    <BarList
                      key={"Historical Searches"}
                      data={searchGroupsBarFirstData}
                      className="mt-2"
                      color={"orange"}
                    />
                  </AccordionBody>
                </Accordion>
              </Card>
            );
          })()}
      </div>
      <div className="flex space-x-4 justify-between">
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 Owner</Text>
          <Metric>
            {(() => {
              const potentialOwnersCount = campaignLeads.filter(
                lead => lead.address.suspected_owners.length > 0
              ).length;
              const totalCount = campaignLeads.length;
              const percentage = ((potentialOwnersCount / totalCount) * 100).toFixed(0);
              return `${potentialOwnersCount} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>

        <Card className="max-w-xs mt-6" decoration="top" decorationColor="orange">
          <Text>Addresses with at least 1 Contender / Verified Owner</Text>
          <Metric>
            {(() => {
              const contenderOwnersCount = campaignLeads.filter(
                lead =>
                  lead.address.suspected_owners.filter(
                    owner =>
                      owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.CONTENDER ||
                      owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.VERIFIED
                  ).length > 0
              ).length;
              const totalCount = campaignLeads.length;
              const percentage = ((contenderOwnersCount / totalCount) * 100).toFixed(0);
              return `${contenderOwnersCount} / ${totalCount} (${percentage}%)`;
            })()}
          </Metric>
        </Card>
      </div>

      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  checked={selectAll.status}
                  onChange={() =>
                    setSelectAll(selectAll => ({
                      status: !selectAll.status,
                      changedByItemToggle: false,
                    }))
                  }
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (
                  {filteredLeads.reduce((acc, lead) => {
                    return (
                      acc + lead.address.suspected_owners.filter(owner => owner.isSelected).length
                    );
                  }, 0)}
                  )
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all owners that you can currently see
                </p>
              </div>
            </div>

            <Dropdown
              items={[
                {
                  icon: HandThumbDownIcon,
                  name: "Mark as Rejected",
                  function: async () => {
                    const suspectedOwnerAddressIds = filteredLeads
                      .flatMap(lead => lead.address.suspected_owners)
                      .filter(owner => owner.isSelected)
                      .map(owner => owner.matching_address.id);

                    if (suspectedOwnerAddressIds.length === 0) {
                      toast.error("Please select owners to perform operations on");
                      return;
                    }
                    await bulkSuspectedOwnerStatusUpdater(
                      suspectedOwnerAddressIds,
                      SUSPECTED_OWNER_STATUS.REJECTED
                    );
                  },
                },
                {
                  icon: ArchiveBoxIcon,
                  name: "Mark as Duplicate",
                  function: async () => {
                    const suspectedOwnerAddressIds = filteredLeads
                      .flatMap(lead => lead.address.suspected_owners)
                      .filter(owner => owner.isSelected)
                      .map(owner => owner.matching_address.id);

                    if (suspectedOwnerAddressIds.length === 0) {
                      toast.error("Please select owners to perform operations on");
                      return;
                    }
                    await bulkSuspectedOwnerStatusUpdater(
                      suspectedOwnerAddressIds,
                      SUSPECTED_OWNER_STATUS.DUPLICATE
                    );
                  },
                },
                {
                  icon: HandThumbUpIcon,
                  name: "Mark as Contender",
                  function: async () => {
                    const suspectedOwnerAddressIds = filteredLeads
                      .flatMap(lead => lead.address.suspected_owners)
                      .filter(owner => owner.isSelected)
                      .map(owner => owner.matching_address.id);

                    if (suspectedOwnerAddressIds.length === 0) {
                      toast.error("Please select owners to perform operations on");
                      return;
                    }
                    await bulkSuspectedOwnerStatusUpdater(
                      suspectedOwnerAddressIds,
                      SUSPECTED_OWNER_STATUS.CONTENDER
                    );
                  },
                },
                {
                  icon: CheckBadgeIcon,
                  name: "Mark as Verified",
                  function: async () => {
                    const suspectedOwnerAddressIds = filteredLeads
                      .flatMap(lead => lead.address.suspected_owners)
                      .filter(owner => owner.isSelected)
                      .map(owner => owner.matching_address.id);

                    if (suspectedOwnerAddressIds.length === 0) {
                      toast.error("Please select owners to perform operations on");
                      return;
                    }
                    await bulkSuspectedOwnerStatusUpdater(
                      suspectedOwnerAddressIds,
                      SUSPECTED_OWNER_STATUS.VERIFIED
                    );
                  },
                },
                {
                  icon: EyeIcon,
                  name: "Mark as Pending Review",
                  function: async () => {
                    const suspectedOwnerAddressIds = filteredLeads
                      .flatMap(lead => lead.address.suspected_owners)
                      .filter(owner => owner.isSelected)
                      .map(owner => owner.matching_address.id);

                    if (suspectedOwnerAddressIds.length === 0) {
                      toast.error("Please select owners to perform operations on");
                      return;
                    }
                    await bulkSuspectedOwnerStatusUpdater(
                      suspectedOwnerAddressIds,
                      SUSPECTED_OWNER_STATUS.NEW
                    );
                  },
                },
                {
                  icon: SwatchIcon,
                  name: "Clean Owners",
                  function: async () => {
                    const selectedCampaignLeadIds = filteredLeads.reduce((acc: number[], lead) => {
                      // If at least one owner in this lead is selected and the campaignLeadId is not already in the accumulator
                      if (
                        lead.address.suspected_owners.some(owner => owner.isSelected) &&
                        !acc.includes(lead.id)
                      ) {
                        acc.push(lead.id);
                      }

                      return acc;
                    }, []);

                    const uniqueSelectedCampaignLeadIds = Array.from(
                      new Set(selectedCampaignLeadIds)
                    );

                    if (uniqueSelectedCampaignLeadIds.length === 0) {
                      toast.error("Please select owners to perform operations on");
                      return;
                    }

                    if (!campaign) {
                      toast.error("Unexpected Error, Missing Campaign Id");
                      return;
                    }

                    await triggerTrackingGroup({
                      campaignId: campaign.id,
                      trackingItemValues: uniqueSelectedCampaignLeadIds.map(item => String(item)),
                      trackingGroupTypeId: TRACKING_GROUP_TYPE.CLEAN_OWNERS,
                    });
                  },
                },
                {
                  icon: MagnifyingGlassCircleIcon,
                  name: "Search Linkedin Profiles",
                  function: async () => {
                    const selectedOwnerAddressIds = filteredLeads
                      .flatMap(lead => lead.address.suspected_owners)
                      .filter(owner => owner.isSelected)
                      .map(owner => owner.suspected_owner_address.id);

                    if (selectedOwnerAddressIds.length === 0) {
                      toast.error("Please select owners to perform operations on");
                      return;
                    }

                    if (!campaign) {
                      toast.error("Unexpected Error, Missing Campaign Id");
                      return;
                    }
                    await triggerSearchGroup({
                      campaignId: campaign.id,
                      suspectedOwnerAddressIds: selectedOwnerAddressIds,
                    });
                  },
                },
                {
                  icon: WrenchIcon,
                  name: "Fix Owner Names",
                  function: async () => {
                    const selectedOwnerIds = filteredLeads
                      .flatMap(lead => lead.address.suspected_owners)
                      .filter(owner => owner.isSelected)
                      .map(owner => owner.id);

                    if (selectedOwnerIds.length === 0) {
                      toast.error("Please select owners to perform operations on");
                      return;
                    }

                    if (!campaign) {
                      toast.error("Unexpected Error, Missing Campaign Id");
                      return;
                    }
                    await fixOwnerNames({
                      campaignId: campaign.id,
                      ownerIds: selectedOwnerIds,
                    });
                  },
                },
                {
                  icon: ShareIcon,
                  name: "Export",
                  function: async () => {
                    exportToCSV({ campaignLeads: filteredLeads });
                  },
                },
              ]}
            />
          </div>
          <Divider className="mt-1 mb-0" />
          <div className="flex p-4 justify-center w-full items-center space-x-4">
            <Text>Displaying</Text>
            <Metric className="text-gray-500">{filteredOwners.length}</Metric>
            <Text>
              {" "}
              / {validatedCampaignAndOwnerAddressOwners.length} Owners{" "}
              <span className="italic">({totalOwners.length} total owners)</span>
            </Text>
          </div>
          <Divider className="mt-1 mb-0" />
          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">EPC:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="epc"
                options={suspectedOwnerEvaluationFilters.epc.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveEpcFilters(options);
                }}
                placeholder="Filter By EPC"
              />
            </div>
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Owner Source:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerSource"
                options={suspectedOwnerEvaluationFilters.ownerSource.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: number;
                  }[];
                  setActiveOwnerSourceFilters(options);
                }}
                placeholder="Filter By Owner Source"
              />
            </div>
          </div>
          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Number of Owners:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerCount"
                options={suspectedOwnerEvaluationFilters.ownerCount.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveOwnerCountFilters(options);
                }}
                placeholder="Filter By Number of Owners"
              />
            </div>
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Owner Tags:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerTags"
                options={suspectedOwnerEvaluationFilters.ownerTag.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveOwnerTagFilters(options);
                }}
                placeholder="Filter By Owner Tags"
              />
            </div>
          </div>
          <div className="flex flex-row w-full  justify-between">
            <div className="py-4 w-full px-4">
              <h2 className="text-sm font-medium text-gray-900 mb-2">Searches Conducted:</h2>

              <Select
                styles={filtersStyling}
                isMulti
                name="ownerSearches"
                options={suspectedOwnerEvaluationFilters.ownerSearch.options}
                onChange={selectedOptions => {
                  const options = [...selectedOptions] as unknown as {
                    label: string;
                    value: string;
                  }[];
                  setActiveOwnerSearchFilters(options);
                }}
                placeholder="Filter By Owner Searches"
              />
            </div>
          </div>
          <Divider className="mt-1 mb-0" />

          <div className="py-4 px-4">
            <RadioGroup value={selectedRadioOption} onChange={setSelectedRadioOption}>
              <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium text-gray-900 ">Show Only :</h2>
              </div>
              <div className="flex flex-row justify-between space-x-6 mt-2">
                {suspectedOwnerEvaluationRadioOptions.map(option => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-orange-500" : "",
                        checked
                          ? option.id === OWNER_EVALUATION_SHOW_TABS.REJECTED
                            ? "bg-pink-600 border-transparent text-white hover:bg-pink-700"
                            : option.id === OWNER_EVALUATION_SHOW_TABS.DUPLICATE
                            ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                            : option.id === OWNER_EVALUATION_SHOW_TABS.CONTENDER_AND_VERIFIED
                            ? "bg-green-600 border-transparent text-white hover:bg-green-700"
                            : "bg-orange-600 border-transparent text-white hover:bg-orange-700"
                          : option.id === OWNER_EVALUATION_SHOW_TABS.REJECTED
                          ? "bg-pink-50 border border-pink-500"
                          : option.id === OWNER_EVALUATION_SHOW_TABS.DUPLICATE
                          ? "bg-indigo-50 border border-indigo-500"
                          : option.id === OWNER_EVALUATION_SHOW_TABS.CONTENDER_AND_VERIFIED
                          ? "bg-green-50 border border-green-500"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border cursor-pointer focus:outline-none rounded-md py-1 px-1 flex items-center justify-center text-sm font-medium sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {filteredLeads.map(lead => {
          return (
            <Card className={"max-w-md mx-auto"}>
              <Flex className="mb-2">
                {epcStatuses.ownerOccupied.includes(lead.address.additional_info?.epc?.tenure) ? (
                  <Text color="green">
                    <Bold>Owner Occupied</Bold>
                  </Text>
                ) : (
                  <Text color="rose">
                    <Bold>
                      {lead.address.additional_info?.epc?.tenure
                        ? lead.address.additional_info?.epc?.tenure
                        : "No EPC Found"}
                    </Bold>
                  </Text>
                )}
              </Flex>

              <Flex justifyContent="start" className="space-x-1" alignItems="baseline">
                <Metric>{lead.stated_address}</Metric>
              </Flex>

              <Flex className="mt-4">
                <div>
                  <Text>Date of EPC</Text>
                  <Text color="orange">
                    <Bold>
                      {lead.address.additional_info?.epc?.lodgementDate
                        ? lead.address.additional_info?.epc?.lodgementDate
                        : "N/A"}
                    </Bold>
                  </Text>
                </div>
                <div>
                  <Text className="text-right">EPC Match Method</Text>
                  <Text className="text-right">
                    <Bold>
                      {lead.address.additional_info?.epc?.matchingMethod
                        ? lead.address.additional_info?.epc?.matchingMethod
                        : "N/A"}
                    </Bold>
                  </Text>
                </div>
              </Flex>

              {lead.address.additional_info?.epc?.lmkKey && (
                <a
                  href={`https://epc.opendatacommunities.org/domestic/certificate/${lead.address.additional_info?.epc?.lmkKey}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    type="button"
                    className="rounded-md mt-5 w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                  >
                    View EPC
                  </button>
                </a>
              )}

              {lead.address.property_sales.length > 0 && (
                <Card className="mt-5">
                  <>
                    <Flex className="" justifyContent="between">
                      <Text className="truncate">
                        <Bold>Transaction Date</Bold>
                      </Text>
                      <Text>Price</Text>
                    </Flex>
                    <List className="mt-2">
                      {lead.address.property_sales.map(sale => (
                        <ListItem className="flex flex-col" key={sale.id}>
                          <Flex flexDirection="row">
                            <a
                              href={`http://landregistry.data.gov.uk/data/ppi/transaction/${sale.external_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="rounded text-xs bg-white px-1.5 py-1 text-xs font-semibold cursor-pointer text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              {truncate(sale.stated_address, {
                                length: 30,
                                separator: /,? +/,
                                omission: "...",
                              })}
                            </a>

                            <Flex justifyContent="end" className="space-x-2">
                              <p className="text-xs">
                                {dayjs(sale.sale_date).format("DD/MM/YYYY")}
                              </p>

                              <Badge color="green" size="xs">
                                <p className="text-xs">
                                  {" "}
                                  £ {Intl.NumberFormat("GBP")
                                    .format(sale.sale_price)
                                    .toString()}{" "}
                                </p>
                              </Badge>
                            </Flex>
                          </Flex>
                        </ListItem>
                      ))}
                    </List>
                  </>
                </Card>
              )}
              <Flex className="w-full">
                <Bold className="mt-6 w-full pt-4 border-t">Potential Owners</Bold>
              </Flex>

              <AccordionList className="mt-6">
                {lead.address.suspected_owners.map((owner, idx) => {
                  const startDate = owner.start_date
                    ? dayjs(owner.start_date).format("MM/YYYY")
                    : "N/A";
                  const endDate = owner.end_date ? dayjs(owner.end_date).format("MM/YYYY") : "";
                  const date = `${startDate} - ${endDate}`;

                  return (
                    <Accordion
                      key={owner.id}
                      className={classNames(
                        owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.REJECTED
                          ? "border border-pink-500 bg-pink-50"
                          : owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.DUPLICATE
                          ? "border border-indigo-500 bg-indigo-50"
                          : owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.VERIFIED ||
                            owner.suspected_owner_status.id === SUSPECTED_OWNER_STATUS.CONTENDER
                          ? "border border-green-500 bg-green-50"
                          : // : owner.address_match_quality.name === "risky"
                            // ? "border border-red-500 bg-red-50"
                            "",
                        "overflow-visible"
                      )}
                    >
                      <AccordionHeader className="w-full">
                        <div className="flex justify-between w-full">
                          <div className="flex h-5 items-center mr-3">
                            <input
                              type="checkbox"
                              checked={owner.isSelected}
                              onClick={event => event.stopPropagation()}
                              onChange={event => {
                                event.stopPropagation();

                                handleIndividualChange(lead.id, owner.id);
                              }}
                              className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                            />
                          </div>
                          <Flex className="flex-col justify-start items-start">
                            <Text>
                              {owner.first_name} {owner.last_name} - ({date})
                            </Text>
                            <p className="text-xs text-gray-400">{owner.name}</p>
                          </Flex>

                          <div className="flex">
                            <Badge color="orange" className="mr-2">
                              {owner.suspected_owner_status.name}
                            </Badge>
                            <Badge>{owner.ownership_source.name}</Badge>
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody className="overflow-visible	">
                        <Text className="flex justify-between mb-2">
                          <Bold>Stated Address</Bold>
                        </Text>

                        <Text className="mt-2">{owner.stated_address}</Text>
                        <Text className="flex flex-row mt-2">
                          <Bold>Matched Addresses</Bold>
                        </Text>
                        <Card className="mt-3 p-0 divide-y bg-inherit divide-gray-100">
                          <li
                            key={owner.suspected_owner_address.id}
                            className={classNames(
                              owner.suspected_owner_address.is_correct_address
                                ? "bg-green-50"
                                : owner.suspected_owner_address.is_false_address
                                ? "bg-red-50"
                                : " bg-gray-50",
                              "flex items-center justify-between gap-x-6 p-2"
                            )}
                          >
                            <div className="min-w-0">
                              <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  {owner.suspected_owner_address.address.full_address}
                                </p>
                                <p
                                  className={classNames(
                                    owner.suspected_owner_address.is_correct_address
                                      ? "text-green-700 bg-green-50 ring-green-600/20"
                                      : owner.suspected_owner_address.is_false_address
                                      ? "text-red-700 bg-red-50 ring-red-600/20"
                                      : "text-gray-600 bg-gray-50 ring-gray-500/10",
                                    "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                                  )}
                                >
                                  {owner.suspected_owner_address.is_correct_address
                                    ? "Correct Address"
                                    : owner.suspected_owner_address.is_false_address
                                    ? "False Address"
                                    : "Not Checked"}
                                </p>
                              </div>
                              <div className="mt-1  gap-x-2 text-xs leading-5 text-gray-500">
                                <ProgressBar
                                  value={owner.suspected_owner_address.address_match_score}
                                  color="orange"
                                  className="mt-6"
                                />

                                <Flex className="mt-4">
                                  <div>
                                    <Text>Match Score</Text>
                                    <Text color="orange">
                                      <Bold>
                                        {owner.suspected_owner_address.address_match_score}
                                      </Bold>
                                    </Text>
                                  </div>
                                  <div>
                                    <Text className="text-right">Match Quality</Text>
                                    <Text className="text-right">
                                      <Bold>
                                        {owner.suspected_owner_address.address_match_quality.name}
                                      </Bold>
                                    </Text>
                                  </div>
                                </Flex>
                              </div>
                            </div>
                          </li>
                        </Card>

                        {owner.ownership_source.id === OWNERSHIP_SOURCE.COMPANIES_HOUSE && (
                          <Flex className="mt-6 flex-col pt-4 border-t">
                            <Flex className="mt-4">
                              <div>
                                <Text>Creation Date</Text>
                                <Text color="orange">
                                  <Bold>{owner.additional_info?.company?.dateOfCreation}</Bold>
                                </Text>
                              </div>
                              <div>
                                <Text className="text-right">Cessation Date</Text>
                                <Text className="text-right">
                                  <Bold>
                                    {owner.additional_info?.company?.dateOfDissolution
                                      ? owner.additional_info?.company?.dateOfDissolution
                                      : "Still Active"}
                                  </Bold>
                                </Text>
                              </div>
                            </Flex>

                            <a
                              href={`https://find-and-update.company-information.service.gov.uk/company/${owner.additional_info?.company?.companyNumber}`}
                              target="_blank"
                              className="w-full"
                              rel="noopener noreferrer"
                            >
                              <button
                                type="button"
                                className="rounded-md mt-5 w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                              >
                                View Companies House
                              </button>
                            </a>
                          </Flex>
                        )}

                        {owner.ownership_source.id === OWNERSHIP_SOURCE.PLANNING_APPLICATION && (
                          <Flex className="mt-6 flex-col pt-4 border-t">
                            <a
                              href={
                                owner.additional_info?.document?.documentsUrl ??
                                owner.additional_info?.contacts?.contactsUrl
                              }
                              target="_blank"
                              className="w-full"
                              rel="noopener noreferrer"
                            >
                              <button
                                type="button"
                                className="rounded-md mt-5 w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                              >
                                View Application Form
                              </button>
                            </a>
                          </Flex>
                        )}
                      </AccordionBody>
                    </Accordion>
                  );
                })}
              </AccordionList>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default SuspectedOwnersEvaluationTab;
