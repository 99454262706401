// useBulkAddToCampaignLeadProfile.ts

import { ApolloError } from "@apollo/client";
import { useBulkAddToCampaignLeadProfileMutation } from "../../../../../shared/infrastructure/graphQL/generatedTypes";
import { toast } from "react-hot-toast";
import { getCampaignById } from "../queries/getCampaignById";
import { getCampaignLeadProfiles } from "../queries/getCampaignLeadProfiles";

export interface BulkAddToCampaignLeadProfileHookResult {
  bulkAddToCampaignLeadProfile: (
    options: BulkAddToCampaignLeadProfileMutationOptions
  ) => Promise<void>;
}

export interface BulkAddToCampaignLeadProfileMutationOptions {
  input: Array<{
    campaignLeadId: number;
    digitalProfileId: number;
    ownerAddressProfileId: number;
  }>;
}

export const useBulkAddToCampaignLeadProfile = ({
  refetchCampaignId,
}: {
  refetchCampaignId: number;
}): BulkAddToCampaignLeadProfileHookResult => {
  const [bulkAddMutation] = useBulkAddToCampaignLeadProfileMutation({
    refetchQueries: [
      { query: getCampaignById, variables: { id: refetchCampaignId } },
      { query: getCampaignLeadProfiles, variables: { id: refetchCampaignId } },
    ],
    awaitRefetchQueries: true,

    onCompleted: () => {
      toast.success("Added to Overlead CSV successfully!");
    },
    onError: error => {
      toast.error(`Failed to add to Overlead CSV: ${error.message}`);
    },
  });

  return {
    bulkAddToCampaignLeadProfile: async ({
      input,
    }: BulkAddToCampaignLeadProfileMutationOptions) => {
      // Convert the camelCase input to snake_case for Hasura
      const snakeCaseInput = input.map(item => ({
        campaign_lead_id: item.campaignLeadId,
        digital_profile_id: item.digitalProfileId,
        owner_address_profile_id: item.ownerAddressProfileId,
      }));

      await bulkAddMutation({
        variables: {
          input: snakeCaseInput,
        },
      });
    },
  };
};
