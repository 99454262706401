import {
  GetCampaignByIdQuery,
  GetCampaignLeadProfilesQuery,
  GetCampaignTypesQuery,
  GetMessageSetsQuery,
} from "../../../../shared/infrastructure/graphQL/generatedTypes";

import { CampaignLead } from "../graphql/hooks/useCampaignById";
import { GetPostcodeUnitByIdLazyHookResult } from "../graphql/hooks/usePostcodeUnitById";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateMatchedAddressValues } from "../types";
import { updateMatchedAddressValidationSchema } from "../validation";
import Select from "react-select";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import {
  ADDRESS_MATCH_QUALITY,
  SUSPECTED_OWNER_STATUS,
} from "../../../../shared/internals/constants/models";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
  Metric,
  Flex,
  Button,
  Bold,
  Callout,
} from "@tremor/react";
import Papa from "papaparse";
import { constructIntroMessage, generateCSVData } from "../utils";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { classNames } from "../../../../shared/internals/utils";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  CalendarIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  CommandLineIcon,
  EllipsisVerticalIcon,
  MegaphoneIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/solid";
import EmptyMessagePanel from "./messaging/EmptyMessagePanel";
import { UpdateCampaignMessageSetMutationOptions } from "../graphql/hooks/useUpdateCampaignMessageSet";
import ActiveMessagePanel from "./messaging/ActiveMessagePanel";
import { DeleteCampaignMessageSetMutationOptions } from "../graphql/hooks/useDeleteCampaignMessageSet";
import { UseMutateAsyncFunction } from "react-query";
import { User } from "firebase/auth";
import { CreateMessageSetBody } from "../../doorKockingCampaign/api/createMessageSet/createMessageSet";

interface CampaignSummaryTabProps {
  campaign: GetCampaignByIdQuery["campaign_by_pk"];
  campaignLeads: CampaignLead[];
  campaignLeadProfiles: GetCampaignLeadProfilesQuery["campaign_lead_profile"];
  messageSets: GetMessageSetsQuery["message_set"];
  deleteCampaignMessageSet: (options: DeleteCampaignMessageSetMutationOptions) => Promise<void>;
  updateCampaignMessageSet: (options: UpdateCampaignMessageSetMutationOptions) => Promise<void>;
  createMessageSet: UseMutateAsyncFunction<number, any, CreateMessageSetBody, void>;
  createMessageSetLoading: boolean;
  campaignTypes: GetCampaignTypesQuery["campaign_type"];
  user: User;
  isFreelancer: boolean;
}

export const FinalLeadsTab = ({
  campaignLeads,
  campaign,
  campaignLeadProfiles,
  campaignTypes,
  createMessageSetLoading,
  user,
  isFreelancer,
  messageSets,
  createMessageSet,
  deleteCampaignMessageSet,
  updateCampaignMessageSet,
}: CampaignSummaryTabProps) => {
  const exportToCSV = () => {
    // Prepare the data
    const csvData = generateCSVData(campaignLeadProfiles, campaign);

    const csv = Papa.unparse(csvData);

    // Trigger download
    const blob = new Blob([`\uFEFF${csv}`], { type: "text/csv;charset=utf-8;" }); // Added BOM and charset
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "digital_profiles.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <div className="flex w-full space-x-4">
        <Callout
          title="Set Messaging for resultant Leads and Export into overlead"
          icon={ShieldCheckIcon}
          color="orange"
          className="mt-6 w-full"
        >
          <div>1. Review existing messaging</div>
          <div>
            2. If you're not happy with it you can edit the message, or select from an existing
            message set
          </div>
          <div>3. Export Leads into Overlead</div>
          <div>4. Double check all Snippets RESOLVED.</div>

          <div>5. Sync with Overlead</div>
        </Callout>
        <Card className="max-w-xs mt-6 " decoration="top" decorationColor="orange">
          <Text>Synced Profiles</Text>
          <Metric>
            {(() => {
              const syncedProfiles = campaignLeadProfiles.filter(
                profile => profile.external_id
              ).length;
              const totalProfiles = campaignLeadProfiles.length;
              const percentage = ((syncedProfiles / totalProfiles) * 100).toFixed(0);
              return `${syncedProfiles} / ${totalProfiles} (${percentage}%)`;
            })()}
          </Metric>
        </Card>
      </div>

      {!campaign?.message_set?.id && (
        <EmptyMessagePanel
          campaign={campaign}
          campaignLeadProfiles={campaignLeadProfiles}
          messageSets={messageSets}
          updateCampaignMessageSet={updateCampaignMessageSet}
          isFreelancer={isFreelancer}
        />
      )}

      {campaign?.message_set?.id && user && (
        <ActiveMessagePanel
          campaign={campaign}
          campaignLeadProfiles={campaignLeadProfiles}
          messageSets={messageSets}
          updateCampaignMessageSet={updateCampaignMessageSet}
          createMessageSet={createMessageSet}
          createMessageSetLoading={createMessageSetLoading}
          deleteCampaignMessageSet={deleteCampaignMessageSet}
          campaignTypes={campaignTypes}
          user={user}
          isFreelancer={isFreelancer}
        />
      )}

      <Card className="mt-10">
        <Flex>
          <Title>List Of Digital Profiles for this Campaign = {campaignLeadProfiles.length}</Title>
          <Button onClick={exportToCSV}>Export to CSV</Button>
        </Flex>
        {/* <Table className="mt-5">
          <TableHead>
            <TableRow>
              <TableHeaderCell>First Name</TableHeaderCell>
              <TableHeaderCell>Last Name</TableHeaderCell>
              <TableHeaderCell>Synced With Overlead?</TableHeaderCell>
              <TableHeaderCell>Profile Name</TableHeaderCell>
              <TableHeaderCell>Profile URL</TableHeaderCell>
              <TableHeaderCell>Address 1</TableHeaderCell>
              <TableHeaderCell>is Under Offer</TableHeaderCell>
              <TableHeaderCell>is Reduced</TableHeaderCell>
              <TableHeaderCell>Price</TableHeaderCell>

              <TableHeaderCell>Message 1</TableHeaderCell>
              <TableHeaderCell>Message 2</TableHeaderCell>
              <TableHeaderCell>Message 3</TableHeaderCell>
              <TableHeaderCell>Message 4</TableHeaderCell>
              <TableHeaderCell>Message 5</TableHeaderCell>

              <TableHeaderCell>Full Address</TableHeaderCell>
              <TableHeaderCell>Full PostCode</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Previous Contact Type</TableHeaderCell>
              <TableHeaderCell>Previous Contact - Notes</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {csvData.map(leadProfile => {
              return (
                <TableRow key={leadProfile.profileUrl}>
                  <TableCell>{leadProfile["First Name"]}</TableCell>
                  <TableCell>
                    <Text>{leadProfile["Last Name"]}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile["Synced with Overlead?"]}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile["Profile Name"]}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.profileUrl}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile["Address 1"]}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.isUnderOffer}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.isReduced}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.price}</Text>
                  </TableCell>

                  <TableCell>
                    <Text>{leadProfile.message1}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.message2}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.message3}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.message4}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.message5}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile["Full Address - CAMPAIGN"]}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile["Full PostCode"]}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile.Status}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile["Previous Contact Type"]}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{leadProfile["Previous Contact - Notes"]}</Text>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table> */}
      </Card>
    </>
  );
};
